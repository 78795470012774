import React from "react";
import { IoChevronDown } from "react-icons/io5";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import AssociatedProfilesCards from "./AssociatedProfilesCards";

export default function AssociatedProfilesContainer() {
  const { fetchBusinessDetails } = DirectoryDetails((state) => state);
  const associated_businesses = fetchBusinessDetails?.data?.associated_businesses
  return (
    <>
      {Array.isArray(associated_businesses) && associated_businesses.length > 0  && (
        <div className="w-full bg-white dark:bg-darkModeMain flex flex-col justify-center items-center rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder">
          <div className="flex w-full justify-between items-center h-[60px] min-h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
            <h2 className="p-4 text-sm font-semibold uppercase flex items-center  dark:border-darkModeBorder">
              Associated Profiles
            </h2>
            <IoChevronDown size={20} className="m-4" />
          </div>
          <AssociatedProfilesCards associated_businesses={associated_businesses}/>
        </div>
      )}
    </>
  );
}
