import React from "react"
import { IalloSimpleIcon } from "./Icons"

const IalloLogo = (props) => {
    const { notifcationDetails, styleClass, iconWidth, iconHeight } = props || { notifcationDetails: null, styleClass: null, iconWidth: null, iconHeight: null }
    return (
        <div className={`${styleClass} relative bg-fff w-[40pt] h-[40pt] flex justify-center  items-center rounded-lg  border border-gray-100`}>
            <IalloSimpleIcon width={iconWidth || "40px"} height={iconHeight || "20px"} />
            {notifcationDetails?.is_read == false ? (
                <div className="absolute -top-1 -right-1 rounded-full bg-ipink w-[12px] h-[12px] border-[1px]  border-gray-100  border-white"></div>
            ) : (
                ""
            )}
        </div>
    )
}
export default IalloLogo