import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.svg";
import androidStore from "../../assets/devices/android-store.png";
import appleStore from "../../assets/devices/apple-store.png";

import PropTypes from "prop-types";
import { HOME_PAGE, iphoneAppLink } from "../../utils/constants";
import {
  AppleAppStoreIcon,
  GoogleAppStoreIcon,
} from "../icons/applicationIcon";
import BackBtn from "../atom/BackBtn";
import { BackBtnIcon } from "../icons/Icons";

const RedirectionMobileApp = ({ children }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize(); // Check screen size on initial load
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const detectDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "IOS";
    }

    return "Other";
  };

  return (
    <>
      {isSmallScreen ? (
        <div className="h-[100dvh] w-full relative">
          {/* <div className="h-[10%] w-full "> */}
          <div className="mt-3 absolute top-1">
            <div
              className="text-sm font-semibold flex items-center cursor-pointer ml-2 p-2  border border-b border-gray-100-[1px] border-gray-100 w-[105px] rounded-lg "
              onClick={() =>
                window.location.pathname === "/"
                  ? HOME_PAGE
                  : window.history.back()
              }
            >
              <BackBtnIcon height={"30px"} width={"30px"} />

              <span className="pl-2">Back</span>
            </div>
            {/* </div> */}
          </div>
          <div className="flex  justify-center items-center h-full">
            <div className="w-full px-5 py-5 flex justify-center items-center flex-col">
              <div className="flex  pb-10 items-center">
                <img src={logo} alt="BrowserImage" className="h-24 " />
              </div>
              <p className="font-bold text-[20px] text-isearch">
                Use iAllo on Mobile
              </p>
              <span className="font-normal text-[14px] leading-[24px] text-isearch text-center mt-4">
                To use the iAllo app on your mobile device,
                <br />
                please download the offical mobile app
                <br />
                for the best experience
              </span>

              <button
                className="mt-8"
                onClick={() => window.open(iphoneAppLink, "_blank")}
              >
                <AppleAppStoreIcon />
              </button>
              <button className="mt-2">
                <GoogleAppStoreIcon />
              </button>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
      {/* Your other content here */}
    </>
  );
};
RedirectionMobileApp.propTypes = {
  children: PropTypes.node.isRequired,
};
export default RedirectionMobileApp;
