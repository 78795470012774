// Import necessary dependencies
import { create } from "zustand";
import AxiosApi from "../../../api/requestInterceptor";
import {
  BASE_URL,
  VITE_API_PAGE_LIMIT,
  VITE_DISTANCE_LIMIT,
} from "../../../../src/utils/constants";
import { locationDetails } from "../../../utils/constants";
import { apiFilterVallidation } from "../../../utils/api/apiFilter";

// Create a Zustand store for top Nav Search Data
export const TopNavSearchService = create((set, get) => ({
  // Initial state variables
  locationDetailsData: {},
  FilterDistance: VITE_DISTANCE_LIMIT,
  mySearchData: {}, // hold details of payload search Details
  mySuggestionsDetails: {}, // hold details of payload suggested Details
  suggestionSearchDetailsData: [], // Holds details of a suggestions search Details
  topNavSearchDetailsData: {}, // Holds details of a search Details
  searchCategory: {}, // Holds an array of Search Details
  locationSuggestionsDetails: {}, // Holds an array of Location Details
  suggestionsLastPage: "", // Holds last page number count
  loadingSearch: false, // Indicates whether data is currently being loaded
  loadingSearchData: false,
  error: null, // Holds any error that may occur during data fetching or processing

  // Async function to create a GET request to all Search directories
  topNavDirectorySearchApi: async (searchData) => {
    try {
      // Set loading to true while waiting for the API response
      const {
        keyword,
        LocationSortBy,
        LocationCategoryData,
        locationLat,
        locationLong,
        user_lat,
        user_lng,
        business_id,
        showEverywhere,
        location,
      } = searchData;
      set({ loadingSearchData: true });
      let locationData = locationDetails();
      let SearchDetails = {
        page: 1,
        lat: locationLat || locationData?.lat,
        lng: locationLong || locationData?.long,
        sort_by: LocationSortBy?.toLowerCase() || "nearest",
        categories: LocationCategoryData || "",
      };
      const { page, categories, sort_by, lat, lng } = SearchDetails;
      set({ mySearchData: SearchDetails || {} });
      // Make a GET request to get Search directories

      let urlArray = [
        {
          name: "limit",
          value: VITE_API_PAGE_LIMIT,
        },
        {
          name: "page",
          value: page,
        },
        {
          name: "lat",
          value: showEverywhere ? "" : lat,
        },
        {
          name: "lng",
          value: showEverywhere ? "" : lng,
        },
        {
          name: "sort_by",
          value: sort_by,
        },
        {
          name: "categories",
          value: categories,
        },
        {
          name: "keyword",
          value: keyword,
        },
        {
          name: "business_id",
          value: business_id,
        },
        {
          name: "user_lat",
          value: user_lat,
        },
        {
          name: "user_lng",
          value: user_lng,
        },
        {
          name: "location",
          value: location,
        },
      ];

      let filteredURL = apiFilterVallidation(
        urlArray,
        `${BASE_URL}businesses/directory/search?`
      );
      const response = await AxiosApi.get(filteredURL);
      // Destructure response data
      let { success, message } = response?.data || {};
      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingSearchData: false,
          topNavSearchDetailsData: response?.data || {},
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingSearchData: false, error: err });
      return err;
    }
  },
  historySearchApi: async (jsonData) => {
    try {
      return await AxiosApi.post(`${BASE_URL}users/search-histories`, jsonData)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("List search history error:", error);
      throw error;
    }
  },
  historySearchListApi: async () => {
    try {
      const response = await AxiosApi.get(`${BASE_URL}users/search-histories`);
      if (response?.data) {
        return response.data;
      } else {
        throw "error";
      }
    } catch (error) {
      console.error("List search history error:", error);
      throw error;
    }
  },
  // Async function to create a suggestions Details
  topNavDirectorySuggestionApi: async (searchData) => {
    try {
      // Set loading to true while waiting for the API response

      let {
        keyword,
        LocationCategoryData,
        locationLat,
        locationLong,
        currentPage,
      } = searchData;

      set({ loadingSearch: true });
      let SearchDetails = {
        page: currentPage || 1,
        categories: LocationCategoryData || "",
      };

      let { page, lat, lng } = SearchDetails;

      set({ mySuggestionsDetails: SearchDetails || {} });

      // Make a GET request to create suggestions
      let urlArray = [
        {
          name: "limit",
          value: VITE_API_PAGE_LIMIT,
        },
        {
          name: "page",
          value: page,
        },
        {
          name: "keyword",
          value: keyword,
        },
      ];

      let filteredURL = apiFilterVallidation(
        urlArray,
        `${BASE_URL}businesses/directory/suggestions?`
      );
      const response = await AxiosApi.get(filteredURL);

      // Destructure response data

      let { success, message, data } = response?.data || {};
      let { last_page } = response?.data?.meta || {};
      if (last_page == 0) {
        set(() => ({
          loadingSearch: false,
          suggestionsLastPage: last_page,
          suggestionSearchDetailsData: [],
        }));
      } else if (success) {
        // If the request is successful, update the state with the response data

        set((state) => ({
          loadingSearch: false,
          suggestionsLastPage: last_page,
          suggestionSearchDetailsData: response?.data?.data || [],
        }));

        return data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingSearch: false, error: err });
      return err;
    }
  },

  // Async function to create a search Details
  getSearchFilterCategoryApi: async (searchData) => {
    let searchDetails = { page: 1, default_category: true };
    let { page, default_category } = searchDetails;
    try {
      // Set loading to true while waiting for the API response
      set({ loading: true });
      // Make a POST request to create a new chat
      const response = await AxiosApi.get(
        `${BASE_URL}categories?page=${page}&limit=${VITE_API_PAGE_LIMIT}&default_category=${default_category}`,
        searchData
      );

      // Destructure response data
      let { success, message, data } = response?.data || {};

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loading: false,
          searchCategory: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loading: false, error: err });
      return err;
    }
  },

  getLocationDetails: (locationData) =>
    set({
      locationDetailsData: locationData,
    }),
  setFilterDistance: (distance) =>
    set({
      FilterDistance: distance,
    }),

  // Async function to create a search Details
  resetSearch: async (searchData) => {
    await set({
      loading: false,
      suggestionSearchDetailsData: [],
    });
  },
}));
