import ReactDOM from "react-dom";
import { IoIosClose } from "react-icons/io";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import { deleteAccountApi } from "../../services/deleteAccountService";
import { userDetails } from "../../routes/ProtectedRoutes";

export default function AccountDeleteModal({ onClose }) {
  const userDetailsProfile = userDetails();

  const handleDeleteAccount = async () => {
    await deleteAccountApi();
  };

  return ReactDOM.createPortal(
    <div className="relative z-40 top-0  text-sm flex items-center justify-center w-full h-screen bg-black opacity-90 overflow-hidden">
      <div className="w-[580px] justify-between  z-40 bg-white rounded-lg dark:bg-darkModeMain flex flex-col items-center space-y-3">
        <div className="w-full items-center z-40 flex justify-between border-b border-gray-100 dark:border-darkModeBorder min-h-[60px]">
          <h1 className="px-4 font-semibold uppercase">Delete Account </h1>
          <IoIosClose size={30} onClick={onClose} className="mx-3" />
        </div>
        <div className="flex flex-col w-full z-40 px-4 ">
          <div className="flex items-center z-40 justify-between w-full ">
            <div className="flex items-center z-40 gap-3 mb-3 ">
              <DirectoryImageInitials className="max-w-[40px]  max-h-[40px] rounded-full" />
              <p className="font-semibold ">{userDetailsProfile?.first_name}</p>
              <p>
                {userDetailsProfile?.country_code}-
                {userDetailsProfile?.phone_number}
              </p>
            </div>
          </div>
          <div className="w-full  h-[1px] bg-gray-200 dark:bg-darkModeBorder "></div>
          <div className="space-y-6">
            <div className="flex justify-center mt-6 space-y -6">
              <p className="w-full  ">
                This action cannot be undone. It will permanently delete your
                entire account, saved businesses, and all your identities. We
                will refund any remaining funds within 7-10 banking days.
              </p>
            </div>

            <div className="flex justify-end">
              <button
                onClick={onClose}
                className="w-[200px] h-[45px] border-[1px] border-black dark:border-white rounded-full uppercase font-[500] hover:font-bold mr-2"
              >
                Nevermind
              </button>
              <button
                onClick={handleDeleteAccount}
                style={{ marginBottom: 24 }}
                className="w-[200px] h-[45px] rounded-full uppercase font-[500] bg-black text-white hover:font-bold dark:border-white rounded-full uppercase font-[500] hover:font-bold mr-2"
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal-root")
  );
}
