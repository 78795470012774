import React, { useEffect, useState } from "react";
import BusinessProfileContainer from "../../business-profile/BusinessProfileContainer";
import { FaBookmark, FaChevronRight } from "react-icons/fa6";
import { FiShare } from "react-icons/fi";
import calendar from "../../../assets/icons/Calendar.svg";
import { MdVerified } from "react-icons/md";
import { TicketsDetails } from "../../../services/tickets/TicketDetails";
import NoImageFound from "../../../assets/NoImageFound.jpg";
import { useNavigate } from "react-router-dom";
import EditableText from "../../EditableText";
import CloseTicketPopup from "../CloseTicketPopup";
import { IoAddOutline } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";
import { IoIosClose } from "react-icons/io";

export default function CallEnded() {
  const [showCloseTicketPopup, setShowCloseTicketPopup] = useState(false);
  const [businessLocationsData, setBusinessLocationsData] = useState([]);
  const [showBusinessProfile, setShowBusinessProfile] = useState(false);
  const navigate = useNavigate();

  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const handleEmojiClick = (index) => {
    setSelectedEmoji(index);
  };

  const handleShowCloseTicketPopup = () => {
    setShowCloseTicketPopup(true);
  };

  const { listSingleTicketDetails, listBusinessLocationsDetailsApi } =
    TicketsDetails((state) => state);
  let { data } = listSingleTicketDetails || {};
  let { business } = data || {};
  let businessId = data?.business_id;

  let getBusinessLocationDetailsApi = () => {
    if (businessId) {
      listBusinessLocationsDetailsApi(businessId).then((data) => {
        if (data?.data && data?.data?.length != 0) {
          setBusinessLocationsData(data?.data || []);
        }
      });
    }
  };
  const handleChevronRightClick = () => {
    navigate("/business-profile/business_id");
  };
  const handleBackToTicketsClick = () => {
    navigate("/tickets");
  };

  useEffect(() => {
    getBusinessLocationDetailsApi();
  }, [businessId]);

  return (
    <div className="flex w-full gap-4 mt-1 text-sm">
      {showBusinessProfile && (
        <div className="absolute z-50 w-full -top-20 ">
          {" "}
          <BusinessProfileContainer />
        </div>
      )}

      <div className="relative w-2/3 my-1 bg-white dark:bg-darkModeMain rounded-lg  flex flex-col border-[1px]  border-gray-100  dark:border-darkModeBorder">
        <div className="w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-[60px] items-center  flex">
          <h1 className="px-4 font-semibold uppercase">Call Ended</h1>
        </div>
        <div className="h-[70px] w-full flex justify-between items-center px-6 py-10 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
          <div className="flex items-center gap-2">
            <img
              src={business?.upload?.file_path || NoImageFound}
              alt={business?.name}
              width={40}
              height={40}
              className="min-w-[40px] min-h-[40px] rounded object-cover"
            />
            <p className="font-semibold">
              {business?.name ? business?.name : ""}
            </p>

            {data?.is_verified && <MdVerified color="#48CFFF" size={16} />}
          </div>
          <div className="flex items-center gap-2">
            <FiShare size={20} />
            {business?.saved_business_id && (
              <FaBookmark size={20} color="#4C5FEF" />
            )}
            <button onClick={handleChevronRightClick}>
              <FaChevronRight size={22} />
            </button>
          </div>
        </div>
        <div className="relative flex flex-col w-full py-2 bg-white dark:bg-darkModeMain ">
          <EditableText initialText="Initial text value" />
        </div>
        <div className="h-[85px] w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex items-center justify-between">
          <div className="flex gap-6 px-5">
            <div className="flex flex-col space-y-1">
              <p>Ticket</p>
              <p className="font-semibold">
                # <span>9883745</span>
              </p>
            </div>
            <div className="flex flex-col space-y-1">
              <p>Created</p>
              <p className="font-semibold">
                Jan 7, 2023 - <span>05:45 PM</span>
              </p>
            </div>
            <div className="flex flex-col space-y-1">
              <p>Ticket status</p>
              <p className="font-semibold">Open</p>
            </div>
          </div>
          <button
            onClick={() => {
              setShowCloseTicketPopup(true);
            }}
            className="text-[13px] mx-5 rounded-full h-[45px] w-[150px] uppercase border-2 border-b border-gray-100lack dark:border-white"
          >
            Close Ticket
          </button>
        </div>
        <div className="min-h-[90px] justify-center flex-col flex w-full border-b border-gray-100-[1px] border-gray-100 space-y-2 dark:border-darkModeBorder">
          <p className="px-5">
            My home internet stopped working. There may be a problem with the
            router.
          </p>
          <button className="w-0 mx-5 font-semibold">Edit</button>
        </div>
        <button
          onClick={handleBackToTicketsClick}
          className="hover:border-2 hover:border-ipurple hover:text-ipurple hover:bg-inherit absolute bottom-5 left-5 rounded-full h-[45px] w-[200px] bg-ipurple uppercase text-[13px] text-white"
        >
          Back to tickets
        </button>
      </div>

      <div className="flex flex-col w-1/3 my-1 space-y-4">
        <div className="bg-white dark:bg-darkModeMain rounded-lg h-[270px]  border-[1px]  border-gray-100  dark:border-darkModeBorder flex flex-col">
          <div className="w-full min-h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex items-center">
            <p className="px-5 font-semibold uppercase">
              Rate your <span>Verizon </span> experience
            </p>
          </div>
          <div className="flex items-center gap-4 px-5 py-4">
            {["😡", "😔", "😐", "🙂", "😍"].map((emoji, index) => (
              <button
                key={index}
                className={`w-[60px] h-[60px] text-[33px]  flex justify-center items-center rounded-lg border-2 dark:border-darkModeBorder border-neutral-100 ${
                  selectedEmoji === index ? "bg-ipurple" : ""
                }`}
                onClick={() => handleEmojiClick(index)}
              >
                {emoji}
              </button>
            ))}
          </div>
          <textarea
            placeholder="Type something here..."
            className="mx-6 bg-inherit rounded-lg p-4 border-neutral-100 dark:border-darkModeBorder border-opacity-50 h-[90px] "
          />
        </div>
        <div className="bg-white dark:bg-darkModeMain  rounded-lg h-[130px] items-center  border-[1px]  border-gray-100  dark:border-darkModeBorder flex flex-col">
          <div className="w-full h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex items-center">
            <p className="px-5 font-semibold uppercase">Schedule a call</p>
          </div>
          <div className="flex items-center justify-between w-full p-5">
            <div className="flex items-center gap-3 ">
              <img src={calendar} alt="calendar icon" width={25} height={25} />
              <p className="">
                Today at <span> 10:00 PM</span>
              </p>
            </div>
            <IoIosClose size={30} />
          </div>
        </div>
        <div className="bg-white dark:bg-darkModeMain  rounded-lg h-[130px]  border-[1px]  border-gray-100  dark:border-darkModeBorder flex flex-col">
          <div className="w-full h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex items-center">
            <p className="px-5 font-semibold uppercase">Reminder</p>
          </div>
          <div className="flex items-center gap-3 p-5">
            <IoAddOutline size={23} />
            <p className="font-semibold">Add reminder</p>
          </div>
        </div>
        <div className="bg-white overflow-hidden dark:bg-darkModeMain  rounded-lg h-[130px] border-[1px]  border-gray-100  dark:border-darkModeBorder flex flex-col">
          <div className="w-full min-h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex items-center">
            <p className="px-5 font-semibold uppercase">Call details</p>
          </div>
          <div className="flex justify-between px-5 py-3">
            <div className="flex gap-3">
              <LuPhone size={17} />
              <div className="flex flex-col space-y-1">
                <p className="font-semibold">1-800-837-4966</p>
                <p className="text-[13px]">00:14:15</p>
              </div>
            </div>
            <p className="text-[13px]">
              June 7. 2024 - <span> 04:55 PM</span>
            </p>
          </div>
        </div>
      </div>
      {showCloseTicketPopup && (
        <CloseTicketPopup onClose={() => setShowCloseTicketPopup(false)} />
      )}
    </div>
  );
}
