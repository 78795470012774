import React from 'react'
import {LuPhone} from 'react-icons/lu'
import { BiSupport } from "react-icons/bi";
import Border from '../../assets/Border.webp'
import {PiDotsThreeVerticalBold} from 'react-icons/pi'
import Avatar from '../Avatar';
import InvitedDropdown from '../profile/InvitedDropdown';

export default function PeopleCard() {
  return (
    <div className='flex flex-col w-full'>
    <div className='w-full border-b border-gray-100-[1px] border-gray-100 h-[70px] dark:border-darkModeBorder flex items-center justify-between p-6'>
        <div className='flex items-center gap-3'>
            <button><LuPhone size={25} /></button>
            <button className='rounded-full w-[40px] h-[40px] border-[1px]  border-gray-100  dark:border-darkModeBG flex items-center justify-center'>
                <BiSupport size={23} />
            </button>
            <div className='flex flex-col ml-1'>
                <p className='font-semibold'>Jeff Williams</p>
                <p>Agent</p>
            </div>
       </div>
       <InvitedDropdown />
    </div>
    <div className='w-full border-b border-gray-100-[1px] border-gray-100 h-[70px] dark:border-darkModeBorder flex items-center p-6'>
        <div className='flex items-center gap-3'>
            <button><LuPhone size={25} /></button>
            <div className='mt-3 -mr-4'>
            <Avatar />
            </div>
            
            <div className='flex flex-col ml-1'>
                <p className='font-semibold'>Jesse Jackson</p>
                <p>You</p>
            </div>
       </div>

    </div>
    </div>
  )
}
