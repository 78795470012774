import { IoIosSearch } from "react-icons/io";
import DropdownCard from "./DropdownCard";

export default function SearchDropdown(props) {
  let {
    suggestionsData,
    setCurrentPage,
    currentPage,
    dropdownRef,
    searchInDashboard,
    formattedAddress,
    setIsDropdownVisible,
    searchHistory,
  } = props || {};

  return (
    <div className="flex dark:divide-darkModeBG  w-full flex-col  h-full shadow-black shadow-lg bg-white dark:bg-darkModeBG text-sm  flex-shrink-1 flex-grow-0  border-neutral-100 dark:border-darkModeBG border-[1px]  border-gray-100  rounded-b-lg  mx-2 overflow-auto !z-[9999]">
      <DropdownCard
        searchHistory={searchHistory}
        suggestionsData={suggestionsData}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        dropdownRef={dropdownRef}
        searchInDashboard={searchInDashboard}
        formattedAddress={formattedAddress}
        setIsDropdownVisible={setIsDropdownVisible}
        {...props}
      />
    </div>
  );
}
// export default function historyDropDown(){

// }
