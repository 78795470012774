import React, {useState} from 'react'
import { IoOptionsOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { RxPlus } from 'react-icons/rx';
import IdentitiesAccordian from '../IdentitiesAccordian';

import identities from '../../../data/identities';

export default function IdentitiesView() {
    const [openAccordionId, setOpenAccordionId] = useState(null);

    const handleAccordionToggle = (accordionId) => {
        setOpenAccordionId(openAccordionId === accordionId ? null : accordionId);
    };
  return (
    <div className='w-full  text-sm min-h-[700px] border-[1px]  border-gray-100  dark:border-darkModeBorder my-6  bg-white rounded-lg dark:bg-darkModeMain flex flex-col'>
    <div className='flex justify-between items-center w-full h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder'>
      <div className='flex items-center gap-4 p-6 text-sm'>
          <p className='font-semibold uppercase'>Identities</p>
          <button className='flex items-center gap-1 ml-4 text-ipurple hover:text-ipurple'>
              <p>All</p>
              <p>15</p>
          </button>
          <button className='flex items-center gap-1 hover:text-ipurple'>
              <p>Shared</p>
              <p>6</p>
          </button>
          <button className='flex items-center gap-1 hover:text-ipurple'>
              <p>Archived</p>
              <p>2</p>
          </button>
      </div>
      <div className='flex items-center gap-4 p-4 mr-6'>
              
              <IoOptionsOutline size={20} />
              <IoIosSearch size={20} />
              <RxPlus size={20} />
          </div>
    </div>
    {identities.map((identity) => (
          <IdentitiesAccordian
            key={identity.id}
            identity={identity}
            isOpen={identity.id === openAccordionId}
            onToggle={() => handleAccordionToggle(identity.id)}
          />
        ))}
    </div>
  )
}
