import axios from "axios";
import { BASE_URL, deviceDetails } from "../utils/constants";
import { getToken } from "../routes/ProtectedRoutes";
import toast from "react-hot-toast";
import { clearLocalStorage } from "../utils/common";

const AxiosApi = axios.create({
  baseURL: BASE_URL,
});

AxiosApi.interceptors.request.use(
  (config) => {
    const userToken = getToken();
    const deviceData = deviceDetails();
    if (userToken) {
      config.headers["Authorization"] = `Bearer ${userToken}`;
      config.headers["device-id"] = deviceData?.deviceID;
      config.headers["device-name"] = deviceData?.deviceName;
    } else {
      config.headers["device-id"] = deviceData?.deviceID;
      config.headers["device-name"] = deviceData?.deviceName;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosApi.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error?.response?.status == 401) {
      const userToken = getToken();

      setTimeout(
        () => {
          toast.error("Unauthorized");
          if (userToken) {
            clearLocalStorage();
            window.location.href = "/";
          }
        },

        1000
      );
    }

    return Promise.reject(error);
  }
);

export default AxiosApi;
