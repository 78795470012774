import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactsCard from "../ContactsCard";
import { MdVerified } from "react-icons/md";
import { FiShare } from "react-icons/fi";
import { FaBookmark, FaChevronRight } from "react-icons/fa6";
import { GoArrowRight } from "react-icons/go";
import ReactMap from "../ReactMap";
import LocationCard from "../LocationCard";
import { TicketsDetails } from "../../../services/tickets/TicketDetails";
import NoImageFound from "../../../assets/NoImageFound.jpg";
import TicketsContainer from "../TicketsContainer";
import BusinessProfileContainer from "../../business-profile/BusinessProfileContainer";
import DirectoryImageInitials from "../../atom/directoryImageInitials";
import { MAP_KEYS } from "../../../utils/constants";
import { Autocomplete } from "@react-google-maps/api";

export default function BusinessView(props) {
  let { setActiveTab } = props || {};
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [businessLocationsData, setBusinessLocationsData] = useState([]);
  const [showBusinessProfile, setShowBusinessProfile] = useState(false);
  const navigate = useNavigate();

  const { listSingleTicketDetails, listBusinessLocationsDetailsApi } =
    TicketsDetails((state) => state);
  let { data } = listSingleTicketDetails || {};
  let { business } = data || {};
  let businessId = data?.business_id;
  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
  };

  let getBusinessLocationDetailsApi = () => {
    if (businessId) {
      listBusinessLocationsDetailsApi(businessId).then((data) => {
        if (data?.data && data?.data?.length != 0) {
          setBusinessLocationsData(data?.data || []);
        }
      });
    }
  };
  const handleChevronRightClick = () => {
    navigate(`/business/${businessId}`);
    //setShowBusinessProfile(!showBusinessProfile);
  };

  useEffect(() => {
    getBusinessLocationDetailsApi();
  }, [businessId]);

  return (
    <div className="flex w-full pt-2 mb-8 overflow-x-hidden text-sm">
      <div className="flex flex-col w-1/2 mx-3 lg:w-2/3">
        <div className="w-full bg-white pb-4  rounded-lg  border-[1px]  border-gray-100  dark:bg-darkModeMain dark:border-darkModeBorder min-h-[130px] flex flex-col">
          <div className="h-[60px] min-h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder w-full flex items-center">
            <p className="px-4 font-bold uppercase">Business</p>
          </div>
          <div className="h-[70px] min-h-[70px] w-full flex justify-between items-center px-6 ">
            <div className="flex items-center gap-2">
              <DirectoryImageInitials
                imageUrl={business?.upload?.file_path}
                alt={business?.name}
                name={business?.name}
                boxColor={business?.business_color?.toLowerCase()}
                className={`max-w-[55px]  max-h-[55px] `}
              />

              <p className="font-semibold">
                {business?.name ? business?.name : ""}
              </p>

              {data?.is_verified && <MdVerified color="#48CFFF" size={16} />}
            </div>
            <div className="flex items-center gap-2">
              <FiShare size={20} />
              {business?.saved_business_id && (
                <FaBookmark size={20} color="#4C5FEF" />
              )}
              <button onClick={handleChevronRightClick}>
                <FaChevronRight size={22} />
              </button>
            </div>
          </div>
        </div>

        <TicketsContainer
          business={data}
          onTicketClick={handleTicketClick}
          setActiveTab={setActiveTab}
        />
      </div>
      <div className="flex flex-col w-1/2 h-screen ml-2 lg:w-1/3">
        <div className="w-full flex  flex-col  mr-8 rounded-lg border-[1px]  border-gray-100  bg-white dark:bg-darkModeMain dark:border-darkModeBorder">
          <div className="h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder w-full flex items-center">
            <p className="px-4 font-bold uppercase">Contacts</p>
          </div>
          <ContactsCard data={data} showOpt={true} />
        </div>
        <div className="w-full flex flex-col my-4 mr-8 rounded-lg border-[1px]  border-gray-100  bg-white dark:bg-darkModeMain dark:border-darkModeBorder">
          <div className="h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder w-full flex items-center">
            <p className="p-4 font-bold uppercase">Hours</p>
          </div>
          <div className="flex items-center justify-between w-full px-4 py-2 mt-2 text-sm">
            <p className="font-semibold">Monday-Friday</p>
            <p>08:00 AM - 07:00 PM</p>
          </div>
          <div className="flex items-center justify-between w-full px-4 text-sm">
            <p className="font-semibold">Saturday</p>
            <p>09:00 AM - 05:00 PM</p>
          </div>
          <div className="flex items-center justify-between w-full px-4 py-2 mb-4 text-sm">
            <p className="font-semibold">Sunday</p>
            <p>09:00 AM - 06:00 PM</p>
          </div>
        </div>

        <div className="w-full flex  flex-col  mr-8 rounded-lg border-[1px]  border-gray-100  bg-white dark:bg-darkModeMain dark:border-darkModeBorder">
          <div className="h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder w-full flex justify-between items-center">
            <p className="p-4 font-bold uppercase">Locations</p>
            <button className="flex items-center gap-2 p-4">
              <p>Show all</p>
              <GoArrowRight size={20} />
            </button>
          </div>

          <div className="h-[220px] object-contain w-full flex overflow-hidden">
            <ReactMap />
          </div>
          <LocationCard businessLocationsData={businessLocationsData} />
        </div>
      </div>
    </div>
  );
}
