import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";
import { BASE_URL, deviceDetails } from "../../utils/constants";
import callAxios from "../../api/requestIntercetorForm";
import { retrieveAuthToken } from "../authService";

export const CompaniesService = create((set) => ({
  defaultUserCompanyPhone: {},
  companyData: {},
  phoneSystemData: {},
  teamInfoData: {},
  removeTeamInfoIconData: {},
  imageUpload: {},
  isLoading: false,
  loadingCreateCompany: false,
  error: null,
  companyPhoneNumbersLoading: false,
  companyPhoneNumberData: {},
  companyPhoneNumbers: [],
  companyEmployeeData: {},
  companyEmployeesData: [],

  createCompanyApi: async (userData, tokenDetails) => {
    try {
      set({ loadingCreateCompany: true });
      const DeviceData = deviceDetails();
      const response = await callAxios.post(`companies`, userData, {
        headers: {
          Authorization: `Bearer ${tokenDetails}`,
          "device-id": DeviceData?.deviceID,
          "device-name": DeviceData?.deviceName,
        },
      });
      const { success, data } = response?.data || {};
      if (success) {
        set({ loadingCreateCompany: false, companyData: data });
        return response?.data;
      } else {
        throw response?.data;
      }
    } catch (err) {
      set({ loadingCreateCompany: false, error: err });
      return err;
    }
  },
  phoneSystemApi: async (userData, tokenDetails) => {
    try {
      const DeviceData = deviceDetails();
      set({ loading: true });
      const response = await AxiosApi.get(`phone-systems`, {
        headers: {
          Authorization: `Bearer ${tokenDetails}`,
          "device-id": DeviceData?.deviceID,
          "device-name": DeviceData?.deviceName,
        },
      });
      const { success, message, data } = response?.data;
      if (success) {
        set({ loading: false, phoneSystemData: data });
        return data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loading: false, error: err });
      return err;
    }
  },
  removeTeamInfoIconApi: async (id) => {
    try {
      const token = retrieveAuthToken();

      set({ loading: true });
      const response = await AxiosApi.delete(`companies/${id}/image`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const { success, message, data } = response?.data || {};

      if (success) {
        set({ loading: false, removeTeamInfoIconData: data });
        return data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loading: false, error: err });
      return err;
    }
  },
  addUpdateTeamInfoIconApi: async (formData) => {
    try {
      const token = retrieveAuthToken();
      set({ isLoading: true });
      const response = await AxiosApi.post(`companies/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, message, data } = response?.data;
      if (success) {
        set({ isLoading: false, imageUpload: data });
        return data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ isLoading: false, error: err });
      return err;
    }
  },
  editTeamInfoApi: async (teamInfoDetails) => {
    const token = retrieveAuthToken();
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.put(
        `${BASE_URL}companies`,
        teamInfoDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          teamInfoData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
  companyDetailsApi: async () => {
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.get(`${BASE_URL}companies`);

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          companyData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },

  //Company phone numbers
  companyPhoneNumberCreateApi: async (companyPhoneNumberDetails) => {
    const token = retrieveAuthToken();

    try {
      set({ companyPhoneNumbersLoading: true });

      const response = await AxiosApi.post(
        `${BASE_URL}company-phone-numbers`,
        companyPhoneNumberDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { success, message, data } = response?.data;

      if (success) {
        set({
          companyPhoneNumbersLoading: false,
          companyPhoneNumberData: data,
        });
        return data;
      } else {
        const errorMessage = message || "Failed to add phone number.";
        set({ companyPhoneNumbersLoading: false, error: errorMessage });
        throw new Error(errorMessage);
      }
    } catch (error) {
      set({
        companyPhoneNumbersLoading: false,
        error: error.message || "An unexpected error occurred.",
      });
      throw error;
    }
  },

  companyPhoneNumberVerifyCodeApi: async (id, verifyCodeDetails) => {
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.post(
        `${BASE_URL}company-phone-numbers/${id}/verify`,
        verifyCodeDetails,
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          companyPhoneNumberData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      if (error.response?.data.message === "INVALID VERIFICATION CODE")
        set({ isLoading: false, error: error });
      throw error;
    }
  },
  companyPhoneNumberResendCodeApi: async (id) => {
    const token = retrieveAuthToken();
    try {
      set({
        companyPhoneNumbersLoading: true,
      });
      const response = await AxiosApi.post(
        `${BASE_URL}company-phone-numbers/${id}/resend-code`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          companyPhoneNumbersLoading: false,
          companyPhoneNumberData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ companyPhoneNumbersLoading: false, error: error });
      throw error;
    }
  },
  companyPhoneNumberListApi: async (id, queryParams) => {
    try {
      set({
        companyPhoneNumbersLoading: true,
      });
      const query = new URLSearchParams(queryParams).toString();

      const response = await AxiosApi.get(
        `companies/${id}/company-phone-numbers${query ? `?${query}` : ""}`,
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          companyPhoneNumbersLoading: false,
          companyPhoneNumbers: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ companyPhoneNumbersLoading: false, error: error });
      throw error;
    }
  },
  companyPhoneNumberGetApi: async (id) => {
    try {
      set({
        companyPhoneNumbersLoading: true,
      });

      const response = await AxiosApi.get(`company-phone-numbers/${id}`);

      const { success, message, data } = response?.data;
      if (success) {
        set({
          companyPhoneNumbersLoading: false,
          companyPhoneNumberData: data,
        });
        return data;
      } else {
        throw { success: success, message: message };
      }
    } catch (error) {
      set({ companyPhoneNumbersLoading: false, error: error });
      throw error;
    }
  },
  companyPhoneNumberDeleteApi: async (id) => {
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.delete(
        `${BASE_URL}company-phone-numbers/${id}`,
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          companyPhoneNumberData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
  companyPhoneNumberEditApi: async (id, companyPhoneNumberDetails) => {
    const token = retrieveAuthToken();
    try {
      set({
        companyPhoneNumbersLoading: true,
      });
      const response = await AxiosApi.put(
        `${BASE_URL}company-phone-numbers/${id}`,
        companyPhoneNumberDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          companyPhoneNumbersLoading: false,
          companyPhoneNumberData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ companyPhoneNumbersLoading: false, error: error });
      throw error;
    }
  },

  //User Company Phone Number
  getDefaultUsercompanyPhoneApi: async () => {
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.get(
        `${BASE_URL}company-phone-numbers/user-phone`,
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          defaultUserCompanyPhone: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
  defaultUsercompanyPhoneEditApi: async (id) => {
    const token = retrieveAuthToken();
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.put(
        `${BASE_URL}company-phone-numbers/${id}/user-phone`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          defaultUserCompanyPhone: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },

  // Company Employee
  companyEmployeeCreateApi: async (companyEmployeeDetails) => {
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.post(
        `${BASE_URL}company-employees`,
        companyEmployeeDetails,
        {},
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          companyEmployeeData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },

  // list all company employees
  companyEmployeesListApi: async (queryParams = {}) => {
    // todo: add pagination
    try {
      set({
        isLoading: true,
      });
      const params = new URLSearchParams(queryParams).toString();

      const response = await AxiosApi.get(
        `${BASE_URL}company-employees${params ? `?${params}` : ""}`,
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          companyEmployeesData: data,
        });

        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
  // deactivate company employee account
  companyEmployeeDeleteApi: async (id) => {
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.delete(
        `${BASE_URL}company-employees/${id}`,
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          companyEmployeeData: data,
        });
        return { success: success, message: message };
      } else {
        throw { success: success, message: message };
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
}));
