import border from "../assets/Border.webp";
import NoImageFound from "../assets/NoImageFound.jpg";
import Online from "../assets/icons/Online.svg";
export default function Avatar({ pathSrc, className }) {
  return (
    <div
      className={`rounded-full w-[50px] h-[60px] flex items-center ${className}`}
    >
      <div
        className={`relative w-[50px] h-full flex items-center justify-center`}
      >
        <img src={border} alt="border" className={`w-[45px] h-[45px] flex`} />

        <img
          src={Online}
          alt="online icon"
          className={`absolute right-0 top-2 w-[15px] h-[15px]`}
        />

        <img
          src={pathSrc || NoImageFound}
          className={`absolute flex object-cover z-30 w-[34px] h-[34px] rounded-full`}
          alt="Avatar"
        />
      </div>
    </div>
  );
}
