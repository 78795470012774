import { useEffect, useRef, useState } from "react";
import {
  PiDotsThreeVerticalBold,
  PiArrowCircleDownLight,
} from "react-icons/pi";
import { IoExpand } from "react-icons/io5";
import { FiTrash } from "react-icons/fi";
import FilePreview from "./FilePreview";
import { downloadFile } from "../atom/downloadFile";
// import docIcon from "../../assets/docIcon.svg";
import { fileSizeCalculate } from "../../utils/numberFormat";
import dayjs from "dayjs";
import { noteDateFormat } from "../../utils/DateTimeFormat";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { DocIcon, PdfIcon } from "../../assets/notesIcon";

export default function FilesDropdown(props) {
  let { imgDetails, noteContent, setDeleteNoteDoc } = props || {};
  const [isOpen, setIsOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false); // State to manage FilePreview visibility
  const [imageIndex, setImageIndex] = useState("");

  const FindData = noteContent?.[imageIndex];

  const toggleDropdown = (id) => {
    setIsOpen((prevIndex) => (prevIndex === id ? -1 : id));
  };

  const togglePreview = (id) => {
    setShowPreview((prevIndex) => (prevIndex === id ? -1 : id));
  }; // Toggle preview visibility

  const dropdownRef = useRef(null);
  const excludedComponents = [dropdownRef];

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element or its ancestors are in the excluded components
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !excludedComponents.some((component) =>
          component.current.contains(event.target)
        )
      ) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, excludedComponents]);

  let ImageOpt = (data) => {
    const { type } = data || {};

    return (
      <div key={imgDetails}>
        <button
          onClick={(event) => {
            toggleDropdown(noteContent?.[imgDetails]?.id, event);
            setImageIndex(imgDetails);
          }}
          className={
            type
              ? " dark:divide-darkModeBG  bg-white dark:border-[0.5px] dark:border-darkModeBorder dark:bg-darkModeBG shadow-black "
              : "absolute right-4 top-4 w-[20px] h-[20px] bg-white   dark:bg-darkModeMain rounded-full flex justify-center items-center hover:cursor-pointer shadow-lg"
          }
        >
          <PiDotsThreeVerticalBold size={30} fontWeight={500} />
        </button>
        {isOpen === noteContent[imgDetails].id && (
          <div
            ref={dropdownRef}
            className={
              " right-0 w-[220px] z-50 z-divide-y-[1px] divide:gray-200 dark:divide-darkModeBG  bg-white dark:border-[0.5px] dark:border-darkModeBorder dark:bg-darkModeBG shadow-lg shadow-black absolute top-8 rounded-lg overflow-hidden flex flex-col"
            }
          >
            <button
              onClick={() => {
                togglePreview(imgDetails);
                setIsOpen(false);
              }}
              className="flex items-center w-full gap-2 px-3 py-1 h-[40px] min-h-[40px] hover:bg-lightBg dark:hover:bg-neutral-800 hover:cursor-pointer"
            >
              <IoExpand size={18} />
              <p className="font-semibold">Preview</p>
            </button>

            <button
              onClick={() => {
                downloadFile(noteContent[imgDetails]);
                setIsOpen(false);
              }}
              className="flex items-center w-full gap-2 px-3 py-1 h-[40px] min-h-[40px] hover:bg-lightBg dark:hover:bg-neutral-800 hover:cursor-pointer"
            >
              <PiArrowCircleDownLight size={18} />
              <p className="font-semibold">Download</p>
            </button>

            <button
              className="flex items-center w-full gap-2 px-3 py-1 h-[40px] min-h-[40px] hover:bg-lightBg dark:hover:bg-neutral-800 hover:cursor-pointer"
              onClick={() => {
                setDeleteNoteDoc(noteContent[imgDetails].id);
                setIsOpen(false);
              }}
            >
              <FiTrash size={17} />
              <p className="font-semibold">Remove</p>
            </button>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="relative w-full">
      {showPreview === imageIndex && (
        <FilePreview
          onClose={togglePreview}
          imgDetails={FindData}
          setImageIndex={setImageIndex}
          imageIndex={imageIndex}
          arrayLength={noteContent.length - 1}
        />
      )}

      {!noteContent[imgDetails]?.file_type?.startsWith("image/") ? (
        <div className=" flex items-center  border width-full my-2 shadow hover:shadow-lg ">
          <div className=" flex items-center justify-between w-10/12">
            <div className=" flex items-center">
              <div className="m-5">
                {noteContent[imgDetails]?.file_type == "application/pdf" ? (
                  <PdfIcon />
                ) : (
                  <DocIcon />
                )}
              </div>
              <div className="ml-2">
                <p className="truncate line-clamp-1 p-1 max-w-1/3 font-bold capitalize">
                  {noteContent[imgDetails]?.file_name}
                </p>
                <p className="truncate line-clamp-1 p-1 max-w-[200px]">
                  {(dayjs(noteContent[imgDetails]?.created_at).format(
                    noteDateFormat
                  ) || "") + " "}
                  -{" "}
                  {fileSizeCalculate(noteContent[imgDetails]?.file_size) || ""}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center ">
            <MdOutlineDownloadForOffline
              size={30}
              className="mr-2"
              onClick={() => downloadFile(noteContent[imgDetails])}
            />{" "}
            <ImageOpt type={"docs"} />
          </div>
        </div>
      ) : noteContent[imgDetails]?.file_type?.startsWith("image/") ? (
        <>
          <div className="border-0 width-full  my-2 shadow hover:shadow-lg">
            <img
              style={{ minWidth: 300 }}
              src={noteContent[imgDetails]?.file_path}
              alt={`Selected file ${imgDetails + 1}`}
              className="rounded-2xl"
            />
          </div>{" "}
          <ImageOpt type={null} />
        </>
      ) : null}
    </div>
  );
}
