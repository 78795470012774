import AddHoursPopup from "./AddViews/AddHoursPopup";
import PropTypes from "prop-types";

SelectDaysDropdown.propTypes = {
  selectedDays: PropTypes.any,
  setSelectedDays: PropTypes.any,
  toggleDaySelection: PropTypes.func,
};
export default function SelectDaysDropdown(props) {
  const { selectedDays, setSelectedDays, toggleDaySelection } = props;
  return (
    <div className="w-full min-h-[95px]  rounded-b-lg bg-white dark:bg-darkModeMain flex flex-col border-[1px] border-t-0 border-gray-100 dark:border-darkModeBorder">
      <AddHoursPopup
        selectedDays={selectedDays}
        setSelectedDays={setSelectedDays}
        toggleDaySelection={toggleDaySelection}
      />
    </div>
  );
}
