import { useEffect, useState } from "react";
import PhoneInput from "../CountryCodePhoneInput";
import {
  PhoneNumberValidation,
  UsPhoneNumberValidation,
} from "../../../utils/validate/NumberValidation";
import toast from "react-hot-toast";
import { parsePhoneNumber } from "libphonenumber-js";
import { AuthPersonalPhone } from "../../../services/auth/personal/AuthPersonal";
import countryData from "../../../utils/country-code/countrycode.json";
import { SettingService } from "../../../services/setting/SettingService";
import PropTypes from "prop-types";

ChangePhoneView.propTypes = {
  onShowPhoneValidate: PropTypes.func,
  setSendPhone: PropTypes.func,
  setSendCode: PropTypes.func,
};
export default function ChangePhoneView({
  onShowPhoneValidate,
  setSendPhone,
  setSendCode,
}) {
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryShortCode, setCountryShortCode] = useState("");
  const [formattedNumber, setFormattedNumber] = useState("");
  const { ProfileDetails } = AuthPersonalPhone((state) => state);
  const { changePhoneNumberApi } = SettingService((state) => state);

  const handleChangePhoneNumberClick = async () => {
    const phoneNumberOne = parsePhoneNumber(
      countryCode + phoneNumber,
      countryShortCode
    );
    const country = countryData?.find(
      (country) => country.dial_code === countryCode
    );
    const oldNumber =
      ProfileDetails?.change_country_code + ProfileDetails?.change_number;
    if (phoneNumberOne?.number == oldNumber) {
      toast.error("The phone number you entered already exists in our records");
    } else if (
      (!phoneNumberOne?.isValid() && !country) ||
      country == undefined
    ) {
      toast.error("Please enter valid phone number");
      return;
    } else {
      await changePhoneNumberApi({
        country_code: countryCode,
        phone_number: PhoneNumberValidation(phoneNumber),
      })
        .then((data) => {
          if (data.message == "ALREADY EXIST") {
            toast.error(
              "The phone number you entered already exists in our records"
            );
          } else {
            setSendPhone(PhoneNumberValidation(phoneNumber));
            setSendCode(countryCode);
            toast.success(
              "Confirmation Code SMS has been sent to changed phone number."
            );
            onShowPhoneValidate(true);
          }
        })
        .catch((error) => console.log(error, "error"));
    }
  };

  useEffect(() => {
    if (countryCode && phoneNumber && countryShortCode) {
      // Ensure that the concatenated string is within the acceptable length range
      const concatenatedNumber = countryCode + phoneNumber;
      if (concatenatedNumber?.length >= 5 && concatenatedNumber?.length <= 21) {
        try {
          const phoneNumberOne = parsePhoneNumber(
            countryCode + phoneNumber,
            countryShortCode
          );
          if (phoneNumberOne) {
            setFormattedNumber(phoneNumberOne.isValid());
          } else {
            // Handle the error appropriately

            setFormattedNumber(false);
          }
        } catch (error) {
          // Handle the error appropriately
          console.error("Error parsing phone number:", error);
          setFormattedNumber(false);
        }
      } else {
        setFormattedNumber(false);
        // Handle the error appropriately
      }
    }
  }, [countryCode, phoneNumber, countryShortCode]);

  return (
    <div className="flex flex-col w-full text-sm scrollable-container h-full overflow-y-auto">
      <div className="w-full space-y-5 py-6 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
        <p className="px-6">
          Here you can change your iAllo phone number. Your account and your
          businesses will be moved to the new number.{" "}
        </p>
        <p className="px-6">Your current phone number</p>
        <p className="px-6 text-lg font-bold">
          {ProfileDetails?.country_code
            ? ProfileDetails?.country_code +
              " " +
              UsPhoneNumberValidation(ProfileDetails?.phone_number)
            : ""}
        </p>
      </div>

      <div className="flex flex-wrap items-center w-full gap-6 p-6 ">
        <div className="flex flex-col pb-8">
          <p className="pb-3">Change Phone Number:</p>
          <PhoneInput
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setCountryShortCode={setCountryShortCode}
          />
        </div>
        <button
          onClick={() => {
            if (formattedNumber) {
              handleChangePhoneNumberClick();
            }
          }}
          className={
            formattedNumber
              ? "rounded-full flex justify-center items-center  w-[250px] h-[45px] bg-ipurple text-white uppercase font-semibold text-[13px]"
              : "rounded-full flex justify-center items-center  w-[250px] h-[45px] bg-gray-300 text-white uppercase font-semibold text-[13px]"
          }
        >
          Change Phone Number
        </button>
      </div>
    </div>
  );
}
