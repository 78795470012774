import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { HiChevronDown } from "react-icons/hi2";
import { TicketsDetails } from "../../services/tickets/TicketDetails";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import { getAccountType } from "../../routes/ProtectedRoutes";
import { CompaniesService } from "../../services/companies/CompaniesService";
const userRole = "admin";
const PRO_USER = "PROFESSIONAL";

export default function CreateTicket() {
  const navigate = useNavigate();
  const { fetchBusinessDetails, fetchBusinessDetailsApi } = DirectoryDetails(
    (state) => state,
  );
  let { data } = fetchBusinessDetails || {};
  const { createTicketApi } = TicketsDetails();
  const [ticketBusinessId, setTicketBusinessId] = useState("");
  const [roles, setRoles] = useState([]); // State to store the roles data from API
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState({
    color: "",
    role: "",
    phone: "",
  });
  const {
    companyPhoneNumberListApi,
    companyPhoneNumbers,
    companyDetailsApi,
    companyData,
  } = CompaniesService((state) => state);

  const business_id = useRef(data?.id);

  useEffect(() => {
    companyDetailsApi().then((res) => {
      if (res) {
        companyPhoneNumberListApi(res.id);
      }
    });
  }, []);

  const { getProfileDetails } = AuthPersonalPhone();

  useEffect(() => {
    getProfileDetails()
      .then((response) => {
        if (typeof response !== "string") {
          setTicketBusinessId(response?.data?.data?.company?.id);
        } else {
          console.error(response);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      title: "",
      note: "",
    },
    onSubmit: (values) => {
      toast.success("Ticket created successfully");
    },
  });

  const handleCreateTicket = () => {
    createTicketApi({
      business_id: data?.id,
      name: formik?.values?.title,
      rating: 4,
      note: formik?.values?.note,
    })
      .then(() => {
        toast.success("Ticket created successfully");
        setTimeout(() => {
          navigate("/tickets");
        }, 2000);
      })
      .catch((error) => {
        toast.error("Error creating ticket");
      });
  };

  const hasPermission = (permission) => {
    const permissions = {
      admin: ["create_ticket", "view_ticket", "edit_ticket"],
      user: ["create_ticket"],
      PROFESSIONAL: ["create_ticket", "view_ticket"],
    };
    const accountType = getAccountType();
    return (
      permissions[userRole]?.includes(permission) ||
      permissions[PRO_USER]?.includes(permission)
    );
  };

  const selectRole = ({ color, phone_number, role }) => {
    setSelectedRole({
      color: color,
      phone: phone_number,
      role: role,
    }); // Set the selected role and phone number
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="w-full h-full rounded-lg relative top-5 pb-6 overflow-hidden">
      <div className="sticky top-0 flex items-center z-20 justify-between bg-white border-b border-gray-100 dark:bg-darkModeMain dark:border-darkModeBorder">
        <div className="flex items-center w-full gap-[15px] px-[15px] py-4">
          <p className="text-sm font-semibold uppercase">New Ticket</p>
        </div>
      </div>
      <div className="p-6 bg-white rounded-b-lg shadow-sm dark:bg-darkModeMain">
        <form onSubmit={formik.handleSubmit}>
          {/* Business Details */}
          <div className="mb-5">
            <label className="block text-sm font-medium text-gray-700 mb-2.5 dark:text-white">
              Business
            </label>
            <div className="flex items-center">
              {data ? (
                <DirectoryImageInitials
                  imageUrl={data?.upload?.file_path}
                  alt={data?.name}
                  name={data?.name}
                  boxColor={data?.business_color?.toLowerCase()}
                  className="mx-2 w-[40px] h-[40px] rounded-lg"
                />
              ) : (
                <div className="bg-gray-200 mx-2 w-[40px] h-[40px] rounded" />
              )}
              <span className="flex flex-col">
                <p className="font-semibold text-sm">
                  {data?.name || "Business Name"}
                </p>
                <p className="font-normal text-[13px]">
                  {data?.business_locations[0]?.address || "Business Address"}
                </p>
              </span>
            </div>
          </div>

          {hasPermission("create_ticket") && (
            <>
              {/* Title Input */}
              <div className="mb-5">
                <label
                  htmlFor="title"
                  className="block text-sm font-normal text-gray-700 mb-2.5 dark:text-white"
                >
                  Title *
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="w-3/6 h-[55px] border  rounded-md font-semibold text-sm dark:bg-darkModeMain"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  required
                />
              </div>
              {/* Role Dropdown */}
              <div className="mb-5">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700 mb-2.5 dark:text-white"
                >
                  Departments *
                </label>
                <div className="relative">
                  <button
                    type="button"
                    className={`w-3/6 h-[55px] p-2.5 border rounded-md dark:border-gray-500 bg-white appearance-none dark:bg-darkModeMain text-left flex justify-between items-center`}
                    onClick={toggleDropdown}
                  >
                    <div className="flex items-center h-[25px]">
                      <span
                        className={`font-semibold text-[14px] bg-[${selectedRole.color}] py-0.5 px-2.5 rounded dark:bg-darkModeMain`}
                      >
                        {selectedRole.role}
                      </span>
                      <span className="ml-3.5 font-semibold text-sm  dark:bg-darkModeMain">
                        {selectedRole.phone}
                      </span>
                    </div>
                    <HiChevronDown className="text-gray-400" />
                  </button>
                  {dropdownOpen && (
                    <div className="absolute z-10 mt-2 w-3/6 bg-white border dark:border-gray-500 rounded-md shadow-lg dark:bg-darkModeMain">
                      {companyPhoneNumbers.map((option) => (
                        <div
                          key={option.roles}
                          className="flex items-center px-4 py-2 cursor-pointer hover:bg-slate-300 dark:hover:bg-darkModeBG"
                          onClick={() =>
                            selectRole({
                              phone_number: option.phone_number,
                              role: option.role,
                            })
                          }
                        >
                          <span
                            className={`inline-block whitespace-nowrap rounded-[0.27rem] px-[0.5rem] py-[0.5rem] text-center align-baseline text-[0.75em] font-bold leading-none`}
                            style={{
                              width: 100,
                            }}
                          >
                            {option.department.toUpperCase() || ""}
                          </span>
                          <div className="ml-3.5 font-semibold text-[14px] text-gray-700 dark:text-white">
                            {option?.phone_number}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {/* Note Textarea */}
              <div className="mb-5">
                <label
                  htmlFor="note"
                  className="block text-sm font-medium text-gray-700 mb-2.5 dark:text-white"
                >
                  Note
                </label>
                <textarea
                  id="note"
                  name="note"
                  rows={6}
                  className="w-full h-[185px] p-4 border dark:bg-darkModeMain dark:text-white rounded-lg bg-white resize-none"
                  placeholder="Enter your note here"
                  onChange={formik.handleChange}
                  value={formik.values.note}
                ></textarea>
              </div>
              {/* Submit Button */}
              <div className="mt-[140px]">
                <button
                  type="submit"
                  className="self-start text-white py-2 px-4 transition duration-300"
                  style={{
                    borderRadius: "22.5px",
                    height: "45px",
                    background:
                      "linear-gradient(90deg, #4F33F5 0%, #5133F6 100%)",
                  }}
                  onClick={handleCreateTicket}
                >
                  Create Ticket
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
}
