import "./App.css";
import RouteFile from "./routes/RouteFile";
import mixpanel from "mixpanel-browser";
import { VITE_MIXPANEL_TOKEN } from "./utils/constants";
import { ThemeProvider } from "./components/ThemeContext";
import RedirectionMobileApp from "./components/mobile/RedirectionMobileApp";

function App() {
  // Initialize Mixpanel
  mixpanel.init(VITE_MIXPANEL_TOKEN);
  return (
    <ThemeProvider>
      <RedirectionMobileApp>
        <div className="app">
          <RouteFile />
        </div>
      </RedirectionMobileApp>
    </ThemeProvider>
  );
}

export default App;
