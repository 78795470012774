import { useEffect, useRef, useState } from "react";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { LiaInboxSolid } from "react-icons/lia";
import { LuTrash } from "react-icons/lu";
import PropTypes from "prop-types";

NotificationsDropdown.propTypes = {
  handleUnReadable: PropTypes.func,
  notifcationDetails: PropTypes.any,
  handleArchived: PropTypes.any,
};

export default function NotificationsDropdown(props) {
  const { handleUnReadable, notifcationDetails, handleArchived } = props;
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownDirection, setDropdownDirection] = useState("down");

  const toggleDropdown = () => {
    if (!isOpen) {
      setTimeout(() => {
        setIsOpen(true);
      }, 100);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const dropdownHeight = 180;

      if (spaceBelow < dropdownHeight) {
        setDropdownDirection("up");
      } else {
        setDropdownDirection("down");
      }
    }
  }, [isOpen]);

  return (
    <div className="flex" ref={dropdownRef}>
      <a
        href="#"
        className="flex items-center text-[12px] transition duration-150 ease-in-out cursor-pointer"
        onClick={toggleDropdown}
      >
        <PiDotsThreeVerticalBold size={25} color={isOpen ? "#4C5FEF" : ""} />
      </a>

      {isOpen && (
        <div
          className={`w-[220px] z-10 flex flex-col absolute shadow-md ${dropdownDirection === "down" ? "top-[45px]" : "bottom-[45px]"
            } right-2 rounded-lg bg-white dark:bg-darkModeMain border-[1px] border-gray-100 dark:border-darkModeBorder`}
        >
          {!notifcationDetails?.is_read ? (
            <div
              className="flex gap-3 items-center border-b border-gray-100 py-2.5 dark:border-darkModeBorder h-1/3 w-full hover:bg-lightBg dark:hover:bg-darkModeBG hover:cursor-pointer"
              onClick={() => {
                setIsOpen(false);
                handleUnReadable(notifcationDetails?.id, true);
              }}
            >
              <LiaInboxSolid size={20} className="ml-2" />
              <p className="font-semibold text-[12px]">Mark as read</p>
            </div>
          ) : (
            <div
              className="flex gap-3 items-center border-b border-gray-100 py-4 dark:border-darkModeBorder h-1/3 w-full hover:bg-lightBg dark:hover:bg-darkModeBG hover:cursor-pointer"
              onClick={() => {
                setIsOpen(false);
                handleUnReadable(notifcationDetails?.id, false);
              }}
            >
              <LiaInboxSolid size={20} className="ml-2" />
              <p className="font-semibold text-[12px]">Mark as unread</p>
            </div>
          )}

          {notifcationDetails?.is_archived ? (
            <div
              className="flex gap-3 items-center border-b border-gray-100 py-4 dark:border-darkModeBorder h-1/3 w-full hover:bg-lightBg dark:hover:bg-darkModeBG hover:cursor-pointer"
              onClick={() => {
                setIsOpen(false);
                handleArchived(notifcationDetails?.id, false);
              }}
            >
              <LuTrash size={18} className="ml-2" />
              <p className="font-semibold text-[12px]">Unarchive</p>
            </div>
          ) : (
            <div
              className="flex gap-3 items-center border-b border-gray-100 py-2.5 dark:border-darkModeBorder h-1/3 w-full hover:bg-lightBg dark:hover:bg-darkModeBG hover:cursor-pointer"
              onClick={() => {
                setIsOpen(false);
                handleArchived(notifcationDetails?.id, true);
              }}
            >
              <LuTrash size={18} className="ml-2" />
              <p className="font-semibold text-[12px]">Archive</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
