import React, { useState, useEffect, useRef } from "react";
import Joyride, { CallBackProps, Step, EVENTS, STATUS } from "react-joyride";
import border from "../assets/Border.webp";
import NoImageFound from "../assets/NoImageFound.jpg";
import useUserStore from "../services/tourGuideServices";
import CallPopup from "./calls/CallPopup";
interface TourGuideProps {
  startTour: boolean;
  setStartTour: React.Dispatch<React.SetStateAction<boolean>>;
  pathSrc?: string;
  userAvatar?: string;
  userName?: string;
}

interface State {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

const TourGuide = ({ startTour, setStartTour, pathSrc }: TourGuideProps) => {
  const { onboardData, getUserDetails, updateUserData, fetchOnBoard } =
    useUserStore();
    const [showCallPopup, setShowCallPopup] = useState(false);
  const userDetailsString = localStorage.getItem("userDetails");
  const userDetail = userDetailsString ? JSON.parse(userDetailsString) : null;
    const [extensionInstalled, setExtensionInstalled] = useState(false);

  const profileId = userDetail?.profile_id;
  const Xname = userDetail?.first_name;

  const tourRef = useRef<HTMLDivElement | null>(null);

  const [userName, setUserName] = useState<string | null>(null);

  const handleTourEnd = () => {
    setStartTour(false);
    setState((prevState) => ({
      ...prevState,
      run: false,
    }));
    const data = { onboarding_seen: true };
    updateUserData(data);
    setShowCallPopup(true); 

  };

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const response = await getUserDetails(profileId);
        if (response?.data?.data?.first_name || profileId) {
          setUserName(response?.data?.data?.first_name);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    if (profileId) {
      getUserProfile();
    }

    return () => {};
  }, [profileId, getUserDetails]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tourRef.current && !tourRef.current.contains(event.target)) {
        handleTourEnd(); // Set startTour to false if clicked outside
        setState((prevState) => ({ ...prevState, run: false })); // Stop the tour
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Clean up the event listener on unmount
    };
  }, [setStartTour, tourRef]);

  const [state, setState] = useState<State>({
    run: false, // Initially set run to false
    stepIndex: 0,
    steps: [
      {
        target: "#Show-Tutorial",
        content: (
          <div
            ref={tourRef}
            className="fixed inset-y-0 w-[540px] flex items-center bg-white dark:bg-darkModeMain bg-opacity-75 z-50"
            style={{
              right: "10%",
              transform: "translateX(20%)",
            }}
          >
            <div className="bg-white rounded-lg  shadow-md p-6 w-[540px] h-[280px] dark:bg-darkModeMain">
              <div className="flex justify-center items-center relative">
                <div className="w-[80px] h-[80px] relative">
                  <img
                    src={border}
                    alt="border"
                    className="w-[80px] h-[80px] object-cover"
                  />
                  <img
                    src={userDetail?.upload?.file_path || NoImageFound}
                    alt="Avatar"
                    className="absolute top-2.5 left-2.5 z-10 w-[60px] h-[60px] rounded-full object-cover"
                  />
                </div>
              </div>

              <h2 className="text-2xl font-bold text-center mt-4 dark:text-white">
                Welcome {Xname || "User"}
              </h2>
              <p className="text-right mt-2 text-gray-800 dark:text-white font-sans font-normal text-[14px] leading-[25px] w-[409px] h-[25px] ml-[28px]">
                Take this brief tutorial to familiarize yourself with the app.
              </p>
              <div ref={tourRef} className="flex justify-center mt-6 space-x-4">
                <button
                  id="skip-tour"
                  className="text-isearch dark:text-white font-regular py-2 px-4 focus:outline-none focus:shadow-outline"
                  style={{
                    width: "220px",
                    height: "45px",
                    borderRadius: "23px",
                    border: "1px solid rgba(20, 15, 38, 1)",
                    opacity: "1",
                  }}
                  onClick={handleTourEnd}
                >
                  SKIP TOUR
                </button>
                <button
                  id="take-tour"
                  className="bg-ipurple hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  style={{
                    width: "220px",
                    height: "45px",
                    borderRadius: "22.5px",
                  }}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      stepIndex: prevState.stepIndex + 1,
                      run: true,
                    }));
                  }}
                >
                  TAKE A TOUR
                </button>
              </div>
            </div>
          </div>
        ),
        placement: "center",
      },
      {
        target: "#step-2",
        content: (
          <div className="w-[340px] h-[200px] bg-white rounded-xl p-4 bg-opacity-75 font-Montserrat dark:bg-darkModeMain">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-lg font-regular font-[14px] text-gray-900 dark:text-white">
                Search for Businesses
              </h2>
              <a
                href="#"
                className="text-sm text-gray-900 hover:underline dark:text-white"
                onClick={handleTourEnd}
              >
                Skip
              </a>
            </div>
            <p className="text-left mt-2 text-gray-800 font-sans font-regular text-[14px] leading-[25px] w-[300px] h-[60px] dark:text-white">
              Here is where you can search for Businesses you need to call. You
              can narrow the search results by providing a geographic area.
            </p>
            <div ref={tourRef} className="flex flex-row justify-between">
              <span className="text-sm text-gray-400 mt-12 dark:text-white">1/8</span>
              <button
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    stepIndex: prevState.stepIndex + 1,
                  }));
                }}
                className="bg-ipurple hover:bg-blue-700 text-white font-regular h-[30px] w-[90px] rounded-[22.5px] mt-10 dark:text-white"
              >
                NEXT
              </button>
            </div>
          </div>
        ),
        placement: "top",
      },

      {
        target: "#step-4",
        content: (
          <div className=" w-[340px] h-[185px] bg-white dark:bg-darkModeMain rounded-xl p-4  bg-opacity-75 font-Montserrat ">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-regular font-[14px] text-gray-900 dark:text-white">
                Navigation
              </h2>
              <button
                className=" text-sm text-gray-900 hover:underline dark:text-white"
                onClick={handleTourEnd}
              >
                Skip
              </button>
            </div>
            <p className=" text-left mt-2 text-gray-800 font-sans font-regular text-[14px] leading-[25px] w-[300px] h-[60px] dark:text-white">
              This is your navigation sidebar, where each icon will take you to
              the corresponding section.
            </p>
            <div
              ref={tourRef}
              className="mt-4 flex justify-between items-center "
            >
              <span className="text-gray-600 dark:text-white">2/8</span>
              <button
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    stepIndex: prevState.stepIndex + 1,
                    run: true,
                  }));
                }}
                className="bg-ipurple hover:bg-blue-700 text-white font-regular h-[30px] w-[90px] rounded-[22.5px] dark:text-white"
              >
                NEXT
              </button>
            </div>
          </div>
        ),
        placement: "right",
      },
      {
        target: "#step-5",
        content: (
          <div className="w-[340px] h-[185px] bg-white dark:bg-darkModeMain rounded-xl p-4  bg-opacity-75 font-Montserrat">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-regular font-[14px] text-gray-900 dark:text-white">
                Navigation
              </h2>
              <button
                onClick={handleTourEnd}
                className="text-sm text-gray-900 hover:underline dark:text-white"
              >
                Skip
              </button>
            </div>

            <p className="text-left mt-2 text-gray-800 font-sans font-regular text-[14px] leading-[25px] w-[300px] h-[60px] dark:text-white">
              Sub-Menus will appear in this area for each section.
            </p>

            <div
              ref={tourRef}
              className="mt-4 flex justify-between items-center"
            >
              <span className="text-gray-500 dark:text-white">3/8</span>
              <button
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    stepIndex: prevState.stepIndex + 1,
                    run: true,
                  }));
                }}
                className="bg-ipurple hover:bg-blue-700 text-white font-regular h-[30px] w-[90px] rounded-[22.5px] dark:text-white"
              >
                NEXT
              </button>
            </div>
          </div>
        ),
        placement: "left",
      },
      {
        target: "#step-6",
        content: (
          <div className="w-[340px] h-[185px] bg-white dark:bg-darkModeMain rounded-xl p-4  bg-opacity-75 font-Montserrat">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-regular font-[14px] text-gray-900 dark:text-white">
                Support
              </h2>
              <button
                onClick={handleTourEnd}
                className="text-sm text-gray-900 hover:underline dark:text-white"
              >
                Skip
              </button>
            </div>

            <p className="text-left mt-2 text-gray-800 font-sans font-regular text-[14px] leading-[25px] w-[300px] h-[60px] dark:text-white">
              Access the iAllo Knowledgebase wih articles on how to make the
              most of iAllo.
            </p>

            <div
              ref={tourRef}
              className="mt-4 flex justify-between items-center"
            >
              <span className="text-gray-500 dark:text-white">4/8</span>
              <button
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    stepIndex: prevState.stepIndex + 1,
                    run: true,
                  }));
                }}
                className="bg-ipurple hover:bg-blue-700 text-white font-regular h-[30px] w-[90px] rounded-[22.5px] dark:text-white"
              >
                NEXT
              </button>
            </div>
          </div>
        ),
        placement: "right-end",
      },
      {
        target: "#step-7",
        content: (
          <div className="w-[340px] h-[185px] bg-white dark:bg-darkModeMain rounded-xl p-4 bg-opacity-75 font-Montserrat">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-regular font-[14px] text-gray-900 dark:text-white">
                Feedback
              </h2>
              <button
                onClick={handleTourEnd}
                className="text-sm text-gray-900 hover:underline dark:text-white"
              >
                Skip
              </button>
            </div>

            <p className="text-left mt-2 text-gray-800 font-sans font-regular text-[14px] leading-[25px] w-[300px] h-[60px] dark:text-white">
              Report Any technical issues, suggest improvements and let us know
              how we're doing.
            </p>

            <div
              ref={tourRef}
              className="mt-4 flex justify-between items-center"
            >
              <span className="text-gray-500 dark:text-white">5/8</span>
              <button
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    stepIndex: prevState.stepIndex + 1,
                    run: true,
                  }));
                }}
                className="bg-ipurple hover:bg-blue-700 text-white font-regular h-[30px] w-[90px] rounded-[22.5px] mt-2 dark:text-white"
              >
                NEXT
              </button>
            </div>
          </div>
        ),
        placement: "right-end",
      },
      {
        target: "#step-8",
        content: (
          <div
            ref={tourRef}
            className="w-[340px] h-[185px] bg-white dark:bg-darkModeMain rounded-xl p-4 bg-opacity-75 font-Montserrat"
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-regular font-[14px] text-gray-900 dark:text-white">
                Add new Business
              </h2>
              <button
                onClick={handleTourEnd}
                className="text-sm text-gray-900 hover:underline dark:text-white"
              >
                Skip
              </button>
            </div>

            <p className="text-left mt-2 text-gray-800 font-sans font-regular text-[14px] leading-[25px] w-[300px] h-[60px] dark:text-white">
              Can't Add new business?Easily add it manually to your personal
              directory.
            </p>

            <div className="mt-4 flex justify-between items-center">
              <span className="text-gray-500 dark:text-white">6/8</span>
              <button
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    stepIndex: prevState.stepIndex + 1,
                    run: true,
                  }));
                }}
                className="bg-ipurple hover:bg-blue-700 text-white font-regular h-[30px] w-[90px] rounded-[22.5px] dark:text-white"
              >
                NEXT
              </button>
            </div>
          </div>
        ),
        placement: "bottom",
      },
      {
        target: "#step-9",
        content: (
          <div
            ref={tourRef}
            className=" bg-white dark:bg-darkModeMain rounded-xl p-4 bg-opacity-75 font-Montserrat w-[340px] h-[145px]"
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-regular font-[14px] text-gray-900 dark:text-white">
                Dialer
              </h2>
              <button
                onClick={handleTourEnd}
                className="text-sm text-gray-900 hover:underline dark:text-white"
              >
                Skip
              </button>
            </div>

            <p className="text-left mt-2 text-gray-800 font-sans font-regular text-[14px] leading-[25px] w-[300px] h-[60px] dark:text-white">
              Opens the dialer to make a call manually.
            </p>

            <div className=" flex justify-between items-center -mt-2">
              <span className="text-gray-500 dark:text-white">7/8</span>
              <button
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    stepIndex: prevState.stepIndex + 1,
                    run: true,
                  }));
                }}
                className="bg-ipurple hover:bg-blue-700 text-white font-regular h-[30px] w-[90px] rounded-[22.5px] dark:text-white"
              >
                NEXT
              </button>
            </div>
          </div>
        ),
        placement: "right",
      },
      {
        target: "#step-10",
        content: (
          <div
            ref={tourRef}
            className="w-[340px] h-[185px] bg-white dark:bg-darkModeMain rounded-xl p-4 bg-opacity-75 font-Montserrat"
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-regular font-[14px] text-gray-900 dark:text-white">
                Alerts
              </h2>
              <button
                onClick={handleTourEnd}
                className="text-gray-600 hover:text-gray-800 dark:text-white"
              >
                Skip
              </button>
            </div>
            <p className="text-left mt-2 text-gray-800 font-sans font-regular text-[14px] leading-[25px] w-[300px] h-[60px] dark:text-white ">
              A number in red circle indicates how many iAllo system
              notifications you have waiting. Click bell to view/ clear.
            </p>
            <div className="flex justify-between items-center mt-6">
              <div className="text-gray-500 dark:text-white">8/8</div>
              <div>
                <button
                  onClick={handleTourEnd}
                  className="bg-ipurple hover:bg-blue-700 text-white font-regular h-[30px] w-[90px] rounded-[22.5px] mt-2 dark:text-white"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        ),
        placement: "bottom",
      },
    ],
  });

  const [onboardingSeen, setOnboardingSeen] = useState(null);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, index } = data;

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      handleTourEnd();
      setStartTour(false);
    } else if (status === EVENTS.STEP_AFTER) {
      setState((prevState) => ({
        ...prevState,
        stepIndex: index + 1,
      }));
    }
  };
  const { run, steps } = state;

  useEffect(() => {
    const getOnboardingStatus = async () => {
      try {
        await fetchOnBoard();
        setOnboardingSeen(onboardData); // Set onboarding seen status
      } catch (error) {
        console.error("Error fetching onboarding data:", error);
      }
    };

    getOnboardingStatus();
  }, []);


  useEffect(() => {
    setOnboardingSeen(onboardData); // Replace with actual key if different

    setState((prevState) => ({
      ...prevState,
      run: !onboardingSeen,
      stepIndex: onboardData ? 1 : 0,
    }));
  }, [onboardingSeen]);

  useEffect(() => {
    if (startTour) {
      setState((prevState) => ({
        ...prevState,
        run: true,
        stepIndex: 1,
      }));
    }
  }, [startTour, onboardingSeen]);

  return (
    <div>
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        stepIndex={state.stepIndex}
        hideBackButton
        hideCloseButton
        disableScrolling
        scrollToFirstStep={false}
        debug
        styles={{
          tooltip: {
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "none",
          },
          tooltipContainer: {
            backgroundColor: "transparent",
            marginLeft: "-30px",
            marginTop: "-30px",
          },
          buttonNext: {
            display: "none",
          },
          options: {
            arrowColor: "transparent",
          },
        }}
      />
          {showCallPopup && <CallPopup onClose={() => setShowCallPopup(false)} />}
    </div>
  );
};

export default TourGuide;
