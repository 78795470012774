import { useState } from "react";
import { IoCloseOutline, IoStar } from "react-icons/io5";
import { MdVerified } from "react-icons/md";
import ReactDOM from "react-dom";
import DirectoryImageInitials from "./atom/directoryImageInitials";
import { DirectoryDetails } from "../services/directory/DirectoryDetails";
import Loader from "./atom/loader/Loader";

export default function ReviewPopup(props) {
  let { fetchBusinessDetails, onClose } = props || {};
  let { data } = fetchBusinessDetails || {};

  const { createReviewApi, listAllReviewsApi, loadingReviewSection } =
    DirectoryDetails((state) => state);

  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const handleEmojiClick = (index) => {
    setSelectedEmoji(index);
  };
  const [selectedRating, setSelectedRating] = useState(0);
  const handleRating = (rating) => {
    setSelectedRating(rating);
  };
  const [reviewText, setReviewText] = useState("");

  const handleChange = async (event) => {
    const newReview = event.target.value;
    setReviewText(newReview);
  };

  const handleAddReview = async (e) => {
    if (reviewText.length == 0) {
      return;
    }
    e.preventDefault();
    await createReviewApi(
      {
        rating: selectedRating || 0,
        review_text: reviewText || "",
        // call_history_id: "",
      },
      data?.id
    );
    await onClose();
    await listAllReviewsApi({ businessId: data?.id });
  };

  return ReactDOM.createPortal(
    <div className=" w-full absolute top-0 -left-3 h-screen z-[200] flex text-sm justify-center items-center ">
      <div className="fixed  w-[100%]  h-[100%] bg-black opacity-90"></div>
      <div className="absolute z-40 flex flex-col w-3/4 bg-white rounded-lg lg:w-1/2 dark:bg-darkModeMain ">
        <div className="w-full p-6 flex justify-between items-center border-b border-gray-100-[1px] border-gray-100 h-[60px] min-h-[60px] dark:border-darkModeBorder">
          <p className="font-semibold uppercase">Add reveiw</p>
          <button onClick={onClose}>
            <IoCloseOutline size={25} />
          </button>
        </div>
        <div className="flex items-center gap-1 p-6">
          <DirectoryImageInitials
            imageUrl={data?.upload?.file_path}
            alt={data?.name}
            name={data?.name}
            boxColor={data?.business_color?.toLowerCase()}
            className={``}
          />

          <p className="pl-3 text-sm font-semibold">{data?.name || ""}</p>

          {data?.is_verified && <MdVerified color="#48CFFF" size={18} />}
        </div>
        <div className="flex items-center gap-1 p-6">
          <p className="mr-2 text-[15px] font-semibold">Rate your experience</p>
          {[1, 2, 3, 4, 5].map((star) => (
            <IoStar
              key={star}
              size={25}
              className={
                selectedRating >= star
                  ? "text-ipink hover:cursor-pointer"
                  : "text-gray-200 hover:cursor-pointer dark:text-darkModeBG"
              }
              onClick={() => handleRating(star)}
            />
          ))}
        </div>
        <p className="px-6 pt-2 pb-4 text-[15px] font-semibold">Your Review</p>

        <div className="items-center hidden gap-4 p-6">
          {["😡", "😔", "😐", "🙂", "😍"].map((emoji, index) => (
            <button
              key={index}
              className={`w-[60px] h-[60px] text-[33px]  flex justify-center items-center rounded-lg border-2 dark:border-darkModeBorder border-neutral-100 ${
                selectedEmoji === index ? "bg-ipurple" : ""
              }`}
              onClick={() => handleEmojiClick(index)}
            >
              {emoji}
            </button>
          ))}
        </div>
        <textarea
          value={reviewText}
          onChange={handleChange}
          placeholder="Type something here..."
          className="mx-6 bg-inherit rounded-lg p-4 border-neutral-100 dark:border-darkModeBorder border-opacity-50 h-[180px] "
        />
        <div className="flex items-center justify-between p-6 text-sm uppercase">
          <button
            onClick={(e) => {
              handleAddReview(e);
            }}
            disabled={loadingReviewSection ? true : false}
            className="w-[240px] h-[45px] rounded-full hover:font-semibold bg-black hover:bg-inherit hover:text-inherit hover:border-2 hover:border-b border-gray-100 lack dark:hover:border-white text-white dark:border-white flex items-center justify-center uppercase"
          >
            Add review{" "}
          </button>
        </div>
      </div>
      {loadingReviewSection && <Loader />}
    </div>,
    document.getElementById("portal-root")
  );
}
