import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";
import { BASE_URL } from "../../utils/constants";
import { apiFilterVallidation } from "../../utils/api/apiFilter";
import callAxios from "../../api/requestIntercetorForm";
import axios from "axios";

export const BusinessService = create((set) => ({
  error: null,
  businessLoading: false,
  contactTypeResp: {},
  categoryResp: {},
  businessResp: {},
  businessImgUploadResp: {},

  createBusinessApi: async (businessData) => {
    try {
      const response = await AxiosApi.post(
        `${BASE_URL}businesses`,
        businessData
      );

      let { success } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          businessLoading: false,
          businessResp: response?.data,
        });
      }

      return response?.data;
    } catch (error) {
      set({ businessLoading: false, error: error });
      return error?.response?.data;
    }
  },

  updateBusinessApi: async (businessData) => {
    let { id, data } = businessData;
    try {
      const response = await AxiosApi.put(`${BASE_URL}businesses/${id}`, data);

      let { success } = response?.data || { message: "error" };
      set({ businessLoading: false });
      if (success) {
        // If the request is successful, update the state with the response data
        return response?.data;
      } else {
        throw "error";
      }
    } catch (error) {
      set({ businessLoading: false, error: error });
      return error?.response?.data;
    }
  },
  addContactApi: async (id, businessData) => {
    try {
      const response = await AxiosApi.post(
        `${BASE_URL}businesses/${id}/contacts`,
        businessData
      );

      let { success } = response?.data || { message: "error" };
      set({ businessLoading: false });
      if (success) {
        // If the request is successful, update the state with the response data
        return response?.data;
      } else {
        throw "error";
      }
    } catch (error) {
      set({ businessLoading: false, error: error });
      return error;
    }
  },
  businessContactTypeApi: async () => {
    try {
      set({ businessLoading: true });

      const response = await AxiosApi.get(`${BASE_URL}account-types`);
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          businessLoading: false,
          contactTypeResp: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (error) {
      set({ businessLoading: false, error: error });
      return error;
    }
  },

  businessCategoryApi: async (businessData) => {
    try {
      let { page, limit, default_category } = businessData;
      set({ businessLoading: true });
      let urlArray = [
        {
          name: "page",
          value: page,
        },
        {
          name: "limit",
          value: limit,
        },
      ];

      let filteredURL = apiFilterVallidation(
        urlArray,
        `${BASE_URL}/categories?`
      );
      const response = await AxiosApi.get(filteredURL);
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          businessLoading: false,
          categoryResp: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (error) {
      set({ businessLoading: false, error: error });
      return error;
    }
  },

  uploadBusinessImage: async (id, file) => {
    try {
      const response = await callAxios.post(
        `${BASE_URL}businesses/${id}/upload-photo`,
        file
      );

      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          businessLoading: false,
          businessImgUploadResp: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (error) {
      set({ businessLoading: false, error: error });
      return error;
    }
  },
}));
