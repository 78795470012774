import { useState, useRef, useEffect } from "react";
import Dropdown from "./Dropdown";
import { IoCloseOutline } from "react-icons/io5";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { getDayTimeDiffDetails } from "../../utils/TimeDetails";
import CloseTicketPopup from "./CloseTicketPopup";
import { CallDetailsService } from "../../services/calls/CallDetailsService";
import toast from "react-hot-toast";
import { TicketsDetails } from "../../services/tickets/TicketDetails";
import { useNavigate } from "react-router-dom";
import { FiCheckSquare } from "react-icons/fi";
import TicketsContainer from "./TicketsContainer";
import { IncomingCallIcon } from "../icons/Icons";
import { sendMixpanelEvent } from "../../services/mixpanelService";

export default function TicketCard(props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState("");
  const [isCloseTicketPopupVisible, setIsCloseTicketPopupVisible] =
    useState(false);
  const [isTicketClosed, setIsTicketClosed] = useState(false);
  let { ticketUpdateCallsDetailsApi } = CallDetailsService((state) => state);
  const { modifyListAllTicketsData, listAllTicketDetails } = TicketsDetails(
    (state) => state
  );
  const dropdownRef = useRef(null);
  const [dropdownDirection, setDropdownDirection] = useState("down");
  const navigate = useNavigate();
  let {
    ticket,
    onTicketClick,
    showLogo,
    onLoadAllTicket,
    index,
    checked,
    formik,
    selectAll,
    setSelectAll,
  } = props || {};
  const toggleCloseTicketPopup = () => {
    setIsCloseTicketPopupVisible(!isCloseTicketPopupVisible);
  };
  const updateStatusToClosed = async ({ ticketId, ticketStatus }) => {
    await ticketUpdateCallsDetailsApi({
      id: ticketId,
      data: { status: ticketStatus == "closed" ? "open" : "closed" },
    }).then((response) => {
      if (response?.message == "SUCCESS UPDATE") {
        let ticketmodifyData = listAllTicketDetails?.map((ticketData) => {
          if (ticketData?.id == ticketId) {
            return {
              ...ticketData,
              status: ticketStatus != "open" ? "open" : "closed",
            };
          } else {
            return { ...ticketData };
          }
        });
        modifyListAllTicketsData(ticketmodifyData);
        toast.success(
          `Ticket ${
            ticketStatus == "closed" ? "re-open" : "closed"
          } successfully`
        );
        setIsCloseTicketPopupVisible(!isCloseTicketPopupVisible);
      }
    });
    await onLoadAllTicket();
  };

  const handleDropdownClick = (index) => {
    setIsDropdownOpen((prevIndex) => (prevIndex === index ? -1 : index));
  };

  let tagData = ticket?.ticket_tags;
  const isClosed = ticket?.status === "closed";
  const Current = ticket?.status === "Current";

  let buttonBgColor = "bg-[#4c5fef17]  text-ipurple"; // Default color
  if (isClosed) {
    buttonBgColor = "bg-[#e3e3e380] text-gray-400";
  } else if (Current) {
    buttonBgColor = "bg-[#2dd4be1d] text-teal-400 ";
  }
  let buttonDotColor = "bg-ipurple";
  if (isClosed) {
    buttonDotColor = "bg-gray-400";
  } else if (Current) {
    buttonDotColor = "bg-teal-400";
  }

  const handleClosedStatus = () => {};
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const dropdownHeight = 180; // Approximate height of your dropdown

      if (spaceBelow < dropdownHeight) {
        setDropdownDirection("up");
      } else {
        setDropdownDirection("down");
      }
    }
  }, [isDropdownOpen]);

  useEffect(() => {}, [dropdownRef]);

  const handleBox = (e) => {
    e.stopPropagation();
    setSelectAll("customSelected");
    formik?.setFieldValue(index, { ...ticket, checked: e?.target?.checked });
  };

  return (
    <div
      className="flex justify flex-col hover:cursor-pointer  flex-grow  text-sm w-full border-b border-gray-100-[1px] border-gray-100  overflow-x-hidden hover:bg-lightBg dark:border-darkModeBorder  dark:hover:bg-darkModeBG"
      ref={dropdownRef}
    >
      {isCloseTicketPopupVisible && (
        <CloseTicketPopup
          onClose={toggleCloseTicketPopup}
          isTicketClosed={isTicketClosed}
          handleApiCall={() => {
            let handleApiCall = async () => {
              await updateStatusToClosed({
                ticketId: ticket.id,
                ticketStatus: ticket.status,
              });
            };
            handleApiCall();
          }}
        />
      )}

      <div className="flex items-center  justify-between w-full">
        {checked && (
          <div className="px-4 !pl-6">
            <input
              type="checkbox"
              className="checkbox-ticket"
              checked={ticket?.checked}
              onChange={handleBox}
            />
          </div>
        )}

        <div
          className={
            checked
              ? "flex items-center w-full gap-3 px-2 mr-4"
              : "flex items-center w-full gap-3 px-2 mx-4"
          }
          onClick={() => {
            onTicketClick(ticket);
            sendMixpanelEvent("Tickets Details Viewed");
            navigate(`/tickets/${ticket.id}`, {
              state: {
                ticketId: ticket.id,
              },
            });
          }}
        >
          {showLogo && (
            <DirectoryImageInitials
              imageUrl={
                ticket?.business?.upload?.file_path || ticket?.upload?.file_path
              }
              alt={ticket?.business?.name}
              name={ticket?.business?.name}
              boxColor={ticket?.business?.business_color?.toLowerCase()}
              className={`max-w-[40px] max-h-[40px] rounded-md border-none`}
            />
          )}
          <div className="flex flex-col">
            <p className="text-[14px] font-semibold truncate max-[1500px]:max-w-[300px] overflow-hidden">
              {ticket?.business?.name}
            </p>
            <p className="text-[13px] text-isearch dark:text-gray-400 truncate overflow-hidden">
              {ticket?.name}
            </p>
          </div>
          <div className="flex items-center justify-end w-full gap-8 p-2 !pr-0 my-4 ">
            {/* <div className="flex items-center gap-1.5 mx-1">
            <IncomingCallIcon
              width={25}
              height={25}
              className="text-black dark:text-white w-[25px]"
            />
            In 10 hours
          </div> */}
            <div className="flex items-center gap-1.5 mx-1">
              <p className="text-[13px] text-gray-400 ">
                {getDayTimeDiffDetails(ticket.last_activity_ts)}
              </p>
            </div>
            <button
              onClick={() => onTicketClick(ticket)}
              className={`font-[600] gap-1 text-[10px] uppercase w-[80px] rounded-full min-w-[80px] h-[22px] flex items-center justify-center  ${buttonBgColor}`}
              disabled={isClosed}
            >
              <div
                className={`w-[6px] h-[6px] rounded-full ${buttonDotColor} `}
              ></div>
              <p>{ticket?.status}</p>
            </button>
            <div className=" w-8 right-0">
              <button
                className=""
                // ref={buttonRef}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDropdownClick(index);
                }}
              >
                <PiDotsThreeVerticalBold
                  size={23}
                  color={isDropdownOpen === index ? "#4C5FEF" : ""}
                />
              </button>
              {isDropdownOpen === index && (
                <div
                  className={`z-30 absolute bg-white divide-y divide-gray-100 rounded-lg shadow  dark:bg-gray-700 mt-20  ${
                    dropdownDirection === "down"
                      ? "-top-[40px]"
                      : "bottom-[45px]"
                  } w-52 right-5`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Dropdown
                    ticketId={ticket.id}
                    status={ticket.status}
                    setIsTicketClosed={(data) => {
                      setIsCloseTicketPopupVisible(true);
                      setIsTicketClosed(data);
                    }}
                    handleClosedStatus={handleClosedStatus}
                    setIsDropdownOpen={() => {
                      handleDropdownClick(index);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="hidden ">
          {tagData?.length !== 0 &&
            tagData?.map((data) => (
              <div
                key={data?.id}
                className="flex flex-wrap items-center mb-4 ml-[90px] -mt-3"
              >
                <button className="border-[1px]  border-gray-100  h-[30px] max-h-[30px] max-w-[180px] -mr-16 rounded-lg bg-[#4c5fef23] border-ipurple p-2 text-ipurple flex items-center justify-center gap-1">
                  <p className="truncate max-w-[170px]">{data?.tag?.name}</p>
                  <IoCloseOutline size={16} className="min-w-[16px]" />
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
