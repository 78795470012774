import BusinessInfo from "../BusinessInfo";
import PropTypes from "prop-types";
import Category from "../Category";
import ProfilePictureDetails from "../ProfilePictureDetails";
UpdateBusDetails.propTypes = {
  formik: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default function UpdateBusDetails(props) {
  const { handleChange, formik, data } = props;
  const handleTrackedChange = (e) => {
    handleChange(e);
  };
  return (
    <div className="flex w-full gap-4 p-4">
      <div className="flex flex-col w-2/3">
        <BusinessInfo handleChange={handleTrackedChange} formik={formik} />
        <Category handleChange={handleTrackedChange} formik={formik} />
      </div>
      <div className="w-1/3">
        <ProfilePictureDetails
          handleChange={handleChange}
          formik={formik}
          data={data}
        />
      </div>
    </div>
  );
}
