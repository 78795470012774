import React, { useMemo, useState } from "react";
import { TECollapse } from "tw-elements-react";
import { sendMixpanelEvent } from "../../../../services/mixpanelService";
export default function SortByAccordian(props): JSX.Element {
  let { LocationSortBy, setLocationSortBy, collapseShow } = props;

  const sortMethods: any = useMemo(
    () => [
      { id: "relevance", title: "Relevance" },
      { id: "nearest", title: "Nearest" },
      // { id: "rating", title: "By Rating" },
    ],
    []
  );
  return (
    <>
      <div id="" className="text-sm -mt-1">
        <div className=" bg-white dark:bg-darkModeMain text-sm">
          <TECollapse
            show={collapseShow}
            className="accordionFilter !mt-0  !rounded-b-none !shadow-none"
          >
            <div className="px-5 py-1 text-sm">
              <div className="space-y-2 ">
                {sortMethods?.map((sortData) => (
                  <div key={sortData?.id} className="flex text-sm items-center">
                    <input
                      id={sortData?.id}
                      name="notification-method"
                      type="radio"
                      defaultChecked={sortData?.id === "nearest"}
                      className="w-4 h-4 z-40 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                      value={LocationSortBy}
                      onChange={(e) => {
                        sendMixpanelEvent("Filter Applied", {
                          Type: " Categories",
                        });
                        setLocationSortBy(sortData.id);
                      }}
                    />
                    <label
                      htmlFor={sortData.id}
                      className="block ml-3 text-sm leading-3 0"
                    >
                      {sortData.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </TECollapse>
        </div>
      </div>
    </>
  );
}
