import { useEffect, useMemo, useRef, useState } from "react";
import TicketCard from "./TicketCard";
import { HiChevronUpDown } from "react-icons/hi2";
import { IoIosClose, IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { TicketsDetails } from "../../services/tickets/TicketDetails";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchIcon } from "../icons/SearchIcon";
import EmptySearchScreen from "../atom/EmptySearchScreen";
import DateFilter from "./filters/DateFilter";
import dayjs from "dayjs";
import { ASC, DESC, NEWEST_FIRST, OLDEST_FIRST } from "../../utils/constants";
import ScrollingLoader from "../atom/loader/scrollingLoader";
import Loader from "../atom/loader/Loader";
import { CalendarIcon, MinusSelectIcon, SelectAllIcon } from "../icons/Icons";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { sendMixpanelEvent } from "../../services/mixpanelService";
export default function TicketsContainer(props) {
  const {
    listAllTicketDetailsApi,
    multipleTicketUpdateApi,
    loadingTicketDetails,
    listAllTicketDetails,
    clearState,
    ticketDetailsLastPage,
    listTicketsMetaData,
  } = TicketsDetails((state) => state);

  const filterState = useRef("all");
  const [sortOrder, setSortOrder] = useState(NEWEST_FIRST);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [submitDate, setSubmitDate] = useState(false);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectAll, setSelectAll] = useState(null);
  const [loadingState, setLoadingState] = useState(true);
  let { business, businessId, setActiveTab } = props || {};
  let business_id = business?.business_id || businessId;

  const navigate = useNavigate();

  let getTicketsDataApi = (paramPage) => {
    let activeFilter = filterState?.current;
    let paginationDetails = {
      start_date:
        startDate && selectedFilter != "all"
          ? dayjs(startDate).format("YYYY-MM-DD")
          : "",
      sort_order: sortOrder == NEWEST_FIRST ? DESC : ASC,
      end_date:
        endDate && selectedFilter != "all"
          ? dayjs(endDate).format("YYYY-MM-DD")
          : "",
      keyword: searchQuery,
    };
    listAllTicketDetailsApi({
      ...paginationDetails,
      business_id: business_id ? business_id : "",
      ticket_status:
        activeFilter == "all"
          ? ""
          : activeFilter == "scheduled"
            ? "open"
            : activeFilter,
      page: paramPage || currentPage,
      scheduled: activeFilter == "scheduled" ? true : "",
    }).then((data) => {
      setLoadingState(false);
      if (paramPage) {
        setCurrentPage(2);
      } else if (data?.data && data?.meta && data?.data?.length != 0) {
        setCurrentPage(currentPage + 1);
      }
    });
  };

  const handleTicketClick = async () => {
    if (setActiveTab) setActiveTab("Status");
  };

  const handleAllClick = () => {
    setSelectAll(null);
    filterState.current = "all";
    clearState();
    setSearchQuery("");
    getTicketsDataApi(1);
  };
  const handleOpenClick = () => {
    setSelectAll(null);
    filterState.current = "open";
    clearState();
    setSearchQuery("");
    getTicketsDataApi(1);
  };
  const handleScheduledClick = () => {
    setSelectAll(null);
    filterState.current = "scheduled";
    setSearchQuery("");
    clearState();

    getTicketsDataApi(1);
  };
  const handleClosedClick = () => {
    setSelectAll(null);
    filterState.current = "closed";
    clearState();
    setSearchQuery("");
    setCurrentPage(1);
    getTicketsDataApi(1);
  };

  const [showSortDropdown, setShowSortDropdown] = useState(false);

  const toggleSortDropdown = () => {
    sendMixpanelEvent("Filter Selection Initiated", { Type: "Tickets" });
    setShowSortDropdown(!showSortDropdown);
  };

  const changeSortOrder = (order) => {
    sendMixpanelEvent("Filter Applied", { Type: "Tags" });
    setSortOrder(order);
    setShowSortDropdown(false);
  };

  //this useeffect used for debouncing and api's hit for search query
  useEffect(() => {
    if (searchQuery?.length != 0) {
      clearState();
      const timeoutDetails = setTimeout(() => {
        getTicketsDataApi(1);
      }, 300);
      return () => {
        clearTimeout(timeoutDetails);
      };
    } else if (searchQuery == 0 || !searchQuery) {
      clearState();
      getTicketsDataApi(1);
    }
  }, [searchQuery, sortOrder, submitDate, businessId]);

  // this useffect used for clear the search
  useEffect(() => {
    if (!isInputOpen) {
      setSearchQuery("");
    }
  }, [isInputOpen]);
  const handleSearchClick = () => {
    setIsInputOpen((prevState) => !prevState);
    sendMixpanelEvent("Ticket Search Initiated");
  };

  const [isDateSelectOpen, setIsDateSelectOpen] = useState(false);

  const handleDateSelectClick = () => {
    setIsDateSelectOpen(!isDateSelectOpen);
  };

  const handleCloseDateFilter = () => {
    setIsDateSelectOpen(false);
  };

  const handleDate = () => {};

  //for Mass action ticket checkbox

  const [checked, setChecked] = useState(false);

  const handleIconClick = (checkedData) => {
    setChecked((prev) => !prev);
    const selectedData = formik?.values?.map((data) => ({
      ...data,
      checked: false,
    }));
    formik?.setValues(selectedData);
    setSelectAll(null);
  };
  const handleSelectAll = (e) => {
    if (!e?.target?.checked && selectAll == "customSelected") {
      const checkedData = formik?.values?.map((data) => ({
        ...data,
        checked: false,
      }));
      formik?.setValues(checkedData);
      setSelectAll(null);
    } else if (e?.target?.checked) {
      const checkedData = formik?.values?.map((data) => ({
        ...data,
        checked: true,
      }));
      formik?.setValues(checkedData);
      setSelectAll("selectedAll");
    } else {
      const checkedData = formik?.values?.map((data) => ({
        ...data,
        checked: true,
      }));

      formik?.setValues(checkedData);
    }
  };

  const handleUpdateStatus = (newStatus) => {
    if (formik?.values?.filter((data) => data?.checked)?.length) {
      let checkedList = formik?.values
        ?.filter((data) => data?.checked)
        ?.map((data) => data?.id);

      const afterFilteration = formik?.values?.map((data) => {
        if (checkedList?.includes(data?.id)) {
          return { ...data, status: newStatus };
        } else {
          return data;
        }
      });

      multipleTicketUpdateApi({
        ids: checkedList,
        status: newStatus,
      }).then(() => {
        formik.setValues(afterFilteration);
        // getTicketsDataApi(1);
        toast.success(`Ticket updated successfully`);
      });
      setSelectAll(null);
      setChecked(false);
    }
  };

  const listAllTicketDetailsData = useMemo(() => {
    return listAllTicketDetails?.map((data) => ({
      ...data,
      checked: selectAll ? true : false,
    }));
  }, [listAllTicketDetails]);

  const formik = useFormik({
    initialValues: listAllTicketDetailsData || [],
    onSubmit: () => {},
    enableReinitialize: true,
  });
  return (
    <div className="flex flex-col items-center justify-center w-full pt-6 text-sm !h-[calc(100dvh-143px)] ">
      {loadingState ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div
          id="ticketPagescrollableDiv"
          className="w-full h-full  overflow-auto bg-white border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg dark:bg-darkModeMain"
        >
          {isDateSelectOpen && (
            <DateFilter
              onClose={handleCloseDateFilter}
              handleDate={handleDate}
              startDate={startDate || new Date()}
              endDate={endDate || new Date()}
              setStartDate={(event) => setStartDate(event)}
              setEndDate={(event) => setEndDate(event)}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              handleApply={() => {
                setSubmitDate(!submitDate);
                setIsDateSelectOpen(false);
                sendMixpanelEvent("Filter Applied", { Type: "Date," });
              }}
            />
          )}
          <div className="flex-grow w-full  ">
            <div className="sticky top-0  flex items-center h-[60px] z-20 justify-between bg-white  border-b border-gray-100 dark:bg-darkModeMain dark:border-darkModeBorder">
              <div className="relative ">
                <button
                  className={`cursor-pointer ml-[21px] theme-icon ${checked ? "active" : ""}`}
                  aria-pressed={checked}
                  onClick={() => {
                    handleIconClick(!checked);
                  }}
                >
                  <SelectAllIcon
                    size={20}
                    fill={checked ? "blue" : "black"}
                    className={`${checked ? "dark:fill-blue-500" : "dark:fill-white"}`}
                  />
                </button>
              </div>

              <div className="flex items-center w-full gap-[15px] px-[15px] py-4">
                <p className="text-sm font-semibold uppercase ">Tickets</p>
                <div className="inline-block h-[27px] min-h-[0.5em] w-[1px] self-stretch bg-gray-100 dark:bg-darkModeBorder"></div>
                <nav className="pl-0">
                  <ul className="flex flex-row items-center gap-4 text-sm ">
                    <li
                      className={`${
                        filterState?.current === "all"
                          ? "text-ipurple font-semibold"
                          : "hover:text-ipurple"
                      }`}
                    >
                      <button
                        onClick={handleAllClick}
                        disabled={loadingTicketDetails ? true : false}
                      >
                        {" "}
                        All {listTicketsMetaData?.all_tickets_count}
                      </button>
                    </li>
                    <li
                      className={`${
                        filterState?.current === "open"
                          ? "text-ipurple font-semibold"
                          : "hover:text-ipurple"
                      }`}
                    >
                      <button
                        onClick={handleOpenClick}
                        disabled={loadingTicketDetails ? true : false}
                      >
                        Open {listTicketsMetaData?.open_tickets_count}
                      </button>
                    </li>
                    <li
                      className={`${
                        filterState?.current === "scheduled"
                          ? "text-ipurple font-semibold"
                          : "hover:text-ipurple"
                      }`}
                    >
                      <button
                        onClick={handleScheduledClick}
                        disabled={loadingTicketDetails ? true : false}
                      >
                        Scheduled {listTicketsMetaData?.scheduled_tickets_count}
                      </button>
                    </li>
                    <li
                      className={`${
                        filterState?.current === "closed"
                          ? "text-ipurple font-semibold"
                          : "hover:text-ipurple"
                      }`}
                    >
                      <button
                        onClick={handleClosedClick}
                        disabled={loadingTicketDetails ? true : false}
                      >
                        Closed {listTicketsMetaData?.closed_tickets_count}
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="flex items-center justify-end  text-[13px] gap-3 mx-4 w-full">
                <p className="line-clamp-1">Sort by</p>
                <div className="relative flex flex-col">
                  <button
                    className="w-[126px] h-[28px] rounded-full border-[1px]  border-gray-100  dark:border-darkModeBorder flex items-center !justify-center font-semibold gap-1"
                    onClick={toggleSortDropdown}
                  >
                    <p>{sortOrder}</p>
                    <HiChevronUpDown />
                  </button>
                  {showSortDropdown && (
                    <div className="absolute w-[126px]   mt-2 bg-white border rounded-lg shadow-lg top-full dark:bg-darkModeMain dark:border-darkModeBorder">
                      <ul className=" divide-y-[1px] divide-gray-100 dark:divide-darkModeBorder w-full text-[13px] font-semibold">
                        <li>
                          <button
                            className="flex w-full h-[30px] items-center px-2 hover:bg-lightBg dark:text-white dark:hover:bg-darkModeBG"
                            onClick={() => changeSortOrder(NEWEST_FIRST)}
                          >
                            {NEWEST_FIRST}
                          </button>
                        </li>
                        <li>
                          <button
                            className="flex w-full h-[30px] items-center px-2 hover:bg-lightBg dark:text-white dark:hover:bg-darkModeBG"
                            onClick={() => changeSortOrder(OLDEST_FIRST)}
                          >
                            {OLDEST_FIRST}
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <p>Date</p>
                <button
                  onClick={handleDateSelectClick}
                  className=" h-[28px] px-3 rounded-full border-[1px]  border-gray-100  dark:border-darkModeBorder flex items-center justify-center font-semibold gap-1"
                >
                  <p className="capitalize flex items-center">
                    {selectedFilter == "custom"
                      ? dayjs(startDate || new Date()).format("DD-MM-YYYY") +
                        " - " +
                        dayjs(endDate || new Date()).format("DD-MM-YYYY")
                      : selectedFilter
                        ? selectedFilter
                        : "All"}
                    <CalendarIcon className={"w-[20px] !ml-1"} />
                  </p>

                  {/* <HiChevronUpDown /> */}
                </button>
                <div className="inline-block mr-[3px] h-[27px] min-h-[0.5em] w-[1px] self-stretch bg-gray-100 dark:bg-darkModeBorder"></div>
                {!isInputOpen && (
                  <button onClick={handleSearchClick} className="">
                    <IoIosSearch size={20} />
                  </button>
                )}
                {isInputOpen && (
                  <div className="relative flex items-center w-[400px] z-30">
                    <SearchIcon className="w-[25px] absolute left-2 text-black dark:text-white" />
                    <input
                      type="text"
                      id="ticket-search"
                      autoComplete="off"
                      placeholder="Search business"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full  h-[45px] border-2 border-gray-100 dark:border-darkModeBorder bg-inherit rounded-lg px-10 placeholder:text-sm focus-within:ring focus-within:ring-ipurple"
                    />

                    <IoIosClose
                      onClick={handleSearchClick}
                      size={25}
                      className="absolute right-2"
                    />
                  </div>
                )}
              </div>
            </div>

            {checked && (
              <div className=" h-[50px] py-[20px] border-b border-gray-130 dark:bg-darkModeMain dark:border-darkModeBorder flex items-center justify-start">
                <div className="flex items-center">
                  <div className="px-4 !pl-6">
                    {selectAll == "selectedAll" ? (
                      <>
                        <input
                          type="checkbox"
                          className="checkbox-ticket"
                          checked={selectAll == "selectedAll" ? true : false}
                          aria-pressed={selectAll}
                          onChange={(e) => {
                            if (e?.target?.checked) {
                              setSelectAll("selectedAll");
                              const checkedData = formik?.values?.map(
                                (data) => ({
                                  ...data,
                                  checked: true,
                                }),
                              );
                              formik?.setValues(checkedData);
                            } else {
                              setSelectAll(null);
                              const checkedData = formik?.values?.map(
                                (data) => ({
                                  ...data,
                                  checked: false,
                                }),
                              );
                              formik?.setValues(checkedData);
                            }
                          }}
                        />
                      </>
                    ) : (
                      // </>
                      <label className="flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="hidden" // Hide the default checkbox
                          checked={selectAll}
                          aria-pressed={selectAll}
                          onClick={handleSelectAll}
                        />
                        <div
                          className={`w-[16px] h-[16px] flex items-center justify-center border rounded ${
                            selectAll
                              ? "bg-ipurple text-white border-[#4C5FEF]" // Border color when selected
                              : "bg-white text-transparent border-[#E0E4EB]" // Border color when not selected
                          }`}
                        >
                          {selectAll ? <MinusSelectIcon /> : <div></div>}
                        </div>
                      </label>
                    )}
                  </div>

                  <label htmlFor="checkbox" className="ml-[10px] font-semibold">
                    {formik?.values?.filter((data) => data?.checked)?.length
                      ? `${
                          formik?.values?.filter((data) => data?.checked)
                            ?.length
                        } selected`
                      : "Select all"}
                  </label>
                </div>

                <div className="flex items-center space-x-4 ml-10">
                  <button
                    className="px-[36px] h-[30px] text-[12px] bg-white rounded-3xl dark:bg-darkModeMain dark:text-white outline outline-offset-2 outline-1 font-semibold"
                    type="button"
                    disabled={
                      !formik?.values?.filter((data) => data?.checked)?.length
                        ? true
                        : false
                    }
                    onClick={() => handleUpdateStatus("open")}
                  >
                    OPEN
                  </button>
                  <button
                    className="px-[36px] h-[30px] text-[12px] bg-white dark:bg-darkModeMain dark:text-white text-black rounded-3xl outline outline-offset-2 outline-1 font-semibold"
                    type="button"
                    disabled={
                      !formik?.values?.filter((data) => data?.checked)?.length
                        ? true
                        : false
                    }
                    onClick={() => handleUpdateStatus("closed")}
                  >
                    CLOSE
                  </button>
                </div>
                {/* )} */}
              </div>
            )}
          </div>
          <div>
            <InfiniteScroll
              dataLength={formik?.values.length}
              next={getTicketsDataApi}
              hasMore={currentPage <= ticketDetailsLastPage}
              scrollableTarget="ticketPagescrollableDiv"
              scrollThreshold={0.5}
              style={{ overflow: "unset" }}
              loader={loadingTicketDetails ? <ScrollingLoader /> : <></>}
            >
              {!loadingTicketDetails && listAllTicketDetails?.length == 0 ? (
                <>
                  <EmptySearchScreen
                    activeTab={
                      filterState?.current == "all"
                        ? "all"
                        : filterState?.current
                    }
                    navigatePage={() => {
                      navigate(`/business/${businessId}`, {
                        state: {
                          business_id: businessId,
                          message_text: "",
                          page: "businessAI",
                        },
                      });
                    }}
                  />
                </>
              ) : (
                Array?.isArray(formik?.values) &&
                formik?.values?.map((ticketItem, index) => (
                  <div key={ticketItem.id} className="relative">
                    <TicketCard
                      ticket={ticketItem}
                      index={index}
                      checked={checked}
                      setChecked={setChecked}
                      selectAll={selectAll}
                      formik={formik}
                      setSelectAll={setSelectAll}
                      onTicketClick={() => {
                        handleTicketClick();
                      }}
                      showLogo={true}
                      onLoadAllTicket={() => {
                        getTicketsDataApi();
                      }}
                    />
                  </div>
                ))
              )}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
}
