import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

export const formatInternationalPhoneNumber = (number) => {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  try {
    const phoneNumber = phoneNumberUtil.parse(number);
    const formattedNumber = phoneNumberUtil.format(
      phoneNumber,
      PhoneNumberFormat.INTERNATIONAL
    );
    return formattedNumber;
  } catch (error) {
    return number;
  }
};

export const truncateString = (str, maxLength = 25) => {
  return str && str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
};
export const fileSizeCalculate = (size) => {
  if (size < 1024) {
    return size + " KB";
  } else {
    const sizeInMB = (size / (1024 * 1024)).toFixed(2);
    if (sizeInMB >= 1) {
      return sizeInMB + " MB";
    } else {
      return (size / 1024).toFixed(2) + " KB";
    }
  }
};

export const formatGoogleBusinessTime = (schedule) => {
  // Array to hold the consolidated schedule
  let consolidatedSchedule = [];

  // Iterate through each day in the schedule
  for (let i = 0; i < schedule.length; i++) {
    // Split each day and time range by ": "
    let parts = schedule[i].split(": ");

    // Extract day and time range
    let day = parts[0];
    let timeRange = parts[1];

    // Check if there's a next day
    if (i < schedule.length - 1) {
      let nextParts = schedule[i + 1].split(": ");
      // let nextDay = nextParts[0];
      let nextTimeRange = nextParts[1];

      // If the current day and the next day have the same time range, consolidate
      if (timeRange === nextTimeRange) {
        // Find the end of the range of consecutive days with the same time range
        let rangeEnd = i + 1;
        while (
          rangeEnd < schedule.length &&
          schedule[rangeEnd].split(": ")[1] === timeRange
        ) {
          rangeEnd++;
        }

        // Determine the format for the range
        let startDay =
          day.charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase();
        let endDay =
          schedule[rangeEnd - 1].split(": ")[0].charAt(0).toUpperCase() +
          schedule[rangeEnd - 1].split(": ")[0].slice(1, 3).toLowerCase();

        // Consolidate the range

        consolidatedSchedule.push(`${startDay}-${endDay}: ${timeRange}`);

        // Skip to the end of the range
        i = rangeEnd - 1;
        continue;
      }
    }

    // Handle closed days or specific time ranges
    if (timeRange === "Closed") {
      // Check if it's a single day or a range
      if (
        i < schedule.length - 1 &&
        schedule[i + 1].split(": ")[1] === "Closed"
      ) {
        let nextParts = schedule[i + 1].split(": ");
        let nextDay = nextParts[0];

        // Determine the format for the range of closed days
        let startDay =
          day.charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase();
        let endDay =
          nextDay.charAt(0).toUpperCase() + nextDay.slice(1, 3).toLowerCase();

        consolidatedSchedule.push(`${startDay}-${endDay}: Closed`);

        // Skip to the next day
        i++;
      } else {
        consolidatedSchedule.push(
          `${
            day.charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase()
          }: Closed`
        );
      }
    } else {
      // For days with specific time ranges
      consolidatedSchedule.push(
        `${
          day.charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase()
        }: ${timeRange}`
      );
    }
  }

  return consolidatedSchedule;
};

export const customTimeFilter = (schedule) => {
  let formattedSchedule = [];
  const formatTime = (time) => {
    return time.slice(0, -3);
  };
  schedule?.forEach((entry) => {
    entry?.days?.forEach((day) => {
      let formattedString = `${day}: ${formatTime(
        entry.start_time
      )}–${formatTime(entry.end_time)}`;
      formattedSchedule.push(formattedString);
    });
  });

  return formattedSchedule;
};
