import mixpanel from "mixpanel-browser";

export const sendMixpanelEvent = (eventName, props = {}) => {
  const defaultProperties = { App: "Web" };
  const eventProperties = { ...defaultProperties, ...props };
  mixpanel.track(eventName, eventProperties);
};

export const identifyMixpanelUser = (userId) => {
  if (!userId) return;

  mixpanel.identify(userId);
};

export const setMixpanelProperties = (attributes) => {
  if (attributes && Object.keys(attributes).length > 0) {
    mixpanel.people.set(attributes);
  }
};
