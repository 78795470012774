import SearchList from "./SearchList";
import { TopNavSearchService } from "../../services/search/topNav/TopNavSearchService";
import Loader from "../atom/loader/Loader";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../../context/SearchContext";
export const renderNoResultsMessage = () => (
  <div className="p-4 text-center ">
    No businesses to be displayed. To search a business, use the input field.
  </div>
);
export default function ResultsContainer(props) {
  const { loadingSearch, topNavSearchDetailsData, loadingSearchData } =
    TopNavSearchService((state) => state);
  let { searchData, selectedMarkerId, setSelectedMarkerId } = props || {};
  const topResults = searchData?.filter(
    (business) => business?.isTopResult === "true",
  );
  const { setSearchViewToggle } = useSearch();
  const navigate = useNavigate();
  const { data } = topNavSearchDetailsData;

  return (
    <div className="relative flex flex-col  text-sm ">
      <div className="!h-[calc(100dvh-266px)] overflow-auto bg-ibluebg dark:border-darkModeBorder dark:bg-darkModeBG dark:text-white">
        {loadingSearchData ? (
          <Loader />
        ) : data?.unsaved_businesses ? (
          <>
            {/* Only display this section if there are top results. */}
            {topResults?.length > 0 && (
              <div>
                <div className="w-full">
                  <h3 className="mt-4 px-6 text-[17px] font-bold ">
                    Top Results
                  </h3>
                </div>
                <SearchList businesses={topResults} />
              </div>
            )}

            {data?.national_businesses?.length != 0 && (
              <div className="full">
                <div className="w-full flex justify-between items-center pt-3 pb-1">
                  <h3 className="  text-[17px] px-6 font-bold">Top Results</h3>
                  {/* <p className="px-6 py-1">{`${
                  data?.national_businesses?.length || 0
                } results`}</p> */}
                </div>
                <SearchList
                  businessDetails={data?.national_businesses}
                  selectedMarkerId={selectedMarkerId}
                  setSelectedMarkerId={(data) => setSelectedMarkerId(data)}
                />
              </div>
            )}
            {/* Only display this section if there are any filtered businesses. */}
            {data?.unsaved_businesses?.length != 0 ? (
              <div className="full">
                <div className="w-full flex justify-between items-center pt-3 pb-1">
                  <h3 className="  text-[17px] px-6 font-bold">All Results</h3>
                  <p className="px-6 py-1">{`${
                    data?.unsaved_businesses?.length || 0
                  } results`}</p>
                </div>
                <SearchList
                  businessDetails={data?.unsaved_businesses}
                  selectedMarkerId={selectedMarkerId}
                  setSelectedMarkerId={(data) => setSelectedMarkerId(data)}
                />
              </div>
            ) : (
              renderNoResultsMessage()
            )}
          </>
        ) : (
          renderNoResultsMessage()
        )}
      </div>
      <div
        className="h-[65px] text-white flex items-center font-bold text-[14px] justify-center business-gradient"
        onClick={() => {
          setSearchViewToggle(false);
          navigate("/create-business");
        }}
      >
        Can’t find business? Add business now.
      </div>
    </div>
  );
}
