import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { IoCloseOutline } from "react-icons/io5";
import { LuClock, LuClock9 } from "react-icons/lu";
import CalendarPicker from "./CalendarPicker";
import TimePicker from "./TimePicker";
import { CallDetailsService } from "../../services/calls/CallDetailsService";
import TimezoneSelector from "./TimezoneSelector";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timeZoneData from "../../utils/time-zone/timeZone.json";
import toast from "react-hot-toast";
import {
  getScheduledCurrentTimeDisplay,
  getScheduledTimeDisplayNow,
} from "../../utils/TimeDetails";
import { CalendarIcon } from "../icons/Icons";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
export default function RescheduleCallModal(props) {
  let {
    onClose,
    ticketId,
    scheduledTime,
    getScheduleCallData,
    businessId,
    singleTicketData,
    showSchedule,
    setSelectedScheduledTime,
    setSingleTicketData,
    closeModal,
  } = props || {};

  const [selectedDateOption, setSelectedDateOption] = useState("");
  const [timeZoneTime, setTimeZoneTime] = useState(scheduledTime);
  const [combinedTime, setCombinedTime] = useState(dayjs);
  const [selectedTimeOption, setSelectedTimeOption] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [showTimezoneSelector, setShowTimezoneSelector] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const abbreviation = dayjs().tz(dayjs.tz.guess()).format();
  const getCurrentTimeZone = new Date(abbreviation)
    .toLocaleTimeString("en", { timeZoneName: "long" })
    ?.split("PM" || "AM")[1];

  const [selectedTimezone, setSelectedTimezone] = useState(
    timeZoneData?.filter(
      (data) => data?.value == getCurrentTimeZone?.trim(" ")
    )?.[0]?.text
  );

  const [selectedDate, setSelectedDate] = useState(() => {
    const scheduledDate = new Date(scheduledTime || new Date());
    return scheduledDate;
  });

  const callType = singleTicketData?.call_requests?.length == 0 || showSchedule;
  const { reScheduleCallApi, addCallRequestApi } = CallDetailsService(
    (state) => state
  );
  const formatTime = (date) => {
    return `${date.getHours() % 12 || 12}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")} ${date.getHours() >= 12 ? "PM" : "AM"}`;
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const [selectedTime, setSelectedTime] = useState(() => {
    const scheduledDate = new Date(scheduledTime || new Date());
    return formatTime(scheduledDate);
  });

  useEffect(() => {
    if (scheduledTime) {
      const now = new Date(scheduledTime);
      setSelectedTime(dayjs(now).local().format("h:mm A"));
    }
  }, [scheduledTime]);

  const handleDateChange = (days, option) => {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + days);
    setSelectedDate(newDate);
    setSelectedDateOption(option);
  };
  const handleTimeChange = (hoursToAdd, option, secondsToAdd) => {
    const now = new Date();
    now.setHours(now.getHours() + hoursToAdd);
    now.setSeconds(now.getSeconds() + secondsToAdd);
    const morning = dayjs(combinedTime)
      .set("hour", 9)
      .set("minute", 0)
      .set("second", 0);
    const afternoon = dayjs(combinedTime).startOf("day").add(12, "hour");

    const evening = dayjs(combinedTime)
      .set("hour", 18)
      .set("minute", 0)
      .set("second", 0);

    setCombinedTime(
      option == "morning"
        ? morning
        : option == "afternoon"
        ? afternoon
        : option == "evening"
        ? evening
        : formatTime(now)
    );
    // setCombinedTime(result)
    if (option == "morning") {
      setSelectedTime(
        getScheduledCurrentTimeDisplay(
          dayjs().set("hour", 9).set("minute", 0).set("second", 0)
        )
      );
    } else if (option == "afternoon") {
      setSelectedTime(
        getScheduledCurrentTimeDisplay(
          dayjs(combinedTime).startOf("day").add(12, "hour")
        )
      );
    } else if (option == "evening") {
      setSelectedTime(
        getScheduledCurrentTimeDisplay(
          dayjs().set("hour", 18).set("minute", 0).set("second", 0)
        )
      );
    } else if (option == "now") {
      setSelectedTime(dayjs(now).add(5, "minutes").format("hh:mm A"));
    } else {
      setSelectedTime(formatTime(now));
    }
    setSelectedTimeOption(option);
  };

  const getButtonClassNames = (option, optionGroup) => {
    const isSelected =
      optionGroup === "date"
        ? selectedDateOption === option
        : selectedTimeOption === option;
    return `h-[30px] px-4 border-[1px]  border-gray-100  ${
      isSelected
        ? "bg-ipurple text-white border-none"
        : "border-ipurple text-ipurple"
    } rounded-md text-[12px] `;
  };

  const handleDateConfirm = (date) => {
    // Check if date is defined
    if (date) {
      setSelectedDate(date); 
    } else {
      const currentDate = new Date(); 
      setSelectedDate(currentDate); 
    }
  
    setShowCalendar(false); 
    setSelectedDateOption(""); 
  };
  

  const handleTimeConfirm = (time) => {
    setSelectedTime(time);
    setShowTimePicker(false);
    setSelectedTimeOption(""); // Reset selected time option
  };

  const formatDateTimeForAPI = async (date, time) => {
    let timeComponents = time?.split(":");

    let hours = parseInt(timeComponents?.[0]);
    let minutes = parseInt(timeComponents?.[1]?.split(" ")?.[0]);
    let ampm = timeComponents?.[1]?.split(" ")?.[1];
    if (ampm === "PM" && hours !== 12) {
      hours += 12;
    }

    let currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");

    let currentGivenTime = dayjs(date).startOf("day");
    currentGivenTime = currentGivenTime.add(hours, "hours");
    currentGivenTime = currentGivenTime.add(minutes, "minutes");
    currentGivenTime = currentGivenTime.add(dayjs().second(), "second");
    const dates = dayjs(currentGivenTime).utc().format("YYYY-MM-DDTHH:mm:ss");

    let timeDifference = dayjs(dayjs(currentGivenTime).local()).diff(
      currentTime,
      "minute"
    );

    const formattedNowTime =
      dayjs(dates).add(20, "second").format("YYYY-MM-DDTHH:mm:ss") + ".000Z";
    if (timeDifference <= 60 && timeDifference > 0) {
      setSelectedScheduledTime(formattedNowTime);
      if (!showSchedule) {
        if (singleTicketData?.call_requests?.length == 0) {
          await addCallRequestApi({
            ticket_id: ticketId,
            business_id: businessId,
            call_at_ts: formattedNowTime,
          }).then((data) => {
            if (data?.message == "SUCCESS CREATE") {
              setSingleTicketData({
                ...singleTicketData,
                call_requests: [data?.data],
              });
              toast.success("Call Scheduled successfully");
              //setSingleTicketData(data?.data)
            }
          });
        } else {
          await reScheduleCallApi({
            call_at_ts: formattedNowTime,
            id: singleTicketData?.call_requests[0]?.id || props?.ticketId,
          }).then((data) => {
            if (data?.message == "SUCCESS UPDATE") {
              getScheduleCallData({
                call_at_ts: formattedNowTime,
                ticketId: ticketId,
              });
              toast.success("Call Scheduled successfully");
            }
          });
        }
      }
      onClose(formattedNowTime);
    } else if (timeDifference >= 60) {
      setSelectedScheduledTime(dates + ".000Z");
      if (!showSchedule) {
        if (singleTicketData?.call_requests?.length == 0) {
          await addCallRequestApi({
            ticket_id: ticketId,
            business_id: businessId,
            call_at_ts: dates + ".000Z",
          }).then((data) => {
            if (data?.message == "SUCCESS CREATE") {
              setSingleTicketData({
                ...singleTicketData,
                call_requests: [data?.data],
              });
              toast.success("Call Scheduled successfully");
            }
          });
        } else {
          await reScheduleCallApi({
            call_at_ts: dates + ".000Z",
            id: singleTicketData?.call_requests[0]?.id || props?.ticketId,
          }).then((data) => {
            if (data?.message == "SUCCESS UPDATE") {
              getScheduleCallData({
                call_at_ts: dates + ".000Z",
                ticketId: ticketId,
              });
              toast.success("Call Scheduled successfully");
            }
          });
        }
      }
      onClose(formattedNowTime);
    } else if (timeDifference <= 0) {
      toast.error("Please select the correct time");
    } else {
      return toast.error("Please select the correct time ");
    }
  };

  const handleTimezoneSelect = (timeZoneString) => {
    // Extract the name of the selected timezone
    const { name } = timeZoneString;
    setSelectedTimezone(name);

    // Get the selected date and time
    const selectedDateProps = dayjs(selectedDate);
    const selectedTimeProps = selectedTime;

    const hour = selectedTimeProps?.split(":")[0];
    // const minute = parseInt(selectedTime[1]?.split(" ")[0], 10);
    const period = selectedTimeProps?.split(":")[1]?.split(" ")[1];
    const adjustedHour =
      period === "PM" && hour < 12
        ? parseInt(hour) + 12
        : period === "AM" && hour == 12
        ? 0
        : parseInt(hour);
    const formattedDateResult = dayjs()
      .set("year", selectedDateProps?.format("YYYY"))
      .set("month", selectedDateProps?.format("MM") - 1)
      .set("date", selectedDateProps?.format("DD"))
      .set("hour", adjustedHour)
      .set("minute", selectedTimeProps?.split(":")[1]?.split(" ")[0]);

    const result = dayjs(dayjs(formattedDateResult), "YYYY-MM-DD h:mm A");

    setCombinedTime(result);
    const parsedTime = dayjs(result.format(), "h:mmA");
    setTimeZoneTime(dayjs(parsedTime).local().format("YYYY-MM-DD HH:mm:ss"));
  };

  useEffect(() => {
    if (getCurrentTimeZone) {
      setSelectedTimezone(
        timeZoneData?.filter(
          (data) =>
            data?.value ==
              getCurrentTimeZone?.trim(" ")?.replace("Daylight", "Standard") ||
            data?.value == getCurrentTimeZone?.trim(" ")
        )?.[0]?.text
      );
    }
  }, []);

  useEffect(() => {
    handleTimezoneSelect({ name: selectedTimezone });
  }, [selectedTime, selectedDate]);

  return ReactDOM.createPortal(
    <div className="absolute flex flex-col items-center justify-center w-full h-screen text-sm">
      <div className="fixed w-full h-screen bg-[#140f26a6] opacity-60"></div>
      <div className="relative w-[470px] max-[515px]:w-[400px] flex flex-col pb-4 border-[1px]  border-gray-100  dark:border-darkModeBorder  min-h-[495px] bg-white rounded-lg dark:bg-darkModeMain z-50">
        <div className="w-full h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex justify-between items-center">
          <div className="w-[56px]"></div>
          <div>
            <h1 className="font-semibold uppercase">
              {callType ? "Schedule a call" : "Reschedule call"}
            </h1>
          </div>
          <div>
            <IoCloseOutline
              onClick={closeModal}
              size={25}
              className="mx-4 hover:cursor-pointer"
            />
          </div>
        </div>
        <p className="px-5 py-4 font-semibold">Select Date</p>

        <div className="flex items-center justify-center w-full ">
          <button
            onClick={() => setShowCalendar(!showCalendar)}
            className="max-w-[430px] mx-4 flex-grow h-[55px] font-semibold border-[1pt] border-gray-100 dark:border-darkModeBorder rounded-lg dark:hover:border-gray-100 flex justify-between items-center px-3  "
          >
            <span>{formatDate(selectedDate)}</span>
            <CalendarIcon className="text-black dark:text-white w-[25px] dark:hover:text-white" />
          </button>
        </div>

        {showCalendar && (
          <div className="absolute z-50 transform -translate-x-1/2 top-28 left-1/2">
            <CalendarPicker
              onClose={handleDateConfirm}
              scheduledTime={selectedDate}
              selectedTime={selectedTime}
            />
          </div>
        )}

        <div className="flex items-center w-full gap-3 p-5 ml-1">
          <button
            onClick={() => handleDateChange(0, "today")}
            className={getButtonClassNames("today", "date")}
          >
            Today
          </button>
          <button
            onClick={() => handleDateChange(1, "tomorrow")}
            className={getButtonClassNames("tomorrow", "date")}
          >
            Tomorrow
          </button>
          <button
            onClick={() => handleDateChange(7, "oneWeek")}
            className={getButtonClassNames("oneWeek", "date")}
          >
            In one week
          </button>
        </div>

        <p className="px-5 py-4 font-semibold">Select Time</p>
        <div className="flex items-center justify-center w-full">
          <button
            onClick={() => setShowTimePicker(!showTimePicker)}
            className="max-w-[430px] mx-4 flex-grow h-[55px] font-semibold  border-[1pt] border-gray-100 dark:border-darkModeBorder rounded-lg dark:hover:border-gray-100 flex justify-between items-center px-3  "
          >
            <time>{dayjs(combinedTime).format("h:mm A")}</time>
            <LuClock9 size={20} />
          </button>
        </div>
        {showTimePicker && (
          <div className="absolute z-50 -translate-x-1/2 top-[290px] left-1/2">
            <TimePicker
              onClose={handleTimeConfirm}
              scheduledTime={combinedTime}
              confirmSelectedDate={selectedDate}
            />
          </div>
        )}

        <>
          {selectedDateOption === "today" && (
            <div className="flex flex-wrap items-center w-full gap-3 px-5 pt-5 ml-1">
              <button
                onClick={() => {
                  selectedTimeOption != "now" && handleTimeChange(0, "now", 15);
                }}
                className={getButtonClassNames("now", "time")}
              >
                Now
              </button>
              <button
                onClick={() => {
                  selectedTimeOption != "oneHour" &&
                    handleTimeChange(1, "oneHour", 0);
                }}
                className={getButtonClassNames("oneHour", "time")}
              >
                In one hour
              </button>
              <button
                onClick={() => {
                  selectedTimeOption != "twoHours" &&
                    handleTimeChange(2, "twoHours", 0);
                }}
                className={getButtonClassNames("twoHours", "time")}
              >
                In two hours
              </button>
              <button
                onClick={() => {
                  selectedTimeOption != "threeHours" &&
                    handleTimeChange(3, "threeHours", 0);
                }}
                className={getButtonClassNames("threeHours", "time")}
              >
                In three hours
              </button>
            </div>
          )}
          {selectedDateOption !== "today" && (
            <div className="flex items-center w-full gap-3 px-5 pt-5 ml-1">
              <button
                onClick={() => handleTimeChange(23, "morning", 0)} // Assuming morning starts at 9 AM
                className={getButtonClassNames("morning", "time")}
              >
                Morning
              </button>
              <button
                onClick={() => handleTimeChange(12, "afternoon", 0)} // Assuming afternoon starts at 2 PM
                className={getButtonClassNames("afternoon", "time")}
              >
                Afternoon
              </button>
              <button
                onClick={() => handleTimeChange(7, "evening", 0)} // Assuming evening starts at 6 PM
                className={getButtonClassNames("evening", "time")}
              >
                Evening
              </button>
            </div>
          )}
        </>

        <div className="flex flex-col items-center justify-center px-6 py-4 text-center sapce-y-2">
          {/* <div> {getScheduledTimeDisplay(timeZoneTime)}</div> */}
          <div className="font-semibold pb-3 pt-1">
            {" "}
            {getScheduledTimeDisplayNow(timeZoneTime)}
          </div>
          <p className="text-sm">{selectedTimezone}</p>
          <button
            onClick={() => setShowTimezoneSelector(!showTimezoneSelector)}
            className="pt-2 mb-6 font-semibold underline underline-offset-2"
          >
            Change Timezone
          </button>
          <button
            onClick={() => {
              formatDateTimeForAPI(
                selectedDate,
                dayjs(combinedTime).format("h:mm A")
              );
            }}
            className="w-[345px] h-[45px] bg-ipurple uppercase text-[13px] text-white hover:border-[1px]  border-gray-100  hover:text-ipurple hover:border-ipurple hover:bg-inherit font-semibold rounded-full"
          >
            {callType ? "Schedule a call" : "Reschedule a call"}
          </button>
          {showTimezoneSelector && (
            <div className="absolute bottom-0">
              <TimezoneSelector
                timezone={selectedTimezone}
                onClose={() => setShowTimezoneSelector(false)}
                onTimezoneSelect={handleTimezoneSelect}
              />
            </div>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("portal-root")
  );
}
