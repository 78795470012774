import React, { useState } from "react";
import { TECollapse } from "tw-elements-react";
import { sendMixpanelEvent } from "../../../../services/mixpanelService";

export default function DistanceAccordian(props): JSX.Element {
  let {
    locationDistance,
    setLocationDistance,
    collapseShow,
    showCheckbox,
    setShowCheckbox,
  } = props || {};

  const handleDistanceChange = (event) => {
    setLocationDistance(event.target.value);
    sendMixpanelEvent("Filter Applied", { Type: "Distance" });
  };
  return (
    <div id="" className="">
      <div className="  dark:bg-darkModeMain">
        <TECollapse
          show={collapseShow}
          className="accordionFilter !mt-0 !rounded-b-none  !shadow-none pb-12"
        >
          {/* <fieldset>
            <div className="ml-5">
              <div className="relative flex items-start">
                <CheckBoxDetails
                  index={1}
                  id={"allResults"}
                  name={"All results"}
                  checked={showCheckbox}
                  onChange={() => {
                    setShowCheckbox(!showCheckbox)
                    if (showCheckbox == true) {
                      setLocationDistance(DEFAULT_LOCATION_DISTANCE)
                    } else {
                      setLocationDistance("")
                    }

                  }
                  }
                />
              </div>
            </div>
          </fieldset> */}

          <div className="mx-4 mt-4">
            <div className="mb-2 text-[13px] ">
              up to <span className="font-semibold">{locationDistance} mi</span>{" "}
              away
            </div>
            <input
              type="range"
              min="0"
              max="100"
              value={locationDistance}
              onChange={handleDistanceChange}
              onMouseLeave={handleDistanceChange}
              // disabled={showCheckbox ? true : false}
              className="custom-slider w-full bg-gray-200 rounded-lg appearance-none cursor-pointer"
              style={
                {
                  "--slider-percentage": `${(locationDistance / 100) * 100}%`,
                } as React.CSSProperties & { "--slider-percentage": string }
              }
            />
          </div>
        </TECollapse>
      </div>
    </div>
  );
}
