import { useRef, useState, useEffect } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "../DropdownEditRemove";
import { formatTimeTo12Hour } from "../../../utils/DateTimeFormat";
import DirectoryImageInitials from "../../atom/directoryImageInitials";
import PropTypes from "prop-types";
import { DirectoryDetails } from "../../../services/directory/DirectoryDetails";
import dummyMap from "../../../assets/Map.png";
LocationHoursCards.propTypes = {
  data: PropTypes.any,
  apiData: PropTypes.object.isRequired,
  handleRemove: PropTypes.func,
  index: PropTypes.number,
  handleEdit: PropTypes.func,
  formikData: PropTypes.any,
};
export default function LocationHoursCards(props) {
  const { data, handleRemove, index, handleEdit, formikData } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { fetchBusinessDetails } = DirectoryDetails((state) => state);
  const profileDetails = fetchBusinessDetails;
  const dropdownRef = useRef(null);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let workingTime =
    data?.business_working_days?.length != 0
      ? data?.business_working_days
      : formikData?.business_working_days?.[0]?.google_working_days
        ? formikData?.business_working_days?.[0]?.google_working_days
        : formikData?.business_working_days;

  return (
    <div className="flex relative min-w-[440px] text-sm rounded-lg border-[1px] border-gray-200 dark:border-darkModeBorder ">
      {/* Directory Image Section */}
      <div className="flex-shrink-0 rounded-lg p-3">
        <DirectoryImageInitials
          imageUrl={dummyMap}
          alt={profileDetails?.data?.name}
          name={""}
          boxColor={""}
        />
      </div>

      {/* Address and Working Time Section */}
      <div className="flex flex-col flex-grow   mt-2 rounded-lg p-4">
        {/* Address Information */}
        <div className="flex flex-col">
          <p className="font-semibold break-words">{data?.address}</p>
          <p className="break-words">
            {`${data.state ? `${data.state}, ` : ""} ${
              data.city ? `${data.city}, ` : ""
            } ${data.zip_code || ""}`}
          </p>
        </div>

        {/* Working Time Section */}
        <div className="mt-3">
          {workingTime?.length > 0 &&
            workingTime.map((data, index) => (
              <div
                key={index}
                className="flex items-center justify-between mt-2"
              >
                {typeof data === "string" ? (
                  <>
                    <p className="font-semibold">
                      {data?.substring(0, 3) || ""}
                    </p>
                    <p className="uppercase">
                      {data?.substring(data?.indexOf(":") + 1) || ""}
                    </p>
                  </>
                ) : Array.isArray(data?.days) && data?.days?.length !== 0 ? (
                  <div className="w-full">
                    <div className="flex justify-between ">
                      <p className="font-semibold">{data?.days.join("-")}</p>
                      <p className="uppercase">
                        {formatTimeTo12Hour(data?.start_time) || ""} -{" "}
                        {formatTimeTo12Hour(data?.end_time) || ""}
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
        </div>
      </div>

      <div className="relative mt-4">
        <button onClick={handleDropdownClick} className="self-start h-4 mr-1 ">
          <BiDotsVerticalRounded size={20} />
        </button>
        <div className="absolute left-5 top-4 !z-50" ref={dropdownRef}>
          {isDropdownOpen && (
            <Dropdown
              handleRemove={() => {
                setIsDropdownOpen(false);
                handleRemove(index);
              }}
              handleEdit={() => {
                setIsDropdownOpen(false);
                handleEdit(data, index);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
