import ReactDOM from "react-dom";
import { CallFlowIcon } from "../../icons/CallIcon";

const CallFlowSaved = ({ handleClose }) => {
  const btnClassName =
    "h-[45px] w-[320px] rounded-full border-[1px]  border-gray-100 text-[12px] font-semibold mb-2";

  const handleCancel = () => {
    handleClose();
  };
  return ReactDOM.createPortal(
    <div className="w-full text-sm h-screen fixed bg-[#666372] bg-opacity-[95%] flex flex-col justify-center items-center">
      <div className="w-[620px] max-h-[260px] h-[260px] justify-between items-center flex flex-col bg-white dark:bg-darkModeMain dark:border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg  relative">
        <div className=" flex items-center justify-center text-center w-[478px] flex-col mt-2">
          <CallFlowIcon
            className="!h-[50px] !w-[50px] mt-4 mb-4"
            fill="#4C5FEF"
          />
          <div className="w-full ">
            <div className="py-2 font-bold text-[18px] pb-4">
              Call Flow Saved
            </div>
            <div>You can access saved call flows on the business profile.</div>
          </div>
        </div>
        <div className="flex justify-center w-full px-4  mt-6 mb-4">
          <div className="px-4 mt-5 flex justify-between flex-col">
            <button
              className={`${btnClassName} bg-ipurple hover:bg-[#383348] hover:text-white text-white`}
              onClick={() => handleCancel()}
            >
              DONE
            </button>
          </div>
        </div>{" "}
      </div>
    </div>,

    document.getElementById("portal-root")
  );
};
export default CallFlowSaved;
