import { useState, useEffect } from "react";
import Calendar from "./Calendar";
import ReactDOM from "react-dom";
import { IoIosClose } from "react-icons/io";
import { CalendarIcon } from "../../icons/Icons";
import dayjs from "dayjs";

export default function DateFilter({
  onClose,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleApply,
  selectedFilter,
  setSelectedFilter,
  futureDateSelect,
}) {
  const [type, setType] = useState("");

  const handleFilterSelect = (filter) => {
    setStartCalendarOpen(false);
    setEndCalendarOpen(false);
    setSelectedFilter(filter);
  };
  const [startCalendarOpen, setStartCalendarOpen] = useState(false);
  const [endCalendarOpen, setEndCalendarOpen] = useState(false);

  const handleStartMonthCalendar = () => {
    // setStartCalendarOpen(false);
    if (selectedFilter !== "all") {
      setStartCalendarOpen(!startCalendarOpen);
      setEndCalendarOpen(false);
    }
  };

  const handleEndMonthCalendar = () => {
    // setEndCalendarOpen(false);

    if (selectedFilter !== "all") {
      setEndCalendarOpen(!endCalendarOpen);
      setStartCalendarOpen(false);
    }
    // selectedFilter !== "all" && setEndCalendarOpen(!endCalendarOpen);
  };

  useEffect(() => {
    if (!startCalendarOpen && !endCalendarOpen) {
      switch (selectedFilter) {
        case "today":
          setStartDate(new Date());
          setEndDate(new Date());
          break;
        case "week":
          let startOfWeek = new Date();
          if (type == "endMonth") {
            if (futureDateSelect) {
              setEndDate(dayjs(startOfWeek).endOf("week")?.$d);
            } else {
              startOfWeek.setDate(new Date().getDate());
              setEndDate(startOfWeek);
            }
          } else {
            if (futureDateSelect) {
              setEndDate(dayjs(startOfWeek).endOf("week")?.$d);
            }
            startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
            setStartDate(startOfWeek);
          }
          break;
        case "month":
          // Logic to set to start of the month
          let startOfMonth = new Date();

          if (type == "endMonth") {
            if (futureDateSelect) {
              setEndDate(dayjs(startOfMonth).endOf("month")?.$d);
            } else {
              setEndDate(startOfMonth);
            }
          } else {
            if (futureDateSelect) {
              setEndDate(dayjs(startOfMonth).endOf("month")?.$d);
            }
            startOfMonth.setDate(1);
            setStartDate(startOfMonth);
          }
          break;
        case "year":
          // Logic to set to start of the month
          let startOfYear = new Date();
          if (type == "endMonth") {
            if (futureDateSelect) {
              setEndDate(dayjs(startOfYear).endOf("year")?.$d);
            } else {
              startOfYear.setFullYear(new Date()?.getFullYear());
              startOfYear.setMonth(new Date()?.getMonth());
              startOfYear.setDate(new Date()?.getDate());
              setEndDate(startOfYear);
            }
          } else {
            if (futureDateSelect) {
              setEndDate(dayjs(startOfYear).endOf("year")?.$d);
            }
            startOfYear.setFullYear(new Date()?.getFullYear());
            startOfYear.setMonth("00");
            startOfYear.setDate("01");
            setStartDate(startOfYear);
          }
          break;
        default:
          // No filter or reset
          // setSelectedDate(new Date());
          break;
      }
    }
  }, [startCalendarOpen, endCalendarOpen, selectedFilter]);
  return ReactDOM.createPortal(
    <div className="absolute top-0 z-50 flex items-center justify-center w-full h-screen text-sm">
      <div className="fixed w-full h-screen bg-black opacity-90"></div>
      <div className="relative min-w-[580px] z-50 rounded-lg bg-white border-[1px]  border-gray-100  dark:border-darkModeBorder dark:bg-darkModeMain min-h-[215px] text-sm flex flex-col justify-center items-center text-center">
        <div className="flex flex-col items-center justify-center w-full text-sm ">
          <div
            className={`flex items-center justify-between w-full h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder`}
          >
            <h1 className="px-4 font-semibold uppercase">Filter by Date</h1>
            <IoIosClose
              onClick={onClose}
              size={30}
              className="mx-4 hover:cursor-pointer"
            />
          </div>
          <>
            <div className="flex flex-wrap items-center w-full gap-2 pb-5 text-[13px] p-4">
              <button
                className={`h-[30px] text-[12px] flex justify-center items-center rounded-md px-4 py-2 ${
                  selectedFilter === "all"
                    ? "bg-ipurple text-white"
                    : "border-[1px]  border-gray-100  border-ipurple  text-ipurple"
                }`}
                onClick={() => handleFilterSelect("all")}
              >
                All
              </button>

              <button
                className={`h-[30px] text-[12px] flex justify-center items-center rounded-md px-4 py-2 ${
                  selectedFilter === "today"
                    ? "bg-ipurple text-white"
                    : "border-[1px]  border-gray-100  border-ipurple text-ipurple "
                }`}
                onClick={() => handleFilterSelect("today")}
              >
                Today
              </button>
              <button
                className={`h-[30px] text-[12px] flex justify-center items-center rounded-md px-4 py-2 ${
                  selectedFilter === "week"
                    ? "bg-ipurple text-white"
                    : "border-[1px]  border-gray-100  border-ipurple text-ipurple "
                }`}
                onClick={() => handleFilterSelect("week")}
              >
                This week
              </button>
              <button
                className={`h-[30px] text-[12px] flex justify-center items-center rounded-md px-4 py-2 ${
                  selectedFilter === "month"
                    ? "bg-ipurple text-white"
                    : "border-[1px]  border-gray-100  border-ipurple text-ipurple "
                }`}
                onClick={() => handleFilterSelect("month")}
              >
                This month
              </button>
              <button
                className={`h-[30px] text-[12px] flex justify-center items-center rounded-md px-4 py-2 ${
                  selectedFilter === "year"
                    ? "bg-ipurple text-white"
                    : "border-[1px]  border-gray-100  border-ipurple text-ipurple "
                }`}
                onClick={() => handleFilterSelect("year")}
              >
                This year
              </button>
              <button
                className={`h-[30px] text-[12px] flex justify-center items-center rounded-md px-4 py-2 ${
                  selectedFilter === "custom"
                    ? "bg-ipurple text-white"
                    : "border-[1px]  border-gray-100  border-ipurple text-ipurple "
                }`}
                onClick={() => handleFilterSelect("custom")}
              >
                Custom
              </button>
            </div>
            <div className="flex items-center w-full gap-4 p-4">
              <div className="flex flex-col w-1/2 space-y-2">
                <p className="self-start">Start date</p>
                <button
                  onClick={handleStartMonthCalendar}
                  disabled={selectedFilter === "all" ? true : false}
                  className="w-full h-[55px] flex justify-between items-center px-3 rounded-lg border-2 border-gray-100 dark:border-darkModeBorder"
                >
                  <p className="font-semibold">
                    {dayjs(startDate)?.format("DD MMMM YYYY")}
                  </p>
                  <CalendarIcon className="w-[25px]" />
                </button>
              </div>{" "}
              {startCalendarOpen && (
                <Calendar
                  filter={selectedFilter}
                  setFilter={() => {
                    setSelectedFilter("custom");
                    setType("startMonth");
                  }}
                  onClose={() => {
                    setStartCalendarOpen(false);
                  }}
                  selectedDate={startDate}
                  setSelectedDate={(date) => setStartDate(date)}
                  keyStart={startCalendarOpen}
                  type="startMonth"
                />
              )}
              <div className="flex flex-col w-1/2 space-y-2">
                <p className="self-start">End date</p>
                <button
                  disabled={selectedFilter === "all" ? true : false}
                  onClick={handleEndMonthCalendar}
                  className="w-full h-[55px] flex justify-between items-center px-3 rounded-lg border-2 border-gray-100 dark:border-darkModeBorder"
                >
                  <p className="font-semibold">
                    {" "}
                    {dayjs(endDate)?.format("DD MMMM YYYY")}
                  </p>
                  <CalendarIcon className="w-[25px]" />
                </button>
              </div>{" "}
            </div>{" "}
            {endCalendarOpen && (
              <Calendar
                filter={selectedFilter}
                setFilter={() => {
                  setSelectedFilter("custom");
                  setType("startMonth");
                }}
                onClose={() => {
                  setEndCalendarOpen(false);
                }}
                selectedDate={endDate}
                setSelectedDate={(date) => setEndDate(date)}
                type="endMonth"
                keyEnd={endCalendarOpen}
              />
            )}
            <div className="w-full border-t-[1px] dark:border-darkModeBorder py-4 mt-2 flex items-center justify-between text-[13px]">
              <button
                onClick={onClose}
                className="mx-4 hover:bg-black dark:hover:bg-white hover:text-white dark:hover:text-black rounded-full font-semibold h-[45px] w-[150px] border-[1px]  border-gray-100  border-b border-gray-100lack dark:border-white uppercase"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleApply();
                }}
                className="mx-4 rounded-full bg-ipurple text-white font-semibold h-[45px] w-[150px] hover:border-[1px]  border-gray-100  hover:border-ipurple hover:text-ipurple hover:bg-inherit  uppercase"
              >
                Apply
              </button>
            </div>
          </>
        </div>
      </div>
    </div>,

    document.getElementById("portal-root")
  );
}
