import { useState, useRef, useEffect } from "react";
import Code from "../../assets/Code.svg";
import { VscEye } from "react-icons/vsc";
import toast from "react-hot-toast";
import { SettingService } from "../../services/setting/SettingService";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import { logOut } from "../../routes/ProtectedRoutes";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { handleTextPaste } from "../../utils/common";
import CustomModal from "../atom/customModal";
import { TbPasswordFingerprint, TbPasswordMobilePhone } from "react-icons/tb";
PinCode.propTypes = {
  setActiveSettingsView: PropTypes.func,
};
export default function PinCode(props) {
  const { setActiveSettingsView } = props;
  const [pin, setPin] = useState(new Array(4).fill(""));
  const inputRefs = useRef(new Array(4).fill(null));
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(90);
  const [eyeView, setEyeView] = useState(false);
  const { changePinApi } = SettingService((state) => state);
  const navigate = useNavigate();
  const { LogoutApi } = AuthPersonalPhone((state) => state);

  const handleChange = (element, index) => {
    const newPin = [...pin];
    newPin[index] = element.value;
    setPin(newPin);

    if (element.value.length === 1 && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (remainingTime == -1) {
        /* empty */
      } else {
        setRemainingTime((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [remainingTime]);

  const handleSubmit = () => {
    if (pin?.filter((data) => data)?.length == 4) {
      setConfirmationModal(true);
    }
  };

  const handleBack = () => {
    setActiveSettingsView("security");
  };

  return (
    <div className="flex flex-col h-full items-center justify-center text-sm p-6 space-y-4">
      <img src={Code} alt="Code" width={60} height={60} />
      <h1 className="font-semibold text-[18px]">PIN Code</h1>
      <p className="w-[250px] text-center">
        Enter a security PIN code to protect your account on this device.
      </p>
      <div className="flex space-x-4 pt-4 pb-2">
        {pin.map((digit, index) => (
          <input
            ref={(el) => (inputRefs.current[index] = el)}
            className="w-[50px] h-[60px] text-lg text-center rounded-lg border-[1px]  border-gray-100  border-neutral-200 dark:bg-darkModeMain"
            key={index}
            type={eyeView ? "text" : "password"}
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(e.target, index)}
            onFocus={(e) => e.target.select()}
            onPaste={(event) => {
              handleTextPaste(event, pin, setPin);
            }}
          />
        ))}
      </div>

      <div className="flex gap-2 items-center pb-8">
        <VscEye
          size={25}
          onClick={() => {
            setEyeView(!eyeView);
          }}
        />
        <p>Show PIN code</p>
      </div>
      <button
        className={
          pin.every((digit) => digit.trim() !== "")
            ? "w-[340px] rounded-full bg-ipurple h-[45px] uppercase text-sm text-white flex justify-center items-center hover:font-bold"
            : "w-[340px] rounded-full bg-gray-300 h-[45px] uppercase text-sm text-white flex justify-center items-center hover:font-bold"
        }
        onClick={handleSubmit}
      >
        Confirm
      </button>
      <button
        className="w-[340px] rounded-full border-[1px]  border-gray-100  border-b border-gray-100lack dark:border-white h-[45px] uppercase text-sm hover:font-bold flex justify-center items-center"
        onClick={handleBack}
      >
        Cancel
      </button>
      {confirmationModal && (
        <CustomModal
          onClose={() => setConfirmationModal(false)}
          icon={<TbPasswordFingerprint size={40} className="black" />}
          title={"Confirmation"}
          description={"Do you want to create the pin?"}
          closeText={"Okay"}
          handleApiCall={() => {
            setConfirmationModal(false);
            if (pin.every((digit) => digit.trim() !== "")) {
              changePinApi({ pin_code: pin.join("") }).then((data) => {
                if (data.success == true) {
                  toast.success(
                    "Pin is updated successfully. Please sign in again."
                  );
                  logOut(LogoutApi, navigate);
                } else {
                  toast.error(data.message);
                }
              });
            }
          }}
        />
      )}
    </div>
  );
}
