import ReactDOM from "react-dom";
import { IoClose } from "react-icons/io5";
import { CallFlowIcon } from "../../../icons/CallIcon";
import { useState } from "react";

const CallFlowModal = ({
  handleClose,
  setShowRescheduleCallModal,
  showRescheduleCallModal,
  selectedData,
}) => {
  const btnClassName =
    "h-[45px] w-[320px] rounded-full border-[1px]  border-gray-100 text-[12px] font-semibold mb-2";
  const handleCallNow = () => {};
  const handleScheduleCallClick = () => {
    setShowRescheduleCallModal(true);
  };
  const handleCancel = () => {
    handleClose();
  };
  return ReactDOM.createPortal(
    <div className="w-full text-sm h-screen fixed bg-[#666372] bg-opacity-[95%] flex flex-col justify-center items-center">
      <div className="w-[620px] max-h-[565px] h-[565px] justify-between items-center flex flex-col bg-white dark:bg-darkModeMain dark:border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg  relative">
        <div className=" flex items-center justify-center text-center w-[478px] flex-col mt-2">
          <CallFlowIcon
            className="!h-[50px] !w-[50px] mt-4 mb-4"
            fill="#4C5FEF"
          />
          <div className="w-full ">
            <div className="py-2 font-bold text-[18px] pb-4">
              {selectedData || ""}
            </div>
            <div>
              Would you like to use the saved call flow to contact
              <span className="font-semibold"> Premier Smile </span>? The
              following actions will be applied automatically:
            </div>
          </div>
        </div>
        <div className="font-semibold  p-3 z-10 text-[13px] mt-[35px] w-[320px] h-[150px] divide-y-[1px] dark:divide-darkModeBorder flex flex-col justify-center items-center rounded-lg bg-white dark:bg-darkModeMain border-[1px]  border-gray-100  dark:border-darkModeBorder">
          <ul className="font-normal">
            <li className="py-1 text-[13px]">
              <span className="font-semibold">Press 2</span> for Provider
              Services
            </li>
            <li className="py-1 text-[13px]">
              <span className="font-semibold">Press 3 </span>for {selectedData}
              Issues
            </li>
            <li className="py-1 text-[13px]">
              Enter your provider number:{" "}
              <span className="font-semibold">575347 </span>
            </li>
            <li className="py-1 text-[13px]">
              <span className="font-semibold"> Press 1</span> to speak with a
              representative
            </li>
          </ul>
        </div>
        <div className="flex justify-center w-full px-4  mt-6 mb-4">
          <div className="px-4 mt-5 flex justify-between flex-col">
            <button
              className={`${btnClassName} bg-ipurple hover:bg-[#5A70F9] text-white `}
              onClick={() => handleCallNow()}
            >
              CALL NOW
            </button>
            <button
              className={`${btnClassName} bg-black hover:bg-[#383348] hover:text-white text-white`}
              onClick={handleScheduleCallClick}
            >
              SCHEDULE A CALL
            </button>
            <button
              className={`${btnClassName} text-black hover:bg-[#383348] hover:text-white `}
              onClick={handleCancel}
            >
              CANCEL
            </button>
          </div>
        </div>{" "}
      </div>
    </div>,

    document.getElementById("portal-root")
  );
};
export default CallFlowModal;
