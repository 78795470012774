import ReactDOM from "react-dom";
import AddContactView from "../CreateEditProfile/AddButtonPopup/AddContactView";

export default function EditContactPopup({ onClose, ...rest }) {
  return ReactDOM.createPortal(
    <div className="absolute top-0 z-10 flex flex-col items-center justify-center w-full h-screen text-sm text-center ">
      <div className="fixed  w-[100%]  h-[100%] bg-black opacity-80"></div>
      <div className="absolute z-40 items-center flex flex-col w-[900px] min-h-[385px] border-[1px]  border-gray-100  dark:border-darkModeBorder bg-white  rounded-lg dark:bg-darkModeMain ">
        <AddContactView onClose={onClose} pageType="" {...rest} />
      </div>
    </div>,
    document.getElementById("portal-root")
  );
}
