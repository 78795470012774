import { useState } from "react";
import { IoAdd } from "react-icons/io5";
import PropTypes from "prop-types";
import LocationHoursCards from "./LocationHoursCards";
import AddLocationHours from "../AddViews/AddLocationHours";
import EditContactPopup from "../../business-profile/EditContactPopup";
import AddLocationPopup from "../AddViews/AddLocationPopup";
UpdateLocationHoursView.propTypes = {
  apiData: PropTypes.any,
  formik: PropTypes.object.isRequired,
};
export default function UpdateLocationHoursView(props) {
  const { formik, apiData } = props;
  const [isAddLocationOpen, setIsAddLocationOpen] = useState(false);
  const [editLocation, setEditLocation] = useState({});
  const [editIndex, setEditIndex] = useState("");

  const handleLocationClick = () => {
    setIsAddLocationOpen(EditContactPopup);
  };

  const handleClose = () => {
    setIsAddLocationOpen(false);
    setEditLocation(editLocation);
  };

  const handleRemove = (index) => {
    const newArray = [...formik.values.business_locations];
    newArray.splice(index, 1);
    formik.setFieldValue("business_locations", newArray);
  };

  const handleEdit = (data, index) => {
    setEditLocation(data);
    setEditIndex(index);
    setIsAddLocationOpen(true);
  };

  return (
    <div className="flex flex-col mb-28 w-full text-sm bg-white rounded-lg dark:bg-darkModeMain border-[1px] border-gray-100 dark:border-darkModeBorder">
      <div className="uppercase h-[60px]  border-b border-gray-100 dark:border-darkModeBorder flex flex-end items-center justify-between font-semibold">
        <h1 className="px-4 flex">Locations & Working hours</h1>
        <button
          onClick={handleLocationClick}
          className="w-[180px] h-[45px] mr-2 uppercase rounded-full lack dark:border-white flex justify-center items-center gap-2 text-[13px] font-[600] dark:hover:bg-white dark:hover:text-black"
        >
          <IoAdd size={22} className="bg-black text-white border rounded-xl" />
          Add Location
        </button>
      </div>

      {Array?.isArray(formik?.values?.business_locations) &&
        formik?.values?.business_locations?.length != 0 && (
          <div className="flex flex-wrap w-full gap-3 px-4 p-4">
            {formik?.values?.business_locations?.map((data, index) => {
              return (
                <div key={index || data.id} className="w-[440px]">
                  <LocationHoursCards
                    data={data}
                    apiData={apiData}
                    handleRemove={handleRemove}
                    handleEdit={handleEdit}
                    index={index}
                    formikData={formik?.values}
                  />
                </div>
              );
            })}
          </div>
        )}

      <div className="flex flex-col items-center w-full dark:border-darkModeBorder"></div>

      {isAddLocationOpen && (
        <AddLocationPopup
          formik={formik}
          onClose={handleClose}
          apiData={apiData}
          editLocation={editLocation}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
        />
      )}
    </div>
  );
}
