const CheckBoxDetails = (props) => {
  let { index,id,name, checked, onChange } = props||{};
  return (
    <>
      <div className="flex items-center h-6">
        <input
          id={id}
          aria-describedby={`category-${index}-description`}
          name="categories"
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={`category-${index}`} className="font-medium ">
          {name}
        </label>{" "}
      </div>
    </>
  );
};

export default CheckBoxDetails
