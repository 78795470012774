import { useState, useCallback, useEffect } from "react";
import NotesContainer from "../NotesContainer";
import NotesCards from "../NotesCards";
import { TicketsDetails } from "../../../services/tickets/TicketDetails";
import { VITE_API_PAGE_LIMIT } from "../../../utils/constants";
import { truncateString } from "../../../utils/numberFormat";
import { IoMdAddCircle } from "react-icons/io";

export default function NewNotesView(props) {
  let {
    business_id,
    selectedNote,
    setSelectedNote,
    setEditNote,
    selectedFiles,
    setSelectedFiles,
    isEditorOpen,
    setIsEditorOpen,
  } = props || {};

  const {
    listTicketNotesDetailsApi,
    listTicketNotesDetails,
    listNotesDetailsApi,
    clearState,
    clearSelectNote,
    createNotesTextApi,
    updateNotesApi,
    totalNotesCount,
    totalPinnedNotesCount,
    createNotesAttachementTextApi,
  } = TicketsDetails((state) => state);
  const [noteContent, setNoteContent] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [deleteNoteDoc, setDeleteNoteDoc] = useState(null);
  const [filter, setFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [pinned, setPinned] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState(null);

  const openNewNoteEditor = () => {
    setSelectedNote("");
    setSelectedContainer({});
    setSelectedFiles([]);
    if (setIsEditorOpen) {
      setIsEditorOpen(true);
    }
    clearSelectNote();
  };

  let getNotesDetailsApi = (page) => {
    if (business_id) {
      let userData = {
        business_id: business_id,
        keyword: "",
        note_type: "",
        sort_by: "",
        sort_order: "desc",
        page: page || currentPage,
        limit: VITE_API_PAGE_LIMIT,
        is_pinned: pinned,
        attachments: "",
        newRecord: page ? true : false,
      };
      clearState();
      listTicketNotesDetailsApi(userData).then(() => {
        setCurrentPage(page + 1 || currentPage + 1);
      });
    }
  };

  useEffect(() => {
    getNotesDetailsApi();
  }, [business_id, filter]);

  const revertCurrentState = () => {
    clearState();
    setCurrentPage(1);
    setEditNote({});
    setSelectedFiles([]);
  };

  const handleSingleTicketDetails = () => {
    if (selectedNote) {
      listNotesDetailsApi({
        notes_id: selectedNote.id,
        business_id: business_id,
      });
    }
  };

  useEffect(() => {
    setNoteContent({});
    handleSingleTicketDetails();
  }, [selectedNote]);

  const saveNote = useCallback(
    async (newContent) => {
      const selectedId = selectedNote?.id == "new note" ? "" : selectedNote?.id;
      if (selectedFiles?.length > 0 && !selectedId) {
        // taxt with attachments
        const data = {
          files: selectedFiles,
          note_type: "files",
          title: "Personal Files",
          description: newContent?.description,
        };

        await createNotesAttachementTextApi(business_id, data).then(
          async () => {
            await getNotesDetailsApi(currentPage > 1 ? 1 : currentPage - 1);
            await listNotesDetailsApi({
              notes_id: selectedId,
              business_id: business_id,
            });
            await setSelectedNote(listTicketNotesDetails[0]);
            await setEditNote(listTicketNotesDetails[0]);
            await setSelectedFiles([]);
          }
        );
      } else if (selectedId) {
        // update
        const editedData = {
          delete_upload_ids: "",
          title: truncateString(newContent?.description || "New Note", 30),
          description: newContent?.description,
          files: selectedFiles,
        };
        await updateNotesApi(
          //for edited note
          business_id,
          selectedId,
          editedData
        );
        await setSelectedFiles([]);
      } else {
        if (newContent?.description?.length != 0) {
          const data = {
            // for text note
            note_type: "text",
            title: truncateString(newContent?.description || "New Note", 30),
            description: newContent?.description,
          };
          await createNotesTextApi(business_id, data).then((noteData) => {
            setEditNote(noteData?.data?.data);
            setSelectedContainer(noteData?.data?.data);
            setSelectedNote(noteData?.data?.data);
          });
        }
      }
      if (setIsEditorOpen) {
        setIsEditorOpen(false);
      }
      // setSelectedNote(null);
    },
    [selectedNote, selectedFiles]
  );

  const handleDeleteNote = async () => {
    const editedData = {
      delete_upload_ids: deleteNoteDoc,
      title: truncateString(selectedNote?.description || "New Note", 30),
      description: selectedNote?.description,
      files: [],
    };

    await updateNotesApi(business_id, selectedNote?.id, editedData);

    setDeleteNoteDoc("");
  };

  useEffect(() => {
    if (deleteNoteDoc) {
      handleDeleteNote();
    }
  }, [deleteNoteDoc]);

  return (
    <div className="w-full  !h-[calc(100dvh-340px)] bg-white dark:bg-darkModeMain rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder flex text-sm overflow-y-auto">
      <div className="flex flex-col items-center w-1/3  border-r-[1px] border-gray-100 dark:border-darkModeBorder">
        <div className="flex w-full items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder justify-between min-h-[60px] h-[60px]">
       
       <span className="font-bold px-3"> NOTES</span>
        <div className="inline-block m-3 h-[27px] min-h-[0.5em] w-0.5 self-stretch bg-gray-100 dark:bg-darkModeBorder"></div>

          <div className="flex items-center w-full gap-3 py-4">
            <button
              className="text-sm font-semibold text-ipurple"
              onClick={() => {
                setFilter("all");
                setPinned(false);
                revertCurrentState();
              }}
            >
              All
              <span>{totalNotesCount > 0 ? totalNotesCount : 0}</span>
            </button>
            <button
              className="text-sm "
              onClick={() => {
                setFilter("pinned");
                setPinned(true);
                revertCurrentState();
              }}
            >
              Pinned
              <span>{totalPinnedNotesCount ? totalPinnedNotesCount : 0}</span>
            </button>
          </div>
        </div>
        <div className="w-full justify-center h-[50px] items-center cursor-pointer  divide-y-[1px] dark:divide-darkModeBorder border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder" onClick={openNewNoteEditor}>
                 <button  className="flex m-4 items-center">
            <IoMdAddCircle size={26} /><span className="font-bold pl-3">Add Note</span>

          </button>
        </div>
        <NotesCards
          notes={listTicketNotesDetails}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          setSelectedNoteId={(e, index) => {
            setActiveIndex(index);
            setSelectedFiles([]);
            setSelectedNote(e);
          }}
          setSelectedContainer={setSelectedContainer}
          selectedNoteId={selectedNote}
          setIsEditorOpen={setIsEditorOpen}
          onDelete={() => {
            setEditNote({});
            setNoteContent({});
          }}
          renderParentData={() => {
            getNotesDetailsApi();
            // handleSingleTicketDetails();
          }}
          businessId={business_id}
          handleApiCall={getNotesDetailsApi}
          currentPage={currentPage}
        />
      </div>
      <div className="flex flex-col w-2/3">
        <NotesContainer
          selectedNote={selectedContainer}
          activeIndex={activeIndex}
          isEditorOpen={isEditorOpen}
          handleSingleTicketDetails={handleSingleTicketDetails}
          setIsEditorOpen={setIsEditorOpen}
          saveNote={saveNote}
          openNewNoteEditor={openNewNoteEditor}
          businessId={business_id}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          handleApiCall={getNotesDetailsApi}
          setDeleteNoteDoc={setDeleteNoteDoc}
          noteContent={noteContent}
          setNoteContent={setNoteContent}
          renderParentData={() => {
            getNotesDetailsApi();
          }}
        />
      </div>
    </div>
  );
}
