import AIbutton from "../../assets/roundbutton.webp";
import logo from "../../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import SearchInputDashboard from "../search-results/searchInputDashboard";
import { AiChatApi } from "../../services/chat/AiChat";

export default function DirectoryLanding() {
  let navigate = useNavigate();
  const { clearDetailState, clearAiChatState, clearState } = AiChatApi(
    (state) => state
  );

  const handleAIChatClick = () => {
    clearDetailState();
    clearAiChatState();
    clearState();
    navigate("/chat");
  };

  return (
    <div className="flex flex-col items-center justify-center text-sm bg-lightBg dark:bg-darkModeBG dashboard  h-full">
      <div className="flex flex-col  border-t-transparent items-center justify-center w-full h-full text-center border-[1px]  border-gray-100  bg-white  rounded-b-lg dark:border-darkModeBorder dark:bg-darkModeMain ">
        <div className="flex flex-col items-center w-full">
          <img
            src={logo}
            alt="website-logo"
            width={"220px"}
            height={"104px"}
            draggable={false}
            className="!mb-[40px]"
          />
          <h1 className="text-[20px] font-bold">Get Started</h1>
          <p className="mt-[10px] mb-[30px]">
            Use search to find the businesses
          </p>
          <SearchInputDashboard pageType="dashboard" />
          <div className="text-center flex flex-col items-center">
            <p className="my-[30px] font-semibold">
              Or have a conversation with a helpful AI-powered assistant
            </p>
            <button
              id="Show-Tutorial"
              onClick={handleAIChatClick}
              className="w-[250px] h-[45px] font-semibold uppercase text-[13px]  text-white bg-gradient-to-r from-[#48CFFF] via-[#24138F] to-ipink rounded-full relative flex items-center justify-center text-center"
            >
              <img
                src={AIbutton}
                alt="round AI button"
                width={35}
                height={35}
                className="absolute left-1"
              />
              <p>Chat with AI</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
