import { useState } from "react";
import ReactDOM from "react-dom";
import { IoClose } from "react-icons/io5";
const SaveCallFlow = ({ handleClose, handleSave }) => {
  const [callFlow, setCallFlow] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const handleSaveChanges = () => {
    if (callFlow.length != 0) {
      setShowAlert(false);
      handleSave(callFlow);
    } else {
      setShowAlert(true);
    }
  };
  return ReactDOM.createPortal(
    <div className="w-full text-sm h-screen fixed bg-[#666372] bg-opacity-[95%] flex flex-col justify-center items-center">
      <div className="w-[640px] max-h-[345px] justify-center items-center flex flex-col bg-white dark:bg-darkModeMain dark:border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg  relative">
        <div className="w-full h-[60px] flex justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder items-center">
          <h1 className="px-4 font-semibold uppercase">Save Call Flow</h1>
          <button
            onClick={() => handleClose()}
            className="absolute top-4 right-4 "
          >
            <IoClose size={25} />
          </button>
        </div>
        <div className="w-full px-4 h-[145px] flex items-center ">
          <div className="w-full">
            <div className="py-2">Flow name *</div>
            <div>
              {" "}
              <input
                type="text"
                placeholder="Enter here"
                value={callFlow}
                onChange={(e) => setCallFlow(e.target.value)}
                className="w-full  h-[45px] font-bold border-gray-100 dark:border-darkModeBorder bg-inherit rounded-lg placeholder:text-sm  focus-within:ring-ipurple focus-within:ring-[1px] focus-within:border-0"
              />
              {showAlert && (
                <span className="text-[red]">Flow name is mandatory</span>
              )}
            </div>
            <div className="w-[600px] pt-3">
              Save the flow of this call so you can get to the representative
              faster next time you call with a similar issue. You can access
              saved call flows on the business profile.
            </div>
          </div>
        </div>

        <div className="flex justify-between w-full px-4  mt-6 mb-4">
          <button
            onClick={() => handleClose()}
            className="w-[220px] h-[45px] rounded-full bg-white border-[1px] border-[black] text-black hover:bg-[#383348] hover:text-white hover:border-[#383348] uppercase text-sm font-semibold  "
          >
            Cancel
          </button>
          <button
            onClick={handleSaveChanges}
            className="w-[220px] h-[45px] rounded-full text-white bg-ipurple"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>,

    document.getElementById("portal-root")
  );
};

export default SaveCallFlow;
