import { MdVerified } from "react-icons/md";
import { TbBrandMessenger, TbLockCancel } from "react-icons/tb";
import { MdOutlineAccessTime } from "react-icons/md";
import { useEffect, useState } from "react";
import { LuMail, LuPhone } from "react-icons/lu";
import { PiChatTeardropText } from "react-icons/pi";
import { BsChatText } from "react-icons/bs";

export default function ContactsCard(props) {
  const [contactDetails, setContactDetails] = useState([]);
  let data = props || {};
  let { business_contacts } = data?.data?.business || {};
  const optionIcon = {
    Phone: LuPhone,
    Service: LuPhone,
    Mail: LuMail,
    Livechat: PiChatTeardropText,
    FacebookMessenger: TbBrandMessenger,
    Website: BsChatText,
  };
  useEffect(() => {
    setContactDetails(
      business_contacts?.length != 0 &&
        business_contacts?.map((contacts) => {
          return {
            id: contacts?.business_id || "",
            name: contacts?.account_type?.name || "",
            icon: optionIcon[contacts?.account_type?.name] || "",
            contact: contacts?.account_value || "",
            hasTime: contacts?.is_hasTime || "",
            isVerified: contacts?.is_verified || "",
            isLocked: contacts?.is_locked || "",
            isPrivate: contacts?.is_private || "",
          };
        })
    );
  }, [business_contacts]);

  const formatNumber = (number) => {
    const Number = `${number?.substring(0, 1)}-${number?.substring(
      1,
      4
    )}-${number?.substring(4, 7)}-${number?.substring(7)}`;
    return Number;
  };

  return (
    <div className="flex flex-col w-full ">
      {contactDetails?.map((contact, index) => (
        <div
          className="h-[70px]  w-full flex-grow flex justify-between items-center px-6 py-12 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder"
          key={index}
        >
          <div className="flex items-center gap-3">
            {contact?.icon && <contact.icon size={25} />}
            <div className="flex flex-col space-y-1 text-sm">
              <div className="flex items-center gap-2">
                <p className="font-bold">{formatNumber(contact?.contact)}</p>
                {contact?.isLocked && <TbLockCancel size={20} />}
                {contact?.isVerified && (
                  <MdVerified color="#48CFFF" size={20} />
                )}
              </div>
              <p>{contact?.name}</p>
            </div>
          </div>
          {contact?.hasTime && (
            <div className="flex items-center gap-2 px-4 py-2 text-sm font-semibold text-black bg-gray-200 rounded-full">
              <MdOutlineAccessTime />
              <p>~20m</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
