import * as React from "react";
import { useEffect } from "react";
import ReactGoogleMapBusinessProfile from "../search-results/LocationMap";

interface ReactMapProps {
  businessDetailsData: any;
  setLocationData: (data: any) => void;
}

export default function ReactMap(props: ReactMapProps) {
  const { businessDetailsData, setLocationData } = props;

  const locationData = businessDetailsData?.data;

  useEffect(() => {
    if (setLocationData) {
      setLocationData(locationData);
    }
  }, [locationData, setLocationData]);

  return (
    <div className="w-full h-full object-cover overflow-hidden flex">
      <ReactGoogleMapBusinessProfile
        locationDataBusinessProfile={locationData}
      />
    </div>
  );
}
