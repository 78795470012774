import { DirectoryDetails } from "../../services/directory/DirectoryDetails";

export default function IaolloScore() {
  const { reviewData } = DirectoryDetails((state) => state);

  let reviewRatings = reviewData?.stats?.ratings?.[0] || [];
  let getReviewValues = Object.entries(reviewRatings || {});

  return (
    <div className="flex flex-col w-full p-6 space-y-2">
      {getReviewValues?.length !== 0 &&
        getReviewValues?.reverse()?.map((score, index) => {
          const ratingValue = score[1];
          const totalReviews = reviewData?.data?.length || 1;
          const percentage =
            ratingValue !== null
              ? Math.round((ratingValue / totalReviews) * 100)
              : 0;

          return (
            <div
              key={index}
              className="flex items-center justify-center w-full gap-4 px-2 text-sm "
            >
              <p className="w-8 text-right">
                {score?.[0]?.split("rating_")?.[1]}
              </p>
              <div className="w-3/4 relative h-[10px] rounded-full bg-gray-100 dark:bg-darkModeBG">
                {ratingValue !== null && (
                  <div
                    style={{
                      width: `${percentage}%`,
                    }}
                    className={`absolute left-[0px] h-[10px] rounded-full bg-gradient-to-r from-[#FA579A] to-[#24138F]  dark:bg-darkModeBG`}
                  ></div>
                )}
              </div>
              <p className="w-12 text-right">{`${percentage}%`}</p>
            </div>
          );
        })}
    </div>
  );
}
