import { useEffect } from "react";
import { AuthPersonalPhone } from "../services/auth/personal/AuthPersonal";
import toast from "react-hot-toast";
import { getUserImage, userDetails } from "../routes/ProtectedRoutes";
import DirectoryContainer from "../components/directory/DirectoryContainer";

export default function Dashboard() {
  const { getProfileDetails, sipAuthLoginApi } = AuthPersonalPhone(
    (state) => state
  );

  useEffect(() => {
    getProfileDetails()
      .then(async (response) => {
        if (typeof response != "string") {
          await userDetails();
          await getUserImage();
          if (!localStorage.getItem("sipDetails")) {
            await sipAuthLoginApi();
          }
        } else {
          toast.error(response);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);

  return (
    <div className="flex justify-between w-full pt-2 overflow-x-hidden font-sans text-sm bg-lightBg dark:bg-darkModeBG">
      <div className="bg-gray-100 dark:bg-darkModeBG">
        <DirectoryContainer />
      </div>
    </div>
  );
}
