import ReactDOM from "react-dom";
import { IoClipboardOutline } from "react-icons/io5";
const DeleteNotePopup = ({ onClose, confirmDeletion }) => {
  return ReactDOM.createPortal(
    <div
      className="absolute top-0 z-[60] flex items-center justify-center w-full h-screen overflow-hidden"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="fixed -top-20 w-[200%]  h-[200%] bg-black -left-10 opacity-90"></div>
      <div className="w-[580px] h-[250px] z-40 bg-white rounded-lg dark:bg-darkModeMain flex flex-col justify-center items-center space-y-4">
        <IoClipboardOutline size={40} className="text-ipurple" />
        <h1 className="text-[20px] font-bold ">Delete Note</h1>
        <p className="text-sm">Are you sure you want to delete this note?</p>
        <div className="flex gap-4 pt-2">
          <button
            onClick={onClose}
            className="w-[260px] h-[45px] rounded-full bg-white border-2 text-black hover:bg-[#383348] hover:text-white hover:border-[#383348] uppercase text-sm font-semibold  "
          >
            Cancel
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              confirmDeletion();
            }}
            className="w-[260px] h-[45px] rounded-full uppercase text-sm hover:border-2 bg-black text-white hover:bg-[#383348] hover:text-white font-semibold"
          >
            Delete
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("portal-root")
  );
};

export default DeleteNotePopup;
