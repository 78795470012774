import React, { useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { sendMixpanelEvent } from "../../../services/mixpanelService";
export default function ChatCallSettingsView() {
  const [callVolume, setCallVolume] = useState(50);
  const [ringVolume, setRingVolume] = useState(50);
  const [fontSize, setFontSize] = useState(2);

  const fontSizeOptions = {
    1: "Small",
    2: "Medium",
    3: "Large",
  };

  const handleSliderChange = (value, type) => {
    const roundedValue = Math.round(value / 10) * 10;
    type === "call" ? setCallVolume(roundedValue) : setRingVolume(roundedValue);
  };

  const getSliderBackgroundStyle = (value) => {
    const percentage = (value / 100) * 100;
    return {
      background: `linear-gradient(to right, #8442EC 0%, #445FF5 ${percentage}%, #e2e8f0 ${percentage}%, #e2e8f0 100%)`,
      borderRadius: "5px",
      height: "4px",
      width: "100%",
    };
  };

  const sliderStyle = {
    appearance: "none",
    width: "100%",
    height: "25px",
    marginTop: "-20px",
    background: "transparent",
    outline: "none",
  };
  const getSliderBackgroundStyleForFontSize = (value) => {
    let percentage;
    switch (value) {
      case 1: // Small
        percentage = 0;
        break;
      case 2: // Medium
        percentage = 50;
        break;
      case 3: // Large
        percentage = 100;
        break;
      default:
        percentage = 0;
    }
    return {
      background: `linear-gradient(to right, #8442EC 0%, #445FF5 ${percentage}%, #e2e8f0 ${percentage}%, #e2e8f0 100%)`,
      borderRadius: "5px",
      height: "4px",
      width: "100%",
    };
  };

  const [isVolumeToggled, setIsVolumeToggled] = useState(false);
  const [isVibrationToggled, setIsVibrationToggled] = useState(false);
  const [isSoundToggled, setIsSoundToggled] = useState(false);
  const [isPronounceToggled, setIsPronounceToggled] = useState(false);

  const handlePronounceToggle = () => {
    setIsPronounceToggled(!isPronounceToggled);
  };

  const handleSoundToggle = () => {
    setIsSoundToggled(!isSoundToggled);
  };

  const handleVolumeToggle = () => {
    setIsVolumeToggled(!isVolumeToggled);
  };
  const handleVibrationToggle = () => {
    setIsVibrationToggled(!isVibrationToggled);
  };

  return (
    <div className="flex flex-col w-full px-6 py-5 text-sm scrollable-container h-full overflow-y-auto">
      <h1 className="text-[16px] font-semibold">Sound</h1>
      <div className=" w-full h-[80px] gap-20 flex items-center justify-between">
        <div className="flex flex-col w-1/2 space-y-1">
          <div className="flex items-center justify-between w-full pt-2 mb-3">
            <p className="font-semibold">Call Volume</p>
            <p>{callVolume}%</p>
          </div>
          <div
            style={{
              ...getSliderBackgroundStyle(callVolume),
              background: `linear-gradient(to right, #8442EC 0%, #445FF5 ${callVolume}%, #e2e8f0 ${callVolume}%, #e2e8f0 100%)`,
            }}
          >
            <input
              type="range"
              min="0"
              max="100"
              value={callVolume}
              onChange={(e) => {
                handleSliderChange(e.target.value, "call");
                sendMixpanelEvent("Call Settings Changed", {
                  Type: "Call Volume",
                });
              }}
              style={sliderStyle}
              className="volume-slider"
            />
          </div>
        </div>
        <div className="flex flex-col w-1/2 space-y-1">
          <div className="flex items-center justify-between w-full pt-2 mb-3">
            <p className="font-semibold">Ring Volume</p>
            <p>{ringVolume}%</p>
          </div>
          <div
            style={{
              ...getSliderBackgroundStyle(ringVolume),
              background: `linear-gradient(to right, #8442EC 0%, #445FF5 ${ringVolume}%, #e2e8f0 ${ringVolume}%, #e2e8f0 100%)`,
            }}
          >
            <input
              type="range"
              min="0"
              max="100"
              value={ringVolume}
              onChange={(e) => {
                handleSliderChange(e.target.value, "ring");
                sendMixpanelEvent("Call Settings Changed", {
                  Type: "Ring Volume",
                });
              }}
              style={sliderStyle}
              className="volume-slider"
            />
          </div>
        </div>
      </div>
      <div className="w-1/2 h-[60px] flex py-8 justify-between pr-10">
        <p className="font-semibold">Vibrate for incoming calls</p>
        <div
          className={`relative w-[45px] h-[25px] rounded-full cursor-pointer ${
            isVibrationToggled
              ? "bg-[#4c5fef3b] dark:bg-secondary-900"
              : "bg-gray-200  dark:bg-gray-400"
          }`}
          onClick={() => {
            handleVibrationToggle();
            sendMixpanelEvent(
              isVibrationToggled
                ? "Call Settings Changed"
                : "Call Settings Changed",
              { Type: "Vibrate" },
            );
          }}
        >
          <div
            className={`h-[21px] w-[21px] rounded-full  absolute top-0.5 transition-transform duration-300 ease-in-out ${
              isVibrationToggled
                ? "translate-x-[21px] bg-ipurple  "
                : "bg-white dark:bg-darkModeMain translate-x-0.5"
            }`}
          ></div>
        </div>
      </div>
      <h2 className="text-[16px] font-semibold pt-8 pb-2">Call Preference</h2>
      <div className="items-center justify-between w-full gap-20 flex h-[80px]">
        <div className="flex items-center justify-between w-1/2 h-full">
          <p className="font-semibold">Language</p>
          <div className="w-[240px] h-[55px] rounded-lg border-[1px]  border-gray-100  border-gray-200 dark:border-darkModeBorder p-3 flex justify-between items-center">
            <p className="font-semibold">English</p>
            <IoChevronDown size={20} />
          </div>
        </div>
        <div className="flex items-center justify-between w-1/2 h-full">
          <p className="font-semibold">IVR Voice</p>
          <div className="w-[240px] h-[55px] rounded-lg border-[1px]  border-gray-100  border-gray-200 dark:border-darkModeBorder p-3 flex justify-between items-center">
            <p className="font-semibold">Female</p>
            <IoChevronDown size={20} />
          </div>
        </div>
      </div>
      <h3 className="text-[16px] font-semibold pt-8 pb-2">Chat</h3>
      <div className=" w-full h-[80px] flex items-center justify-between gap-20">
        <div className="flex flex-col w-1/2 space-y-1">
          <div className="flex items-center justify-between w-full pt-2 mb-3">
            <p className="font-semibold">Font Size</p>
            <p>{fontSizeOptions[fontSize]}</p>
          </div>
          <div style={getSliderBackgroundStyleForFontSize(fontSize)}>
            <input
              type="range"
              min="1"
              max="3"
              step="1"
              value={fontSize}
              style={sliderStyle}
              onChange={(e) => {
                setFontSize(Number(e.target.value));
              }}
              className="w-full font-size-slider"
            />
          </div>
        </div>

        <div className="flex items-center justify-between w-1/2 pt-2 mb-3">
          <p className="font-semibold">Bubble Color</p>
          <div className="w-[190px] flex items-center gap-2">
            <div className="w-[30px] h-[30px] rounded-full bg-gradient-to-tr via-[#f7b500] from-[#fa3600] relative  to-sky-400">
              <div className="bg-white absolute w-[8px] h-[8px] z-30 top-[11px] left-[11px] rounded-full"></div>
            </div>
            <div className="w-[30px] h-[30px] rounded-full bg-sky-400"></div>
            <div className="w-[30px] h-[30px] rounded-full bg-inherit border-4 border-ipurple"></div>
            <div className="w-[30px] h-[30px] rounded-full bg-ipurple"></div>
            <div className="w-[30px] h-[30px] rounded-full bg-black"></div>
          </div>
        </div>
      </div>
      <div className="w-1/2 h-[60px] flex py-8 justify-between pr-10">
        <p className="font-semibold">Chat Vibration</p>
        <div
          className={`relative w-[45px] h-[25px] rounded-full cursor-pointer ${
            isVolumeToggled
              ? "bg-[#4c5fef3b] dark:bg-secondary-900"
              : "bg-gray-200 dark:bg-gray-400"
          }`}
          onClick={handleVolumeToggle}
        >
          <div
            className={`h-[21px] w-[21px] rounded-full  absolute top-0.5 transition-transform duration-300 ease-in-out ${
              isVolumeToggled
                ? "translate-x-[21px] bg-ipurple"
                : "bg-white dark:bg-darkModeMain translate-x-0.5"
            }`}
          ></div>
        </div>
      </div>
      <div className="w-1/2 h-[60px] flex py-8 justify-between pr-10">
        <p className="font-semibold">Sound</p>
        <div
          className={`relative w-[45px] h-[25px] rounded-full cursor-pointer ${
            isSoundToggled
              ? "bg-[#4c5fef3b] dark:bg-secondary-900 "
              : "bg-gray-200 dark:bg-gray-400"
          }`}
          onClick={handleSoundToggle}
        >
          <div
            className={`h-[21px] w-[21px] rounded-full  absolute top-0.5 transition-transform duration-300 ease-in-out ${
              isSoundToggled
                ? "translate-x-[21px] bg-ipurple"
                : "bg-white dark:bg-darkModeMain translate-x-0.5"
            }`}
          ></div>
        </div>
      </div>
      <div className="w-1/2 h-[60px] flex py-8 justify-between pr-10">
        <p className="font-semibold">Pronounce messages</p>
        <div
          className={`relative w-[45px] h-[25px] rounded-full cursor-pointer ${
            isPronounceToggled
              ? "bg-[#4c5fef3b] dark:bg-secondary-900"
              : "bg-gray-200 dark:bg-gray-400"
          }`}
          onClick={handlePronounceToggle}
        >
          <div
            className={`h-[21px] w-[21px] rounded-full  absolute top-0.5 transition-transform duration-300 ease-in-out ${
              isPronounceToggled
                ? "translate-x-[21px] bg-ipurple"
                : "bg-white dark:bg-darkModeMain translate-x-0.5"
            }`}
          ></div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full pt-14">
        <button className="w-[220px] h-[45px] rounded-full border-2 border-b border-gray-100lack uppercase hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black font-semibold dark:border-white bg-inherit">
          Reset
        </button>
        <button className="w-[220px] h-[45px] rounded-full bg-ipurple text-white uppercase font-semibold hover:bg-inherit hover:border-2 hover:border-ipurple hover:text-ipurple">
          Save
        </button>
      </div>
    </div>
  );
}
