
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { getTimeDiffDetails } from "../../utils/TimeDetails";
import { downloadFile } from "../atom/downloadFile.jsx";
import { useEffect, useRef, useState } from "react";
import { AiChatApi } from "../../services/chat/AiChat";
import { fileSizeCalculate } from "../../utils/numberFormat";
import { DownloadIcon } from "../icons/Icons.jsx";

export default function AttachmentMessage(props) {
  let { data, hitShowChatApi } = props || {};
  const { removeFileApi, clearState } = AiChatApi((state) => state);

  const [removeFile, setRemovefile] = useState("");
  const [openDropDown, setOpenDropDown] = useState(false);

  let { file_name, file_path, file_size, note_id } = data?.message_json || {};
  const handleRemoveFileClick = async (deleteId) => {
    let noteId = note_id;
    await removeFileApi(data?.chat_id, noteId, deleteId);
    await setOpenDropDown(false);
    // await clearState();
    await hitShowChatApi();
  };

  const dropdownRef = useRef(null);
  const excludedComponents = [dropdownRef];

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element or its ancestors are in the excluded components
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !excludedComponents.some((component) =>
          component.current.contains(event.target)
        )
      ) {
        setRemovefile(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, excludedComponents]);

  return (
    <div className="flex items-center justify-end w-full px-6 mx-4 text-sm ">
    <div className="flex w-[390px] h-[80px] min-w-[390px] max-w-[390px] min-h-[80px] bg-white dark:bg-darkModeMain max-h-[80px] border-gray-100 dark:border-darkModeBorder border-2 rounded-lg ">
      <div className="flex items-center justify-between w-full px-4">
        <div className="flex items-center gap-3">
          <div className="w-[40px] h-[40px]  rounded-md overflow-hidden">
              <img src={file_path} alt="img not found" />
          </div>
          <div className="flex flex-col">
              <p className="font-semibold truncate line-clamp-1 max-w-[240px]">{file_name}</p>
              <p className="text-sm truncate line-clamp-1 max-w-[280px] ">{getTimeDiffDetails(data?.created_at)} - <span>{fileSizeCalculate(file_size)}</span></p>
          </div>
          </div>
          <div className="flex items-center">
            <button onClick={() => downloadFile(data?.message_json)}>
            <DownloadIcon className='w-[25px]' />
            </button>
            <button
              onClick={() => {
                setRemovefile(data?.id);
              }}
            >
              <div className="relative">
                <PiDotsThreeVerticalBold
                  size={20}
                  className=""
                  onClick={(event) => {
                    setOpenDropDown(true);
                    event?.stopPropagation();
                    setRemovefile((prevNoteId) =>
                      prevNoteId === data?.id ? null : data?.id
                    );
                  }}
                />

                {removeFile == data?.id && openDropDown ? (
                  <div
                    ref={dropdownRef}
                    onClick={() => {
                  
                      handleRemoveFileClick(data?.message_json?.upload_id);
                    }}
                    className=" border-[1px]  border-gray-100  dark:bg-darkModeMain hover:bg-lightBg dark:hover:bg-darkModeBG  dark:border-darkModeBorder absolute p-2 w-[150px] text-xs  bg-white rounded-md shadow top-6 right-2 line-clamp-1"
                  >
                    Remove
                  </div>
                ) : (
                  ""
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
