export const downloadFile=(filePath)=>{
    try{
    const xhr = new XMLHttpRequest();
    xhr.open("GET", filePath?.file_path, true);
    xhr.responseType = "blob";
    
    xhr.onload = function () {
      if (xhr.status === 200) {
        const blob = xhr.response;
    
        // Create anchor link
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob); // Create object URL from the blob
        element.download = filePath.file_name;
    
        // Simulate link click
        document.body.appendChild(element); // Required for this to work in Firefox
        element.click();
    
        // Clean up
        document.body.removeChild(element);
        URL.revokeObjectURL(element.href);
      }
    };
    
    xhr.send();
  }catch(err){
    console.log(err,"error")
  }
  }
