import { useEffect, useRef, useState } from "react";
import QuickChat from "./QuickChat";
import { BsThreeDotsVertical } from "react-icons/bs";
import MessageList from "./MessageList";
import ChatForm from "./ChatForm";
import { AiChatApi } from "../../services/chat/AiChat";
import { io } from "socket.io-client";
import { getToken } from "../../routes/ProtectedRoutes";
import {
  getCurrentLocation,
  SOCKET_BASE_URL,
  VITE_API_PAGE_LIMIT,
} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import AIChatCards from "../business-profile/AIChatCards";
import InfiniteScroll from "react-infinite-scroll-component";
import { IoAddCircle, IoCloseCircle } from "react-icons/io5";
import ScrollingLoader from "../atom/loader/scrollingLoader";
import { IoIosClose, IoIosSearch } from "react-icons/io";
import { SearchIcon } from "../icons/SearchIcon";
import { BookMarkIcon, BusinessViewIcon } from "../icons/Icons";
import Loader from "../atom/loader/Loader";

export default function ChatContainer(props) {
  const { className, businessIdAiChat, pageType } = props || {};

  const {
    CreateAiChat,
    ChatDetailsData,
    ChatDetails,
    getShowChatDetailsApi,
    getlistChatsApi,
    getShowChatDetails,
    clearState,
    getAIChatList,
    clearAiChatState,
    aiChatListShow,
    aiChatMetaData,
    aiChatListShowLastPage,
    loadingAiChat,
  } = AiChatApi((state) => state);

  const [createChat, setCreateChat] = useState(false);
  let aiChatId = ChatDetails?.chat?.id;
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const currentPageRef = useRef(1);
  const [businessIdShow, setBusinessIdShow] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [chatName, setChatName] = useState("");
  const locationDetails = useRef({
    lat: "",
    lng: "",
  });
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [click, setClick] = useState(false);
  const [callSocket, setCallSocket] = useState(false);
  const [chatDetailsLocally, setChatDetailsLocally] = useState([]);
  const [chatRecord, setChatRecord] = useState({});
  const [loadingChatScreen, setLoadingChatScreen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [temporaryMessage, setTemporaryMessage] = useState("");
  const [requestCall, setRequestcall] = useState(false);
  const [chatId, setChatId] = useState("");
  const [calling, setCalling] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedScheduledTime, setSelectedScheduledTime] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [cardData, setCardData] = useState({});

  const [activeTab, setActiveTab] = useState("all");

  const baseClass =
    "box-border bg-white  dark:bg-darkModeMain h-full border-b-[0.5px] border-r-[0.5px]  border-gray-100  dark:border-darkModeBorder  w-full relative flex flex-col rounded-br-lg";

  const combinedClasses = `${baseClass} ${className}`;
  const [chat, setChat] = useState(true);

  useEffect(() => {
    if (getShowChatDetails?.data) {
      setChatDetailsLocally(getShowChatDetails?.data);
      setChatRecord(getShowChatDetails?.chat);
    } else {
      setChatDetailsLocally([]);
      setChatRecord([]);
    }
  }, [getShowChatDetails]);

  let { message_text, business_id, businessChatId, hitChatApi, page } =
    state || {};

  useEffect(() => {
    if (business_id || businessIdAiChat) {
      setBusinessIdShow(business_id || businessIdAiChat || "");
    }
  }, [business_id, businessIdAiChat]);
  useEffect(() => {
    if (aiChatId) {
      setChatId(aiChatId);
    }
  }, [aiChatId]);

  const hitShowChatApi = async () => {
    if (chatId) {
      await getShowChatDetailsApi({
        id: chatId,
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    if (hitChatApi && businessChatId) {
      getShowChatDetailsApi({
        id: businessChatId,
      });
    }
  }, [hitChatApi, businessChatId]);

  const connectionOptions = {
    transports: ["websocket"],
    enabledTransports: ["ws", "wss"],
    autoConnect: false,
    reconnection: true,
    // reconnectionAttempts: Infinity,
  };

  const socketRef = useRef(io(SOCKET_BASE_URL, connectionOptions));

  const tokenDetails = getToken();

  const chatWindowRef = useRef();

  useEffect(() => {
    if (Array?.isArray(ChatDetailsData) && ChatDetailsData?.length != 0) {
      setChatId(ChatDetailsData?.[0]?.chat_id || 0);
    }
  }, [ChatDetailsData]);

  useEffect(() => {
    if (selectedScheduledTime) {
      setInputValue(selectedScheduledTime);
    }

    if (calling) {
      setInputValue("1");
    }
    if ((inputValue && selectedScheduledTime) || (inputValue && calling)) {
      handleSubmit();
      setSelectedScheduledTime(false);
      setCalling(false);
    }
  }, [inputValue, selectedScheduledTime, calling]);

  // Handle incoming socket message
  useEffect(() => {
    const socket = socketRef.current;

    const handleChatMessage = (data) => {
      setLoader(false);
      setChatDetailsLocally((prevMessages) => [...prevMessages, data]);
    };
    const handleErrorMessage = (data) => {
      setLoader(false);
      console.error(data?.error_text || "Error");
      // setChatDetailsLocally((prevMessages) => [...prevMessages, data]);
    };
    socket.on("chat-message", handleChatMessage);
    socket.on("error-message", handleErrorMessage);
    socket.on("disconnect", (reason) => {
      console.error(reason);
      // socket.connect();
    });

    return () => {
      socket.off("chat-message", handleChatMessage);
      socket.disconnect();
      // socket.off("connect");
      // socket.off("user-connected");
      // socket.off("error-message");
      // socket.off("disconnect");
    };
  }, []);

  const handleSocketJoined = () => {
    const socket = socketRef.current;

    if (socket?.connected) {
      socket.emit("chat-joined", chatId, tokenDetails);
      setTimeout(() => {
        socket.emit("send-chat-message", {
          chat_id: chatId,
          message: inputValue,
        });
      }, 2000);
    } else {
      socket.connect();

      socket.emit("chat-joined", chatId, tokenDetails);
      // socket.timeout(500).on("user-connected", () => {
      setTimeout(() => {
        socket.emit("send-chat-message", {
          chat_id: chatId,
          message: inputValue,
        });
      }, 2000);

      // });
    }

    setInputValue("");
    setCallSocket(false);
  };
  const handleCustomMessage = (data) => {
    setLoader(true);
    const socket = socketRef.current;

    // socket.connect();
    // socket.timeout(500).emit("chat-joined", chatId, tokenDetails);

    // // setTimeout(() => {
    // socket.timeout(500).on("user-connected", () => {
    //   socket.emit("send-chat-message", {
    //     chat_id: chatId,
    //     message: data,
    //   });
    // });

    if (socket?.connected) {
      // socket.emit("chat-joined", chatId, tokenDetails);
      setTimeout(() => {
        socket.emit("send-chat-message", {
          chat_id: chatId,
          message: data,
        });
      }, 2000);
    } else {
      socket.connect();

      socket.emit("chat-joined", chatId, tokenDetails);
      // socket.timeout(500).on("user-connected", () => {
      setTimeout(() => {
        socket.emit("send-chat-message", {
          chat_id: chatId,
          message: data,
        });
      }, 2000);

      // });
    }

    setInputValue("");
    setCallSocket(false);
  };
  useEffect(() => {
    if (callSocket && chatId && inputValue?.length !== 0) {
      handleSocketJoined();
    }
  }, [callSocket, chatId, tokenDetails]);

  useEffect(() => {
    getCurrentLocation(
      (lat, lon) => {
        locationDetails.current = { lat: lat, lng: lon };
      },
      (error) => {
        locationDetails.current = { lat: null, lng: null };
      },
    );
  }, []);
  const handleSubmit = async (msg = null) => {
    const inputValueNew = inputValue || msg;

    if (inputValueNew === "") {
      return;
    }

    setTemporaryMessage(inputValueNew);
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }

    setLoader(true);

    const currentPathName = pathname?.split("/")[1];
    const objId = pathname?.split("/")[2];

    if ((!click && !chatId) || message_text == "Request Call") {
      setInputValue("");
      await CreateAiChat({
        message_text: inputValueNew,
        business_id:
          currentPathName === "business" && objId
            ? objId
            : businessIdShow
              ? businessIdShow
              : "",
        lat: locationDetails.current?.lat?.toString() || "",
        lng: locationDetails.current?.lng?.toString() || "",
        request_call:
          message_text == "Request Call" || objId
            ? true
            : requestCall
              ? requestCall
              : "",
        chat_id: chatId || "",
      }).then((response) => {
        if (response) {
          setLoader(false);
          if (businessIdShow) {
            getlistChatsApi({
              page: "1",
              perpage: VITE_API_PAGE_LIMIT,
              business_id: businessIdShow || "",
            });
          }
          navigate(pathname, { replace: true });
          setChatDetailsLocally(response);
        }
        setLoader(false);
      });

      await hitShowChatApi();
    } else {
      setCallSocket(true);
    }

    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
    setSelectedFiles([]);
    setClick(true);
  };
  const joinSocket = () => {
    const socket = socketRef.current;
    if (!socket?.connected) {
      socket.connect();
      socket.emit("chat-joined", chatId, tokenDetails);
    }
  };
  useEffect(() => {
    const chatContainer = document.getElementById("chat-container");
    if (temporaryMessage !== "") {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [temporaryMessage]);

  useEffect(() => {
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      setTemporaryMessage("");

      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [chatDetailsLocally]);

  useEffect(() => {
    if (businessIdShow) {
      clearState();
      setChatDetailsLocally([]);
    }
  }, [businessIdShow]);

  const getAiChatList = () => {
    getAIChatList({
      page: currentPageRef?.current,
      business_id: business_id || businessIdAiChat || businessIdShow || "",
      deleted: activeTab != "all" ? true : false,
    }).then((data) => {
      setShowLoader(false);

      if (data?.meta?.current_page) {
        currentPageRef.current = data?.meta?.current_page + 1;
      }
    });
  };

  useEffect(() => {
    if (chatDetailsLocally?.length == 0 && chatId) {
      setLoadingChatScreen(true);
      getShowChatDetailsApi({ id: chatId }).then((response) => {
        setLoadingChatScreen(false);
        setChatDetailsLocally(response);
      });
    }
  }, [chatId]);
  useEffect(() => {
    let getData = async () => {
      await clearAiChatState();
      await getAiChatList();
      await setShowLoader(true);
    };
    if (activeTab) {
      getData();
    }
  }, [activeTab]);
  const handleSearchClick = () => {
    setIsInputOpen((prevState) => !prevState);
  };
  useEffect(() => {
    if (!isInputOpen) {
      setSearchQuery("");
    }
  }, [isInputOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      className={`${
        pathname?.split("/")[1] == "tickets"
          ? "!h-[calc(100dvh-390px)] pt-0"
          : pathname?.split("/")[1] == "business"
            ? "!h-[calc(100dvh-361px)]"
            : "!h-[calc(100dvh-200px)] pt-6"
      }   relative`}
    >
      <div
        className={`w-full h-[60px] justify-between items-center relative text-sm bg-white border-[1px] border-gray-100 rounded-t-lg dark:bg-darkModeMain dark:border-darkModeBorder flex`}
      >
        <div className="flex px-5">
          <button className="font-semibold uppercase h-[60px]">AI Chat</button>
          <div className="inline-block m-[15px]  h-[27px] min-h-[0.5em] w-[1px] self-stretch bg-gray-100 dark:bg-darkModeBorder"></div>
          <div className="flex gap-[15px]">
            <button
              className={
                activeTab == "all" ? " text-blue-600 font-semibold" : ""
              }
              onClick={() => {
                currentPageRef.current = 1;
                setActiveTab("all");
              }}
            >
              All{" "}
              <span
                className={
                  activeTab == "all"
                    ? " text-blue-600 font-normal"
                    : " text-gray-400"
                }
              >
                {aiChatMetaData?.total || 0}
                {/* total number of chats should be here */}
              </span>
            </button>

            <button
              className={
                activeTab == "archive" ? " text-blue-600 font-semibold" : " "
              }
              onClick={() => {
                currentPageRef.current = 1;
                setActiveTab("archive");
              }}
            >
              Archive{" "}
              <span
                className={
                  activeTab == "archive"
                    ? " text-blue-600 font-normal"
                    : " text-gray-400"
                }
              >
                {aiChatMetaData?.archived_count || 0}
                {/* total number of archived records */}
              </span>
            </button>
          </div>
        </div>
        <div className="px-3 flex items-center">
          {!isInputOpen && (
            <button onClick={handleSearchClick} className="">
              <IoIosSearch size={23} />
            </button>
          )}
          {isInputOpen && (
            <div className="relative flex items-center w-[400px] z-30">
              <SearchIcon className="w-[25px] absolute left-2" />
              <input
                type="text"
                autoComplete="off"
                id="ticket-search"
                placeholder="Search business"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full  h-[45px] border-2 border-gray-100 dark:border-darkModeBorder bg-inherit rounded-lg px-10 placeholder:text-sm focus-within:ring focus-within:ring-ipurple"
              />

              <IoIosClose
                onClick={handleSearchClick}
                size={25}
                className="absolute right-2"
              />
            </div>
          )}
          <div className="relative  pl-3 cursor-pointer">
            <BsThreeDotsVertical
              size={23}
              onClick={() => {
                setShowOptions(!showOptions);
              }}
            />
            <div className="absolute right-4 top-9 !z-50">
              {showOptions && (
                <div
                  className="font-semibold  z-10 text-[13px] w-[220px] divide-y-[1px] dark:divide-darkModeBorder flex flex-col justify-center items-center h-[80px] rounded-lg bg-white dark:bg-darkModeMain border-[1px]  border-gray-100  dark:border-darkModeBorder"
                  ref={dropdownRef}
                >
                  <button
                    className="  flex items-center rounded-t-lg w-full gap-1 px-2 hover:bg-lightBg h-[40px] dark:hover:bg-darkModeBG"
                    onClick={() => {}}
                  >
                    <BookMarkIcon
                      className="w-[23px] svg-icon"
                      width={"24px"}
                      height={"24px"}
                    />
                    <p>Show Chat Tips</p>
                  </button>
                  <button
                    className=" flex items-center rounded-b-lg w-full gap-1 px-2 hover:bg-lightBg h-[40px]  dark:hover:bg-darkModeBG"
                    onClick={() => {}}
                  >
                    <BusinessViewIcon
                      className="w-[23px] dark:text-white svg-icon"
                      size={24}
                    />
                    <p>View Tickets</p>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex border-l-[1px] h-full bg-white rounded-b-lg dark:border-darkModeBorder  ">
        <div className="flex flex-col w-1/3 max-[1100px]:w-1/2  border-r-[1px] border-b-[1px] rounded-bl-lg border-gray-100 dark:border-darkModeBorder ">
          <div className="w-full flex items-center border-b-[1px] !min-h-[50px] dark:bg-darkModeMain dark:border-darkModeBorder">
            <button
              className="flex items-center justify-between w-full p-[13px] dark:bg-darkModeMain dark:border-darkModeBorder"
              onClick={() => {
                const socket = socketRef.current;
                socket.disconnect(true);
                setCreateChat(!createChat);
                setChatDetailsLocally([]);
                setClick(false);
                setCallSocket(false);
                setChatId(null);
                setBusinessIdShow(null);
                setChat(false);
                // clearDetailState();
              }}
            >
              <div className="flex items-center gap-[13px] text-center justify-center">
                {/* <div className="  relative rounded-full border-[1px]  border-gray-100  min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] z-20  flex items-center justify-center dark:border-darkModeBorder">
                  <img
                    src={button}
                    alt="chat button"
                    className=" w-full h-full z-10 min-w-[45px] min-h-[45px] mt-2.5 absolute"
                  />
                </div> */}
                <button>
                  {createChat ? (
                    <IoCloseCircle size={25} />
                  ) : (
                    <IoAddCircle size={25} />
                  )}
                </button>
                <p className="font-semibold text-[14px]">New chat</p>
              </div>
            </button>
          </div>

          <div
            id="chatScrollableDiv"
            className="overflow-y-auto h-full dark:border-darkModeBorder dark:bg-darkModeMain "
          >
            {showLoader ? (
              <Loader />
            ) : aiChatListShow?.length != 0 ? (
              <InfiniteScroll
                dataLength={aiChatListShow?.length}
                next={getAiChatList}
                hasMore={currentPageRef?.current <= aiChatListShowLastPage}
                scrollableTarget="chatScrollableDiv"
                scrollThreshold={0.5}
                style={{ overflow: "unset" }}
                loader={loadingAiChat ? <ScrollingLoader /> : <></>}
              >
                <AIChatCards
                  data={aiChatListShow}
                  loading={loadingAiChat}
                  chatId={chatId}
                  handleChat={(data) => {
                    // const socket = socketRef.current;
                    // // socket.emit("disconnect");
                    // socket.disconnect(true);

                    setChatId(data?.id);
                    setChatName(data?.llm_result?.name);
                    setCreateChat(false);
                    setCardData(data);
                    setBusinessIdShow(data?.business_id);
                    if (data?.id != chatId) {
                      setChatDetailsLocally([]);
                    }
                    setClick(false);
                  }}
                />
              </InfiniteScroll>
            ) : (
              <div className="flex justify-center pt-3 text-[16px]">
                No records
              </div>
            )}
          </div>
        </div>
        <div ref={chatWindowRef} className="flex flex-col w-full ">
          <div className={combinedClasses}>
            <div
              className=" h-[calc(100dvh-290px)] overflow-x-hidden scrollbar-hide"
              id="chat-container"
            >
              <QuickChat />
              {loadingChatScreen ? (
                <Loader />
              ) : (
                <MessageList
                  handleSubmit={handleSubmit}
                  setInputValue={setInputValue}
                  chatDetailsLocally={chatDetailsLocally}
                  chatRecord={chatRecord}
                  loader={loader}
                  temporaryMessage={temporaryMessage}
                  message_text={message_text}
                  selectedScheduledTime={selectedScheduledTime}
                  setSelectedScheduledTime={setSelectedScheduledTime}
                  chatId={chatId}
                  setCalling={setCalling}
                  setIsModalOpen={(data) => {
                    setIsModalOpen(data);
                  }}
                  setChatDetailsLocally={(data) => setChatDetailsLocally(data)}
                  initiateSocketChat={(data) => {
                    handleCustomMessage(data);
                  }}
                  joinSocket={joinSocket}
                  pageType={pageType}
                  page={page}
                  businessIdStatusTab={businessIdShow}
                  hitShowChatApi={hitShowChatApi}
                />
              )}
            </div>
            {!isModalOpen && (
              <div className="bottom-0 w-full">
                <ChatForm
                  handleSubmit={handleSubmit}
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  chatId={chatId}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  hitShowChatApi={hitShowChatApi}
                  setClick={setClick}
                  handleLoader={(data) => setLoader(data)}
                  chat={chat}
                  setChat={setChat}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
