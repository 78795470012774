import FilesDropdown from "./FilesDropdown";

export default function SingleTicketFiles() {
  return (
    <div className="flex flex-col w-[110px] min-h-[500px] mx-3 space-y-1 text-center relative">
      <div className="w-[110px] mb-2 bg-gray-100 dark:bg-darkModeBG rounded-lg h-[110px]"></div>
      <p className="text-[12px] font-semibold uppercase">IMG1234124.JPG</p>
      <p className="text-[12px] mb-3 uppercase">1.21 MB</p>
      <div className="absolute top-1 right-1">
        <FilesDropdown imgDetails={""} />
      </div>
    </div>
  );
}
