import { formatInternationalPhoneNumber } from "../../utils/numberFormat";
import { DateTimeFormat, callDurationFormat } from "../../utils/DateTimeFormat";
import { LuPhone } from "react-icons/lu";
import { CallIcon } from "../icons/CallIcon";

export default function CallHistoryCard(props) {
  let { ticketCallHistoryDetailsData } = props || {};

  return (
    <div>
      {ticketCallHistoryDetailsData?.length !== 0 &&
        ticketCallHistoryDetailsData?.map((data) => (
          <div
            key={data.id}
            className="w-full border-b border-gray-100-[1px] border-gray-100 h-[70px] flex justify-between items-center dark:border-darkModeBorder"
          >
            <div className="flex items-center gap-3 p-4">
              <CallIcon className='text-black dark:text-white' />
              <div className="flex flex-col">
                <p className="text-sm font-semibold">
                  {formatInternationalPhoneNumber(data?.phone_number)}
                </p>
                <p className="text-sm">
                  {callDurationFormat(data?.call_duration)}
                </p>
              </div>
            </div>
            <p className="p-6 text-[13px]">
              {DateTimeFormat(data?.created_at)}
            </p>
          </div>
        ))}
    </div>
  );
}
