/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from "react";
import RescheduleCallModal from "../calls/RescheduleCallModal";
import IalloLogo from "../icons/IalloLogo";
import { useNavigate } from "react-router-dom";
import { CALL_NUMBER_CHECK } from "../../utils/constants";
import { AiChatApi } from "../../services/chat/AiChat";
import dayjs from "dayjs";
import MessageAnswered from "./MessageAnswered";
const RequestCallCard = (props) => {
  const navigate = useNavigate();

  const selectedMessageId = useRef(null);
  const [showRescheduleCallModal, setShowRescheduleCallModal] = useState(false);
  const { GetBusinessChatData, BusinessesChatDetails } = AiChatApi(
    (state) => state
  );

  const [businessDetails, setBusinessDetails] = useState([]);
  const btnClassName =
    "h-[45px] w-[305px] rounded-full border-[1px]  border-gray-100 text-[12px] font-semibold";

  const callingNumber =
    businessDetails?.business_contacts?.filter(
      (x) => x?.account_type_id == CALL_NUMBER_CHECK
    )?.[0]?.account_value || null;

  useEffect(() => {
    if (props?.businessIdStatusTab) {
      GetBusinessChatData({
        id: props?.businessIdStatusTab,
      }).then((response) => {
        setBusinessDetails(response);
      });
    }
  }, [props?.businessIdStatusTab]);

  const handleCallNow = async () => {
    await props.setCalling(true);

    setTimeout(() => {
      navigate(`/business/call`, {
        state: {
          callingNumber: callingNumber,
          businessId: props?.businessIdStatusTab,
          ticketId: BusinessesChatDetails?.data?.latest_open_ticket_id,
          page: "calls",
          pageName: "search",
        },
      });
    }, 3000);
  };

  const socketReply = (selectedOpt, msgText, messageJson) => {
    selectedMessageId.current = messageJson?.id;
    // setSelectedMessageId(messageJson?.id);
    if (selectedOpt == 1) {
      let currentGivenTime = dayjs();
      currentGivenTime = currentGivenTime.add(5, "minutes");
      const dates = dayjs(currentGivenTime).utc().format("YYYY-MM-DDTHH:mm:ss");
      props.initiateSocketChat(dates + ".000Z");
      const localData = props.chatDetailsLocally?.map((data) => {
        if (data?.id == messageJson?.id) {
          return {
            ...data,
            message_json: { ...data?.message_json, answered: true },
          };
        } else {
          return {
            ...data,
          };
        }
      });

      props.setChatDetailsLocally(localData);
    } else {
      setShowRescheduleCallModal(true);
    }
  };
  return (
    !props?.data?.message_json?.answered && (
      <div className="w-full flex items-center justify-center relative">
        <div className="w-[450px] h-[202px] items-center justify-evenly flex  flex-col bg-white dark:hover:bg-darkModeBG  rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder">

          <div className="absolute  -top-[18px]">
            <IalloLogo
              styleClass="!w-[35px] !h-[35px] absolute"
              iconWidth="27px"
              iconHeight="13px"
            />
          </div>
          <div className="h-[1px]"></div>
          {props?.data?.message_json?.options?.map((option, index) => (
            <button
              key={index}
              className={
                option.title == "Choose Date/Time"
                  ? `${btnClassName} bg-black hover:bg-[#383348] text-white`
                  : `${btnClassName} bg-ipurple hover:bg-[#5A70F9] text-white`
              }
              onClick={() =>
                socketReply(option.value, option.title, props?.data)
              }
            >
              {option.title}
            </button>
          ))}
        </div>
        {showRescheduleCallModal && (
          <RescheduleCallModal
            onClose={(data) => {
              props.initiateSocketChat(data);
              let localData = props.chatDetailsLocally?.map((chatData) => {
                if (chatData?.id == selectedMessageId.current) {
                  return {
                    ...chatData,
                    message_json: { ...chatData?.message_json, answered: true },
                  };
                } else {
                  return {
                    ...chatData,
                  };
                }
              });
              props.setChatDetailsLocally(localData);
              setShowRescheduleCallModal(false);
            }}
            closeModal={() => {
              setShowRescheduleCallModal(false);
            }}
            selectedScheduledTime={props?.selectedScheduledTime}
            setSelectedScheduledTime={props?.setSelectedScheduledTime}
            businessId={props?.businessIdStatusTab}
            scheduledTime={new Date().setMinutes(new Date().getMinutes() + 5)}
            showSchedule={true}
          />
        )}
      </div>
    )
  );
};
export default RequestCallCard;
