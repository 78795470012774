import { useEffect, useMemo, useState } from "react";
import BusProfileDropdown from "./BusProfileDropdown";
import verified from "../../assets/icons/Verification.svg";
import { FaBookmark } from "react-icons/fa";
import { CiBookmark } from "react-icons/ci";
import { IoStar } from "react-icons/io5";
import { HiOutlineDotsVertical } from "react-icons/hi";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ReviewsView from "./views/ReviewsView";
import ContactsView from "./views/ContactsView";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import TicketsView from "./views/TicketsView";
import ReportPopup from "./ReportPopup";
import FilesView from "./views/FilesView";
import InboxView from "./views/InboxView";
import IdentitiesView from "./views/IdentititesView";
import DirectoryImageInitials from "../atom/directoryImageInitials";

import NewNotesView from "./views/NewNotesView";
import { businessProfileContainer } from "../../utils/TimeDetails";
import { validate as isValidUUID } from "uuid";
import ShareButtonDropdown from "./ShareButtonDropdown";
import Loader from "../atom/loader/Loader";
import ChatContainer from "../aichat/ChatContainer";
import { getAccountType } from "../../routes/ProtectedRoutes";
import {
  getCurrentLocation,
  locationDetails,
  PRO_USER,
} from "../../utils/constants";

import { GoLink } from "react-icons/go";
import toast from "react-hot-toast";
import CallFlow from "./views/CallFlow";
import { EditIcon } from "../icons/Icons";
import { useSearch } from "../../context/SearchContext";
import DeleteBusinessPopup from "./DeleteBusinessPopup";

const BusinessProfileContainer = () => {
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [editNote, setEditNote] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { state } = useLocation();
  const { setSearchViewToggle } = useSearch();
  const navigate = useNavigate();
  window.onpopstate = (event) => {
    const previousPathname = event.state ? event.state.pathname : null;
    if (state == "fromSearch" && state?.page != "businessAI") {
      setSearchViewToggle(true);
      navigate(previousPathname, {
        state: state == "fromSearch" ? "search" : "",
      });
    }
  };

  const handleShowDeletePopup = () => {
    setShowDeletePopup(true);
  };
  const handleShowReportPopup = () => {
    setShowReportPopup(!showReportPopup);
  };

  const handleClose = () => {
    setShowReportPopup(false);
    setShowDeletePopup(false);
  };

  const [isShareDropdownOpen, setIsShareDropdownOpen] = useState(false);

  const handleShareDropdownClick = () => {
    setIsShareDropdownOpen(!isShareDropdownOpen);
  };

  const [activeTab, setActiveTab] = useState("Contacts");
  const {
    fetchBusinessDetails,
    unsavedBusinessApi,
    savedBusinessApi,
    fetchBusinessDetailsApi,
    loadingBusinessDetails,
  } = DirectoryDetails((state) => state);
  let { data } = fetchBusinessDetails || {};
  const locationData = locationDetails();
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [locationDetail, setLocationDetail] = useState({});
  const { businessid } = useParams();

  let googlePlaceId = state?.googleBusinessId;
  let business_id = state?.businessid;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOpenDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const accountType = getAccountType();

  let targetBusinessId = googlePlaceId ? data?.id : businessid;

  const renderView = () => {
    switch (activeTab) {
      case "Reviews":
        return <ReviewsView business_id={businessid} />;
      case "Tickets":
        return <TicketsView business_id={businessid} />;
      case "Notes":
        return (
          <NewNotesView
            business_id={businessid}
            key={"business-profile"}
            setSelectedNote={setSelectedNote}
            selectedNote={selectedNote}
            editNote={editNote}
            setEditNote={setEditNote}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            isEditorOpen={isEditorOpen}
            setIsEditorOpen={setIsEditorOpen}
          />
        );
      case "Call Flows":
        return <CallFlow business_id={businessid} />;
      case "Files":
        return <FilesView />;
      case "Identities":
        // opens vaults view and send businessDetails as props
        return <IdentitiesView businessDetails={data} />;
      case "Inbox":
        return <InboxView />;
      case "AI":
        return (
          <div className="h-full">
            <ChatContainer businessIdAiChat={businessid} pageType="AI_CHAT" />
          </div>
        );

      default:
        return (
          <ContactsView
            business_id={targetBusinessId}
            setActiveTab={(data) => {
              setActiveTab(data);
            }}
          />
        );
    }
  };

  useEffect(() => {
    getCurrentLocation(
      (lat, lon) => {
        setLocationDetail({ lat: lat, lng: lon });
      },
      (error) => {
        setLocationDetail({
          lat: locationData?.lat,
          lng: locationData?.long,
        });
      },
    );
  }, []);

  let getBusinessDetails = () => {
    if (!isValidUUID(businessid || business_id)) {
      return navigate("/page-not-found-404");
    }
    fetchBusinessDetailsApi({
      businessId: businessid || business_id,
      googlePlaceId: googlePlaceId || "",
      lat: locationDetail?.lat,
      lng: locationDetail?.lng,
    })
      .then((data) => {
        if (
          data?.response?.data?.message?.includes(
            "invalid input syntax for type uuid:",
          )
        ) {
          navigate("/page-not-found-404");
        }
      })
      .catch(() => {});
  };
  const businessID = useMemo(() => {
    return business_id || businessid;
  }, [business_id, businessid]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getBusinessDetails();
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [businessID, locationDetail]);

  useEffect(() => {
    if (state?.page == "businessAI") {
      setActiveTab("AI");
    } else if (state?.tab == "searchTab") {
      setActiveTab("Contacts");
    }
  }, [state]);
  const tabClass = (tabName) => {
    return `cursor-pointer ${
      activeTab === tabName
        ? "border-b border-gray-100-[3px] mt-[18px] pb-3 border-ipurple text-ipurple"
        : "border-b border-gray-100-[3px] mt-[18px] pb-3 border-transparent "
    }`;
  };
  const handleUnsaved = async (businessId) => {
    await unsavedBusinessApi({ business_id: businessId });
    // await getBusinessDetails();
  };
  const handlesaved = async (businessId) => {
    await savedBusinessApi({
      business_id: businessId,
    });
    // await getBusinessDetails();
  };

  //copy link function
  const handleCopyLink = () => {
    window.navigator.clipboard.writeText(window.location?.href);
    toast.success("Link Copied");
  };
  const handleEditClick = () => {
    navigate(`/edit-business/${businessid}`);
  };

  return (
    <div className="flex flex-col items-center w-full overflow-y-hidden-auto ">
      <>
        {loadingBusinessDetails ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="w-full relative mt-4 flex h-[125px] flex-col border-[1px] bg-white dark:bg-darkModeMain dark:border-darkModeBorder rounded-lg">
            <div className="border-[#ECEDF2]-[1px] border-[#ECEDF2] dark:border-darkModeBorder dark:bg-darkModeBG relative rounded-t-lg w-full h-[81px] flex items-center">
              <DirectoryImageInitials
                imageUrl={data?.upload?.file_path}
                alt={data?.name}
                name={data?.name}
                boxColor={data?.business_color?.toLowerCase()}
                className="mt-2 ml-4"
              />
              <div className="flex flex-col ml-4  mt-2 w-full">
                <div className="flex items-center gap-2">
                  <p className="font-bold text-[20px] capitalize">
                    {data?.name || ""}
                  </p>
                  {data?.is_verified && (
                    <img src={verified} alt="Verified Icon" className="ml-0" />
                  )}
                </div>
                <div className="flex items-center">
                  {data?.is_national && (
                    <div className="flex items-center text-sm text-[#6C7B8A] mr-4">
                      <span>National profile</span>
                    </div>
                  )}
                  {data?.is_verified && (
                    <div className="flex items-center ml-4">
                      <IoStar className="text-ipink" size={16} />
                      <p className="text-sm font-bold text-[13px]">{`${
                        data?.average_review_rating || data?.rating || 0
                      }`}</p>
                      <p className="text-[13px] ml-2">
                        (<span>{data?.reviews_count || 0}</span>)
                        <span className="text-gray-500 ml-2">
                          Google Reviews
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex text-[14px] text-[#6C7B8A] mt-1">
                  <span>{data?.category?.name || ""}</span>
                </div>
              </div>
            </div>

            <div className="w-full h-10  rounded-b-lg flex flex-col  bg-white dark:bg-darkModeMain ">
              <div className="flex items-center justify-between w-full">
                <div className="flex flex-col px-5 pt-8 space-y-0.5 cursor-default w-full">
                  {/* <p className=" text-sm "> {data?.is_verified || ""}</p> */}
                  <div className="flex justify-between w-full pt-2">
                    <div className="flex justify-end items-start w-full absolute top-[70px] mr-6 right-0 mt-2 pt-2 ">
                      {data?.last_updated_at || data?.created_at ? (
                        <div className="text-gray-600 text-sm ">
                          Last time updated{" "}
                          {businessProfileContainer(
                            data?.last_updated_at || data?.created_at || "",
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="absolute top-[12px] right-0 flex items-center gap-2 p-1">
                  <div className="flex justify-center items-center">
                    <button
                      onClick={handleEditClick}
                      className="flex items-center w-full item-centre"
                    >
                      <EditIcon className=" text-black hover:text-ipurple dark:text-white h-[23px] w-[21px] mt-[5px] mr-[3px]" />
                      <p className="font-bold text-[14px] mr-[20px] h-[25px] mt-[10px] text-nowrap">
                        Edit Business
                      </p>
                    </button>
                  </div>

                  <div className="flex">
                    <div className="relative flex items-center w-full">
                      <div className="mr-[3px] mt-1">
                        <GoLink className=" text-black hover:text-ipurple dark:text-white h-[23px] w-[14px] mr-[3px]" />
                      </div>
                      <button
                        onClick={handleCopyLink}
                        className="flex font-bold text-[14px] text-nowrap h-[25px] mt-[10px] pr-5"
                      >
                        {" "}
                        Copy Business Link
                      </button>
                    </div>
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 translate-y-0 opacity-0 group-hover:opacity-100 group-hover:translate-y-[-2px] transition-all duration-200">
                      <div className="relative bg-black text-white text-xs rounded py-1 px-2 w-max whitespace-no-wrap">
                        Copy Business Link
                        <svg
                          className="absolute text-black h-2 w-full left-0 top-full"
                          x="0px"
                          y="0px"
                          viewBox="0 0 255 255"
                          xmlSpace="preserve"
                        >
                          <polygon
                            className="fill-current"
                            points="0,0 127.5,127.5 255,0"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  {Object?.values(data || {})?.length !== 0 &&
                  data?.saved_business_id ? (
                    <button
                      onClick={() => {
                        handleUnsaved(data?.id);
                      }}
                      className="w-[120px] dark:border-darkModeBG rounded-full h-[45px] outline outline-offset-0 outline-white outline-4 border-4  border-ipurple items-center uppercase justify-center text-[12px] mr-2 bg-ipurple font-semibold text-white flex gap-2 p-1"
                    >
                      <FaBookmark className="" size={"16"} />
                      Saved
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handlesaved(data?.id);
                      }}
                      className="w-[120px] outline  dark:bg-darkModeBG outline-offset-0 outline-white outline-4 bg-white rounded-full h-[45px] border-[1px] border-black items-center uppercase justify-center text-[13px] mr-2 font-semibold text-black flex gap-2 p-1"
                    >
                      <CiBookmark
                        className="font-semibold dark:text-white bold stroke-[0.65]"
                        size={"20"}
                      />
                      <span className="text-[13px] dark:text-white dark:bg-darkModeBG font-semibold">Save</span>
                    </button>
                  )}
                  <button onClick={handleOpenDropdown} className=" mr-2 mt-1">
                    <HiOutlineDotsVertical size={20} />
                  </button>
                  {isDropdownOpen && (
                    <div className="absolute right-4 z-[1000] top-20 mt-2">
                      <BusProfileDropdown
                        onReportClick={handleShowReportPopup}
                        onDeleteClick={handleShowDeletePopup}
                        verified={data?.is_verified}
                        businessid={data.id}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-start items-end w-full absolute top-12 ml-6  mt-4 pt-2 text-[14px] ">
              <nav className="">
                <ul className="flex items-center  gap-6 font-semibold">
                  <li
                    className={tabClass("Contacts")}
                    onClick={() => setActiveTab("Contacts")}
                  >
                    Contacts
                  </li>
                  {accountType == PRO_USER && (
                    <li
                      className={tabClass("Call Flows")}
                      onClick={() => setActiveTab("Call Flows")}
                    >
                      Call Flows
                    </li>
                  )}
                  <li
                    className={tabClass("Tickets")}
                    onClick={() => setActiveTab("Tickets")}
                  >
                    Tickets
                  </li>
                  <li
                    className={tabClass("Notes")}
                    onClick={() => {
                      setEditNote(null);
                      setSelectedNote(null);
                      setSelectedFiles(null);
                      setIsEditorOpen(true);
                      setActiveTab("Notes");
                    }}
                  >
                    Notes
                  </li>
                  <li
                    className={tabClass("AI")}
                    onClick={() => setActiveTab("AI")}
                  >
                    AI
                  </li>{" "}
                  <li
                    className={tabClass("Identities")}
                    onClick={() => setActiveTab("Identities")}
                  >
                    Vault
                  </li>
                  <div className="hidden">
                    <li
                      className={tabClass("Files")}
                      onClick={() => setActiveTab("Files")}
                    >
                      Files
                    </li>
                    <li
                      className={tabClass("Identities")}
                      onClick={() => setActiveTab("Identities")}
                    >
                      Identities
                    </li>
                    <li
                      className={tabClass("Reviews")}
                      onClick={() => setActiveTab("Reviews")}
                    >
                      Reviews
                    </li>
                    <li
                      className={tabClass("Inbox")}
                      onClick={() => setActiveTab("Inbox")}
                    >
                      Inbox
                    </li>

                    <div className="rounded-full flex items-center justify-center w-[16px] h-[16px] text-[9px] -ml-4 font-bold text-white bg-ipink ">
                      2
                    </div>
                  </div>
                </ul>
              </nav>

              <div className="flex">
                {/* <div className="flex justify-center items-center">
                  <button
                    onClick={handleEditClick}
                    className="flex items-center w-full flex item-centre"
                  >
                    <EditIcon className=" text-black hover:text-ipurple dark:text-white h-[23px] w-[21px] mt-[5px] mr-[3px]" />
                    <p className="font-bold text-[14px] mr-[20px] h-[25px] mt-[10px] text-nowrap">
                      Edit Business
                    </p>
                  </button>
                </div> */}
                {/* <div className="flex">
                  <div className="relative flex items-center w-full">
                    <div className="mr-[3px] mt-1">
                      <GoLink className=" text-black hover:text-ipurple dark:text-white h-[23px] w-[14px] mr-[3px]" />
                    </div>
                    <button
                      onClick={handleCopyLink}
                      className="flex font-bold text-[14px] text-nowrap h-[25px] mt-[10px] pr-1"
                    >
                      {" "}
                      Copy link
                    </button>
                  </div>
                  <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 translate-y-0 opacity-0 group-hover:opacity-100 group-hover:translate-y-[-2px] transition-all duration-200">
                    <div className="relative bg-black text-white text-xs rounded py-1 px-2 w-max whitespace-no-wrap">
                      Copy Link
                      <svg
                        className="absolute text-black h-2 w-full left-0 top-full"
                        x="0px"
                        y="0px"
                        viewBox="0 0 255 255"
                        xmlSpace="preserve"
                      >
                        <polygon
                          className="fill-current"
                          points="0,0 127.5,127.5 255,0"
                        />
                      </svg>
                    </div>
                  </div>
                </div> */}

                {/* drop down of share button */}
                {isShareDropdownOpen && (
                  <ShareButtonDropdown
                    handleBtn={() => handleShareDropdownClick()}
                  />
                )}
              </div>
            </div>
          </div>
        )}{" "}
        <div className="relative w-full mt-5 !h-[calc(100dvh-450px)]">
          {renderView()}
        </div>
      </>
      {showReportPopup && <ReportPopup onClose={handleClose} />}
      {showDeletePopup && (
        <DeleteBusinessPopup onClose={handleClose} businessid={businessid} />
      )}
    </div>
  );
};
export default BusinessProfileContainer;
