import React, { useEffect, useRef } from "react"
import { HiChevronUpDown } from "react-icons/hi2"
import { NEWEST_FIRST, OLDEST_FIRST } from "../utils/constants"
import { IoIosClose, IoIosSearch } from "react-icons/io"
import { SearchIcon } from "./icons/SearchIcon"
import { CalendarIcon } from "./icons/Icons"
import dayjs from "dayjs"

const FilterOpt = ({ toggleSortDropdown, sortOrder, showSortDropdown, handleSortOrder, handleDateSelectClick, handleSearchClick, handleSearchCancel, setSearchQuery, searchQuery, isInputOpen, selectedFilter, dateDetails }) => {
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleSortDropdown()
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="flex items-center justify-end  text-[13px] gap-3 mx-4 w-full">

      <p className="line-clamp-1">Sort by</p>
      <div className="relative flex flex-col">
        <button
          className="w-[126px] h-[28px] rounded-full border-[1px]  border-gray-100  dark:border-darkModeBorder flex items-center !justify-center font-semibold gap-1"
          onClick={toggleSortDropdown}
        >
          <p>{sortOrder}</p>
          <HiChevronUpDown />
        </button>
        {showSortDropdown && (
          <div className="absolute w-[126px]   mt-2 bg-white border rounded-lg shadow-lg top-full dark:bg-darkModeMain dark:border-darkModeBorder"  >
            <ul className=" divide-y-[1px] divide-gray-100 dark:divide-darkModeBorder w-full text-[13px] font-semibold" ref={dropdownRef}>
              <li>
                <button
                  className="flex w-full h-[30px] items-center px-2 hover:bg-lightBg dark:text-white dark:hover:bg-darkModeBG"
                  onClick={() => handleSortOrder(NEWEST_FIRST)}
                >
                  {NEWEST_FIRST}
                </button>
              </li>
              <li>
                <button
                  className="flex w-full h-[30px] items-center px-2 hover:bg-lightBg dark:text-white dark:hover:bg-darkModeBG"
                  onClick={() => handleSortOrder(OLDEST_FIRST)}
                >
                  {OLDEST_FIRST}
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
      <p>Date</p>
      <button
        onClick={handleDateSelectClick}
        className=" h-[28px] px-3 rounded-full border-[1px]  border-gray-100  dark:border-darkModeBorder flex items-center justify-center font-semibold gap-1"
      >
        <p className="capitalize flex items-center">
          {selectedFilter == "custom" ? dayjs(dateDetails[0] || new Date()).format("DD-MM-YYYY") + " - " + dayjs(dateDetails[1] || new Date()).format("DD-MM-YYYY") : selectedFilter ? selectedFilter : "All"}
          <CalendarIcon className={"w-[20px] !ml-1"} />
        </p>

      </button>
      <div className="inline-block m-3  h-[27px] min-h-[0.5em] w-0.5 self-stretch bg-gray-100 dark:bg-darkModeBorder"></div>
      {!isInputOpen && (
        <button onClick={handleSearchClick} className="" title="click">
          <IoIosSearch size={20} />
        </button>
      )}
      {isInputOpen && (
        <div className="relative flex items-center w-[400px] z-30">
          <SearchIcon className="w-[25px] absolute left-2" />
          <input
            type="text"
            id="ticket-search"
            placeholder="Search business"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full  h-[45px] border-2 border-gray-100 dark:border-darkModeBorder bg-inherit rounded-lg px-10 placeholder:text-sm focus-within:ring focus-within:ring-ipurple focus-within:ring-[1px] focus-within:border-0"
          />

          <IoIosClose
            onClick={handleSearchCancel}
            size={25}
            className="absolute right-2"
          />
        </div>
      )}
    </div>
  )
}
export default FilterOpt