import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";
import { BASE_URL, VITE_API_PAGE_LIMIT } from "../../utils/constants";
import { apiFilterVallidation } from "../../utils/api/apiFilter";

export const SettingService = create((set) => ({
  error: null,
  settingLoading: false,
  updatePhoneResp: {},
  verifyEmailResp: {},
  confirmEmailResp: {},
  pinCodeStatusResp: {},
  changePasswordResp: {},
  changePinResp: {},
  updateResp: {},

  changePhoneNumberApi: async (settingData) => {
    try {
      const response = await AxiosApi.post(
        `${BASE_URL}auth/verify-change-number`,
        settingData
      );

      let { success } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          settingLoading: false,
          updatePhoneResp: response?.data,
        });
      }
      return response?.data;
    } catch (error) {
      set({ settingLoading: false, error: error });
      return error;
    }
  },
  confirmChangeNumberApi: async (settingData) => {
    try {
      const response = await AxiosApi.post(
        `${BASE_URL}auth/confirm-change-number`,
        settingData
      );

      let { success } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          settingLoading: false,
          updatePhoneResp: response?.data,
        });
      }
      return response?.data;
    } catch (error) {
      set({ settingLoading: false, error: error });
      return error;
    }
  },

  verifyChangeEmailApi: async (settingData) => {
    try {
      const response = await AxiosApi.post(
        `${BASE_URL}auth/verify-change-email`,
        settingData
      );

      let { success } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          settingLoading: false,
          verifyEmailResp: response?.data,
        });
      }
      return response?.data;
    } catch (error) {
      set({ settingLoading: false, error: error });
      return error;
    }
  },

  confirmEmailApi: async (settingData) => {
    try {
      const response = await AxiosApi.post(
        `${BASE_URL}auth/confirm-change-email`,
        settingData
      );

      let { success } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          settingLoading: false,
          confirmEmailResp: response?.data,
        });
      }
      return response?.data;
    } catch (error) {
      set({ settingLoading: false, error: error });
      return error;
    }
  },
  pinCodeStatusApi: async (settingData) => {
    try {
      const response = await AxiosApi.post(`${BASE_URL}settings`, settingData);

      let { success } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          settingLoading: false,
          pinCodeStatusResp: response?.data,
        });
      }
      return response?.data;
    } catch (error) {
      set({ settingLoading: false, error: error });
      return error;
    }
  },
  //auth/change-password
  changePasswordApi: async (settingData) => {
    try {
      const response = await AxiosApi.post(
        `${BASE_URL}auth/change-password`,
        settingData
      );

      let { success } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          settingLoading: false,
          changePasswordResp: response?.data,
        });
      }
      return response?.data;
    } catch (error) {
      set({ settingLoading: false, error: error });
      return error;
    }
  },

  //auth/change-pin
  changePinApi: async (settingData) => {
    try {
      const response = await AxiosApi.post(
        `${BASE_URL}auth/change-pin`,
        settingData
      );

      let { success } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          settingLoading: false,
          changePinResp: response?.data,
        });
      }
      return response?.data;
    } catch (error) {
      set({ settingLoading: false, error: error });
      return error;
    }
  },
  notificationApi: async (settingData) => {
    try {
      const { in_app_sounds, in_app_vibrate, recieve_marketing_emails } =
        settingData;
      let urlArray = [
        {
          name: "in_app_sounds",
          value: in_app_sounds,
        },
        {
          name: "in_app_vibrate",
          value: in_app_vibrate,
        },
        {
          name: "recieve_marketing_emails",
          value: recieve_marketing_emails,
        },
      ];
      let filteredURL = apiFilterVallidation(urlArray, `${BASE_URL}settings?`);
      const response = await AxiosApi.get(filteredURL);

      let { success } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          settingLoading: false,
          notifyResp: response?.data,
        });
      }
      return response?.data;
    } catch (error) {
      set({ settingLoading: false, error: error });
      return error;
    }
  },
  updateSettingApi: async (settingData) => {
    try {
      const response = await AxiosApi.post(`${BASE_URL}settings`, settingData);

      let { success } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          settingLoading: false,
          updateResp: response?.data,
        });
      }
      return response?.data;
    } catch (error) {
      set({ settingLoading: false, error: error });
      return error;
    }
  },
 
}));
