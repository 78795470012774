import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import "tw-elements-react/dist/css/tw-elements-react.min.css";
import "./services/sentry/Sentry.tsx";

const VITE_MODE = import.meta.env.MODE;

ReactDOM.createRoot(document.getElementById("root")).render(
  VITE_MODE === "development" ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  ),
);
