import { useEffect } from "react";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import { getTimeDiffDetails } from "../../utils/TimeDetails";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import Loader from "../atom/loader/Loader";

export default function ReviewReplies(props) {
  let { reviewId } = props || {};
  const { listReviewCommentsApi, allReviewComments, loadingReviewComments } =
    DirectoryDetails((state) => state);
  let { data } = allReviewComments || {};

  let getListReviewCommentsApi = () => {
    if (reviewId) {
      listReviewCommentsApi({
        reviewId: reviewId,
      });
    }
  };

  useEffect(() => {
    getListReviewCommentsApi();
  }, [reviewId]);

  return (
    <div className="w-full bg-lightBg dark:bg-darkModeBG flex text-[13px] flex-col">
      {loadingReviewComments ? (
        <Loader />
      ) : (
        data?.length !== 0 &&
        data?.map((data) => (
          <div key={data?.id}>
            <div className="flex items-center gap-2 pb-2 ml-16">
              <div className="border-2 rounded-full min-w-[35px] min-h-[35px] dark:border-darkModeBorder">
                <DirectoryImageInitials
                  imageUrl={data?.user?.upload?.file_path}
                  alt={data?.user?.first_name + " " + data?.user?.last_name}
                  name={data?.user?.first_name + " " + data?.user?.last_name}
                  boxColor={data?.business_color?.toLowerCase()}
                  className={`rounded-full h-10 w-10 overflow-hidden`}
                />
              </div>
              <p className="font-semibold">
                {`${data.user.first_name ? data.user.first_name : ""} ${
                  data.user.middle_name ? data.user.middle_name : ""
                } ${data.user.last_name ? data.user.last_name : ""}`}
              </p>
              <p className="font-light text-gray-400">
                {getTimeDiffDetails(data?.updated_at)}
              </p>
            </div>
            <p className="pb-3 ml-16">{data?.description}</p>
          </div>
        ))
      )}
    </div>
  );
}
