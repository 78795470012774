import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { IoClose, IoCloseCircleOutline } from "react-icons/io5";
import { UploadClip } from "../icons/Icons";
import { FeedbackPencil } from "../icons/Icons";
import Angry from "../../assets/emoji/Angry.png";
import Sad from "../../assets/emoji/Sad.png";
import Neutral from "../../assets/emoji/Neutral.png";
import Smile from "../../assets/emoji/Smile.png";
import Love from "../../assets/emoji/Love.png";
import Draggable from "react-draggable";
import { useFeedbackStore } from "../../../src/services/feedbackService";

const emojis = [Angry, Sad, Neutral, Smile, Love];

export default function FeedbackPopup({ onClose }) {
  const {
    selectedRating,
    feedback,
    filePreviews,
    isFormComplete,
    isSubmitted,
    errorMessage,
    setSelectedRating,
    setFeedback,
    setFilePreviews,
    setIsFormComplete,
    setIsSubmitted,
    setErrorMessage,
    handleAttachFile,
  } = useFeedbackStore();

  const fileInputRef = useRef(null);

  const resetFeedbackForm = () => {
    setSelectedRating(null);
    setFeedback("");
    setFilePreviews([]);
    setIsFormComplete(false);
    setIsSubmitted(false);
    setErrorMessage("");
    onClose();
  };

  const handleDrop = (files) => {
    if (filePreviews.length + files.length > 10) {
      setErrorMessage("You can only upload a maximum of 10 files.");
      return;
    }

    const previews = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFilePreviews([...filePreviews, ...previews]);
    setErrorMessage("");
  };

  const handleRating = (rating) => {
    setSelectedRating(rating);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleRemoveFile = (index) => {
    setFilePreviews(filePreviews.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("rating", selectedRating);
    filePreviews.forEach((preview) => {
      formData.append("files", preview.file);
    });
    formData.append("review_text", feedback);
    formData.append("screen_name", "Business details");

    await handleAttachFile(formData);
  };

  useEffect(() => {
    setIsFormComplete(selectedRating && feedback.trim());
  }, [selectedRating, feedback]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDropFiles = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);

    if (filePreviews.length + files.length > 10) {
      setErrorMessage("You can only upload a maximum of 10 files.");
      return;
    }

    handleDrop(files);
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);

    if (filePreviews.length + files.length > 10) {
      setErrorMessage("You can only upload a maximum of 10 files.");
      return;
    }

    handleDrop(files);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return ReactDOM.createPortal(
    <div className="w-full text-sm h-screen fixed bg-[#140F26] bg-opacity-[95%] flex flex-col md:flex-row justify-center items-center">
      <div
        className={`${
          isSubmitted ? "w-[564px]" : "w-[820px]"
        } justify-center items-center flex flex-col bg-white dark:bg-darkModeMain dark:border-[1px] border-gray-100 dark:border-darkModeBorder rounded-lg relative text-isearch`}
      >
        <div className="w-full h-[60px] flex justify-between border-b border-gray-100 items-center">
          <h1 className="m-5 font-semibold uppercase dark:text-white">FEEDBACK</h1>
          <button onClick={onClose} className="absolute top-4 right-4 dark:text-white">
            <IoClose size={25} />
          </button>
        </div>
        {isSubmitted ? (
          <div className="w-full py-5 flex flex-col items-center justify-center">
            <div className="mb-5">
              <FeedbackPencil />
            </div>
            <h2 className="font-bold text-xl mb-2.5 dark:text-white">Feedback Sent</h2>
            <p className="text-sm text-center mx-[50px] mb-5 dark:text-white">
              Thank you! Your feedback has been sent successfully. Our team will
              review it and take any necessary actions.
            </p>
            <button
              className="w-[280px] h-[45px] uppercase rounded-full bg-ipurple text-white"
              onClick={() => {
                resetFeedbackForm();
              }}
            >
              Done
            </button>
          </div>
        ) : (
          <div className="w-full py-5 flex flex-col flex-start border-t-[1px] dark:border-darkModeBorder">
            <p className="font-semibold mx-5 mb-5 text-[14px] dark:text-white">
              Rate your experience of this page
            </p>
            <div className="rating-options flex mb-5">
              {emojis.map((emoji, index) => (
                <button
                  key={index}
                  className={`rating-button text-xl ml-5 border border-gray-200 rounded-md p-4 ${
                    selectedRating === index + 1
                      ? " bg-ipurple border-blue-500 "
                      : ""
                  }`}
                  onClick={() => handleRating(index + 1)}
                >
                  <img src={emoji} alt="emoji" className="w-[28px] h-[28px]" />
                </button>
              ))}
            </div>
            <textarea
              placeholder="Type something here..."
              value={feedback}
              onChange={handleFeedbackChange}
              className="rounded-md h-[110px] mx-5 p-2 border-gray-200 dark:text-white dark:border-darkModeBorder bg-inherit active:border-ipurple active:ring-ipurple focus:border-ipurple text-sm mb-5"
            />

            <div
              onDragOver={handleDragOver}
              onDrop={handleDropFiles}
              onClick={handleClick}
              className="flex flex-col items-center justify-center border border-gray-200 border-dashed rounded-lg cursor-pointer bg-ibluebg dark:bg-gray-700 mx-5 py-5"
            >
              <input
                type="file"
                multiple
                ref={fileInputRef}
                onChange={handleFileSelect}
                className="hidden"
              />
              <div className="flex items-center justify-center">
                <UploadClip color="#140F26 dark:text-gray-400" />
                <p className="text-sm dark:text-isearch dark:text-gray-400 font-semibold underline">
                  Drag files here or click to upload
                </p>
              </div>
            </div>
            {errorMessage && (
              <p className="text-red-500 text-sm mx-5 mt-2">{errorMessage}</p>
            )}

            <div className="flex flex-wrap max-h-[300px] overflow-y-auto py-4">
              {filePreviews.map((preview, index) => (
                <Draggable key={index}>
                  <div className="w-auto h-auto max-w-[110px] mb-5 mx-5 text-isearch relative">
                    <img
                      src={preview.preview}
                      alt="Preview"
                      className="w-[110px] h-[110px] rounded-md"
                    />
                    <button
                      className="absolute top-0 right-0"
                      onClick={() => handleRemoveFile(index)}
                    >
                      <IoCloseCircleOutline
                        size={20}
                        className="text-isearch bg-gray-300 rounded-xl bg-opacity-80"
                      />
                    </button>
                    <div className="flex flex-col text-center text-xs">
                      <p className="flex text-wrap justify-center font-semibold my-1 uppercase dark:text-white">
                        {preview.file.name}
                      </p>
                      <p className="flex justify-center dark:text-white">
                        {(preview.file.size / (1024 * 1024)).toFixed(2)} MB
                      </p>
                    </div>
                  </div>
                </Draggable>
              ))}
            </div>

            <div className="w-full text-[13px] font-[600] flex items-center mx-5">
              <button
                className={`w-[280px] h-[45px] mt-[20px] uppercase rounded-full bg-ipurple text-white border-gray-100 duration-500 ${
                  isFormComplete ? "" : "opacity-30"
                }`}
                onClick={(e) => {
                  if (isFormComplete) {
                    handleSubmit(e);
                  }
                }}
                disabled={!isFormComplete}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    </div>,
    document.getElementById("portal-root")
  );
}
