// Import necessary dependencies
import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";
import { apiFilterNotification } from "../../utils/api/apiFilter";
import { VITE_API_PAGE_LIMIT } from "../../utils/constants";

export const NotificationService = create((set, get) => ({
  notificationList: {}, //list of the notification
  notificationListDetails: [],
  notificationStatus: [],
  totalNotification: 0,
  unReadNotificationTotal: 0,
  ArchieveNotificationTotal: 0,
  loadingNotification: false,
  error: null,

  // Async function to create get data for single ticket by id
  getlistNotificationApi: async (userData, filter, setLoadingState) => {
    try {
      set({ loadingNotification: true });
      let {
        page,
        businessId,
        start_date,
        end_date,
        is_archived,
        is_read,
        limit,
      } = userData;
      let urlArray = [
        {
          name: "page",
          value: page,
        },
        {
          name: "businessId",
          value: businessId,
        },
        {
          name: "start_date",
          value: start_date,
        },
        {
          name: "start_date",
          value: start_date,
        },
        {
          name: "end_date",
          value: end_date,
        },
        {
          name: "is_archived",
          value: is_archived,
        },
        {
          name: "is_read",
          value: is_read,
        },
        {
          name: "limit",
          value: VITE_API_PAGE_LIMIT,
        },
      ];

      let filteredURL = apiFilterNotification(urlArray, `users/notifications?`);

      const response = await AxiosApi.get(filteredURL);

      let { success, message, data, meta } = response?.data || {
        message: "error",
      };
      if (success) {
        if (filter == "all") {
          set((state) => ({
            ...state,
            totalNotification: !is_archived ? meta?.total : 0,
            unReadNotificationTotal:
              state?.unReadNotificationTotal != meta?.total
                ? meta?.total - (meta?.read_notifications_count || 0)
                : state?.unReadNotificationTotal,
            ArchieveNotificationTotal:
              state?.ArchieveNotificationTotal !=
              meta?.archived_notifications_count
                ? meta?.archived_notifications_count
                : state?.ArchieveNotificationTotal,
          }));
        }
        set((state) => ({
          loadingNotification: false,
          notificationList: response?.data,
          notificationListDetails: [
            ...state.notificationListDetails,
            ...data,
          ].filter(
            // Filter out duplicates based on the 'id' property
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          ),
        }));

        return response?.data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loadingNotification: false, error: err });
      return err;
    }
  },
  notificationStatusUpdate: async (notificationData, filter) => {
    try {
      set({ loadingNotification: true });
      let { id, notifcationDetails } = notificationData;
  
      // Dynamically decide which value to send based on the filter
      const payload = {};
      if (filter === "is_read") {
        payload.is_read = notifcationDetails?.is_read;
      }
      if (filter === "is_archived") {
        payload.is_archived = notifcationDetails?.is_archived;
      }
  
      const response = await AxiosApi.put(`users/notifications/${id}`, payload);
  
      let { success, message } = response?.data || { message: "error" };
  
      if (success) {
        if (filter == "is_read") {
          set(() => ({
            notificationListDetails: get().notificationListDetails?.map(
              (notification) => {
                if (notification?.id == id) {
                  return {
                    ...notification,
                    is_read: notification?.is_read ? false : true,
                  };
                } else {
                  return { ...notification };
                }
              }
            ),
            unReadNotificationTotal:
              notifcationDetails?.is_read == false &&
              get().unReadNotificationTotal != 0
                ? get().unReadNotificationTotal + 1
                : notifcationDetails?.is_read == true
                ? get().unReadNotificationTotal - 1
                : 0,
            loadingNotification: false,
            notificationStatus: response?.data,
          }));
        } else {
          set(() => ({
            notificationListDetails: get().notificationListDetails?.map(
              (notification) => {
                if (notification?.id == id) {
                  return {
                    ...notification,
                    is_archived: notification?.is_archived ? false : true,
                  };
                } else {
                  return { ...notification };
                }
              }
            ),
            ArchieveNotificationTotal:
              notifcationDetails?.is_archived == false &&
              get().ArchieveNotificationTotal != 0
                ? get().ArchieveNotificationTotal + 1
                : notifcationDetails?.is_archived == true
                ? get().ArchieveNotificationTotal - 1
                : 0,
            loadingNotification: false,
            notificationStatus: response?.data,
          }));
        }
  
        return response?.data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loadingNotification: false, error: err });
      return err;
    }
  },
  
  clearNotificationState: () => {
    set({
      notificationList: {},
      notificationListDetails: [],
    });
  },
}));
export default NotificationService;
