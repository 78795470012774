import { ClientJS } from "clientjs";
import { v4 as uuidv4 } from "uuid";

export const deviceId = () => {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();
  const uniqueId = uuidv4();
  const secretDeviceId = `${fingerprint}-${uniqueId}`;

  if (!localStorage.getItem("uuid")) {
    localStorage.setItem("uuid", secretDeviceId);
  }
  const uuid = localStorage.getItem("uuid", secretDeviceId);
  return uuid;
};
