import { useState, useEffect, useRef, useCallback } from "react";
import { IoChevronDown } from "react-icons/io5";
import { IoIosPower } from "react-icons/io";
import { LuCheck } from "react-icons/lu";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import {
  getCompanyId,
  getUserName,
  useAuth,
  userDetails,
} from "../../routes/ProtectedRoutes";
import { useNavigate } from "react-router-dom";
import Avatar from "../Avatar";
import { clearSipParams } from "../../utils/DeviceDetails/SipDetails";
import { PRO_USER, VITE_API_PAGE_LIMIT } from "../../utils/constants";
import { getAccountType } from "../../routes/ProtectedRoutes";
import { sendMixpanelEvent } from "../../services/mixpanelService";
import { CompaniesService } from "../../services/companies/CompaniesService";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import User from "../../assets/icons/User.svg"

export default function Accordian() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const userDetailsProfile = userDetails();
  const userName = getUserName();
  const accountType = getAccountType();
  const {
    companyPhoneNumberListApi,
    getDefaultUsercompanyPhoneApi,
    defaultUsercompanyPhoneEditApi,
  } = CompaniesService();
  const [companyPhones, setCompanyPhones] = useState([]);
  const [defaultUserCompanyPhone, setDefaultUserCompanyPhone] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = VITE_API_PAGE_LIMIT;
  const companyId = getCompanyId();

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
    fetchCompanyPhones()
  };

  const fetchCompanyPhones = async () => {
    const queryParams = { page: page, limit: limit, verified: true };

    try {
      if (companyId) {
        const response = await companyPhoneNumberListApi(
          companyId,
          queryParams,
        );
        setCompanyPhones(response);
        setPage(page);
        setHasMore(true);
      }
    } catch (error) {
      console.error("Error fetching company phone numbers:", error);
    }
  };

  // Fetch remaining company phones if exists - Used for Pagination
  const fetchMoreCompanyPhones = useCallback(async () => {
    if (!hasMore) return;
    try {
      const nextPage = page + 1;

      const queryParams = {
        page: nextPage,
        limit: limit,
        verified: true,
      };

      const response = await companyPhoneNumberListApi(companyId, queryParams);
      if (response.length > 0) {
        // Append new data to current data
        setCompanyPhones((prevPhonesData) => [...prevPhonesData, ...response]);
        setPage(nextPage);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more company phones:", error);
    }
  }, [page, companyId, hasMore]);

  const fetchUserCompanyPhone = async () => {
    try {
      const response = await getDefaultUsercompanyPhoneApi();
      setDefaultUserCompanyPhone(response);
    } catch (error) {
      console.error("Error fetching default user company phone number:", error);
    }
  };
  const handlePhoneUpdateClick = async (phone) => {
    if (defaultUserCompanyPhone?.id === phone.id) {
      toast.error(
        "This phone number is already set as default. Please choose a different one!",
      );
      return;
    }

    const previousDefaultPhone = defaultUserCompanyPhone;
    setDefaultUserCompanyPhone(phone);

    try {
      await defaultUsercompanyPhoneEditApi(phone.id);
      toast.success("Default Phone number updated successfully!");
    } catch (error) {
      setDefaultUserCompanyPhone(previousDefaultPhone);
      toast.error("Error updating Default Phone number!");
      console.error("Error updating Default Phone number!", error);
    }
  };
  if (accountType === PRO_USER) {
    
    useEffect(() => {
      fetchCompanyPhones();
      fetchUserCompanyPhone();
    }, [companyId]);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const { LogoutApi } = AuthPersonalPhone((state) => state);

  const handleLogout = async () => {
    await LogoutApi()
      .then(async () => {
        const sipCallData = clearSipParams();
        await window.postMessage(sipCallData, "*");
        localStorage.removeItem("theme");
        await navigate("/");
        sendMixpanelEvent("Logged Out Successfully");
      })
      .catch(() => {
        navigate("/");
      });
  };

  return (
    <div ref={dropdownRef} className=" flex items-center justify-center pl-1">
      <a
        href="#"
        className="flex items-center text-xs transition duration-150 ease-in-out cursor-pointer"
        onClick={toggleDropdown}
      >
        <div className="flex items-center gap-4">
          <IoChevronDown size={20} />
        </div>
      </a>

      {isOpen && (
        <div className=" mt-[30px] absolute right-2 z-50  border-gray-100 dark:border-darkModeBorder flex flex-col w-[342px] top-[50px] bg-white rounded-b-md shadow-lg outline-none dark:bg-darkModeMain">
          <div className="flex flex-col mr-1">
            {accountType !== PRO_USER ? (
              <>
                <div className="flex pl-2 border-b border-gray-100 dark:border-darkModeBorder items-center py-1">
                  <a
                    href="#"
                    className="p-3 text-sm font-semibold text-darkModeBG dark:text-neutral-200"
                  >
                    Switch Profile
                  </a>
                </div>
                <div className="hover:bg-lightBg dark:hover:bg-darkModeBG flex pl-4 border-b border-gray-100 gap-2 items-center dark:border-darkModeBorder h-[70px]">
                  <div>
                    <Avatar pathSrc={userDetailsProfile?.upload?.file_path || User} />
                  </div>
                  <a
                    href="#"
                    className="text-[14px] -ml-0 mb-1 font-semibold text-darkModeBG dark:text-neutral-200"
                  >
                    {userName}
                  </a>
                  <p className="text-[13px] text-gray-400">Personal</p>
                  <div className="right-6 text-ipurple">
                    <LuCheck size={20} />
                  </div>
                </div>
                <button
                  onClick={handleLogout}
                  className="flex gap-2 -mb-1 w-full py-4 text-[13px] ml-7 mb-3 hover:bg-lightBg dark:hover:bg-darkModeBG text-darkModeBG dark:text-neutral-200"
                >
                  <IoIosPower size={20} />
                  <p>Logout</p>
                </button>
              </>
            ) : (
              <div className="right-0 w-[340px] h-[350px] rounded-lg dark:border-darkModeBorder">
                <div className="hover:bg-lightBg dark:hover:bg-darkModeBG flex pl-[5px] pr-4 border-b border-gray-100 gap-2 items-center dark:border-darkModeBorder min-h-[70px] min-w-[340px]">
                  <div className="flex justify-start items-center">
                    <Avatar pathSrc={userDetailsProfile?.upload?.file_path} />
                  </div>
                  <div className="flex-grow overflow-hidden">
                    <p className="truncate text-[14px] font-semibold text-darkModeBG dark:text-neutral-200">
                      {userName}
                    </p>
                    <p className="text-[13px] font-normal text-isearch dark:text-white">

                      {userDetailsProfile?.email}
                    </p>
                  </div>
                  <button
                    onClick={handleLogout}
                    className="flex ml-auto mb-[35px] mt-[15px] hover:bg-lightBg dark:hover:bg-darkModeBG text-darkModeBG dark:text-neutral-200"
                  >
                    <IoIosPower size={20} />
                  </button>
                </div>

                <div className="hover:bg-lightBg dark:hover:bg-darkModeBG z-100 flex pl-4 border-b border-gray-100 dark:border-darkModeBorder items-center py-3 w-[340px] h-[55px]">
                  <p className="text-sm font-normal mt-[35px] mb-[15px]">
                    Department
                  </p>
                </div>

                <div
                  id="scrollableDiv"
                  className="relative h-[calc(350px-125px)] overflow-y-auto"
                >
                  {/** Scroll to use pagination */}
                  <InfiniteScroll
                    dataLength={companyPhones.length}
                    next={fetchMoreCompanyPhones}
                    hasMore={hasMore}
                    loader
                    scrollThreshold={1.0}
                    scrollableTarget="scrollableDiv"
                    style={{ display: "table", width: "100%" }}
                  >
                    {companyPhones.length > 0 &&
                      companyPhones.map((phone) => (
                        <div
                          key={phone.id}
                          className="hover:bg-lightBg dark:hover:bg-darkModeBG flex  border-b space-x-8 border-gray-100 dark:border-darkModeBorder items-center py-3 w-[340px] h-[55px]"
                          onClick={() => {
                            const confirmUpdate = window.confirm(
                              "Are you sure you want to update the default phone number?",
                            );
                            if (confirmUpdate) {
                              handlePhoneUpdateClick(phone);
                            }
                          }}
                        >
                          <span
                            className={`inline-block whitespace-nowrap rounded-[0.27rem] px-[0.5rem] py-[0.5rem] text-center align-baseline text-[0.75em] font-bold leading-none`}
                            style={{
                              width: 100,
                            }}
                          >
                            {phone?.department?.toUpperCase() || ""}
                          </span>
                          {defaultUserCompanyPhone?.country_code ===
                            phone?.country_code &&
                            defaultUserCompanyPhone?.phone_number ===
                              phone?.phone_number && (
                              <div className="absolute right-6  text-ipurple">
                                <LuCheck size={20} />
                              </div>
                            )}
                          <p className=" font-semibold h-[25px]">
                            {phone?.country_code +
                              "-" +
                              phone?.phone_number.replace(
                                /^(\d{3})(\d{3})(\d+)/,
                                "$1-$2-$3",
                              ) || ""}
                          </p>
                        </div>
                      ))}
                  </InfiniteScroll>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
