import { useState } from "react";
import ReactDOM from "react-dom";
import { IoIosClose } from "react-icons/io";
import { IoClipboardOutline } from "react-icons/io5";
import dayjs from "dayjs";
import { GoChevronRight, GoChevronLeft, GoPencil } from "react-icons/go";
import { DocIcon, PdfIcon } from "../../assets/notesIcon.jsx";
import {
  PiArrowCircleDownLight,
  PiMagnifyingGlassPlusLight,
  PiMagnifyingGlassMinusLight,
  PiArrowCircleUpFill,
} from "react-icons/pi";
import { AiOutlineRotateLeft } from "react-icons/ai";
import { DATE_COMMON_FORMAT } from "../../utils/constants";
import { fileSizeCalculate } from "../../utils/numberFormat";
import { downloadFile } from "../atom/downloadFile";

export default function FilePreview(props) {
  let { onClose, imgDetails, setImageIndex, imageIndex, arrayLength } =
    props || {};

  const [inputContent, setInputContent] = useState("");

  const handleInputChange = (event) => {
    setInputContent(event?.target?.value);
  };
  const inputBgColor = inputContent ? "bg-white" : "bg-gray-700";

  const [zoom, setZoom] = useState(1);

  const [rotation, setRotation] = useState(0);

  const rotate = () => {
    let newRotation = rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotation(newRotation);
  };

  return ReactDOM.createPortal(
    <div className="fixed z-50 flex flex-col items-center w-full h-full text-sm text-white bg-gray-950 bg-opacity-95">
      <div className="flex items-center justify-between w-full px-8 mt-12 z-50">
        <div className="flex items-center gap-3">
          <div className="w-[50px] min-w-[50px] h-[50px] min-h-[50px] rounded flex justify-center items-center bg-gray-700 text-white">
            <IoClipboardOutline size={20} />
          </div>
          <div className="flex flex-col">
            <p className="font-semibold">{imgDetails?.file_name}</p>
            <p className="text-[12px]">
              {dayjs(imgDetails?.created_at)?.format(DATE_COMMON_FORMAT)}
            </p>
          </div>
        </div>
        <div className="absolute flex flex-col text-center uppercase left-[47%] top-12">
          <p>{imgDetails?.file_name}</p>
          <p className="text-[12px]">
            {fileSizeCalculate(imgDetails?.file_size)}
          </p>
        </div>
        <div className="flex items-end">
          <button
            onClick={onClose}
            className="w-[50px] min-w-[50px] h-[50px] min-h-[50px] rounded flex justify-center items-center bg-gray-700 text-white"
          >
            <IoIosClose size={30} onClick={onClose} />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-center w-full gap-10 py-8">
        <button
          onClick={() => {
            setZoom(1);
            setRotation(0);
            if (imageIndex == 0) {
              setImageIndex(arrayLength);
              onClose(arrayLength);
            } else {
              onClose(imageIndex - 1);
              setImageIndex(imageIndex - 1);
            }
          }}
          className="w-[75px] min-w-[75px] h-[75px] min-h-[75px] rounded flex justify-center items-center bg-gray-700 text-white z-50"
        >
          <GoChevronLeft size={30} />
        </button>

        <div className="w-[70%] h-[700px] rounded-lg flex justify-center">
          {imgDetails?.file_type == "application/pdf" ? (
            <div className="flex items-center">
              <PdfIcon />
            </div>
          ) : !imgDetails?.file_type?.startsWith("image/") ? (
            <div className="flex items-center">
              <DocIcon />
            </div>
          ) : (
            <img
              src={imgDetails?.file_path}
              style={{
                transform: `scale(${zoom}) rotate(${rotation}deg)`,
              }}
            />
          )}
        </div>
        <button
          onClick={() => {
            setZoom(1);
            setRotation(0);
            if (arrayLength === imageIndex) {
              setImageIndex(0);
              onClose(0);
            } else {
              onClose(imageIndex + 1);
              setImageIndex(imageIndex + 1);
            }
          }}
          className="w-[75px] min-w-[75px] h-[75px] min-h-[75px] rounded flex justify-center items-center bg-gray-700 text-white z-50"
        >
          <GoChevronRight size={30} />
        </button>
      </div>
      <div className="relative flex items-center justify-center w-[60%] ">
        <input
          placeholder="Enter a comment for the photo..."
          className={`-mt-4 px-4 w-full h-[60px] border-none text-black outline-none ${inputBgColor} placeholder:opacity-80 rounded-md placeholder:px-4`}
          onChange={handleInputChange}
          value={inputContent}
        />
        {inputContent ? (
          <button>
            <PiArrowCircleUpFill
              size={25}
              className="absolute text-ipurple right-4 bottom-4 "
            />
          </button>
        ) : (
          <GoPencil size={20} className="absolute right-4 bottom-5" />
        )}
      </div>
      <div className="relative w-full pt-12 z-50">
        <button
          onClick={() => {
            downloadFile(imgDetails);
          }}
          className="absolute left-12 bottom-0 w-[50px] min-w-[50px] h-[50px] min-h-[50px] rounded flex justify-center items-center bg-gray-700 text-white"
        >
          <PiArrowCircleDownLight size={25} />
        </button>
        <div className="absolute bottom-0 flex flex-col items-center space-y-4 justify-center right-12 w-[60px] h-[200px]">
          <button
            onClick={() => {
              if (zoom < 2.0) {
                setZoom(zoom + 0.1);
              }
            }}
            className=" w-[50px] min-w-[50px] h-[50px] min-h-[50px] rounded flex justify-center items-center bg-gray-700 text-white"
          >
            <PiMagnifyingGlassPlusLight size={25} />
          </button>
          <button
            onClick={() => {
              if (zoom > 0.2) {
                setZoom(zoom - 0.1);
              }
            }}
            className=" w-[50px] min-w-[50px] h-[50px] min-h-[50px] rounded flex justify-center items-center bg-gray-700 text-white"
          >
            <PiMagnifyingGlassMinusLight size={25} />
          </button>
          <button
            onClick={() => {
              rotate();
            }}
            className=" w-[50px] min-w-[50px] h-[50px] min-h-[50px] rounded flex justify-center items-center bg-gray-700 text-white"
          >
            <AiOutlineRotateLeft size={25} className="mb-1 ml-1" />
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("portal-root")
  );
}
