import React from 'react'
import { IoOptionsOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import TicketFilesContainer from '../../tickets-tab/TicketFilesContainer';


export default function FilesView() {
  return (
    <div className='w-full text-sm  border-[1px]  border-gray-100  dark:border-darkModeBorder bg-white rounded-lg dark:bg-darkModeMain flex flex-col'>
      <div className='flex justify-between items-center  h-[60px]  border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder'>
        <div className='flex items-center gap-4 p-6 text-sm'>
            <p className='font-semibold uppercase'>Files</p>
            <button className='flex items-center gap-1 ml-4 text-ipurple hover:text-ipurple'>
                <p>All</p>
                <p>15</p>
            </button>
            <button className='flex items-center gap-1 hover:text-ipurple'>
                <p>Ticket</p>
                <p>6</p>
            </button>
            <button className='flex items-center gap-1 hover:text-ipurple'>
                <p>Pinned</p>
                <p>2</p>
            </button>
        </div>
        <div className='flex items-center gap-4 p-4 mr-6'>
                
                <IoOptionsOutline size={20} />
                <IoIosSearch size={20} />
            </div>
      </div>
      <div className=''>
      <TicketFilesContainer />
      </div>
    
       
    </div>
  )
}
