import BusinessAccordian from "./filter-accordians/BusnessesAccordian";
import SortByAccordian from "./filter-accordians/SortByAccordian";
import LocationAccordian from "./filter-accordians/LocationAccordian";
import DistanceAccordian from "./filter-accordians/DistanceAccordian";
import CategoriesAccordian from "./filter-accordians/CategoriesAccordian";
import { TopNavSearchService } from "../../../services/search/topNav/TopNavSearchService";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useSearch } from "../../../context/SearchContext";
import {
  DEFAULT_LOCATION_DISTANCE,
  getCurrentLocation,
  locationDetails,
  VITE_DISTANCE_LIMIT,
} from "../../../utils/constants";
import { sendMixpanelEvent } from "../../../services/mixpanelService";

export default function FiltersContainer(props) {
  const { triggerApi, setTriggerApi } = props;
  const { searchTerm, inputValueRef, setSelectedLocation } = useSearch();
  const [selectBtn, setSelectedBtn] = useState("");
  const showEverywhere = useRef(false);
  const {
    searchCategory,
    topNavDirectorySearchApi,
    getSearchFilterCategoryApi,
    locationDetailsData,
    setFilterDistance,
  } = TopNavSearchService((state) => state);

  const [showCheckbox, setShowCheckbox] = useState(false);
  const [allResults, setAllResults] = useState(false);
  const [recentBusiness, setRecentBusiness] = useState(false);
  const [savedBusiness, setSavedBusiness] = useState(false);
  const locationDetail = useRef({ lat: "", long: "" });

  const locationData = locationDetails();
  const { data } = searchCategory;
  const initialValues = {
    LocationCategoryData: [],
    LocationDistance: DEFAULT_LOCATION_DISTANCE,
    LocationName: "",
    LocationSortBy: "nearest",
    locationLat: "",
    locationLong: "",
    LocationBusiness: "",
    collapseDetails: [
      { id: 1, name: "Sort results by", show: true },
      // { id: 2, name: "Business", show: true },
      { id: 3, name: "Location", show: true },
      // { id: 5, name: "Categories", show: true },
    ],
  };

  useEffect(() => {
    setFilterDistance(VITE_DISTANCE_LIMIT);
  }, []);
  useEffect(() => {
    getCurrentLocation(
      (lat, lon) => {
        formik?.setFieldValue("locationLat", lat);
        formik?.setFieldValue("locationLong", lon);
      },
      (error) => {
        // console.error("Error getting location", error);
      }
    );
  }, []);

  const handleCollapseClick = (id) => {
    const index = initialValues?.collapseDetails?.findIndex(
      (item) => item.id === id
    );

    const updatedCollapseDetails = [...formik.values.collapseDetails];

    updatedCollapseDetails[index] = {
      ...updatedCollapseDetails[index],
      show: !updatedCollapseDetails?.[index]?.show,
    };

    formik.setValues({
      ...formik.values,
      collapseDetails: updatedCollapseDetails,
    });
  };
  const resetFilters = () => {
    formik.setFieldValue("LocationCategoryData", []);
  };

  useEffect(() => {
    getCurrentLocation(
      (lat, lon) => {
        locationDetail.current = { lat: lat, lng: lon };
      },
      (error) => {
        // console.error("Error getting location", error);
      }
    );
  }, []);

  const handleSubmit = async (data) => {
    const {
      LocationBusiness,
      LocationCategoryData,
      LocationDistance,
      LocationName,
      LocationSortBy,
      locationLat,
      locationLong,
    } = data;

    const searchData = {
      LocationBusiness: LocationBusiness,
      LocationCategoryData: LocationCategoryData?.join(","),
      LocationDistance:
        formik?.values?.LocationDistance || DEFAULT_LOCATION_DISTANCE,
      LocationName: LocationName,
      LocationSortBy: LocationSortBy,
      locationLat: showEverywhere.current
        ? ""
        : locationDetailsData?.lat ||
          locationDetail?.current?.lat ||
          locationData?.lat ||
          "",
      locationLong: showEverywhere.current
        ? ""
        : locationDetailsData?.lng ||
          locationDetail?.current?.lng ||
          locationData?.lng ||
          "",
      keyword: inputValueRef.current || searchTerm,
      showEverywhere: showEverywhere.current,
    };
    setFilterDistance(
      formik?.values?.LocationDistance || DEFAULT_LOCATION_DISTANCE
    );

    topNavDirectorySearchApi(searchData).then((data) => {
      setTriggerApi(false);
    });
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
  });
  const handleReset = () => {
    sendMixpanelEvent("Filter Reset");
    formik.setValues({
      ...formik.initialValues,
    });
    setSelectedBtn("");
    setSavedBusiness(false);
    setAllResults(false);
    setRecentBusiness(false);
    setSelectedLocation("");
    formik.handleSubmit();
  };

  useEffect(() => {
    if (triggerApi) {
      formik.handleSubmit();
    }
  }, [triggerApi]);

  return (
    <div className="relative flex flex-col w-full !h-[calc(100dvh-140px)] mt-3 text-sm ">
      <div className="flex flex-col h-full">
        <div className="h-[70px] flex flex-row items-center p-4 justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
          <div className="flex gap-3 ">
            <p className="text-[15px]">
              <span className="font-semibold">Filters</span> (
              {(formik?.values?.LocationCategoryData?.length ? +1 : 0) +
                1 +
                (formik?.values?.LocationDistance ? +1 : 0) +
                (allResults || recentBusiness || savedBusiness ? +1 : 0)}
              )
            </p>
          </div>
          <div className="px-4 text-[13px]">
            <p className="font-normal">Hide</p>
          </div>
        </div>
        <div
          className="flex-grow overflow-y-auto "
          // style={{ paddingBottom: "70px" }}
        >
          {" "}
          {/* Adjust padding to height of button group */}
          {formik?.values?.collapseDetails?.map((details) => {
            return (
              <div
                key={details?.id}
                className={`border-b border-gray-100-[1px] border-gray-100 py-2 dark:border-darkModeBorder ${
                  details.name == "Location" && details?.show
                    ? " !h-[160px]"
                    : ""
                }`}
              >
                <h2 className="mb-0 " id="headingOne ">
                  <button
                    className={`${
                      details?.show && ` font-bold  -mt-2 `
                    } group relative h-full text-[14px] font-semibold flex w-full items-center  border-0 px-5 py-4 text-left transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-darkModeMain `}
                    type="button"
                    onClick={() => handleCollapseClick(details?.id)}
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {details?.name}
                    <span
                      className={`${
                        details?.show
                          ? `rotate-[-180deg] -mr-1 `
                          : `rotate-0 fill-ipurple`
                      } ml-auto h-5 w-5 shrink-0  transition-transform duration-200 ease-in-out motion-reduce:transition-none `}
                    >
                      <IoIosArrowDown color={"5165F0"} size={25} />
                    </span>
                  </button>
                  {details?.name == "Categories" && details?.show && (
                    <button
                      className="mx-6 my-2 text-sm underline transition text-ipurple focus:outline-none underline-offset-2"
                      type="button"
                      onClick={resetFilters}
                    >
                      Reset
                    </button>
                  )}
                </h2>

                {details?.id == 2 && details?.show ? (
                  <BusinessAccordian
                    key={details?.id}
                    collapseShow={formik?.values?.collapseDetails?.[1]?.show}
                    allResults={allResults}
                    recentBusiness={recentBusiness}
                    savedBusiness={savedBusiness}
                    actionSavedResults={() => setSavedBusiness(!savedBusiness)}
                    actionRecentResults={() =>
                      setRecentBusiness(!recentBusiness)
                    }
                    actionResults={() => setAllResults(!allResults)}
                  />
                ) : details?.id == 3 && details?.show ? (
                  <LocationAccordian
                    LocationName={formik?.values?.LocationName}
                    setLocationName={(lname) => {
                      formik.setFieldValue("LocationName", lname);
                      setTriggerApi(true);
                    }}
                    setSelectedBtn={setSelectedBtn}
                    selectBtn={selectBtn}
                    locationSelected={() => {
                      setTriggerApi(true);
                    }}
                    resetLocation={() => {
                      locationDetail.current = {
                        lat: "",
                        long: "",
                      };

                      showEverywhere.current = true;
                      setTriggerApi(true);
                    }}
                    hideEveryWhere={() => {
                      showEverywhere.current = false;
                      setTriggerApi(true);
                    }}
                    currentLocation={(data) => {
                      showEverywhere.current = false;

                      locationDetail.current = {
                        lat: data?.lat,
                        long: data?.lng,
                      };
                      setTriggerApi(true);
                    }}
                    locationLat={formik?.values?.locationLat}
                    locationLong={formik?.values?.locationLong}
                    collapseShow={formik?.values?.collapseDetails?.[1]?.show}
                  />
                ) : details?.id == 1 && details?.show ? (
                  <SortByAccordian
                    key={details?.id}
                    collapseShow={formik?.values?.collapseDetails?.[0]?.show}
                    LocationSortBy={formik?.values?.LocationSortBy}
                    setLocationSortBy={(sort) => {
                      formik.setFieldValue("LocationSortBy", sort);
                      setTriggerApi(true);
                    }}
                  />
                ) : details?.id == 4 && details?.show ? (
                  <DistanceAccordian
                    key={details?.id}
                    locationDistance={formik?.values?.LocationDistance}
                    setLocationDistance={(distance) => {
                      formik.setFieldValue("LocationDistance", distance);
                      setTimeout(() => {
                        setTriggerApi(true);
                      }, 150);
                    }}
                    showCheckbox={showCheckbox}
                    setShowCheckbox={setShowCheckbox}
                    collapseShow={formik?.values?.collapseDetails?.[2]?.show}
                  />
                ) : (
                  details?.id == 5 &&
                  details?.show && (
                    <CategoriesAccordian
                      key={details?.id}
                      data={data || []}
                      locationCategory={formik?.values?.LocationCategoryData}
                      setLocationCategory={(location) =>
                        formik.setFieldValue(
                          "LocationCategoryData",
                          location || []
                        )
                      }
                      collapseShow={formik?.values?.collapseDetails?.[3]?.show}
                    />
                  )
                )}
              </div>
            );
          })}
        </div>
        <div className="bg-white dark:bg-darkModeMain border-t-[1px] dark:border-darkModeBorder "></div>
        <div className="h-[60px] flex justify-center">
          <div className="w-full px-4 flex items-center">
            <button
              onClick={() => {
                handleReset();
              }}
              type="button"
              className="  uppercase text-[13px] w-full h-[40px] font-semibold text-black dark:text-white rounded-full border-[1px] border-black py-[5.5px] px-4 hover:bg-neutral-200 dark:hover:bg-darkModeBG transition-colors"
            >
              RESET ALL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
