import ReactDOM from "react-dom";
import { IoClose } from "react-icons/io5";
import { CallFlowIcon } from "../../../icons/CallIcon";
const RemoveCallFlow = ({ handleClose, handleRemove, modalTitle }) => {
  return ReactDOM.createPortal(
    <div className="w-full text-sm h-screen fixed bg-[#666372] bg-opacity-[95%] flex flex-col justify-center items-center">
      <div className="w-[620px] max-h-[285px] h-[285px] justify-between items-center flex flex-col bg-white dark:bg-darkModeMain dark:border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg  relative">
        <CallFlowIcon className="!h-[50px] !w-[50px] mt-4" fill="#4C5FEF" />
        <div className=" flex items-center justify-center text-center w-[438px]">
          <div className="w-full ">
            <div className="py-2 font-bold text-[18px] pb-4">Remove Flow</div>
            <div>
              Do you want to remove the{" "}
              <span className="font-semibold">{modalTitle}</span> call flow?
              This action cannot be undone.
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full px-4  mt-6 mb-4">
          <button
            onClick={() => handleClose()}
            className="w-[260px] h-[45px] rounded-full bg-white border-[1px] border-[black] text-black hover:bg-[#383348] hover:text-white hover:border-[#383348] uppercase text-sm font-semibold  "
          >
            Cancel
          </button>
          <button
            onClick={() => handleRemove()}
            className="w-[260px] h-[45px] rounded-full text-white bg-black"
          >
            Remove
          </button>
        </div>
      </div>
    </div>,

    document.getElementById("portal-root")
  );
};
export default RemoveCallFlow;
