import { useState, useEffect, useRef } from "react";
import CallCard from "./CallCard";

import { useNavigate } from "react-router-dom";
import { CallDetailsService } from "../../services/calls/CallDetailsService";
import { useFormik } from "formik";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptySearchScreen from "../atom/EmptySearchScreen";
import ScrollingLoader from "../atom/loader/scrollingLoader";
import Loader from "../atom/loader/Loader";
import FilterOpt from "../FilterOpt";
import { ASC, DESC, NEWEST_FIRST } from "../../utils/constants";
import DateFilter from "../tickets-tab/filters/DateFilter";
import dayjs from "dayjs";

export default function CallsContainer() {
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastPage, setLastPage] = useState("");
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [sortOrder, setSortOrder] = useState(NEWEST_FIRST);
  const [loadingState, setLoadingState] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDateSelectOpen, setIsDateSelectOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [submitDate, setSubmitDate] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const handleDateSelectClick = () => {
    setIsDateSelectOpen(!isDateSelectOpen);
  };

  const handleCloseDateFilter = () => {
    setIsDateSelectOpen(false);
  };

  let filter = location?.pathname?.split("/calls/")?.[1] || "recent";
  const initialState = {
    currentPage: 1,
    activeFilter: filter,
    recentPageTotal: 0,
    schedulePageTotal: 0,
    callData: [],
  };
  useEffect(() => {
    if (location?.pathname) {
      formik.setFieldValue(
        "activeFilter",
        location?.pathname?.split("/calls/")?.[1]
      );
    }
  }, [location?.pathname]);

  const formik = useFormik({
    initialValues: initialState,
  });
  let pageName = location?.pathname?.split("/")?.[1];

  const {
    listAllScheduleCallDetailsApi,
    listAllRecentCallsDetailsApi,
    loadingCallDetails,
    clearCallData,
  } = CallDetailsService((state) => state);

  let scheduledCalls = {
    ticket_id: "",
    business_id: "",
    keyword: "",
    tag_ids: "",
    sort_order: "",
    start_date: "",
    end_date: "",
  };

  let recentCalls = {
    business_id: "",
    call_sid: "",
    stream_sid: "",
    recording_url: "",
    keyword: "",
    Outcode: "",
    sort_order: "",
    start_date: "",
    end_date: "",
  };

  const getButtonClass = (filterName) => {
    return `flex items-center gap-2 ${
      formik?.values?.activeFilter === filterName
        ? "text-ipurple font-semibold"
        : ""
    }`;
  };

  const navigate = useNavigate();

  const handleCallClick = (callId) => {
    navigate(`/tickets/${callId}`, {
      state: {
        ticketId: callId,
      },
    });
  };

  const getCallDataApi = (page) => {
    let paginationDetails = {
      start_date:
        startDate && selectedFilter != "all"
          ? dayjs(startDate).format("YYYY-MM-DD")
          : "",
      sort_order: sortOrder == NEWEST_FIRST ? DESC : ASC,
      end_date:
        endDate && selectedFilter != "all"
          ? dayjs(endDate).format("YYYY-MM-DD")
          : "",
      keyword: searchQuery,
    };
    setLoadingMore(true);

    formik?.values?.activeFilter === "recent"
      ? listAllRecentCallsDetailsApi({
          ...recentCalls,
          page: page || formik?.values?.currentPage,
          ...paginationDetails,
        }).then((recentCallsData) => {
          setLoadingState(false);

          let { success, meta, data } = recentCallsData;

          if (success) {
            setLastPage(meta?.last_page);
            if (meta?.current_page == meta?.last_page) {
              setHasMore(false);
            }

            formik.setFieldValue("apiPageLimit", meta?.last_page);
            formik.setFieldValue("recentPageTotal", meta?.total);
            formik.setFieldValue("currentPage", meta?.current_page + 1);

            const callData = () => {
              const filteredResponseData = data?.filter((newItem) => {
                const isDuplicate = formik?.values?.callData?.some(
                  (prevItem) => prevItem?.id === newItem?.id
                );

                return !isDuplicate;
              });
              return (
                [
                  ...(formik?.values?.callData || []),
                  ...filteredResponseData,
                ] || []
              );
            };

            formik.setFieldValue("callData", callData());
          }
        })
      : listAllScheduleCallDetailsApi({
          ...scheduledCalls,
          page: formik?.values?.currentPage,
          future: true,
          start_time:
            startDate && selectedFilter != "all"
              ? new Date(startDate).toISOString()
              : "",
          sort_order: sortOrder == NEWEST_FIRST ? ASC : DESC,
          end_time:
            endDate && selectedFilter != "all"
              ? new Date(endDate).toISOString()
              : "",
          keyword: searchQuery,
        }).then((recentScheduleCallsData) => {
          setLoadingState(false);
          let { success, meta, data } = recentScheduleCallsData || {};

          if (success) {
            formik.setFieldValue("apiPageLimit", meta?.last_page);
            formik.setFieldValue("apiPageTotal", meta?.total);
            formik.setFieldValue("currentPage", meta?.current_page + 1);
            // setCurrentPage(meta?.current_page + 1);

            const callData = () => {
              const filteredResponseData = data?.filter((newItem) => {
                const isDuplicate = formik?.values?.callData?.some(
                  (prevItem) => prevItem?.id === newItem?.id
                );

                return !isDuplicate;
              });

              return (
                [
                  ...(formik?.values?.callData || []),
                  ...filteredResponseData,
                ] || []
              );
            };

            formik.setFieldValue("callData", callData());
          }
        });

    setLoadingMore(false);
  };

  useEffect(() => {
    if (searchQuery?.length != 0) {
      const timeoutDetails = setTimeout(() => {
        getCallDataApi(1);
      }, 300);
      return () => {
        clearTimeout(timeoutDetails);
      };
    } else if (searchQuery == 0) {
      handleReset();
      getCallDataApi(1);
    }
  }, [formik?.values?.activeFilter, searchQuery, sortOrder, submitDate]);

  const handleReset = () => {
    clearCallData();

    formik?.setFieldValue("currentPage", 1);
    formik?.setFieldValue("callData", []);
  };
  const divRef = useRef(null);
  const handleScroll = () => {
    const container = divRef?.current;
    if (
      container?.scrollHeight - container?.scrollTop ===
        container?.clientHeight &&
      hasMore &&
      !loadingMore
    ) {
      getCallDataApi(formik?.values?.currentPage + 1);
    }
  };
  const changeSortOrder = (order) => {
    setSortOrder(order);
    setShowSortDropdown(false);
  };
  return (
    <div className="flex flex-col items-center justify-center w-full !h-[calc(100dvh-143px)] pt-4 text-sm relative">
      {loadingState ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div
          ref={divRef}
          id="scrollableDiv"
          onScroll={handleScroll}
          className="w-full h-screen mx-6 my-1 overflow-auto bg-white border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg dark:bg-darkModeMain"
        >
          <div className="sticky top-0 flex items-center h-[60px] z-20 justify-between bg-white border-b border-gray-100 dark:bg-darkModeMain dark:border-darkModeBorder">
            <div className="flex items-center gap-4 px-6">
              <p className="font-bold uppercase">Calls</p>
              <div className="h-[30px] w-[1px] bg-gray-100 dark:bg-darkModeBG"></div>
              <button
                className={getButtonClass("recent")}
                onClick={(event) => {
                  if (formik?.values?.activeFilter == "recent") {
                    event.stopPropagation();
                  } else {
                    formik.setFieldValue("activeFilter", "recent");
                    const newFilter = "recent";
                    navigate(`/calls/${newFilter}`);
                    handleReset();
                  }
                }}
                disabled={loadingCallDetails || false}
              >
                <p className="px-2">Recent</p>
              </button>
              <button
                className={getButtonClass("scheduled")}
                onClick={(event) => {
                  if (formik?.values?.activeFilter == "scheduled") {
                    event.stopPropagation();
                  } else {
                    formik.setFieldValue("activeFilter", "scheduled");
                    const newFilter = "scheduled";
                    navigate(`/calls/${newFilter}`);
                    handleReset();
                  }
                }}
                disabled={loadingCallDetails || false}
              >
                <p>Scheduled</p>
              </button>
            </div>
            {isDateSelectOpen && (
              <DateFilter
                onClose={handleCloseDateFilter}
                handleDate={() => {}}
                startDate={startDate || new Date()}
                endDate={endDate || new Date()}
                setStartDate={(event) => setStartDate(event)}
                setEndDate={(event) => setEndDate(event)}
                selectedFilter={selectedFilter}
                futureDateSelect={
                  formik?.values?.activeFilter == "scheduled" ? true : false
                }
                setSelectedFilter={setSelectedFilter}
                handleApply={() => {
                  handleReset();
                  setSubmitDate(!submitDate);
                  setIsDateSelectOpen(false);
                }}
              />
            )}
            <div className="flex items-center gap-4">
              <FilterOpt
                toggleSortDropdown={() =>
                  setShowSortDropdown(!showSortDropdown)
                }
                dateDetails={[startDate, endDate]}
                showSortDropdown={showSortDropdown}
                sortOrder={sortOrder}
                handleSortOrder={(order) => {
                  handleReset();
                  setSortOrder(order);
                  setShowSortDropdown(false);
                }}
                handleDateSelectClick={handleDateSelectClick}
                isInputOpen={isInputOpen}
                handleSearchCancel={() => {
                  setSearchQuery("");
                  setIsInputOpen((prevState) => !prevState);
                }}
                handleSearchClick={() => {
                  setIsInputOpen((prevState) => !prevState);
                }}
                setSearchQuery={(data) => {
                  handleReset();
                  setSearchQuery(data);
                }}
                selectedFilter={selectedFilter}
                searchQuery={searchQuery}
              />
            </div>
          </div>
          {loadingCallDetails && formik?.values?.currentPage == 1 ? (
            <div>
              <Loader />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={formik?.values?.callData.length}
              next={getCallDataApi}
              hasMore={formik?.values?.currentPage <= lastPage}
              scrollableTarget="scrollableDiv"
              scrollThreshold={0.5}
              style={{ overflow: "unset" }}
              loader={
                formik?.values?.callData.length > 15 &&
                loadingCallDetails && <ScrollingLoader />
              }
            >
              {formik?.values?.callData?.length !== 0
                ? formik?.values?.callData?.map((callDetails, index) => (
                    <CallCard
                      getApiCallHistory={(data) => {
                        let ticketArray = [];
                        formik?.values?.callData?.map((ticketData) => {
                          if (ticketData?.ticket_id == data?.ticketId) {
                            ticketArray.push({
                              ...ticketData,
                              ticket: {
                                ...ticketData?.ticket,
                                status:
                                  data?.ticketStatus == "closed"
                                    ? "open"
                                    : "closed",
                              },
                            });
                          } else {
                            ticketArray.push(ticketData);
                          }
                        });
                        formik.setFieldValue("callData", ticketArray);
                      }}
                      getScheduleCallData={(data) => {
                        let scheduleTicketArray = [];
                        formik?.values?.callData?.map((ticketData) => {
                          if (ticketData?.id === data?.ticketId) {
                            scheduleTicketArray.push({
                              ...ticketData,
                              call_at_ts: data?.call_at_ts,
                            });
                          } else {
                            scheduleTicketArray.push(ticketData);
                          }
                        });

                        formik.setFieldValue("callData", scheduleTicketArray);
                      }}
                      key={index}
                      callDetails={callDetails}
                      onCallClick={() =>
                        handleCallClick(callDetails?.ticket?.id)
                      }
                      activeFilterName={formik?.values?.activeFilter}
                    />
                  ))
                : !loadingCallDetails &&
                  formik?.values?.callData.length == 0 &&
                  !formik?.values?.callData && (
                    <EmptySearchScreen
                      activePage={formik?.values?.activeFilter}
                      pageName={pageName}
                    />
                  )}
            </InfiniteScroll>
          )}
        </div>
      )}
    </div>
  );
}
