// Import necessary dependencies
import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";
import { BASE_URL, VITE_API_PAGE_LIMIT } from "../../utils/constants";
import { apiFilterVallidation } from "../../utils/api/apiFilter";

// Create a Zustand store for Calls Details
export const CallDetailsService = create((set, get) => ({
  // Initial state variables
  listAllScheduleCallDetails: {}, // Holds details of a all scheduled call listings
  listAllRecentCallsDetails: {}, // Holds details of a all recent call listings
  ticketUpdateCallsDetails: [], // Holds details of a ticket update details
  listAllCallHistoryDetails: {}, // Holds details of all call history details
  listAllTranscriptsDetails: [], //Holds details of all transcripts
  reScheduleCallDetails: [], //Holds details of all reschedule call api
  addCallRequestDetails: {}, //Holds details of all schedule call api
  loadingCallDetails: false, // Indicates whether data is currently being loaded
  error: null, // Holds any error that may occur during data fetching or processing
  newTranscripts: [],
  transcriptLastPage: "",
  lastTimePage: "",

  // Async function to create a list all scheduled calls details
  listAllScheduleCallDetailsApi: async (callData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingCallDetails: true });
      let {
        start_time,
        end_time,
        ticket_id,
        business_id,
        keyword,
        tag_ids,
        page,
        future,
        sort_order,
      } = callData;
      // Make a GET request to create a list all scheduled call details

      let urlArray = [
        {
          name: "start_time",
          value: start_time,
        },
        {
          name: "end_time",
          value: end_time,
        },
        {
          name: "ticket_id",
          value: ticket_id,
        },
        {
          name: "business_id",
          value: business_id,
        },
        {
          name: "keyword",
          value: keyword,
        },
        {
          name: "limit",
          value: VITE_API_PAGE_LIMIT,
        },

        {
          name: "tag_ids",
          value: tag_ids,
        },
        {
          name: "page",
          value: page,
        },
        {
          name: "future",
          value: future,
        },
        {
          name: "sort_order",
          value: sort_order,
        },
      ];

      let filteredURL = apiFilterVallidation(
        urlArray,
        `${BASE_URL}call-requests?`,
      );

      const response = await AxiosApi.get(filteredURL);

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingCallDetails: false,
          listAllScheduleCallDetails: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingCallDetails: false, error: err });
      return err;
    }
  },

  // Async function to create a list all recent calls details
  listAllRecentCallsDetailsApi: async (callData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingCallDetails: true });
      let {
        business_id,
        call_sid,
        stream_sid,
        recording_url,
        page,
        keyword,
        sort_order,
        start_date,
        end_date,
      } = callData;

      // Make a GET request to create a list all recent call details
      let urlArray = [
        {
          name: "business_id",
          value: business_id,
        },
        {
          name: "call_sid",
          value: call_sid,
        },
        {
          name: "stream_sid",
          value: stream_sid,
        },
        {
          name: "sort_order",
          value: sort_order,
        },
        {
          name: "recording_url",
          value: recording_url,
        },
        {
          name: "page",
          value: page || 1,
        },
        {
          name: "limit",
          value: VITE_API_PAGE_LIMIT,
        },
        {
          name: "keyword",
          value: keyword,
        },
        {
          name: "start_date",
          value: start_date,
        },
        {
          name: "end_date",
          value: end_date,
        },
      ];

      let filteredURL = apiFilterVallidation(
        urlArray,
        `${BASE_URL}call-history?`,
      );
      const response = await AxiosApi.get(filteredURL);
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingCallDetails: false,
          listAllRecentCallsDetails: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingCallDetails: false, error: err });
      return err;
    }
  },

  // Async function to update the ticket  Change Archive ticket text to Close ticket
  ticketUpdateCallsDetailsApi: async ({ id, data }) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingCallDetails: true });
      // Make a PUT request to update the ticket
      const response = await AxiosApi.put(`${BASE_URL}tickets/${id}`, data);
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingCallDetails: false,
          ticketUpdateCallsDetails: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingCallDetails: false, error: err });
      return err;
    }
  },

  // Async function to create a list call history details
  listAllCallHistoryDetailsApi: async (callData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingCallDetails: true });
      let { callHistoryId } = callData;
      // Make a GET request to create a list call history details
      const response = await AxiosApi.get(
        `${BASE_URL}call-history/${callHistoryId}`,
      );
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingCallDetails: false,
          listAllCallHistoryDetails: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingCallDetails: false, error: err });
      return err;
    }
  },
  // Async function to create a list of get transcripts api
  listAllTranscriptsDetailsApi: async (transcriptsdata) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingCallDetails: true });
      let { callHistoryId, page } = transcriptsdata;
      // Make a GET request to create a list of get transcripts api
      const response = await AxiosApi.get(
        `${BASE_URL}call-history/${callHistoryId}/transcript-messages?limit=${VITE_API_PAGE_LIMIT}&page=${page}`,
      );
      // Destructure response data
      let { success, message, data } = response?.data || { message: "error" };
      let { last_page } = response?.data?.meta || {};
      if (success) {
        // If the request is successful, update the state with the response data
        set((state) => ({
          loadingCallDetails: false,
          transcriptLastPage: last_page,
          listAllTranscriptsDetails: [
            ...state.listAllTranscriptsDetails,
            ...data,
          ].filter(
            // Filter out duplicates based on the 'id' property
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id),
          ),
        }));

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingCallDetails: false, error: err });
      return err;
    }
  },

  // Async function to integrate reschedule call api

  reScheduleCallApi: async (callData) => {
    let { id, call_at_ts } = callData;
    try {
      // Set loading to true while waiting for the API response
      set({ loadingCallDetails: true });
      // Make a PUT request to reschedule call api

      const response = await AxiosApi.put(`${BASE_URL}call-requests/${id}`, {
        call_at_ts: call_at_ts,
      });

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        const allCallScheduleDetails = get().listAllScheduleCallDetails;
        // If the request is successful, update the state with the response data
        set({
          loadingCallDetails: false,
          reScheduleCallDetails: response?.data,
          listAllScheduleCallDetails: {
            ...allCallScheduleDetails,
            data: allCallScheduleDetails?.data?.map((x) =>
              x?.ticket_id == id ? { ...x, call_at_ts: call_at_ts } : { ...x },
            ),
          },
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingCallDetails: false, error: err });
      return err;
    }
  },

  // Async function to integrate cancel schedule call api

  cancelScheduleCallApi: (data) => {
    let { ticketId } = data;
    try {
      // Set loading to true while waiting for the API response
      set({ loadingCallDetails: true });
      // Make a DELETE request to integrate cancel schedule call api
      const response = AxiosApi.delete(`${BASE_URL}call-requests/${ticketId}`);

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingCallDetails: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingCallDetails: false, error: err });
      return err;
    }
  },

  // Async function to create the Schedule call/ Add call request api

  addCallRequestApi: async (callData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingCallDetails: true });
      // Make a POST request to the call request api

      const response = await AxiosApi.post(
        `${BASE_URL}call-requests`,
        callData,
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingCallDetails: false,
          addCallRequestDetails: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingCallDetails: false, error: err });
      return err;
    }
  },

  fetchNewTranscript: async (transcriptData) => {
    const { business_id, page } = transcriptData;
    try {
      // Set loading to true while waiting for the API response
      set({ loadingCallDetails: true });
      // Make a POST request to the call request api

      const response = await AxiosApi.get(
        `${BASE_URL}businesses/${business_id}/transcripts?page=${page}&limit=${VITE_API_PAGE_LIMIT}`,
      );

      // Destructure response data
      let { success, message, data } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set((state) => ({
          loadingCallDetails: false,
          lastTimePage: response?.data?.meta?.last_page,
          newTranscripts: [...state.newTranscripts, ...data].filter(
            // Filter out duplicates based on the 'id' property
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id),
          ),
        }));

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingCallDetails: false, error: err });
      return err;
    }
  },
  fetchOngoingCall: async () => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingCallDetails: true });

      const response = await AxiosApi.get(
        `${BASE_URL}call-history?ongoing=true`,
      );

      // Destructure response data
      let { success, message, data } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set(() => ({
          loadingCallDetails: false,
        }));

        return data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingCallDetails: false, error: err });
      return err;
    }
  },
  clearTranscriptsState: () => set({ listAllTranscriptsDetails: [] }),
  clearTranscriptOpt: () => set({ newTranscripts: [] }),
  clearCallData: () => {
    set({
      listAllRecentCallsDetails: {},
      listAllScheduleCallDetails: {},
    });
  },
}));
