import { BsChevronRight } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { useSidebarVisibility } from "../../context/SidebarVisibilityContext";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import { TicketsDetails } from "../../services/tickets/TicketDetails";
import { useEffect, useMemo, useRef, useState } from "react";
import { CallDetailsService } from "../../services/calls/CallDetailsService";
import BackBtn from "../atom/BackBtn";
import PropTypes from "prop-types";
import { sipCallParams } from "../../utils/DeviceDetails/SipDetails";
import dayjs from "dayjs";

Subnav.propTypes = {
  pageType: PropTypes.any,
};

export default function Subnav(props) {
  let { pageType } = props;
  const { pathname } = useLocation();
  const locationName = pathname?.substring(1)?.split("/");
  const businessNameNav = useRef("");
  // const [ businessNameNav.current, set businessNameNav.current] = useState("");
  const { fetchBusinessDetails } = DirectoryDetails((state) => state);
  const { listSingleTicketDetails } = TicketsDetails((state) => state);
  const { listAllRecentCallsDetails } = CallDetailsService((state) => state);
  let navigate = useNavigate();
  const { isSidebarVisible } = useSidebarVisibility();
  const extensionStatus = JSON.parse(localStorage.getItem("isExtension"));
  const [businessid, setBusinessId] = useState(null);

  useEffect(() => {
    // if (mixpanel) {
    //   mixpanel.track("Top nav", {
    //     locationName: locationName[0],
    //   });
    // }

    if (extensionStatus) {
      const sipCallData = sipCallParams(null);
      window.postMessage(sipCallData, "*");
    }
  }, [extensionStatus]);

  useEffect(() => {
    if (locationName[0] === "edit-business") {
      console.log(fetchBusinessDetails?.data?.name);
      businessNameNav.current = fetchBusinessDetails?.data?.name;
    } else if (locationName[0] === "create-business") {
      businessNameNav.current = "Create Business";
    } else if (
      locationName[0] === "business" &&
      fetchBusinessDetails?.data?.name
    ) {
      businessNameNav.current = fetchBusinessDetails?.data?.name;
    } else if (
      locationName[0] === "tickets" &&
      listSingleTicketDetails?.data?.business?.name
    ) {
      businessNameNav.current = listSingleTicketDetails?.data?.business?.name;
      setBusinessId(listSingleTicketDetails?.data?.business?.id);
    } else if (
      locationName[0] === "calls" &&
      listAllRecentCallsDetails?.data?.name
    ) {
      businessNameNav.current = listAllRecentCallsDetails?.data?.name;
    } else if (locationName[0] === "page-not-found-404") {
      businessNameNav.current = "Page Not Found";
    } else {
      businessNameNav.current = "";
    }
  }, [
    locationName,
    pathname,
    fetchBusinessDetails,
    listSingleTicketDetails,
    listAllRecentCallsDetails,
  ]);

  const navigateTo = (location) => {
    if (location === "recent" && pathname?.includes("calls")) {
      return navigate("/calls/recent");
    }

    if (location === businessNameNav.current && locationName[0] == "tickets") {
      return navigate(`/business/${businessid}`);
    }
    switch (location) {
      case "chat":
        return navigate("/chat");
      case "create-business":
        return navigate("/create-business");
      case "dashboard":
        return navigate("/directory/suggested");
      case "directory":
        return navigate("/directory/suggested");
      case "saved":
        return navigate("/directory/saved");
      case "recent":
        return navigate("/directory/recent");
      case "search":
        return navigate("/directory/search");
      case "suggested":
        return navigate("/directory/suggested");
      case "tickets":
        return navigate("/tickets");
      case "calls":
        return navigate("/calls/recent");
      case "scheduled":
        return navigate("/calls/scheduled");
      case "business":
        return navigate("/directory/saved");
      default:
        if (location.match(/\d+/)) {
          return null;
        }
        return navigate("/");
    }
  };

  const locationSearch = useMemo(() => {
    if (pageType === "Search") {
      return [locationName[0], "Search Results"];
    } else if (locationName[0] === "business") {
      return ["directory", fetchBusinessDetails?.data?.name];
    } else if (
      locationName[0] === "tickets" &&
      listSingleTicketDetails?.data?.last_activity_ts === undefined
    ) {
      return ["tickets"];
    } else if (
      locationName[0] === "tickets" &&
      locationName[1] &&
      listSingleTicketDetails?.data?.reason === undefined
    ) {
      return [
        "tickets",
        listSingleTicketDetails?.data?.business?.name,
        listSingleTicketDetails?.data?.name,
      ];
    } else if (locationName[0] == "create-business") {
      return ["Directory", "Add Business"];
    } else {
      return locationName;
    }
  }, [pageType, locationName, listSingleTicketDetails, fetchBusinessDetails]);

  const checkBackBtn =
    pathname === "/directory/suggested" ||
    pathname === "/directory/saved" ||
    pathname === "/directory/search" ||
    pathname === "/directory/recent";
  const isValidDateWithYear = (dateString) => {
    const date = dayjs(dateString);
    const year = date.year();
    return date.isValid() && year >= 2020 && year <= 2099;
  };

  return (
    <div
      className={`w-full h-[55px] pl-2 border-b border-gray-100-[1px] border-gray-100 flex min-h-[55px] items-center text-[13px] bg-white fixed dark:fill-white  dark:bg-darkModeMain dark:border-darkModeBorder z-[39] ${
        isSidebarVisible ? "" : "ml-0"
      }`}
    >
      {pageType === "Search" ? (
        <>
          <BackBtn pageType={pageType} />
          <div className="h-[30px] !ml-4 mx-2 bg-gray-100 w-[2px] dark:bg-darkModeBorder"></div>
        </>
      ) : checkBackBtn ? (
        <></>
      ) : (
        <>
          <BackBtn pageType={pageType} />
          <div className="h-[30px] !ml-4 mx-2 bg-gray-100 w-[2px] dark:bg-darkModeBorder"></div>
        </>
      )}
      <div className="flex items-center gap-2 p-3 pt-4 pl-0 dark:text-white">
        <div className="flex items-center gap-4 p-3 ">
          {locationSearch?.map((location, index) => {
            const formattedLocation =
              index === locationSearch.length - 1 &&
              isValidDateWithYear(location)
                ? dayjs(location).format("MMMM D, YYYY")
                : location;

            // Check if you're editing a business and display the business name
            const buttonText =
              location === "create-business"
                ? "Add Business"
                : location === "chat"
                  ? "AI Customer Service"
                  : locationName[0] === "edit-business" &&
                      businessNameNav.current
                    ? businessNameNav.current
                    : location;

            return (
              <div className="flex items-center gap-2 " key={index}>
                <button
                  onClick={() => {
                    navigateTo(location);
                  }}
                  className={`capitalize text-[13px] dark:text-white ${
                    locationSearch?.length === 1
                      ? "text-isearch underline"
                      : locationSearch?.length - 1 === index
                        ? "text-[#878590]"
                        : "text-isearch underline"
                  }`}
                >
                  {isValidDateWithYear(location) &&
                  index === locationSearch.length - 1
                    ? formattedLocation
                    : buttonText}
                </button>
                {locationSearch?.length === 1 ? (
                  <></>
                ) : locationSearch?.length === index + 1 ? (
                  <></>
                ) : (
                  <BsChevronRight size={16} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
