import { LuClock9, LuPhone } from "react-icons/lu";
import { GiBackwardTime } from "react-icons/gi";
import { IoCalendarClearOutline, IoClipboardOutline } from "react-icons/io5";
import Dropdown from "./Dropdown";
import getScheduledTimeDisplay, {
  getTimeDiffDetails,
} from "../../utils/TimeDetails";
import { callDurationFormat } from "../../utils/DateTimeFormat";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import { formatInternationalPhoneNumber } from "../../utils/numberFormat";
import { SlPhone } from "react-icons/sl";
import { PiPhone, PiPhoneThin } from "react-icons/pi";
import { HiOutlinePhone } from "react-icons/hi2";
import {
  BusinessCalendarIcon,
  BusinessPhoneIcon,
  BusinessTimeIcon,
} from "../icons/Icons";

export default function CallCard(props) {
  let {
    callDetails,
    onCallClick,
    getApiCallHistory,
    activeFilterName,
    getScheduleCallData,
    isDashboardView,
  } = props || {};
  let { business } = callDetails || {};

  return (
    <div className=" call-card w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex justify-between items-center hover:bg-lightBg dark:hover:bg-darkModeBG h-[75px]">
      <div className="flex items-center gap-4 px-6 cursor-default">
        <DirectoryImageInitials
          imageUrl={business?.uploads?.file_path || business?.upload?.file_path}
          alt={business?.name}
          name={business?.name}
          boxColor={business?.business_color?.toLowerCase()}
          boxColorImg={"border border-gray-100 w-[40px] h-[40px] rounded-lg "}
          boxBorder="1px solid #ECEDF2"
          className={`border-none max-w-[40px] max-h-[40px] rounded-lg`}
        />

        <p
          className="font-bold hover:cursor-pointer "
          onClick={() => onCallClick(callDetails?.id)}
        >
          {callDetails?.business?.name}
        </p>
      </div>
      {/* {!isDashboardView && ( */}
      <div className={`flex items-center pl-6  pr-2 mr-0 cursor-default `}>
        {activeFilterName == "recent" && (
          <div className="flex items-center w-[204px]">
            <BusinessPhoneIcon />
            <p className="pl-2 font-semibold">
              {formatInternationalPhoneNumber(callDetails?.phone_number)}
            </p>
          </div>
        )}
        {activeFilterName == "recent" && (
          <div className="flex items-center w-[86px]">
            <span>
              <BusinessTimeIcon />
            </span>
            <p className="pl-2">
              {callDurationFormat(callDetails?.call_duration)}
            </p>
          </div>
        )}

        <div
          className={
            activeFilterName == "scheduled"
              ? "flex items-center w-[330px] justify-center "
              : "flex items-center w-[150px] justify-center "
          }
        >
          {activeFilterName == "recent" && (
            <p className="w-[100px] text-right">
              {getTimeDiffDetails(callDetails?.created_at)}
            </p>
          )}

          {activeFilterName == "scheduled" && (
            <div className="flex items-center m-w-[150px] ">
              <BusinessCalendarIcon size={"25px"} />
              <p className="pl-2">
                {"Scheduled: " +
                  getScheduledTimeDisplay(callDetails?.call_at_ts)}
              </p>
            </div>
          )}
          <div>
            <Dropdown
              getScheduleCallData={getScheduleCallData}
              getApiCallHistory={getApiCallHistory}
              callDetailsData={callDetails}
              activeFilterName={activeFilterName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
