import React, { useEffect, useState } from "react";
import { useTheme } from "./ThemeContext";

const svg = {
  light: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="inline-block w-[30px] h-[30px]"
    >
      <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z" />
    </svg>
  ),
  dark: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      fill="none"
      className="inline-block w-[30px] h-[30px]"
    >
      <path
        fillRule="nonzero"
        d="M15,25 C20.5228,25 25,20.5228 25,15 C25,9.47715 20.5228,5 15,5 C9.47715,5 5,9.47715 5,15 C5,20.5228 9.47715,25 15,25 Z M15,23.5 L15,6.5 C19.6944,6.5 23.5,10.30558 23.5,15 C23.5,19.6944 19.6944,23.5 15,23.5 Z"
        fill="#140F26"
      />
    </svg>
  ),
};

const ThemeSwitcher: React.FC = (): JSX.Element => {
  const { theme, setTheme } = useTheme();
  const [activeTheme, setActiveTheme] = useState<string>(theme);

  // Retrieve saved theme from localStorage on component mount
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    document.documentElement.classList.toggle("dark", savedTheme === "dark");
    setTheme(savedTheme);
    setActiveTheme(savedTheme);
  }, [setTheme]);

  const toggleTheme = () => {
    const newTheme = activeTheme === "light" ? "dark" : "light";
    document.documentElement.classList.toggle("dark", newTheme === "dark");
    localStorage.setItem("theme", newTheme); // Save theme to localStorage
    setTheme(newTheme);
    setActiveTheme(newTheme);
  };

  return (
    <div id="theme-switcher">
      <button
        className="w-[30px] h-[30px] text-neutral-800 dark:text-white uppercase hover:dark:text-ipurple rounded-full hover:shadow-lg hover:bg-neutral-200 dark:hover:bg-darkModeBG focus:bg-neutral-300 dark:focus:bg-darkModeBG focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out flex items-center justify-center"
        type="button"
        onClick={toggleTheme}
      >
        {svg[activeTheme === "light" ? "dark" : "light"]}
      </button>
    </div>
  );
};

export default ThemeSwitcher;
