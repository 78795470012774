import logo from "../../assets/logo.webp";
import SearchInput from "../search-results/SearchInput";
import Avatar from "../Avatar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Accordian from "./Accordian";
import { userDetails } from "../../routes/ProtectedRoutes";
import { useSearch } from "../../context/SearchContext";
import { useEffect, useState } from "react";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import pusherNotification from "../pusher/pusherNotification";
import { deviceId } from "../../utils/DeviceDetails/DeviceDetails";
import NotificationService from "../../services/notification/NotificationService";
import { GoBell } from "react-icons/go";
import { TETooltip } from "tw-elements-react";
import { sendMixpanelEvent } from "../../services/mixpanelService";
import { CallDetailsService } from "../../services/calls/CallDetailsService";
import CallPro from "../../assets/icons/CallPro.svg";
import User from "../../assets/icons/User.svg" 

export default function TopNavBar() {
  const { fetchOngoingCall } = CallDetailsService((state) => state);
  const { ProfileDetails, getProfileDetails } = AuthPersonalPhone(
    (state) => state,
  );
  const location = useLocation();
  const { getlistNotificationApi, unReadNotificationTotal } =
    NotificationService((state) => state);
  const { setSearchTerm, setSelectedLocation, setSearchViewToggle } =
    useSearch();
  const [activeCall, setActiveCall] = useState(false);
  const locationName = location?.pathname?.substring(1);
  const deviceUid = deviceId();

  const profileButtonClass =
    locationName === "profile"
      ? " flex items-center justify-center  h-20 text-white bg-ipurple"
      : "";
  const navigate = useNavigate();
  const userDetailsProfile = userDetails();

  useEffect(() => {
    userDetails();
  }, [ProfileDetails]);

  const handleNotificationsClick = () => {
    sendMixpanelEvent("Inbox Viewed");
    navigate("/notifications");
  };

  useEffect(() => {
    getProfileDetails();

    // Show active call button if there is an ongoing call
    fetchOngoingCall().then((data) => {
      if (data && data.length > 0) {
        setActiveCall(true);
      } else {
        setActiveCall(false);
      }
    });
  }, []);

  const getNotificationCount = () => {
    getlistNotificationApi(
      {
        page: 1,
        businessId: "",
        start_date: "",
        end_date: "",
        is_archived: false,
        limit: "",
      },
      "all",
    );
  };

  useEffect(() => {
    const channel = pusherNotification.subscribe(deviceUid);
    getNotificationCount();
    const eventHandlers = {
      "email.confirm_code_sent": () => {
        getNotificationCount();
      },
      "call.end_on_hold": () => {
        getNotificationCount();
      },
      "business.create_contact": () => {
        getNotificationCount();
      },
      "business.update_contact": () => {
        getNotificationCount();
      },
      "business.create_location": () => {
        getNotificationCount();
      },
      "business.update_location": () => {
        getNotificationCount();
      },
      "business.create": () => {
        getNotificationCount();
      },
      "business.update": () => {
        getNotificationCount();
      },
      "business.create_working_days": () => {
        getNotificationCount();
      },
      "business.update_working_days": () => {
        getNotificationCount();
      },
      "call_request.create": () => {
        getNotificationCount();
      },
      "call_request.update": () => {
        getNotificationCount();
      },
      "reminder.create": () => {
        getNotificationCount();
      },
      "reminder.update": () => {
        getNotificationCount();
      },
      "business.report": () => {
        getNotificationCount();
      },
      "call-started": () => {
        setActiveCall(true);
      },
      "call-ended": () => {
        setActiveCall(false);
      },
    };
    Object.keys(eventHandlers).forEach((event) => {
      channel.bind(event, eventHandlers[event]);
    });

    return () => {
      channel.unbind(); // Unbind all callbacks for this channel
    };
  }, [deviceUid]);

  return (
    <div className="fixed bg-white dark:bg-darkModeMain flex h-[80px] z-40 justify-between w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder items-center">
      <div className="flex flex-row items-center pr-6">
        <img
          src={logo}
          alt="logo"
          width={106}
          height={50}
          // height={auto}
          className="ml-[9px] cursor-pointer"
          draggable={false}
          onClick={() => {
            setSearchViewToggle(false);
            navigate("/directory/search");
          }}
        />
      </div>
      <SearchInput />
      <div className="flex items-center h-full px-2 !pr-5">
        {/* Call Active Button  */}
        {activeCall && (
          <div className="absolute-right-0 w-full max-w-[126px] h-[36px] flex justify-end items-center text-nowrap">
            <button className="flex items-center gap-[5px] px-4 py-2 rounded-full call-active-btn text-white">
              <img src={CallPro} alt="Call Icon" />
              <span className="text-white font-semibold text-[12px] leading-[4px]">
                Call Active
              </span>
            </button>
          </div>
        )}
        <div className="relative flex flex-row items-center gap-3 mx-4 mt-2">
          <button id="step-10" onClick={handleNotificationsClick}>
            <TETooltip placement="right" title={"Notification"}>
              <GoBell className="hover:cursor-pointer" size={28} />
              {unReadNotificationTotal == 0 || unReadNotificationTotal < 0 ? (
                ""
              ) : (
                <div className="absolute border-[1px]  border-gray-100  flex text-white justify-center  shadow-xl shadow-black  items-center text-[8px] right-0 -top-1 rounded-full w-[15px] font-semibold bg-ipink h-[15px]">
                  {unReadNotificationTotal > 9 ? "9+" : unReadNotificationTotal}
                </div>
              )}
            </TETooltip>
          </button>
        </div>
        <div className="h-[30px] mx-2 bg-gray-100 w-[2px] dark:bg-darkModeBorder"></div>
        {userDetailsProfile && (
          <div
            className={`flex items-center pl-4 justify-center ${profileButtonClass}`}
          >
            <button className="rounded-full">
              <NavLink
                to="/profile"
                onClick={() => {
                  setSearchTerm("");
                  setSelectedLocation("");
                }}
              >
                <Avatar pathSrc={userDetailsProfile?.upload?.file_path || User} />
              </NavLink>
            </button>
            <Accordian />
          </div>
        )}
      </div>
    </div>
  );
}
