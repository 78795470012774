import React from 'react'
import ReactDOM from 'react-dom'
import { IoChevronDown, IoClose } from 'react-icons/io5'
import DirectoryImageInitials from '../atom/directoryImageInitials'

export default function ReportPopup({onClose}) {
  return ReactDOM.createPortal(
    

    <div className='w-full text-sm h-screen fixed bg-[#140F26] bg-opacity-[95%] flex flex-col justify-center items-center'>
        <div className='w-[820px] justify-center items-center flex flex-col bg-white dark:bg-darkModeMain dark:border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg  relative'>
            <div className='w-full h-[60px] flex justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder items-center'>
                <h1 className='px-4 font-semibold uppercase'> Report / Request Update</h1>
                <button onClick={onClose} className='absolute top-4 right-4 '>
                    <IoClose size={25} />
                </button>
            </div>
        <h1 className='uppercase px-4 w-full flex h-[60px] font-semibold items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder'>Business</h1>
        <div className='flex items-center self-start gap-3 p-4'>
            <DirectoryImageInitials 
                className={`max-w-[45px] max-h-[45px] border-none rounded-lg`}
            />
            <h2 className='font-semibold text-[15px]'>Verizon</h2>
        </div>
        <h2 className='font-semibold px-4 flex items-center h-[60px] uppercase w-full'>Reason</h2>
        <div className='w-full p-4 border-t-[1px] dark:border-darkModeBorder'>
            <div className='flex h-[55px] items-center justify-between w-full px-3 border-2 border-gray-100 rounded-md dark:border-darkModeBorder'>
                <p className='font-semibold text-[14px]'>Business does not exist</p>
                <IoChevronDown size={20} />
            </div>
        </div>
        <h2 className='self-start p-4 font-semibold uppercase flex items-center h-[60px]'>Additional Details</h2>
        <div className='w-full py-4 flex border-t-[1px] dark:border-darkModeBorder'>
            <textarea
            placeholder='Enter any additional details here...'
                className='rounded-md h-[110px] w-full mx-4 my-3 p-2 border-gray-200  dark:border-darkModeBorder bg-inherit active:border-ipurple active:ring-ipurple focus:border-ipurple text-sm'
            />
        </div>
        <div className='w-full  text-[13px] mt-1 py-6 font-[600] h-[85px] flex items-center justify-between'>
                <button className='m-4 w-[250px] h-[45px] uppercase rounded-full border-b border-gray-100lack border-[1px]  border-gray-100  dark:border-white hover:bg-black hover:text-white hover:border-none dark:hover:bg-white dark:hover:text-black'>Cancel</button>
                <button className='m-4 w-[250px] h-[45px] uppercase rounded-full bg-ipurple text-white hover:border-[1px]  border-gray-100  hover:border-ipurple hover:text-ipurple hover:bg-inherit'>Report / Request Update</button>
        </div>
        </div>

    </div>

    ,
  document.getElementById("portal-root")
  )
}
