import { useEffect, useState } from "react";
import { AuthPersonalPhone } from "../../../services/auth/personal/AuthPersonal";
import validateEmail from "../../../utils/validate/EmailValidate";
import { SettingService } from "../../../services/setting/SettingService";
import toast from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import PropTypes from "prop-types";
import { sendMixpanelEvent } from "../../../services/mixpanelService";

ChangeEmailView.propTypes = {
  setSendEmail: PropTypes.func,
  setActiveSettingsView: PropTypes.func,
};

export default function ChangeEmailView(props) {
  const { setActiveSettingsView, setSendEmail } = props;
  const { ProfileDetails } = AuthPersonalPhone((state) => state);
  const { verifyChangeEmailApi, settingLoading } = SettingService(
    (state) => state
  );

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (ProfileDetails) {
      setEmail(ProfileDetails?.email);
    }
  }, [ProfileDetails]);

  const validEmail = validateEmail(email);
  const handleChangeEmailAddress = () => {
    if (ProfileDetails?.email == email) {
      toast.error(
        "The email address you entered already exists in our records"
      );
    } else if (validEmail) {
      verifyChangeEmailApi({ new_email: email })
        .then((data) => {
          if (data.message == "ALREADY EXIST") {
            toast.error(
              "The email address you entered already exists in our records"
            );
          } else {
            setSendEmail(email);
            setActiveSettingsView("phoneValidate");
            sendMixpanelEvent("Email Changed Successfully");
          }
        })
        .catch((error) => console.log(error, "error"));
    } else {
      toast.error("Please enter a valid email");
    }
  };
  return (
    <div className="flex flex-col w-full text-sm scrollable-container h-full overflow-y-auto">
      <div className="w-full py-6 space-y-5 ">
        <p className="px-6">
          Your current email is{" "}
          <span> {ProfileDetails ? ProfileDetails?.email : ""} </span> What
          would you like to update it to?
        </p>
      </div>

      <div className="flex items-center w-full gap-6 px-6">
        <div className="flex flex-col">
          <p className="py-3">Enter new email address:</p>
          <input
            type="email"
            placeholder="jessejackson@gmail.com"
            className="w-[400px] font-semibold dark:border-darkModeBorder bg-inherit dark:placeholder:text-white h-[55px] border-gray-100 rounded-lg placeholder:text-gray-300 placeholder:text-sm text-sm placeholder:font-semibold"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        {settingLoading ? (
          <button
            className={
              validEmail
                ? "rounded-full flex justify-center items-center min-w-[240px] h-[45px] mt-10 bg-ipurple text-white uppercase font-semibold text-[13px]"
                : "rounded-full flex justify-center items-center min-w-[240px] h-[45px] mt-10 bg-gray-300 text-white uppercase font-semibold text-[13px]"
            }
          >
            <CgSpinner />
          </button>
        ) : (
          <button
            className={
              validEmail
                ? "rounded-full flex justify-center items-center min-w-[240px] h-[45px] mt-10 bg-ipurple text-white uppercase font-semibold text-[13px]"
                : "rounded-full flex justify-center items-center min-w-[240px] h-[45px] mt-10 bg-gray-300 text-white uppercase font-semibold text-[13px]"
            }
            onClick={() => {
              handleChangeEmailAddress();
            }}
          >
            Change Email Address
          </button>
        )}
      </div>
    </div>
  );
}
