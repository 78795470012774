import { CalendarIcon } from "../../icons/Icons";
import { TicketIcon } from "../../icons/TicketIcon";
import { HiMiniArrowRight } from "react-icons/hi2";
import { SearchIcon } from "../../icons/SearchIcon";
import { HiOutlineChevronDown } from "react-icons/hi2";
import ActivityView from "./ActivityView";

export default function DetailsView() {
  return (
    <div className="flex flex-col items-center justify-center w-full space-y-4 text-sm ">
      <div className="w-full flex flex-col h-[175px] bg-white dark:bg-darkModeMain rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder">
        <div className="w-full h-[80px] flex justify-between items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
          <div className="flex items-center gap-2 px-4">
            <CalendarIcon className="text-black dark:text-white w-[25px]" />
            <p>Ticket Created</p>
            <p className="font-semibold">
              June 7, 2024 <span> 05:45 PM</span>
            </p>
          </div>
          <div className="flex items-center gap-2 px-5">
            <TicketIcon className="text-black dark:text-white w-[25px]" />
            <p>Ticket Status</p>
            <div className="w-[80px] mx-4 h-[22px] text-ipurple rounded-full bg-[#4c5fef23] flex items-center relative uppercase text-[10px] font-semibold justify-center ">
              <div className="absolute w-[7px] h-[7px] rounded-full bg-ipurple left-4"></div>
              <p className="ml-1.5 text-center flex justify-center">Open</p>
            </div>
            <button className="w-[150px] uppercase border-2 border-b border-gray-100lack dark:border-white rounded-full font-semibold h-[45px] hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black">
              Close Ticket
            </button>
          </div>
        </div>
        <div className="flex flex-col w-full p-4 space-y-2">
          <p className="overflow-x-hidden truncate max-w-1/2">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Exercitationem, dicta nobis dolores impedit rerum ullam ex, facere
            corporis officia dolorum cumque culpa aliquam et hic eius delectus
            commodi ipsa illo!
          </p>
          <button className="flex items-center gap-2 font-semibold">
            <p>Show original message</p> <HiMiniArrowRight />
          </button>
        </div>
      </div>
      <div className="w-full flex flex-col  bg-white dark:bg-darkModeMain rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder">
        <div className="w-full h-[60px] flex justify-between items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
          <p className="px-4 font-semibold uppercase">Activity</p>
          <SearchIcon className="mr-4 text-black dark:text-white w-[25px]" />
        </div>
        <div className="h-[50px] w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex items-center justify-between">
          <p className="px-4">June 9, 2024</p>
          <HiOutlineChevronDown className="mx-4" size={20} />
        </div>
        <ActivityView />
      </div>
    </div>
  );
}
