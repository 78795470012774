import axios from "axios";
import { retrieveAuthToken } from "./authService";
import AxiosApi from "../api/requestInterceptor";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

/**
 * Fetch search history GET request
 * @returns {Promise<object>} A promise that resolves to the server's response data.
 */
  export const listSearchHistory = async () => {
    try {
      const response = await AxiosApi.get(`${API_BASE_URL}users/search-histories`)
      return response.data;
    } catch (error) {
      console.error("List search history error:", error);
      throw error;
    }
  };

/**
 * Create search history POST request
 * @param {object} searchHistoryParams - The parameters for creating the search history.
 * @returns {Promise<object>} A promise that resolves to the server's response data.
 */
export const createSearchHistory =  async (searchHistoryParams) => {
  const token = retrieveAuthToken();
  const jsonData = searchHistoryParams;

  return await AxiosApi.post(`${API_BASE_URL}users/search-histories`, jsonData)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Create search history error:", error);
      throw error;
    });
};

/**
 * Update search history PUT request
 * @param {object} id - Search History id.
 * @param {object} searchHistoryParams - The parameters for updating the search history.
 * @returns {Promise<object>} A promise that resolves to the server's response data.
 */
export const updateSearchHistory = (id, searchHistoryParams) => {
  const token = retrieveAuthToken();
  const jsonData = JSON.stringify(searchHistoryParams);

  return AxiosApi.put(`${API_BASE_URL}/users/search-histories/${id}`, jsonData, {
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Update search history error:", error);
      throw error;
    });
};

/**
 * Delete one/all search history record(s) DELETE request
 * @param {object} id - SearchHistory id.
 * @param {object} deleteAll - Boolean. If true, all search history records will get deleted.
 * @returns {Promise<object>} A promise that resolves to the server's response data.
 */
export const deleteSearchHistory = (id = null, deleteAll = false) => {
  const token = retrieveAuthToken();

  let url = `${API_BASE_URL}/users/search-histories`;
  if (id && !deleteAll) url += `/${id}`;

  return axios
    .delete(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Delete search history error:", error);
      throw error;
    });
};

export const handleClearSearchHistory = async () => {
  const token = localStorage.getItem("authLogin");
  if (!token) {
    console.error("No authentication token found");
    return;
  }

  try {
    await axios.delete(`${API_BASE_URL}/users/search-histories`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    
  } catch (error) {
    console.error("Error clearing search history:", error);
  }
};

