/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { forwardRef, useState, useEffect } from "react";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

// eslint-disable-next-line react/display-name
const PasswordInput = forwardRef(
  (
    {
      label,
      id,
      name,
      value,
      onChange,
      isConfirmation = false,
      isExistingPassword = false,
      disablePasswordSuggestion = false,
      confirmPasswordValue = "", // Pass confirmation password value if needed
      autoComplete,
      inputStyleClass,
      ...props
    },
    ref
  ) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
      const newPassword = e.target.value;
      onChange(e); // Call parent onChange if provided
      
      const passwordValidation = {
        length: newPassword.length >= 6,
        hasUppercase: /[A-Z]/.test(newPassword),
        hasNumber: /\d/.test(newPassword),
        isMatch: isConfirmation ? newPassword === confirmPasswordValue : true,
      };

      const isPasswordValid = Object.values(passwordValidation).every(Boolean);

      if (
        !isPasswordValid &&
        !disablePasswordSuggestion &&
        newPassword.length > 0
      ) {
        setErrorMessage(
          <div className="py-2 space-y-3 text-[13px] flex flex-col">
            <div className="flex items-center gap-2">
              <div
                className={`w-[8px] h-[8px] rounded-full ${
                  passwordValidation.length ? "bg-ipurple" : "bg-gray-200"
                }`}
              ></div>
              <p>Be at least 6 characters long</p>
            </div>
            <div className="flex items-center gap-2">
              <div
                className={`w-[8px] h-[8px] rounded-full ${
                  passwordValidation.hasUppercase
                    ? "bg-ipurple"
                    : "bg-gray-200"
                }`}
              ></div>
              <p>Have at least one uppercase letter</p>
            </div>
            <div className="flex items-center gap-2">
              <div
                className={`w-[8px] h-[8px] rounded-full ${
                  passwordValidation.hasNumber ? "bg-ipurple" : "bg-gray-200"
                }`}
              ></div>
              <p>Have at least one number</p>
            </div>
            {isConfirmation && (
              <div className="flex items-center gap-2">
                <div
                  className={`w-[8px] h-[8px] rounded-full ${
                    passwordValidation.isMatch ? "bg-ipurple" : "bg-gray-200"
                  }`}
                ></div>
                <p>Both passwords should match</p>
              </div>
            )}
          </div>
        );
      } else {
        setErrorMessage("");
      }
    };

    const handleBlur = () => {
      // Clear error message on blur (when input loses focus)
      // Clear error message on blur
      setErrorMessage("");
    };

    return (
      <div className="relative flex flex-col items-center justify-center w-full">
        {label && (
          <label htmlFor={id} className="block text-sm font-medium leading-6">
            {label}
          </label>
        )}
        <div
          className={`${inputStyleClass} flex items-center justify-center w-[340px] h-[55px] rounded-md border-[1px]  border-gray-100  text-gray-900 shadow-sm outline-none focus-within:ring-1 focus-within:ring-ipurple placeholder:text-[14px]`}
        >
          <input
            ref={ref}
            type={showPassword ? "text" : "password"}
            id={id}
            name={name}
            value={value}
            placeholder="Your Password..."
            autoComplete={autoComplete || "off"}
            onChange={handleChange}
            onBlur={handleBlur}
            className="flex-1 w-11/12 focus:ring-transparent bg-transparent border-0 focus:border-transparent dark:text-white focus:outline-none placeholder:text-[14px]"
          />
          {!showPassword ? (
            <VscEye
              size={27}
              onClick={() => setShowPassword(true)}
              className="cursor-pointer w-1/12 pr-2  dark:text-white"
            />
          ) : (
            <VscEyeClosed
              size={27}
              onClick={() => setShowPassword(false)}
              className="cursor-pointer w-1/12 pr-2 dark:text-ipurple"
            />
          )}
        </div>

        {errorMessage && name !== "confirmPassword" ? (
          <div className="absolute text-sm text-black space-y-3 left-[-310px] w-[265px] top-[-40px]   flex flex-col p-4 m-4 bg-white shadow-2xl rounded-lg z-50">
            <p>{errorMessage}</p>
          </div>
        ) : errorMessage && name === "confirmPassword" ? (
          <div className="absolute text-sm text-black space-y-3 right-[-300px] w-[265px] top-[-40px] flex flex-col p-4 m-4 bg-white shadow-2xl rounded-lg z-50">
            <p>{errorMessage}</p>
          </div>
        ) : (
          <p></p>
        )}
      </div>
    );
  }
);

export default PasswordInput;
