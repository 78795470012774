import IaolloScore from "../IaolloScore";
import busScore from "../../../assets/BusinessScore.webp";
import { GoPlus } from "react-icons/go";
import ReviewsCard from "../ReviewsCard";
import { DirectoryDetails } from "../../../services/directory/DirectoryDetails";
import ReviewPopup from "../../ReviewPopup";
import { IoOptionsOutline, IoSearch } from "react-icons/io5";
import { useState } from "react";

export default function ReviewsView(props) {
  const { reviewData } = DirectoryDetails((state) => state);
  let { average_review_rating } = reviewData?.stats?.reviews || {};
  const [showReviewPopup, setShowReviewPopup] = useState(false);

  const handleAddReviewClick = () => {
    setShowReviewPopup(true);
  };

  const handleClosePopup = () => {
    setShowReviewPopup(false);
  };

  return (
    <div className="flex flex-col w-full h-screen space-y-4">
      <div className="w-full bg-white dark:bg-darkModeMain h-[180px] rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder">
        <div className="flex items-center w-full gap-6">
          <div className="flex flex-col items-center justify-center w-1/3 p-4 pb-6 ml-12 space-y-3 text-sm text-center">
            <div className="flex items-center justify-center w-full gap-3 pt-4 ">
              <p className="text-sm uppercase ">Iallo score</p>
              <img src={busScore} alt="Business Score" width={40} height={20} />
              <p className="text-sm font-bold">
                {average_review_rating || 0} / 5
              </p>
            </div>
            <p className="text-[13px]">
              Based on {reviewData?.meta?.total} reviews
            </p>
            <button
              onClick={handleAddReviewClick}
              className="w-[240px] text-sm h-[45px] bg-ipurple rounded-full text-white uppercase flex justify-center items-center p-2 gap-2"
            >
              <GoPlus size={20} />
              <p className="text-[12px]">Add review</p>
            </button>
          </div>
          <IaolloScore />
        </div>
      </div>
      <div className=" flex flex-col items-center w-full bg-white rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder dark:bg-darkModeMain pb-4">
        <div className="h-[60px] flex  border-b border-gray-100-[1px] border-gray-100 justify-between dark:border-darkModeBorder w-full  items-center p-4">
          <div className="flex items-center gap-6 px-2">
            <p className="text-sm font-bold uppercase">Reviews</p>
            <p className="text-sm font-bold ">
              All({reviewData !== 0 && reviewData?.data?.length})
            </p>
            <p className="text-sm ">Verified</p>
          </div>
          <div className="flex items-center gap-4 px-2">
            <IoOptionsOutline size={20} />
            <IoSearch size={20} />
          </div>
        </div>
        <div className="flex flex-col w-full overflow-y-scroll">
          <ReviewsCard {...props} />
          {showReviewPopup && <ReviewPopup onClose={handleClosePopup} />}
        </div>
      </div>
    </div>
  );
}
