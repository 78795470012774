export default function Footer() {
  return (
    <div className="p-4 text-sm max-[780px]:flex-col w-full z-[9999] h-[65px] flex flex-row items-center justify-center gap-4 bg-gradient-to-r from-ipurple to-purple-600">
      <p className="text-center text-white">
        Get free calls & unlock more features on your smartphone.
      </p>
      <a
        href="https://iallo.io/get-iallo"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="bg-ipink text-[13px] font-semibold w-[200px] h-[45px] rounded-full text-white uppercase hover:bg-white hover:text-ipink hover:border-2 hover:border-ipink">
          Get iAllo
        </button>
      </a>
    </div>
  );
}
