import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";
import { VITE_API_PAGE_LIMIT_DASHBOARD } from "../../utils/constants";

// Create a Zustand store for Calls Details
export const CallFlowService = create((set, get) => ({
  listCallFlow: {},
  listCallFlowList: {},
  loadingList: false,
  loading: false,
  error: null,

  createCallFlowApi: async (userData) => {
    try {
      set({ loading: true });

      const response = await AxiosApi.post(`call-flows`, userData);

      let { success, message } = response?.data || { message: "error" };

      if (success) {
        set({
          loading: false,
        });

        return response?.data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loading: false, error: err });
      return err;
    }
  },
  listCallFlowApi: async (userData) => {
    try {
      set({ loading: true });

      const response = await AxiosApi.get(
        `call-flows?business_id=${userData?.businessId}&page=${userData?.page}&limit=${VITE_API_PAGE_LIMIT_DASHBOARD}`
      );

      let { success, message } = response?.data || { message: "error" };

      if (success) {
        set({
          loadingList: false,
          listCallFlowList: response?.data,
        });

        return response?.data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loading: false, error: err });
      return err;
    }
  },
  updateCallFlowApi: async (id, userData) => {
    try {
      set({ loading: true });

      const response = await AxiosApi.put(`call-flows/${id}`, userData);

      let { success, message } = response?.data || { message: "error" };

      if (success) {
        set({
          loading: false,
          listCallFlowList: {
            ...get().listCallFlowList,
            data: get().listCallFlowList?.data?.map((data) => {
              if (data?.id == id) {
                return { ...data, name: userData?.name };
              } else {
                return { ...data };
              }
            }),
          },
        });
        return response?.data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loading: false, error: err });
      return err;
    }
  },

  deleteCallFlowApi: async (id) => {
    try {
      set({ loading: true });

      const response = await AxiosApi.delete(`call-flows/${id}`);

      let { success, message } = response?.data || { message: "error" };

      if (success) {
        set({
          loading: false,
          listCallFlowList: {
            ...get().listCallFlowList,
            data: get().listCallFlowList?.data?.filter((data) => data.id != id),
          },
        });

        return response?.data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loading: false, error: err });
      return err;
    }
  },
}));
