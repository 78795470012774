import dayjs from "dayjs";
import { CalendarIcon, HangupIcon, IncomingCallIcon } from "../icons/Icons";
import { LuCalendarClock, LuPhoneIncoming } from "react-icons/lu";
import { ImPhoneHangUp } from "react-icons/im";
import PropTypes from "prop-types";
import { TECollapse } from "tw-elements-react";
import { useEffect, useState } from "react";
import { formatInternationalPhoneNumber } from "../../utils/numberFormat";
import { TicketsDetails } from "../../services/tickets/TicketDetails";
import { TicketIcon } from "../icons/TicketIcon";
import { MdEventAvailable } from "react-icons/md";
import { convertSecondsToReadableFormat } from "../../utils/DateTimeFormat";

ActivityCard.propTypes = {
  ActivityCard: PropTypes.any,
  IconName: PropTypes.any,
};

export default function ActivityCard(props) {
  let { ticketId, ticketActivitiesdata } = props || {};

  const [activeElement, setActiveElement] = useState("");

  const {
    listSingleTicketDetailsApi,
    listSingleTicketDetails,
    loadingTicketDetails,
  } = TicketsDetails((state) => state);

  const handleClick = (id) => {
    setActiveElement((prevId) => (prevId === id ? -1 : id));
  };

  const handleActivityText = (event, id, item) => {
    let textToDisplay;
    let IconName = null; // Initialize IconName here
    const durationInSeconds = item?.payload?.duration;
    const originalDateTime = item?.payload?.call_at_ts;
    const previousDateTime = item?.payload?.old_call_at_ts;
    const summary = item?.payload?.after_call_summary;

    const scheduledCall = dayjs(originalDateTime)
      .local()
      .format("MMMM D, YYYY [at] h:mm A");
    const previousCallTime = dayjs(previousDateTime)
      .local()
      .format("MMMM D, YYYY [at] h:mm A");
    const callCancelledAt = dayjs(item?.payload?.deleted_at)
      .local()
      .format("MMMM D, YYYY [at] h:mm A");
    switch (event) {
      case "ticket.create":
        textToDisplay = `Ticket created ${item?.payload?.name}`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );

        break;
      case "ticket.update":
        textToDisplay = `Ticket updated`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );

        break;
      case "Ticket.call_request":
        textToDisplay = `Scheduled Call on ${scheduledCall}`;
        IconName = (
          <CalendarIcon
            className="w-[25px] text-black dark:text-white"
            width={25}
            height={25}
          />
        );
        break;
      case "Ticket.call_request_update":
        textToDisplay = `Call scheduled on ${previousCallTime} rescheduled to ${scheduledCall}`;
        IconName = (
          <CalendarIcon
            className="w-[25px] text-black dark:text-white "
            width={25}
            height={25}
          />
        );
        break;
      case "ticket.pin_note":
        textToDisplay = `Ticket note pinned`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "ticket.create_profile":
        textToDisplay = `Ticket profile created`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "Ticket.create_ticket_agent":
        textToDisplay = `Ticket agent created`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "Ticket.delete_ticket_agent":
        textToDisplay = `Ticket agent deleted`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "Ticket.create_transcript":
        textToDisplay = `Ticket transcript created`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "Ticket.delete_Transcript":
        textToDisplay = `Ticket transcript deleted`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "reminder.create":
        textToDisplay = `Ticket reminder created`;
        IconName = <LuCalendarClock />;
        break;
      case "reminder.delete":
        textToDisplay = `Ticket reminder deleted`;
        IconName = <LuCalendarClock />;
        break;
      case "note.create":
        textToDisplay = `Ticket note created`;
        IconName = (
          <TicketIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "ticket_tag.create":
        textToDisplay = `Ticket tag created`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "ticket_tag.delete":
        textToDisplay = `Ticket tag deleted`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "ticket.closed":
        textToDisplay = `Ticket closed`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "ticket.reopened":
        textToDisplay = `Ticket re-opened`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "ticket.title_updated":
        textToDisplay = `Ticket title updated to ${item?.payload?.name}`;
        IconName = (
          <CalendarIcon className="text-black dark:text-white w-[25px]" />
        );
        break;
      case "ticket.call_started":
        textToDisplay = `Call started`;
        IconName = (
          <IncomingCallIcon
            width={25}
            height={25}
            className="text-black dark:text-white w-[25px]"
          />
        );
        break;
      case "Ticket.call_incoming":
        textToDisplay = `Incoming call : ${formatInternationalPhoneNumber(
          item?.payload?.phone_number,
        )} `;
        IconName = (
          <IncomingCallIcon
            width={25}
            height={25}
            className="text-black dark:text-white w-[25px]"
          />
        );
        break;
      case "Ticket.call_ended":
        textToDisplay = "Call ended";
        if (durationInSeconds) {
          textToDisplay += ` (${convertSecondsToReadableFormat(durationInSeconds)})`;
        }
        IconName = (
          <HangupIcon
            width={25}
            height={25}
            className="text-black dark:text-white w-[25px]"
          />
        );
        break;
      case "ticket.after_call_summary":
        textToDisplay = `Summary - ${summary}`;
        IconName = (
          <TicketIcon
            width={25}
            height={25}
            className="text-black dark:text-white w-[25px]"
          />
        );
        break;
      case "Ticket.cancel_call":
        textToDisplay = `Cancelled call on ${callCancelledAt}`;
        IconName = (
          <CalendarIcon
            className="w-[25px] text-black dark:text-white"
            width={25}
            height={25}
          />
        );
        break;
      default:
        textToDisplay = `Unknown event for Ticket ${id}`;
        IconName = (
          <MdEventAvailable
            width={25}
            height={25}
            className="text-black dark:text-white w-[25px]"
          />
        );
    }

    return { textToDisplay, IconName }; // Add return statement
  };

  // useEffect(() => {
  //   if (ticketId) {
  //     listSingleTicketDetailsApi(ticketId);
  //   }
  // }, []);

  useEffect(() => {
    if (
      Array?.isArray(listSingleTicketDetails?.data?.log_activities) &&
      listSingleTicketDetails?.data?.log_activities?.length != 0
    ) {
      setActiveElement(0);
    }
  }, [listSingleTicketDetails]);

  return (
    <>
      <div id="accordionExample" className="">
        {listSingleTicketDetails?.data?.grouped_log_activities?.length !== 0 &&
          listSingleTicketDetails?.data?.grouped_log_activities?.map(
            (data, index) => (
              <div
                className="  border-gray-100 bg-white dark:border-neutral-300 dark:bg-neutral-800"
                key={data?.id}
              >
                <h2 className="mb-0" id="headingOne">
                  <button
                    className={`text-sm ${
                      activeElement === index &&
                      `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400 dark:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]`
                    } group relative text-[13px] flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white`}
                    type="button"
                    onClick={() => handleClick(index)}
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {dayjs(data.created_at).format("MMM D, YYYY")}
                    <span
                      className={`${
                        activeElement === index
                          ? `rotate-[-180deg] -mr-1`
                          : `rotate-0 fill-[#212529]  dark:fill-white`
                      } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  </button>
                </h2>
                <TECollapse
                  show={activeElement === index}
                  className="!mt-0 !rounded-b-none !shadow-none pt-2 "
                >
                  {data?.records?.map((data) => (
                    <div
                      className="px-5 py-2 flex justify-between items-center"
                      key={data?.id}
                    >
                      <p className="flex gap-4 items-center text-[13px] flex-grow">
                        {handleActivityText(data.key, data.id, data).IconName}{" "}
                        {
                          handleActivityText(data.key, data.id, data)
                            .textToDisplay
                        }
                      </p>
                      <p className="text-[#140f2666] flex-shrink-0 ml-4 dark:text-white">
                        {dayjs(data?.created_at).local().format("h:mm A")}
                      </p>
                    </div>
                  ))}
                </TECollapse>
              </div>
            ),
          )}
      </div>
    </>
  );
}
