import React, { useState, useEffect } from 'react';
import { IoChevronDown } from 'react-icons/io5';

export default function LanguageSettingsView() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');

  const languages = ['English', 'Spanish'];

  // Load selected language from localStorage on component mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    localStorage.setItem('selectedLanguage', language); // Save to localStorage
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div className='flex flex-col w-full p-5 space-y-6 text-sm scrollable-container h-full overflow-y-auto'>
      <p>Select app language. The language will be changed immediately.</p>
      <div className='flex items-center w-full gap-20'>
        <h2 className='font-semibold'>App Language</h2>
        <div className='relative'>
          <div
            className='flex font-semibold justify-between items-center rounded-lg border-[1px] border-gray-100 dark:border-darkModeBorder p-4 w-[240px] h-[55px] cursor-pointer' 
            onClick={toggleDropdown}
          >
            <p>{selectedLanguage}</p>
            <IoChevronDown size={22} />
          </div>
          {isOpen && (
            <div className='absolute bg-white border border-gray-300 rounded-md shadow-lg mt-2 w-[240px] z-10 dark:bg-darkModeMain dark:hover:text-black'>
              {languages.map((language) => (
                <div
                  key={language}
                  className='p-2 hover:bg-gray-100 cursor-pointer'
                  onClick={() => handleLanguageSelect(language)}
                >
                  {language}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
