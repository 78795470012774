import { useEffect, useState } from "react";
import { SettingService } from "../../../services/setting/SettingService";

export default function NotificationsView() {
  const [isSoundsToggled, setIsSoundsToggled] = useState(false);
  const [isVibrateToggled, setIsVibrateToggled] = useState(false);
  const [isEmailToggled, setIsEmailToggled] = useState(false);
  const { notificationApi, updateResp, updateSettingApi } = SettingService(
    (state) => state
  );

  const handleSoundsToggle = async () => {
    setIsSoundsToggled(!isSoundsToggled);
    if (updateResp?.data?.in_app_sounds == false) {
      await updateSettingApi({
        in_app_sounds: true,
      });
      notificationApi({});
    } else {
      await updateSettingApi({
        in_app_sounds: "false",
      });
      notificationApi({});
    }
  };

  const handleVibrateToggle = async () => {
    setIsVibrateToggled(!isVibrateToggled);
    if (updateResp?.data?.in_app_vibrate == false) {
      await updateSettingApi({
        in_app_vibrate: true,
      });
      notificationApi({});
    } else {
      await updateSettingApi({
        in_app_vibrate: "false",
      });
      notificationApi({});
    }
  };

  const handleEmailToggle = async () => {
    setIsEmailToggled(!isEmailToggled);
    if (updateResp?.data?.recieve_marketing_emails == false) {
      await updateSettingApi({
        recieve_marketing_emails: true,
      });
      notificationApi({});
    } else {
      await updateSettingApi({
        recieve_marketing_emails: "false",
      });
      notificationApi({});
    }
  };

  useEffect(() => {
    setIsSoundsToggled(updateResp?.data?.in_app_sounds);
    setIsVibrateToggled(updateResp?.data?.in_app_vibrate);
    setIsEmailToggled(updateResp?.data?.recieve_marketing_emails);
  }, [updateResp]);

  useEffect(() => {
    notificationApi({});
  }, []);

  return (
    <div className="w-full flex divide-y-[1px] dark:divide-darkModeBG flex-col border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder text-sm scrollable-container h-full overflow-y-auto">
      <div className="w-full h-[59px] flex items-center">
        <h1 className="px-5 font-semibold text-[16px]">In-App Notifications</h1>
      </div>
      <div className="w-full h-[60px] flex items-center justify-between">
        <p className="p-5 font-semibold">In-App Sounds</p>
        <div
          className={`m-5 relative w-[45px] h-[25px] rounded-full cursor-pointer ${
            isSoundsToggled
              ? "bg-[#4c5fef3b] dark:bg-secondary-900"
              : "bg-gray-200 dark:bg-gray-400"
          }`}
          onClick={handleSoundsToggle}
        >
          <div
            className={`h-[21px] w-[21px] rounded-full absolute top-0.5 transition-transform duration-300 ease-in-out ${
              isSoundsToggled
                ? "translate-x-[21px] bg-ipurple"
                : "bg-white dark:bg-darkModeMain translate-x-0.5"
            }`}
          ></div>
        </div>
      </div>
      <div className="w-full h-[60px] flex items-center justify-between">
        <p className="p-5 font-semibold">In-App Vibrate</p>
        <div
          className={`m-5 relative w-[45px] h-[25px] rounded-full cursor-pointer ${
            isVibrateToggled
              ? "bg-[#4c5fef3b]  dark:bg-secondary-900"
              : "bg-gray-200 dark:bg-gray-400"
          }`}
          onClick={handleVibrateToggle}
        >
          <div
            className={`h-[21px] w-[21px] rounded-full  absolute top-0.5 transition-transform duration-300 ease-in-out ${
              isVibrateToggled
                ? "translate-x-[21px] bg-ipurple "
                : "bg-white dark:bg-darkModeMain translate-x-0.5"
            }`}
          ></div>
        </div>
      </div>
      <div className="w-full h-[69px] flex items-center">
        <h1 className="px-5 font-semibold text-[16px]">Email</h1>
      </div>
      <div className="w-full h-[60px] flex items-center justify-between">
        <p className="p-5 font-semibold">Recieve marketing emails</p>
        <div
          className={`m-5 relative w-[45px] h-[25px] rounded-full cursor-pointer ${
            isEmailToggled ? "bg-[#4c5fef3b] dark:bg-secondary-900 ": "bg-gray-200 dark:bg-gray-400"
          }`}
          onClick={handleEmailToggle}
        >
          <div
            className={`h-[21px] w-[21px] rounded-full  absolute top-0.5 transition-transform duration-300 ease-in-out ${
              isEmailToggled
                ? "translate-x-[21px] bg-ipurple"
                : "bg-white dark:bg-darkModeMain translate-x-0.5"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
}
