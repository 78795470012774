import { useState } from "react";
import { Trash } from "../icons/Icons";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import toast from "react-hot-toast";

export default function DeleteBusinessPopup(props) {
  const navigate = useNavigate();
  const { businessid, onClose } = props;
  const { deleteBusinessApi } = DirectoryDetails((state) => state);

  const handleDelete = () => {
    deleteBusinessApi(businessid).then((data) => {
      if (data?.success) {
        toast.success("Business Deleted Successfully");
        navigate("/directory/search");
      } else {
        toast.error("Something went wrong");
      }
      onClose();
    });
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-[#6d687d] bg-opacity-[97%] flex items-center justify-center">
      <div className="bg-white rounded-[10px] shadow-lg w-full max-w-2xl h-auto mx-4 dark:bg-darkModeMain">
        <div className="p-6 space-y-4">
          <div className="flex justify-center">
            <Trash className=" w-12 h-12 text-blue-800" />
          </div>
          <h2 className="text-2xl font-bold text-center text-isearch">

            Delete Business
          </h2>
          <p className="text-center text-[#6B7280] dark:text-white">
            Are you sure you want to delete this business?
          </p>
          <p className="text-center text-[#6B7280] dark:text-white">
            This action cannot be undone.
          </p>
        </div>
        <div className="flex justify-between p-4 space-x-4">
          <button
            onClick={onClose}
            className="w-1/2 py-3 px-4 bg-white dark:bg-darkModeMain dark:text-white dark:hover:bg-white dark:hover:text-black border-2 border-black hover:bg-slate-800  text-black rounded-full hover:bg-opacity-90 transition duration-200"
          >
            CANCEL
          </button>
          <button
            onClick={handleDelete}
            className="w-1/2 py-3 px-4 bg-black border-2 border-black text-white rounded-full hover:bg-opacity-90 transition duration-200"
          >
            DELETE
          </button>
        </div>
      </div>
    </div>,

    document.getElementById("portal-root")
  );
}
