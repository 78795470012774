// src/layouts/RootLayout.jsx
import React, { useState } from "react";
import Footer from "../components/auth/Footer";
import ThemeSwitcher from "../components/ThemeSwitcher";

import TopNavBar from "../components/navigation/TopNavBar";
import TopNav from "../components/auth/TopNav";
import { useLocation } from "react-router-dom";

function AuthLayout({ children }) {
  const [view, setView] = useState('PhoneInput')
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {view, setView })
  );
  return (
    <div className="flex flex-col items-center justify-between w-full h-screen font-sans bg-white ">
      {/* <ThemeSwitcher /> */}
      <TopNav view={view} setView={setView}/>
      <main className="flex h-screen bg-white w-full">{childrenWithProps}</main>
      {location.pathname === "/" && <Footer />}
    </div>
  );
}

export default AuthLayout;
