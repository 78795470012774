import { useEffect, useRef, useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import SelectDaysDropdown from "../SelectDaysDropdown";
import { TrashIcon } from "../../icons/Icons";
import Autocomplete from "react-google-autocomplete";
import { MAP_KEYS } from "../../../utils/constants";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { formatTimeTo12Hour } from "../../../utils/DateTimeFormat";
import { TimeOptions } from "../../../utils/common";

AddLocationHours.propTypes = {
  onClose: PropTypes.func,
  formik: PropTypes.object.isRequired,
  apiData: PropTypes.any,
  editLocation: PropTypes.func,
  editIndex: PropTypes.number,
  setEditIndex: PropTypes.func,
};

export default function AddLocationHours(props) {
  const { formik, onClose, editLocation, editIndex, setEditIndex } = props;
  const inputStartDateRef = useRef([]);
  const inputEndDateRef = useRef([]);
  const initialValues = {
    selectedLocation: "",
    selectedLocationLat: "",
    selectedLocationLong: "",
    fromDate: "",
    toDate: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    business_working_days: [
      {
        days: [],
        start_time: "00:00:00",
        end_time: "00:00:00",
        Timezone: "",
      },
    ],
  };

  const [isSelectDaysDropdownOpen, setIsSelectDaysDropdownOpen] =
    useState(false);

  const [location, setLocation] = useState();

  useEffect(() => {
    if (Object?.keys(editLocation || {})?.length != 0) {
      locationFormik.setFieldValue("city", editLocation?.city);
      locationFormik.setFieldValue("zipCode", editLocation?.zip_code);
      locationFormik.setFieldValue("country", editLocation?.country);
      locationFormik.setFieldValue("state", editLocation?.state);
      locationFormik.setFieldValue(
        "business_working_days",
        editLocation?.business_working_days,
      );
      locationFormik.setFieldValue("selectedLocationLat", editLocation.lat);
      locationFormik.setFieldValue("selectedLocationLong", editLocation.log);
      locationFormik.setFieldValue("selectedLocation", editLocation?.address);
    }
  }, [editLocation]);

  const handleIsSelectDaysDropdownOpen = (index) => {
    setIsSelectDaysDropdownOpen(
      isSelectDaysDropdownOpen == index ? null : index,
    );
  };

  const toggleDaySelection = (day) => {
    let data = locationFormik?.values?.business_working_days;
    let DayTime = [...(data?.[day?.index]?.days || {})];
    const index = DayTime.indexOf(day.name);
    if (index !== -1) {
      DayTime.splice(index, 1);
    } else {
      DayTime.push(day.name);
    }

    data[day.index] = {
      days: DayTime,
      start_time: data?.[day.index]?.start_time,
      end_time: data?.[day.index]?.end_time,
      ...(data?.id && { id: data.id }),
    };
    setIsSelectDaysDropdownOpen(day?.index);
    locationFormik.setFieldValue("business_working_days", data);
  };

  const handleAddLocation = async () => {
    const newLocation = {
      city: locationFormik?.values?.city,
      state: locationFormik?.values?.state,
      address: locationFormik?.values?.selectedLocation,
      lat: locationFormik?.values?.selectedLocationLat,
      lng: locationFormik?.values?.selectedLocationLong,
      zip_code: locationFormik?.values?.zipCode,
      country: locationFormik?.values?.country,
      business_working_days: locationFormik?.values.business_working_days,
    };
    if (editIndex === "" || typeof editIndex !== "number") {
      // Add new location
      await formik.setFieldValue("business_locations", [
        ...formik.values.business_locations,
        newLocation,
      ]);
      onClose();
      setEditIndex("");
    } else if (editIndex === 0 || typeof editIndex === "number") {
      // Edit existing location
      let updatedLocations = [...formik.values.business_locations];
      updatedLocations[editIndex] = newLocation;
      await formik.setFieldValue("business_locations", updatedLocations);
      onClose();
      setEditIndex("");
    }
  };

  const locationFormik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    onSubmit: handleAddLocation,
  });

  const handleRemoveTime = (index) => {
    const newArray = [...(locationFormik?.values?.business_working_days || [])];

    if (index !== -1) {
      newArray.splice(index, 1);
    }

    locationFormik.setFieldValue("business_working_days", newArray);
  };

  return (
    <div className="flex flex-col w-full mt-2 text-sm my-5 ">
      <div className="flex flex-col px-4 my-6 space-y-3 font-semibold uppercase">
        {editLocation ? "Update" : "New"} Location
      </div>

      <div className="flex items-center w-full gap-4 px-4">
        <div className="flex flex-col w-1/2 space-y-3">
          <label>Country *</label>
          <input
            className="placeholder:text-sm placeholder:font-normal font-semibold text-sm placeholder:text-gray-400 dark:placeholder:text-white bg-inherit w-full h-[55px] rounded-lg border-2 border-gray-100 dark:border-darkModeBorder flex focus:outline-0 focus:ring-none  focus:border-ipurple justify-between items-center px-4"
            placeholder="Add country here..."
            type="text"
            value={
              locationFormik?.values?.country
                ? locationFormik?.values?.country
                : ""
            }
            onChange={(event) => {
              locationFormik.setFieldValue("country", event.target.value);
            }}
          />
        </div>
        <div className="flex flex-col w-1/2 space-y-3">
          <label>State *</label>
          <input
            className="placeholder:text-sm placeholder:font-normal font-semibold text-sm placeholder:text-gray-400 dark:placeholder:text-white bg-inherit w-full h-[55px] rounded-lg border-2 border-gray-100 dark:border-darkModeBorder flex focus:outline-0 focus:ring-none  focus:border-ipurple justify-between items-center px-4"
            placeholder="Add state here..."
            type="text"
            value={
              locationFormik?.values?.state ? locationFormik?.values?.state : ""
            }
            onChange={(event) => {
              locationFormik.setFieldValue("state", event.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex items-center w-full gap-4 p-4">
        <div className="flex flex-col w-1/2 space-y-3">
          <label>City *</label>
          <input
            className="placeholder:text-sm placeholder:font-normal font-semibold text-sm placeholder:text-gray-400 dark:placeholder:text-white bg-inherit w-full h-[55px] rounded-lg border-2 border-gray-100 dark:border-darkModeBorder flex focus:outline-0 focus:ring-none  focus:border-ipurple justify-between items-center px-4"
            placeholder="Add city here..."
            type="text"
            value={
              locationFormik?.values?.city ? locationFormik?.values?.city : ""
            }
            onChange={(event) => {
              locationFormik.setFieldValue("city", event.target.value);
            }}
          />
        </div>
        <div className="flex flex-col w-1/2 space-y-3">
          <label>Zip Code *</label>
          <input
            className="placeholder:text-sm placeholder:font-normal font-semibold text-sm placeholder:text-gray-400 dark:placeholder:text-white bg-inherit w-full h-[55px] rounded-lg border-2 border-gray-100 dark:border-darkModeBorder flex focus:outline-0 focus:ring-none  focus:border-ipurple justify-between items-center px-4"
            placeholder="Add zip code here..."
            type="number"
            value={
              locationFormik?.values?.zipCode
                ? locationFormik?.values?.zipCode
                : ""
            }
            onChange={(event) => {
              locationFormik.setFieldValue("zipCode", event.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex flex-col p-4 space-y-3">
        <label>Address *</label>
        <div>
          <Autocomplete
            apiKey={MAP_KEYS}
            onPlaceSelected={(place) => {
              locationFormik.setFieldValue(
                "selectedLocation",
                place?.formatted_address,
              );
              locationFormik.setFieldValue(
                "selectedLocationLat",
                place?.geometry?.location?.lat(),
              );
              locationFormik.setFieldValue(
                "selectedLocationLong",
                place?.geometry?.location?.lng(),
              );

              // Extract state, city, and country
              const addressComponents = place?.address_components || [];
              let state, city, country, zipCode;

              // Iterate through address components to find state, city, and country
              addressComponents &&
                addressComponents.forEach((component) => {
                  if (component.types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                  } else if (component.types.includes("locality")) {
                    city = component.long_name;
                  } else if (component.types.includes("country")) {
                    country = component.long_name;
                  } else if (component.types.includes("postal_code")) {
                    zipCode = component.long_name;
                  }
                });
              locationFormik.setFieldValue("country", country);
              locationFormik.setFieldValue("state", state);
              locationFormik.setFieldValue("city", city);
              locationFormik.setFieldValue("zipCode", zipCode);
            }}
            value={
              locationFormik?.values.selectedLocation
                ? locationFormik?.values.selectedLocation
                : location
            }
            language="en"
            options={{
              types: ["geocode", "establishment"],
            }}
            onChange={(data) => {
              setLocation(data?.formatted_address);
              locationFormik.setFieldValue(
                "selectedLocation",
                data?.formatted_address,
              );
            }}
            className="placeholder:text-sm placeholder:font-normal font-semibold text-sm placeholder:text-gray-400 dark:placeholder:text-white bg-inherit w-full h-[55px] rounded-lg border-2 border-gray-100 dark:border-darkModeBorder flex focus:outline-0 focus:ring-none  focus:border-ipurple justify-between items-center px-4"
          />
        </div>
      </div>
      <h1 className="px-4 pt-4 font-semibold uppercase">Working Hours *</h1>
      {locationFormik?.values?.business_working_days?.map((item, index) => {
        return (
          <div className="flex items-center w-full gap-2" key={index}>
            <div className="relative flex flex-col w-1/2 m-4 ">
              <div
                className="flex h-[55px] px-3 hover:cursor-pointer rounded-lg border-2 border-gray-100 dark:border-darkModeBorder items-center justify-between w-full"
                onClick={() => {
                  handleIsSelectDaysDropdownOpen(index);
                }}
              >
                <p className="font-semibold">{item?.days.join("-")}</p>
                {isSelectDaysDropdownOpen == index ? (
                  <IoChevronUp size={18} />
                ) : (
                  <IoChevronDown size={18} />
                )}
              </div>
              <div className="z-20 w-full ">
                {isSelectDaysDropdownOpen == index && (
                  <SelectDaysDropdown
                    selectedDays={item?.days}
                    toggleDaySelection={(data) => {
                      toggleDaySelection({
                        name: data.name,
                        id: data.id,
                        index: index,
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <p>from</p>
            <div>
              <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <IoChevronDown size={18} />
              </div>
              <select
                id="time"
                className="relative flex font-semibold w-[120px] h-[55px] px-3 hover:cursor-pointer rounded-lg border-2 border-gray-100 dark:bg-darkModeMain dark:border-darkModeBorder items-center justify-between"
                value={item.end_time}
                onChange={(event) => {
                  let data = locationFormik?.values?.business_working_days;
                  data[index] = {
                    days: item?.days,
                    start_time: event?.target.value + ":00",
                    end_time: item?.end_time,
                    ...(data?.id && { id: data.id }),
                  };
                  locationFormik.setFieldValue("business_working_days", data);
                }}
              >
                <option value="">{formatTimeTo12Hour(item.start_time)}</option>
                {TimeOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <p>to</p>
            <div>
              <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <IoChevronDown size={18} />
              </div>
              <select
                id="time"
                className="relative flex font-semibold w-[120px] h-[55px] px-3 hover:cursor-pointer rounded-lg border-2 border-gray-100 dark:bg-darkModeMain dark:border-darkModeBorder items-center justify-between"
                value={item.end_time}
                onChange={(event) => {
                  let data = locationFormik?.values?.business_working_days;
                  data[index] = {
                    days: item?.days,
                    start_time: item.start_time,
                    end_time: event?.target.value + ":00",
                    ...(data?.id && { id: data.id }),
                  };
                  locationFormik.setFieldValue("business_working_days", data);
                }}
              >
                <option value="">{formatTimeTo12Hour(item.end_time)}</option>
                {TimeOptions.map((option, index) => (
                  <option
                    key={index}
                    value={option.value}
                    className="overflow-y-auto h-20"
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div
              onClick={() => {
                handleRemoveTime(index);
              }}
            >
              <TrashIcon className="text-black dark:text-white hover:text-ipurple hover:cursor-pointer" />
            </div>
          </div>
        );
      })}

      <div
        className="flex gap-2 ms-4 my-5 items-center cursor-pointer"
        onClick={() => {
          locationFormik.setFieldValue("business_working_days", [
            ...(locationFormik?.values?.business_working_days || []),
            {
              days: [],
              start_time: "00:00:00",
              end_time: "00:00:00",
            },
          ]);
        }}
      >
        <p className="text-3xl font-normal ">+</p> Add Working Hours
      </div>
      <div className="w-full border-t-[1px] font-[600] mt-4 py-6 dark:border-darkModeBorder h-[85px] flex items-center justify-between">
        <button
          onClick={onClose}
          className="m-4 w-[220px] h-[45px] uppercase rounded-full border-b border-gray-100lack border-[1px]  border-gray-100  dark:border-white hover:bg-black hover:text-white hover:border-none dark:hover:bg-white dark:hover:text-black"
        >
          Cancel
        </button>
        <button
          className="m-4 w-[220px] h-[45px] uppercase rounded-full bg-ipurple text-white hover:border-[1px]  border-gray-100  hover:border-ipurple hover:text-ipurple hover:bg-inherit"
          onClick={locationFormik.handleSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
