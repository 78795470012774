import React, { useState } from "react";
import PropTypes from "prop-types";

function ExtensionInstallationFooter(props) {
  const { setExtensionInstalled } = props;

  // Immediately check localStorage before rendering the component
  const isExtensionClosed = typeof window !== "undefined" && localStorage.getItem("isExtensionClosed") === "true"; 
  // Set the initial state based on the localStorage value
  const [isBannerVisible, setIsBannerVisible] = useState(!isExtensionClosed);

  const handleCloseBanner = async () => {
    // Store the banner close status in localStorage and hide the banner
    await localStorage.setItem("isExtensionClosed", "true");
    setIsBannerVisible(false);
    setExtensionInstalled(true); // Call this to indicate extension installed, if needed
  };

  if (!isBannerVisible) return null; // Don't render the banner if it's closed

  return (
    <div className="p-2 text-sm w-full h-[40px] z-[900] fixed bottom-0 left-0 right-0 flex items-center justify-between gap-4 bg-gradient-to-r from-ipurple to-purple-600">
      <div className="flex items-center justify-center gap-4 flex-grow">
        <p className="text-center text-white">
          Make and Receive Calls and See Real-Time Transcriptions right in your
          browser
        </p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://chromewebstore.google.com/detail/iallo-companion-dialer/dimhlpifdleffpgkdfdlklljkmbkmlji?hl=en-US&utm_source=ext_sidebar"
        >
          <button className="bg-ipink text-[13px] font-semibold w-[200px] h-[35px] rounded-full text-white uppercase hover:bg-white hover:text-ipink hover:border-2 hover:border-ipink">
            GET THE EXTENSION
          </button>
        </a>
      </div>
      <button className="text-white text-xl" onClick={handleCloseBanner}>
        &#10005;
      </button>
    </div>
  );
}

ExtensionInstallationFooter.propTypes = {
  setExtensionInstalled: PropTypes.func.isRequired,
};

export default ExtensionInstallationFooter;
