import { IoStar } from "react-icons/io5";

export default function BusinessScore(props) {
  const { rating, styleClass, iconClass } = props || { rating: 0 };
  // Check if rating exists and is not "0". If not, display "n/a"
  const displayRating = rating && rating !== "0" ? rating : "n/a";

  // Ensure the rating displays with one decimal place if it's a whole number, except when it's "n/a"
  const formattedRating =
    displayRating !== "n/a" && Number.isInteger(parseFloat(displayRating))
      ? `${parseFloat(displayRating)}.0`
      : displayRating;

  return (
    <div
      className={styleClass ? styleClass : "relative flex items-center gap-1 "}
    >
      {rating && rating != "0" ? (
        <>
          <IoStar size={iconClass || 20} className="text-ipink" />
          <p className=" z-40 ml-1 flex-shrink-0 text-[13px] font-bold  ">
            {formattedRating}
          </p>
        </>
      ) : (
        <div className="w-[50px]"></div>
      )}
    </div>
  );
}
