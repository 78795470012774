import React from "react";
import Checkmark from "../../../assets/Checkmark.svg";

export default function PhoneNumberUpdated() {
  return (
    <div className="w-full flex flex-col h-full space-y-6 p-4 justify-center items-center text-center text-sm scrollable-container overflow-y-auto">
      <img src={Checkmark} alt="Checkmark" width={60} height={60} />
      <h1 className="font-bold text-[18px] ">Phone Number Updated</h1>
      <p className="w-[210px]">
        Your phone number has been successfully updated.
      </p>
      <button className="rounded-full w-[340px] h-[45px] flex justify-center items-center bg-ipurple uppercase hover:font-bold font-semibold text-white">
        Done
      </button>
    </div>
  );
}
