import React, { useState } from "react";
import { TECollapse } from "tw-elements-react";
import Checkbox from "./Checkbox";
import { IoIosArrowDown } from "react-icons/io";

export default function BusinessAccordian(props): JSX.Element {
  let { collapseShow } = props;

  return (
    <>
      <div >
        <div className=" dark:bg-darkModeMain -mt-1">
          <TECollapse
            show={collapseShow}
            className="!mt-0 !rounded-b-none !shadow-none accordionFilter accordionFilter"
          >
            <div className="px-5  py-0">
              <Checkbox  {...props} />
            </div>
          </TECollapse>
        </div>
      </div>
    </>
  );
}
