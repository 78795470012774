import React from 'react'
import { GoPaperclip } from "react-icons/go";
import SingleTicketFiles from './SingleTicketFiles';

export default function TicketFilesContainer() {
  return (
    <div className='flex flex-col items-center w-full h-full py-3 mt-2'>
        
        <div className='w-[95%] bg-gray-100 dark:bg-darkModeBG rounded-lg my-4 h-[60px] flex justify-center items-center'>
            <button className='flex items-center gap-2 '>
                <GoPaperclip  size={20}/>
                <p className='text-sm underline'>Choose a file or drag it here</p>
            </button>
        </div>
        <div className='w-[97%] flex flex-wrap items-center justify-start h-full gap-4 mx-4  py-1 '>
        <SingleTicketFiles />
       
        
        </div>

    </div>
  )
}
