import { LuPhone, LuMail } from "react-icons/lu";
import { TbBrandMessenger } from "react-icons/tb";
import { PiChatTeardropText } from "react-icons/pi";
import { TbLockCancel } from "react-icons/tb";
import { MdOutlineAccessTime } from "react-icons/md";

const identities = [
    {
        id: 1,
        title: 'My Identities',
        
    },
    {
        id: 2,
        title: 'Kodiak Construction Identities',
     
    },
    {
        id: 3,
        title: "Mom's Identities",
      
    },
    
]

export default identities;