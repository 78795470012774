import React, {useState} from 'react'
import logo from '../../../assets/LargeIallo.svg'
import { BiSolidChevronsRight, BiSolidChevronsLeft } from "react-icons/bi";
import { GiSoundWaves } from "react-icons/gi";
import { BsPlay } from "react-icons/bs";
import { LuCheck, LuRotateCcw } from "react-icons/lu";

export default function RecordVoice() {
    const [isRecording, setIsRecording] = useState(false);

    const handleRecordButtonClick = () => {
        setIsRecording(!isRecording)
    }
  return (
    <div className='flex flex-col items-center justify-center w-full h-full text-sm text-center scrollable-container overflow-y-auto'>
      <h1 className='text-[20px] p-3 font-bold'>Record Your Voice</h1>
      <p className='p-2'>Please tap on the record button and say the next phrase.</p>
      
      <div className='flex items-center gap-4'>
        <BiSolidChevronsLeft  size={28} />
        <h1 className='text-[28px] my-20 font-bold'> Hello </h1>
        <BiSolidChevronsRight size={28} />
      </div>
      <GiSoundWaves size={80} className='-mt-16 text-gray-300' />
      <div className='relative'>
        <button
        onClick={handleRecordButtonClick}>
            <img src={logo} alt='Record Voice Button' width={105} height={105} className='mt-4 rounded-full dark:shadow-xl' />
        </button>
        {isRecording && (
          <div className='absolute flex items-center gap-10 top-4 -left-[85px]'>
              <button><BsPlay size={50} /></button>
              <button className="w-[95px] h-[95px] text-xl text-white bg-ipurple rounded-full flex justify-center items-center">
                  <LuCheck size={40} />
              </button>
              <button><LuRotateCcw size={40} /></button>
          </div>
        )}
      </div>
     
    </div>
  )
}
