import SearchCard from "./SearchCard";

export default function SearchList(props) {
  const { businessDetails } = props || {};

  return (
    <div className="flex flex-col items-center justify-center w-full px-4 py-2  dark:bg-darkModeBG ">
      {businessDetails?.length != 0 &&
        businessDetails?.map((data) => (
          <SearchCard
            key={data?.business?.id}
            business={data?.business || data}
            pageType="list"
            {...props}
          />
        ))}
    </div>
  );
}
