import React from "react";
import { IoLocationOutline, IoStar } from "react-icons/io5";
import { data } from "autoprefixer";

export default function AssociatedProfilesCards(props) {
  const { associated_businesses } = props || {};
  return (
    <div className="w-full text-[13px] flex flex-col overflow-y-auto items-center justify-center divide-y-[1px] dark:divide-darkModeBG">
      {associated_businesses?.map((item, index) => {
        const business = item?.associated_business || {};
        const locations = business?.business_locations || [];
        const location = locations[0] || {};
        const address = location?.address || " ";
        const city = location?.city || false;
        const state = location?.state || false;
        const zipCode = location?.zip_code || false;
        const rating = business?.rating || 0;
        const locationDetails = [city, state, zipCode].filter(Boolean).join(", ");
        const updatedAddress = zipCode
          ? address.replace(new RegExp(`\\b${zipCode}\\b`, 'g'), '').replace(/,\s*$/, '')
          : address;
        return (
          <div className="w-full items-center flex p-4 justify-between" key={index}>
            <div className="flex gap-4 ">
              <div className="flex bg-inherit justify-center items-center w-[45px] h-[45px] min-w-[45px] min-h-[45px] rounded-md border-[1px]  border-gray-100  dark:border-darkModeBorder">
                <IoLocationOutline size={25} />
              </div>
              <div className="flex flex-col ">
                <p className="font-semibold text-sm">
                  {business.name}
                </p>
                <p>{locationDetails}</p>
                <p>{updatedAddress}</p>
              </div>
            </div>
            <div className="flex self-start gap-3 p-2">
              <IoStar size={20} className="text-ipink" />
              <p className="font-semibold text-sm">{rating} </p>
            </div>
          </div>
        );
      })};
    </div>
  );
}
