import { deviceId } from "./DeviceDetails/DeviceDetails";

export const APP_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
export const BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const SOCKET_BASE_URL = import.meta.env.VITE_API_WEBSOCKET_CHAT_URL;
export const MAP_KEYS = import.meta.env.VITE_MAP_KEYS;
export const VITE_SIP_DOMAIN = import.meta.env.VITE_SIP_DOMAIN;
export const CALL_NUMBER_CHECK = import.meta.env.VITE_CONTACT_TYPE_ID;
export const VITE_SIP_ADDRESS = import.meta.env.VITE_SIP_ADDRESS;
export const TRANSCRIPT_SOCKET_BASE_URL = import.meta.env
  .VITE_TRANSCRIPT_WEB_SOCKET_URL;

export const PUSHER_KEY = import.meta.env.VITE_PUSHER_KEY;
export const PUSHER_CLUSTER = import.meta.env.VITE_PUSHER_CLUSTER;
export const VITE_API_PAGE_LIMIT = import.meta.env.VITE_API_PAGE_LIMIT;
export const PRO_USER = "PROFESSIONAL";
export const VITE_API_PAGE_LIMIT_DASHBOARD = import.meta.env
  .VITE_API_PAGE_LIMIT_DASHBOARD;
export const VITE_DISTANCE_LIMIT = import.meta.env.VITE_DISTANCE_LIMIT;
export const DATE_COMMON_FORMAT = "YYYY-MM-DD h:mm A";
export const ASC = "ASC";
export const DESC = "DESC";
export const NEWEST_FIRST = "Newest first";
export const OLDEST_FIRST = "Oldest first";
export const DEFAULT_LOCATION_DISTANCE = "10";
export const HOME_PAGE = "https://www.iallo.io";
export const PRIVACY_PAGE = "https://iallo.io/privacy-policy/";
export const TERMS_PAGE = "https://iallo.io/terms-of-use/";
export const COOKIE_PAGE = "https://iallo.io/cookies-policy/";
export const VITE_SIDEBAR_SUPPORT_URL = import.meta.env
  .VITE_SIDEBAR_SUPPORT_URL;
export const iphoneAppLink = import.meta.env.VITE_APPLE_LINK;
export const VITE_MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const mixpanelEventProperty = { app: "Web" };

export const deviceDetails = () => {
  return {
    deviceID: deviceId(),
    deviceName: "web",
  };
};
export const locationDetails = () => {
  return {
    lat: 34.05223,
    long: -118.24368,
  };
};

// utils/geolocation.js
export const getCurrentLocation = (successCallback, errorCallback) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        successCallback(location.coords.latitude, location.coords.longitude);
      },
      (error) => {
        if (errorCallback) {
          errorCallback(error);
        } else {
          // console.error(error);
        }
      },
    );
  } else {
    // console.error('Geolocation is not supported by this browser.');
  }
};

export const professionFlow = {
  otpConfirm: "PersonalOtpConfirm",
  email: "enterEmail",
  emailOtp: "ConfirmEmailOtp",
  enterEmail: "enterEmail",
  personalInfo: "PersonalInfo",
  createTeam: "createTeam",
  enterPwd: "EnterPassword",
};
