// Import necessary dependencies
import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";
import {
  BASE_URL,
  VITE_API_PAGE_LIMIT,
  VITE_API_PAGE_LIMIT_DASHBOARD,
} from "../../utils/constants";
import { apiFilterVallidation } from "../../utils/api/apiFilter";

// Create a Zustand store for Directory Details
export const DirectoryDetails = create((set, get) => ({
  // Initial state variables
  recentBusinessDetails: [], // Holds details of a recent business
  savedBusinessDetails: [], // Holds details of a saved business
  suggestedBusinessDetails: [], // Holds details of a suggested business
  fetchBusinessDetails: {}, // Holds details of a business
  fetchBusinessGoogleDetails: {}, // Holds details of a business by google
  allReviewComments: {}, // Holds details of a review comments
  createComments: [], // Holds details of a created comments
  updateComments: [], // Holds details of a upadted comments
  reviewDataList: [], // Holds details of a review data
  reviewData: {},

  reviewDataMeta: {}, // Holds details of a review data
  reviewDatastats: {}, // Holds details of a review data
  allTicketDetails: [], //// Holds details of a tickets
  recentBusinessLastPage: "",
  savedBusienssLastPage: "",
  suggestedBusinessLastPage: "",
  lastReviewPage: "",
  loadingReviewComments: false,
  loadingComments: false,
  loadingBusinessDirectory: false,
  loadingBusinessDetails: false, // Indicates whether data is currently being loaded
  loadingReviewSection: false,
  error: null, // Holds any error that may occur during data fetching or processing

  // Async function to create a Recent business details
  recentBusinessDetailsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingBusinessDirectory: true });
      let { page } = userData;

      // Make a GET request to create a recent business details
      const response = await AxiosApi.get(
        `${BASE_URL}users/recent-businesses?limit=${VITE_API_PAGE_LIMIT_DASHBOARD}&page=${
          page || 1
        }`,
        userData
      );

      let { success, message, data } = response?.data || { message: "error" };

      let { last_page } = response?.data?.meta || {};
      if (success) {
        // If the request is successful, update the state with the response data
        set((state) => ({
          loadingBusinessDirectory: false,
          recentBusinessLastPage: last_page,
          recentBusinessDetails: [
            ...state.recentBusinessDetails,
            ...data,
          ].filter(
            // Filter out duplicates based on the 'id' property
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          ),
        }));

        return data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDirectory: false, error: err });
      return err;
    }
  },

  savedBusinessDetailsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingBusinessDirectory: true });
      let { page } = userData;
      // Make a GET request to create saved business details
      const response = await AxiosApi.get(
        `${BASE_URL}users/saved-businesses?page=${
          page || 1
        }&limit=${VITE_API_PAGE_LIMIT_DASHBOARD}`,
        userData
      );

      let { success, message, data } = response?.data || { message: "error" };

      let { last_page } = response?.data?.meta || {};
      if (success) {
        let businessData = data?.map((x) => ({ ...x, id: x.business?.id }));
        set((state) => ({
          loadingBusinessDirectory: false,
          savedBusienssLastPage: last_page,
          savedBusinessDetails: [
            ...state.savedBusinessDetails,
            ...businessData,
          ].filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id == item.id)
          ),
        }));

        return data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDirectory: false, error: err });
      return err;
    }
  },

  suggestedBusinessDetailsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingBusinessDirectory: true });
      let { page } = userData;
      // Make a GET request to create a suggested business details
      const response = await AxiosApi.get(
        `${BASE_URL}users/suggested-businesses?page=${
          page || 1
        }&limit=${VITE_API_PAGE_LIMIT}`,
        userData
      );

      let { success, message, data } = response?.data || { message: "error" };
      let suggestedBusinessData = data?.map((x) => ({
        ...x,
        id: x.business?.id,
      }));

      let { last_page } = response?.data?.meta || {};
      if (success) {
        // If the request is successful, update the state with the response data
        set((state) => ({
          loadingBusinessDirectory: false,
          suggestedBusinessLastPage: last_page,

          suggestedBusinessDetails: [
            ...state.suggestedBusinessDetails,
            ...suggestedBusinessData,
          ].filter(
            // Filter out duplicates based on the 'id' property
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          ),
        }));

        return data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDirectory: false, error: err });
      return err;
    }
  },

  savedBusinessApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      // set({ loadingBusinessDetails: true });
      // Make a POST request to save business details
      const response = await AxiosApi.post(
        `${BASE_URL}users/saved-businesses`,
        userData
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      const fetchData = get();
      if (success) {
        // If the request is successful, update the state with the response data

        set({
          fetchBusinessDetails: {
            ...fetchData?.fetchBusinessDetails,
            data: {
              ...fetchData?.fetchBusinessDetails?.data,
              saved_business_id: true,
            },
          },
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  unsavedBusinessApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      // set({ loadingBusinessDetails: true });
      // Make a DELETE request to unsave business details
      const response = await AxiosApi.delete(
        `${BASE_URL}users/saved-businesses`,
        {
          data: userData,
        }
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        const fetchData = get();
        // If the request is successful, update the state with the response data
        set({
          fetchBusinessDetails: {
            ...fetchData?.fetchBusinessDetails,
            data: {
              ...fetchData?.fetchBusinessDetails?.data,
              saved_business_id: null,
            },
          },
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  // Async function to fetch business details
  fetchBusinessDetailsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingBusinessDetails: true });
      let { businessId, googlePlaceId, lat, lng } = userData;
      let response;
      let urlArray = [
        {
          name: "lat",
          value: lat,
        },
        {
          name: "lng",
          value: lng,
        },
      ];
      // Conditionally choose the API endpoint based on the presence of googlePlaceId
      if (googlePlaceId) {
        response = await AxiosApi.get(
          `${BASE_URL}businesses/google/${googlePlaceId}`, //google place id
          userData
        );
      } else {
        let filteredURL = apiFilterVallidation(
          urlArray,
          `${BASE_URL}businesses/${businessId}?`
        );
        response = await AxiosApi.get(filteredURL, userData);
      }

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingBusinessDetails: false,
          fetchBusinessDetails: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        return err;
      }
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  // Async function to get all review  comments
  listReviewCommentsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingReviewComments: true });
      // Make a GET request to all review  comments
      let { reviewId, page } = userData;

      const response = await AxiosApi.get(
        `${BASE_URL}/comments?review_id=${reviewId}&limit=${VITE_API_PAGE_LIMIT}&page=${
          page || 1
        }`,
        userData
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingReviewComments: false,
          allReviewComments: response?.data,
          reviewDataMeta: response?.meta || {},
          reviewDatastats: response?.stats || {},
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingReviewComments: false, error: err });
      return err;
    }
  },

  // Async function to create the comment
  createCommentApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      // set({ loadingBusinessDetails: true });
      // Make a POST request to CREATE comments

      const response = await AxiosApi.post(`${BASE_URL}comments`, userData);

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          createComments: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  // Async function to update the comment
  updateCommentApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingComments: true });
      let { id } = userData;
      // Make a PUT request to update the comment
      const response = await AxiosApi.put(
        `${BASE_URL}/comments/${id}`,
        userData
      );
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingComments: false,
          updateComments: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingComments: false, error: err });
      return err;
    }
  },

  // Async function to delete the comment
  deleteCommentApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingComments: true });
      let { id } = userData;
      // Make a DELETE request to delete the comment
      const response = await AxiosApi.delete(
        `${BASE_URL}/comments/${id}`,
        userData
      );
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingComments: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingComments: false, error: err });
      return err;
    }
  },

  // Async function to create reply to the comment
  createReplyToCommentApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingBusinessDetails: true });
      // Make a POST request to reply to the comment
      const response = await AxiosApi.post(`${BASE_URL}comments`, userData);
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingBusinessDetails: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  // Async function to add reaction to the comment
  addReactionToCommentApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingBusinessDetails: true });
      // Make a POST request to add reaction to the comment
      const response = await AxiosApi.post(`${BASE_URL}/reactions`, userData);
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingBusinessDetails: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  // Async function to add reaction to the reply
  addReactionToReplyApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingBusinessDetails: true });
      // Make a POST request to add reaction to the reply
      const response = await AxiosApi.post(`${BASE_URL}/reactions`, userData);
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingBusinessDetails: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  // Async function to list all reviews
  listAllReviewsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingBusinessDetails: true });
      // Make a GET request to list all reviews
      let { businessId, page } = userData;
      const response = await AxiosApi.get(
        `${BASE_URL}businesses/${businessId}/reviews?limit=${VITE_API_PAGE_LIMIT}&page=${page}`,
        userData
      );
      // Destructure response data
      let { success, message, data } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set((state) => ({
          lastReviewPage: response?.data.meta.last_page,
          loadingBusinessDetails: false,
          reviewData: response.data,
          reviewDataList: [...state.reviewDataList, ...data].filter(
            // Filter out duplicates based on the 'id' property
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          ),
        }));

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  // Async function to update the review text
  updateReviewTextApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingBusinessDetails: true });
      let { businessId, reviewId } = userData;
      // Make a PUT request to update the review text
      const response = await AxiosApi.put(
        `${BASE_URL}/businesses/${businessId}/reviews/${reviewId}`,
        userData
      );
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingBusinessDetails: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  // Async function to delete the review
  deleteReviewTextApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingBusinessDetails: true });
      let { businessId, reviewId } = userData;
      // Make a DELETE request to  delete the review
      const response = await AxiosApi.delete(
        `${BASE_URL}/businesses/${businessId}/reviews/${reviewId}`,
        userData
      );
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingBusinessDetails: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  // Async function to create the create the review
  createReviewApi: async (userData, businessId) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingReviewSection: true });
      // Make a POST request to  create the review

      const response = await AxiosApi.post(
        `${BASE_URL}/businesses/${businessId}/reviews`,
        userData
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingReviewSection: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingReviewSection: false, error: err });
      return err;
    }
  },

  //delete the manually entered businesses
  deleteBusinessApi: async (id) => {
    try {
      const response = await AxiosApi.post(
        `${BASE_URL}businesses/${id}/archive`
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        const fetchData = get();
        // If the request is successful, update the state with the response data
        set({
          fetchBusinessDetails: {
            ...fetchData?.fetchBusinessDetails,
            data: {
              ...fetchData?.fetchBusinessDetails?.data,
              saved_business_id: null,
            },
          },
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingBusinessDetails: false, error: err });
      return err;
    }
  },

  resetBusinessDeatils: () => {
    set({
      loadingBusinessDetails: false,
      fetchBusinessDetails: [],
      recentBusinessDetails: [],
      savedBusinessDetails: [],
      suggestedBusinessDetails: [],
      reviewDataList: [],
      lastReviewPage: "",
      reviewData: {},
      recentBusinessLastPage: "",
      savedBusienssLastPage: "",
      suggestedBusinessLastPage: "",
    });
  },
}));
