import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types"; // Import PropTypes
// import { IoClose } from "react-icons/io5";
import { ChromeExtension } from "../icons/Icons";
import BrowserImage from "../../assets/icons/BrowserImage.png";
const CallPopup = ({ onClose }) => {
  const [selectedRating, setSelectedRating] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [file, setFile] = useState([]);
  const [filePreview, setFilePreview] = useState([]);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const fileInputRef = useRef(null);

  const handleRating = (rating) => {
    setSelectedRating(rating);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleFileChange = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFile(uploadedFiles);
    const previews = uploadedFiles.map((file) => URL.createObjectURL(file));
    setFilePreview(previews);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = file.filter((_, i) => i !== index);
    const updatedPreviews = filePreview.filter((_, i) => i !== index);
    setFile(updatedFiles);
    setFilePreview(updatedPreviews);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    window.open(
      "https://chromewebstore.google.com/detail/iallo-companion-dialer/dimhlpifdleffpgkdfdlklljkmbkmlji?hl=en-US&utm_source=ext_sidebar",
      "_blank",
    );
  };

  function detectBrowser() {
    const userAgent = navigator.userAgent;
    const vendor = navigator.vendor;

    if (
      /Safari/.test(userAgent) &&
      /Apple Computer/.test(vendor) &&
      !/Chrome/.test(userAgent)
    ) {
      return "Safari";
    } else if (/Chrome/.test(userAgent) && /Google Inc/.test(vendor)) {
      return "Chrome";
    }
    return "Other";
  }

  useEffect(() => {
    setIsFormComplete(selectedRating && feedback.trim() && file.length > 0);
  }, [selectedRating, feedback, file]);

  return typeof window !== "undefined" && document.getElementById("root")
    ? ReactDOM.createPortal(
        <div className="w-full text-sm h-screen fixed bg-[#646171] bg-opacity-[95%] flex flex-col justify-center items-center">
          <div
            className={`${
              detectBrowser() === ("Safari" || "Other")
                ? "w-[709px]"
                : "w-[720px]"
            } flex flex-col bg-white dark:bg-darkModeMain dark:border-[1px] border-gray-100 dark:border-darkModeBorder rounded-lg relative text-isearch`}
          >
            {detectBrowser() === ("Safari" || "Other") ? (
              <div className="w-full py-[30px] flex flex-col items-center justify-center text-center dark:text-white">
                <h2 className="text-xl font-bold mt-[10px] dark:text-white">
                  Unsupported Browser
                </h2>
                <p className="font-normal text-sm m-[30px] px-5 text-isearch dark:text-white">
                  For the best experience, please use the{" "}
                  <span className="font-semibold dark:text-white">Google Chrome</span> browser.
                  The call feature is available only in Google Chrome. More
                  browsers will be supported soon.
                </p>
                <ChromeExtension />
                <p className="text-sm my-[30px] underline font-semibold">
                  Open Google Chrome
                </p>
                <button
                  className="w-[180px] h-[45px] uppercase rounded-full bg-ipurple text-white"
                  onClick={onClose}
                >
                  GOT IT
                </button>
              </div>
            ) : (
              ""
            )}
            {detectBrowser() === "Chrome" ? (
              <div>
                <div className="w-full h-[170px] border-[2px] rounded-lg border-gray-100 bg-custom-gradient text-white flex flex-col items-start pt-[25px] relative">
                  {/* <button onClick={onClose} className="absolute top-4 right-4">
                <IoClose size={25} />
              </button> */}
                  <div className="flex justify-between">
                    <div className="w-full px-5 py-5 flex flex-col justify-center">
                      <h1 className="text-2xl font-bold mb-2">
                        Get iAllo Browser Extension
                      </h1>
                      <h2 className="text-sm font-semibold  ">
                        Make and Receive Calls and See Real-Time Transcriptions
                        right in your browser.
                      </h2>
                    </div>
                    <div className="w-1/2 h flex justify-end pb-0">
                      <img
                        src={BrowserImage}
                        alt="BrowserImage"
                        className="h-38 pt-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full py-5 flex flex-col items-start text-sm">
                  <p className="mx-5 mb-[35px] dark:text-white">
                    The iAllo Browser Extension is now available for Google
                    Chrome.
                  </p>
                  <div className="w-full px-5 mb-5">
                    <p className=" font-bold text-[18px] text-isearch mb-5 dark:text-white">
                      How to install
                    </p>
                    <ul className="list-inside  font-normal text-sm mb-10 list-none dark:text-white">
                      <li>
                        - Go To{" "}
                        <a
                          href="https://chrome.google.com/webstore"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-bold underline"
                        >
                          Google Web Store
                        </a>
                      </li>
                      <li>- Search for iAllo</li>
                      <li>
                        - Click Add to Chrome and confirm the installation
                      </li>
                      <li>- Pin iAllo Browser Extension (optional)</li>
                      <li>- Click on the extension icon to start using it</li>
                    </ul>
                  </div>
                  <div className="w-full px-4 mt-5 flex justify-end items-center space-x-4">
                    <button
                      className="w-[150px] h-[45px] uppercase rounded-full font-semibold text-black border dark:text-white dark:bg-black border-black duration-500"
                      onClick={onClose}
                    >
                      Not Now
                    </button>
                    <button
                      className="w-[180px] h-[45px] uppercase rounded-full bg-ipurple text-sm font-semibold text-white duration-500"
                      onClick={handleSubmit}
                    >
                      Get the Extension
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>,

        document.getElementById("portal-root"),
      )
    : null;
};

// Add prop types validation
CallPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CallPopup;
