import React, { useEffect, useRef, useState } from "react";
import { TECollapse } from "tw-elements-react";
import Autocomplete from "react-google-autocomplete";
import { LocationPin } from "../../../icons/SearchIcon";
import { getCurrentLocation, MAP_KEYS } from "../../../../utils/constants";
import { useSearch } from "../../../../context/SearchContext";
import { TopNavSearchService } from "../../../../services/search/topNav/TopNavSearchService";

export default function LocationAccordian(props) {
  const {
    collapseShow,
    resetLocation,
    currentLocation,
    hideEveryWhere,
    locationSelected,
    selectBtn,
    setSelectedBtn,
  } = props;

  const { getLocationDetails } = TopNavSearchService((state) => state);
  const [placeSelected, setPlaceSelected] = useState(false);
  const { selectedLocation, setSelectedLocation, locationDetailsRef } =
    useSearch();
  useEffect(() => {
    if (selectBtn == "currentLocation") {
      hideEveryWhere();
      getCurrentLocation(
        (lat, lon) => {
          currentLocation({
            lat: lat,
            lng: lon,
          });
          getLocationDetails({
            lat: lat,
            lng: lon,
          });
        },
        (error) => {
          setSelectedBtn("");
          currentLocation({
            lat: "",
            lng: "",
          });
        },
      );
    } else if (selectBtn == "everywhere") {
      getLocationDetails({
        lat: null,
        lng: null,
      });
      resetLocation();
    }
  }, [selectBtn]);

  return (
    <>
      <style>
        {placeSelected &&
          `
        .pac-container {
          background-color: #fff !important;
          border-radius: 0 0 10px 10px !important;
          box-shadow: 0 4px 6px rgba(0,0,0,0.1) !important;
          padding: 10px !important;
          margin-top: 0px !important;
          margin-right: 120px !important;
          border: none !important;
          divide: none !important;
          left:80px!important;
          width:18%!important
        }
      `}
      </style>
      <TECollapse
        show={collapseShow}
        className="accordionFilter !mt-0 !rounded-b-none  !shadow-none pb-12"
      >
        <div className="flex items-center justify-center mb-4">
          <div className="relative flex items-center w-[260px] auto-complete-container h-[45px] rounded-md border-[1px]  border-gray-100  mx-1">
            <div className="flex items-center gap-2.5 pr-2 ml-2">
              <LocationPin width={""} height={""} />
            </div>
            <Autocomplete
              apiKey={MAP_KEYS}
              id="google-place-details"
              onPlaceSelected={(place) => {
                setSelectedBtn("");

                if (place?.formatted_address) {
                  locationDetailsRef.current = place?.formatted_address;
                }

                getLocationDetails({
                  lat: place?.geometry?.location?.lat(),
                  lng: place?.geometry?.location?.lng(),
                });
              }}
              options={{
                types: ["geocode", "establishment"],
              }}
              value={selectedLocation}
              language="en"
              // onKeyDown={handleSearch}
              onClick={() => setPlaceSelected(true)}
              onBlur={() => setPlaceSelected(false)}
              onChange={(data) => {
                if (data?.target?.value === "") {
                  getLocationDetails({
                    lat: "",
                    lng: "",
                  });
                  locationDetailsRef.current = "";
                }
                if (data?.formatted_address) {
                  locationDetailsRef.current = data?.formatted_address;
                }
                setSelectedLocation(data?.formatted_address);
              }}
              className="w-full h-full text-[13px] border-none rounded opacity-100 font-semibold placeholder:font-normal placeholder:text-[13px] focus:text-semibold active:text-semibold focus:ring-transparent dark:bg-inherit "
            />
          </div>
        </div>
        <div className=" h-[30px] flex text-center justify-center ">
          <button
            className={`${
              selectBtn === "currentLocation" ? "!bg-black !text-white" : ""
            } uppercase text-xs sm:text-sm md:text-base lg:text-lg px-3 py-1 w-auto max-w-[90%] md:max-w-[200px] font-semibold text-black dark:text-white dark:hover:text-black rounded-full border border-black hover:bg-neutral-200 dark:hover:bg-white transition-colors whitespace-nowrap`}
            type="button"
            onClick={() => {
              selectBtn === "currentLocation"
                ? setSelectedBtn("")
                : setSelectedBtn("currentLocation");
              setSelectedLocation("");
            }}
          >
            current location
          </button>

          <div>&nbsp;&nbsp;&nbsp;</div>
          <button
            className={`${
              selectBtn == "everywhere" ? "!bg-black !text-white" : ""
            }  uppercase text-[11px] w-[120px]  font-semibold text-black dark:text-white dark:hover:text-black rounded-full border-[1px] border-black  hover:bg-neutral-200 dark:hover:white transition-colors`}
            type="click"
            onClick={() => {
              selectBtn == "everywhere"
                ? setSelectedBtn("")
                : setSelectedBtn("everywhere");
              setSelectedLocation("");
            }}
          >
            EVERYWHERE
          </button>
        </div>
      </TECollapse>{" "}
    </>
  );
}
