import axios from 'axios';
import { BASE_URL } from '../utils/constants';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const retrieveAuthToken = () => {
  const token = localStorage.getItem('authToken');
  return token;
};

export const initialRegistration = (phoneNumberData) => {
  const jsonData = JSON.stringify(phoneNumberData);

  return axios
    .post(`${BASE_URL}/api/auth/register`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Initial registration error:', error);
      throw error;
    });
};

export const verifyConfirmationCode = (verificationData) => {
  // Convert confirmation_code to a number. Ensure it's an actual number, not NaN
  const parsedConfirmationCode = Number(verificationData.confirmation_code);
  if (!isNaN(parsedConfirmationCode)) {
    verificationData.confirmation_code = parsedConfirmationCode;
  } else {
    // Handle the case where conversion to number fails
    throw new Error('Invalid confirmation code format.');
  }

  const jsonData = JSON.stringify(verificationData);

  return axios
    .post(`${API_BASE_URL}auth/verify-otp`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      localStorage.setItem('authToken', response.data.token);
      return response.data;
    })
    .catch((error) => {
      console.error('Verification error:', error);
      throw error;
    });
};

export const resendConfirmationCode = (resendData) => {
  resendData.confirmation_code = parseInt(resendData.confirmation_code);

  const jsonData = JSON.stringify(resendData);

  return axios
    .post(`${API_BASE_URL}/api/auth/resend-confirmation-code`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Resend confirmation code error:', error);
      throw error;
    });
};

export const setProfile = async (profileData) => {
  const token = retrieveAuthToken();
  if (!token) {
    console.error('No auth token available');
    throw new Error('Authentication required');
  }

  const config = {
    method: 'post',
    url: `${API_BASE_URL}/api/auth/profile`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: JSON.stringify(profileData),
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error(
      'Error setting profile:',
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const setPassword = async (newPassword) => {
  const token = retrieveAuthToken();
  const data = { password: newPassword };
  const config = {
    method: 'post',
    url: `${API_BASE_URL}/api/auth/set-password`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include the bearer token here
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
