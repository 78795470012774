import calendar from '../../assets/icons/Calendar.svg'
import ReactDOM from 'react-dom';

export default function CancelCallModal(props) {
  let { onClose, onCancelCall,handleApiCall } = props || {};
  return ReactDOM.createPortal ( 
    (
    <div className="absolute top-0 z-[60] flex items-center justify-center w-full h-screen overflow-hidden">
      <div className="fixed -top-20 w-[200%]  h-[200%] bg-black -left-10 opacity-90"></div>
      <div className="w-[580px] h-[250px] z-40 bg-white rounded-lg dark:bg-darkModeMain flex flex-col justify-center items-center space-y-4">
        <img src={calendar} alt='calendar icon' width={30} height={30} />
        <p className="text-[20px] font-bold ">Cancel Scheduled Call</p>
        <p className="text-sm">
          Do you really want to cancel the scheduled call?
        </p>
        <div className="flex gap-4 pt-2">
          <button
            onClick={onClose}
            className="w-[260px] h-[45px] rounded-full bg-white border-[1px]  border-gray-100  text-black border-b border-gray-100lack uppercase text-sm "
          >
            No
          </button>
          <button
            onClick={() => {
              handleApiCall();
              onCancelCall();
            }}
            className="w-[260px] h-[45px] rounded-full bg-black text-white uppercase text-sm "
          >
            Yes
          </button>
        </div>
      </div>
    </div>
    ),
    document.getElementById('portal-root')
  );
}
