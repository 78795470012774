import { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useSearch } from "../../context/SearchContext";
import { TopNavSearchService } from "../../services/search/topNav/TopNavSearchService";
import { IoIosClose } from "react-icons/io";
import { SlLocationPin } from "react-icons/sl";
import { IoSearchOutline } from "react-icons/io5";
import SearchDropdown from "./SearchDropdown";
import Autocomplete from "react-google-autocomplete";
import {
  DEFAULT_LOCATION_DISTANCE,
  getCurrentLocation,
  MAP_KEYS,
} from "../../utils/constants";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import { LocationPin, SearchIcon } from "../icons/SearchIcon";
import locationPin from "../../assets/LocationPin.png";
import { sendMixpanelEvent } from "../../services/mixpanelService";

export default function SearchInputDashboard() {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [selectedLocationLat, setSelectedLocationLat] = useState("");
  const [selectedLocationLong, setSelectedLocationLong] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  let [currentPage, setCurrentPage] = useState(1);
  const [searchInDashboard, setSearchInDashboard] = useState("");
  const inputCloseRef = useRef(false);
  const {
    setSearchTerm,
    inputRef,
    setSearchViewToggle,
    hideDropdown,
    isDropdownVisible,
    setIsDropdownVisible,
    setSelectedLocation,
    setDashboardFilters,
    locationDetailsRef,
  } = useSearch();

  const {
    resetSearch,
    historySearchApi,
    historySearchListApi,
    topNavDirectorySearchApi,
    topNavDirectorySuggestionApi,
    suggestionSearchDetailsData,
    suggestionsLastPage,
    getLocationDetails,
  } = TopNavSearchService((state) => state);

  const dropdownRef = useRef(null);
  const excludedComponents = [dropdownRef];

  const handleSearch = (e) => {
    if (searchInDashboard.length == 0) {
      return;
    }
    if (e.key === "Enter" || e.type == "click") {
      e.preventDefault(); // Prevent the form from submitting
      setSearchTerm(searchInDashboard);
      setSelectedLocation(formattedAddress);
      hideDropdown(); // Assuming hideDropdown is correctly retrieved from useSearch
      setSearchViewToggle(true); // Show the SearchContainer

      getLocationDetails({
        lat: selectedLocationLat,
        lng: selectedLocationLong,
      });

      historySearchApi({ title: searchInDashboard, search_type: "local" });

      topNavDirectorySearchApi({
        LocationDistance: DEFAULT_LOCATION_DISTANCE,
        keyword: searchInDashboard,
        locationLat: selectedLocationLat ? selectedLocationLat : latitude,
        locationLong: selectedLocationLong ? selectedLocationLong : longitude,
        location: formattedAddress,
        user_lat: latitude,
        user_lng: longitude,
      });

      // Optionally handle API response here
    } else {
      setIsDropdownVisible(true);
    }
  };
  useEffect(() => {
    if (searchInDashboard?.length == 0) {
      resetSearch();
    }
  }, [searchInDashboard]);
  const [show, setShow] = useState(false);

  const handleChange = async (event) => {
    const newSearchTerm = event.target.value;
    setSearchInDashboard(newSearchTerm);
    setShow(false);
  };

  const [searchHistory, setSearchHistory] = useState([]);

  const handleSearchHistory = async () => {
    sendMixpanelEvent("Business Search Initiated");
    const fetchTitles = async () => {
      try {
        const response = await historySearchListApi();
        const titlesArray = response.data.map((item) => item.title); // Extracting titles
        setSearchHistory(titlesArray); // Setting the titles in state
      } catch (error) {
        console.error("Error fetching search history:", error);
      }
    };
    setShow((prev) => !prev);
    fetchTitles();
  };

  useEffect(() => {
    if (searchInDashboard?.length >= 2) {
      const timer = setTimeout(() => {
        topNavDirectorySuggestionApi({
          keyword: searchInDashboard,
          locationLat: selectedLocationLat ? selectedLocationLat : latitude,
          locationLong: selectedLocationLong ? selectedLocationLong : longitude,
          currentPage: currentPage,
        });
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchInDashboard, currentPage]);

  useEffect(() => {
    getCurrentLocation(
      (lat, lon) => {
        setLatitude(lat);
        setLongitude(lon);
      },
      (error) => {},
    );
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element or its ancestors are in the excluded components
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !excludedComponents.some((component) =>
          component.current.contains(event.target),
        )
      ) {
        setIsDropdownVisible(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, excludedComponents]); // Make sure to include excludedComponents in the dependency array
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element or its ancestors are in the excluded components
      if (
        inputCloseRef.current &&
        !inputCloseRef.current.contains(event.target) &&
        !excludedComponents.some((component) =>
          component.current.contains(event.target),
        )
      ) {
        setShow(false);
        setIsDropdownVisible(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputCloseRef, excludedComponents]);
  return (
    <>
      <style>
        {`
        .pac-container {
          width: 20% !important;
          background-color: #fff !important;
          border-radius: 0 0 10px 10px !important;
          box-shadow: 0 4px 6px rgba(0,0,0,0.1) !important;
          padding: 10px !important;
          margin-top: 20px !important;
          margin-right: 120px !important;
          border: none !important;
          divide: none !important;

        }
        html.dark .pac-container {
          background-color: #1B1D21 !important;
          color: #fff !important;
        }
        .auto-complete-container {
          position: relative !important;
        }

        .pac-item {

          border: none !important;
          width: 100% !important;

        }
           html.dark .pac-item span {
          color: #fff !important; 
        }
          html.dark .pac-item .pac-icon {
          filter: brightness(0) invert(1); /* Turns the icon white */
        }
        html.dark .pac-item {
          color: #fff !important
        }

        .pac-item:hover {
          background-color: #f9fcff !important;
        }
        html.dark .pac-item:hover {
          background-color: #232529 !important;
        }

      `}
      </style>

      <div className="relative w-full max-w-[840px] flex mx-4 text-sm h-[60px] min-h-[50px] border-gray-100 dark:border-darkModeBorder border-[1px] bg-white dark:bg-darkModeMain rounded-lg items-center">
        <div className="relative flex items-center w-1/2">
          {/* Adjusted Dropdown Position */}
          <div className="w-[425px] absolute -left-2 top-[47px] z-[999]">
            {isDropdownVisible && suggestionSearchDetailsData.length !== 0 && (
              <div ref={inputCloseRef}>
                <SearchDropdown
                  titles={searchHistory}
                  suggestionsData={suggestionSearchDetailsData}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  dropdownRef={dropdownRef}
                  setIsDropdownVisible={(data) => setIsDropdownVisible(data)}
                  searchInDashboard={searchInDashboard}
                />
              </div>
            )}
          </div>

          <div className="w-[425px] absolute -left-2 top-[47px] z-[999]">
            {show && searchHistory.length > 0 && (
              <div ref={inputCloseRef}>
                <SearchDropdown
                  searchHistory={searchHistory}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  dropdownRef={dropdownRef}
                  setIsDropdownVisible={(data) => {
                    setIsDropdownVisible(data);
                  }}
                  searchInDashboard={searchInDashboard}
                />
              </div>
            )}
          </div>
          <input
            value={searchInDashboard}
            onChange={handleChange}
            onClick={(e) => {
              handleSearchHistory(e);
            }}
            onKeyDown={handleSearch}
            className="w-full h-full px-1.5 ml-[10px] font-semibold text-[14px] opacity-100 border-none rounded placeholder:text-gray-400 placeholder:font-normal focus:ring-transparent placeholder:text-[14px] dark:bg-darkModeMain dark:text-white "
            type="text"
            placeholder="Type to search businesses"
            style={{ maxWidth: "calc(100% - 4rem)" }}
            ref={inputRef}
          />

          {searchInDashboard?.length > 0 && (
            <button
              tabIndex="-1"
              ref={dropdownRef}
              onClick={() => {
                setSearchInDashboard("");
                setFormattedAddress("");
                resetSearch();
                hideDropdown();
              }}
              className="flex items-center px-2 py-1 mr-2 text-sm text-gray-500 border border-transparent rounded hover:text-gray-600 focus:outline-none dark:text-gray-400"
            >
              <p className="max-[1350px]:hidden">Clear</p>{" "}
              <IoIosClose size={20} className="ml-1" />
            </button>
          )}
        </div>

        {/* <div className="h-[30px] mx-2 bg-gray-100 w-[2px] dark:bg-darkModeBorder"></div> */}
        <div className="relative flex items-center w-1/2 ml-1 auto-complete-container">
          <div className="flex items-center gap-2.5">
            <div className="h-[30px] w-[1px]  bg-gray-100 dark:bg-darkModeBorder"></div>
            <LocationPin />
          </div>
          <Autocomplete
            apiKey={MAP_KEYS}
            onPlaceSelected={(place) => {
              if (place?.formatted_address) {
                locationDetailsRef.current = place?.formatted_address;
              }
              setDashboardFilters(place?.formatted_address);
              setFormattedAddress(place?.formatted_address);
              setSelectedLocationLat(place?.geometry?.location?.lat());
              setSelectedLocationLong(place?.geometry?.location?.lng());
            }}
            options={{
              types: ["geocode", "establishment"],
            }}
            value={formattedAddress}
            language="en"
            onChange={(data) => {
              if (data?.target?.value == "") {
                setSelectedLocationLat("");
                setSelectedLocationLong("");
                locationDetailsRef.current = "";
              }
              setDashboardFilters(data?.formatted_address);
              setFormattedAddress(data?.target?.value);
              if (data?.formatted_address) {
                locationDetailsRef.current = data?.formatted_address;
              } else {
                locationDetailsRef.current = "";
              }
            }}
            className="w-full h-full text-[13px] border-none rounded opacity-100 font-semibold placeholder:font-normal placeholder:text-[13px] focus:text-semibold active:text-semibold focus:ring-transparent dark:bg-inherit"
          />

          <button
            onClick={(e) => {
              handleSearch(e);
            }}
            className="w-[60px] min-w-[60px] absolute right-0 flex justify-center items-center text-white h-[60px] min-h-[55px]  bg-black rounded-r-lg -mr-2"
          >
            <SearchIcon className="svg-icon text-white w-[25px]" />
          </button>
        </div>
      </div>
    </>
  );
}
