import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";
import Loader from "../../atom/loader/Loader";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "../../CreateEditProfile/DropdownEditRemove";
import { VaultsService } from "../../../services/vaults/VaultsService";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useFormik } from "formik";
import { CustomIcon } from "../../icons/profileIcons";

/**
 * Component to render existing vaultItems
 * @param {*} vaultItemsData vault items
 * @param {*} vaultId vaultId
 * @param {*} onRemove callBack function to update parent component with removed record
 * @param {*} onUpdate callBack function to update parent component with updated record
 * @returns
 */
const VaultItemCustomComponent = ({
  vaultItemsData,
  vaultId,
  onRemove,
  onUpdate,
  dropdownIdentify,
  setDropdownIdentify,
  onAddVaultItemClick,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(null);
  const [showEditView, setShowEditView] = useState(false);
  const [selectedVaultItem, setSelectedVaultItem] = useState({});
  const checkValidation = useRef(false);
  const { deleteVaultItemApi, editVaultItemApi } = VaultsService();
  const dropdownRef = useRef([]);

  // open/close dropdown on click
  const handleDropdownClick = (index) => {
    setDropdownIdentify("item");
    setIsDropdownOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // If dropdown is open and click happens outside of the dropdown, close it
      if (
        isDropdownOpen !== null &&
        dropdownRef.current[isDropdownOpen] &&
        !dropdownRef.current[isDropdownOpen].contains(event.target)
      ) {
        setIsDropdownOpen(null);
      }
    };

    if (isDropdownOpen !== null) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDropdownOpen]);

  // delete vaultItem in DB
  const handleRemove = async (vaultItemId) => {
    try {
      await deleteVaultItemApi(vaultId, vaultItemId);
      toast.success("Vault Item removed successfully!");
      onRemove();
    } catch (error) {
      toast.error(`${error || "Error removing vault item!"}`);
      console.error("Error removing Vault Item:", error);
    }
  };

  // schema to validate VaultItem form
  const schema = yup.object().shape({
    item_name: yup
      .string()
      .min(2, "Please enter at least 2 letters")
      .max(50, "Please enter a maximum of 50 letters")
      .required("This field is required."),
    item_value: yup
      .string()
      .min(2, "Please enter at least 2 letters")
      .max(50, "Please enter a maximum of 50 letters")
      .required("This field is required."),
  });

  // update vault item in DB
  const handleEdit = async (values) => {
    setIsLoading(true);
    try {
      await editVaultItemApi(vaultId, selectedVaultItem?.id, values);
      toast.success("Vault Item updated successfully!");
      onUpdate({ ...values, id: selectedVaultItem?.id });
      setShowEditView(false);
    } catch (error) {
      toast.error(`${error || "Error updating vault item!"}`);
      console.error("Error updating Vault Item:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // to handle form
  const formik = useFormik({
    initialValues: {
      item_name: selectedVaultItem?.item_name || "",
      item_value: selectedVaultItem?.item_value || "",
    },
    validationSchema: schema,
    onSubmit: handleEdit,
    enableReinitialize: true,
  });

  return (
    <div className="h-full mt-5 ">
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : !showEditView ? (
        <>
          {/** Button to add vault items -- enabled only for user vaults */}
          {onAddVaultItemClick && (
            <button
              key={"Custom"}
              className="flex p-4 border border-gray-200 rounded-lg h-[65px] w-full sm:w-[48%] md:w-[30%] lg:w-[30%] max-w-[205px] bg-blue-500 text-white ml-5 mb-4"
              onClick={() => {
                onAddVaultItemClick();
              }}
            >
              <div className="flex items-center">
                <CustomIcon
                  className={"mr-2 fill-white text-white"}
                  val={"add"}
                  size={18}
                />
                <span>Custom</span>
              </div>
              {/* <IoIosAddCircleOutline className="text-lg" /> */}
            </button>
          )}{" "}
          {
            // Renders to display all vault-items
            vaultItemsData?.length > 0 && (
              <div className="flex flex-wrap ml-5 gap-4">
                {vaultItemsData.map((vaultItem) => (
                  <div
                    key={vaultItem?.id} // Ensure this is unique
                    className="relative p-4 border border-gray-200 rounded-lg w-full sm:w-[48%] md:w-[30%] lg:w-[30%] max-w-[300px] bg-white text-blue flex items-center justify-between dark:border-darkModeBorder dark:bg-darkModeBG dark:text-white"
                  >
                    <div className="flex items-center w-full">
                      <div className="flex items-start gap-2 flex-col w-full text-start">
                        <p className="font-bold truncate w-full">
                          {vaultItem?.item_name}
                        </p>
                        <p className="capitalize w-full">
                          {vaultItem?.item_value}
                        </p>
                      </div>
                      <div className="flex justify-end w-[10%]">
                        <button
                          onClick={() => handleDropdownClick(vaultItem?.id)}
                        >
                          <BiDotsVerticalRounded
                            size={20}
                            color={
                              isDropdownOpen === vaultItem?.id ? "#5A70F9" : ""
                            }
                          />
                        </button>
                        <div
                          className="flex justify-end absolute top-12 right-0"
                          ref={(el) =>
                            (dropdownRef.current[vaultItem?.id] = el)
                          }
                        >
                          {isDropdownOpen === vaultItem?.id && (
                            <Dropdown
                              dropdownIdentify={dropdownIdentify}
                              setDropdownIdentify={setDropdownIdentify}
                              handleRemove={() => {
                                setIsDropdownOpen(null);
                                handleRemove(vaultItem?.id);
                              }}
                              handleEdit={() => {
                                checkValidation.current = false;
                                setIsDropdownOpen(null);
                                setShowEditView(true);
                                setSelectedVaultItem(vaultItem);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
          }
        </>
      ) : (
        // Renders form to edit vaultItem
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col justify-between h-full p-2"
        >
          <div>
            {/** Field vaultItem name */}
            <div>
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="item_name"
              >
                Title *
              </label>
              <input
                id="item_name"
                type="text"
                placeholder="Type here"
                {...formik.getFieldProps("item_name")}
                className={`w-[420px] mt-2 p-2 border mb-2 rounded-md dark:bg-darkModeMain dark:border-darkModeBorder${
                  formik.errors.item_name && formik.touched.item_name
                    ? "border-red-500"
                    : "border-gray-200"
                }`}
              />
              {formik.errors.item_name && formik.touched.item_name && (
                <p className="text-red-600 text-xs mt-1">
                  {formik.errors.item_name}
                </p>
              )}
            </div>
            {/** Field vaultItem value */}
            <textarea
              id="item_value"
              placeholder="Type here"
              {...formik.getFieldProps("item_value")}
              className={`w-full p-2 border mt-4 rounded-md h-[300px] overflow-y-auto  dark:bg-darkModeMain dark:border-darkModeBorder ${
                formik.errors.item_value && formik.touched.item_value
                  ? "border-red-500"
                  : "border-gray-200"
              }`}
            />
            {formik.errors.item_value && formik.touched.item_value && (
              <p className="text-red-600 text-xs mt-1">
                {formik.errors.item_value}
              </p>
            )}
          </div>
          {/** Save button */}
          <button
            type="submit"
            className="mt-4 md:w-[260px] mb-4 h-[45px] px-6 py-2 !bg-ipurple text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
          {isLoading && <Loader />}
        </form>
      )}
    </div>
  );
};

VaultItemCustomComponent.propTypes = {
  vaultItemsData: PropTypes.array.isRequired,
  vaultId: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAddVaultItemClick: PropTypes.func,
};

export default VaultItemCustomComponent;
