import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";
import { BASE_URL } from "../../utils/constants";
import { retrieveAuthToken } from "../authService";

/**  service to call Vaults APIs */
export const VaultsService = create((set) => ({
  error: null,
  vaultsData: [],
  vaultData: {},
  vaultItemData: {},
  isLoading: false,

  // create vault
  createVaultApi: async (vaultDetails) => {
    const token = retrieveAuthToken();
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.post(`${BASE_URL}vaults`, vaultDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          vaultData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
  // list all vaults
  listAllVaultsApi: async (queryParams = {}) => {
    const token = retrieveAuthToken();
    try {
      set({
        isLoading: true,
      });
      const params = new URLSearchParams(queryParams).toString();
      const response = await AxiosApi.get(
        `${BASE_URL}vaults${params ? `?${params}` : ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          vaultData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
  // fetch vault data by vaultId
  getVaultById: async (id) => {
    const token = retrieveAuthToken();
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.get(`${BASE_URL}vaults/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          vaultData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
  // delete vault by vaultId
  deleteVaultApi: async (id) => {
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.delete(`${BASE_URL}vaults/${id}`);

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          vaultItemData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
  // Update vault by vaultId
  editVaultApi: async (id, vaultItemDetails) => {
    const token = retrieveAuthToken();
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.put(
        `${BASE_URL}vaults/${id}`,
        vaultItemDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          vaultItemData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },

  //VaultItem

  // create new VaultItem record for a vaultId
  createVaultItemApi: async (id, vaultItemDetails) => {
    const token = retrieveAuthToken();
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.post(
        `${BASE_URL}vaults/${id}/vault-items`,
        vaultItemDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          vaultItemData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
  // delete vaultItem by vaultItemId

  deleteVaultItemApi: async (vaultId, id) => {
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.delete(
        `${BASE_URL}vaults/${vaultId}/vault-items/${id}`,
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          vaultItemData: data,
        });
        return { success: success, message: message, data: data };
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
  // Update vaultItem by vaultItemId
  editVaultItemApi: async (vaultId, id, vaultItemDetails) => {
    const token = retrieveAuthToken();
    try {
      set({
        isLoading: true,
      });
      const response = await AxiosApi.put(
        `${BASE_URL}vaults/${vaultId}/vault-items/${id}`,
        vaultItemDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { success, message, data } = response?.data;
      if (success) {
        set({
          isLoading: false,
          vaultItemData: data,
        });
        return data;
      } else {
        throw message;
      }
    } catch (error) {
      set({ isLoading: false, error: error });
      throw error;
    }
  },
}));
