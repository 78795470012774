export const handleTextPaste = (event, pin, setPin) => {
  event.preventDefault();

  const copyCode = event.clipboardData.getData("Text");

  const values = copyCode.split("");
  const newArray = pin.slice();
  newArray.fill("", 0, newArray.length);
  values.forEach((value, index) => {
    if (index < newArray.length) {
      newArray[index] = value;
    }
  });

  setPin(newArray);
};

export const convertSecondsToTime = (seconds) => {
  var hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  var minutes = Math.floor(seconds / 60);
  seconds %= 60;

  // Format hours, minutes, and seconds to have leading zeros if necessary
  var formattedHours = hours < 10 ? "0" + hours : hours;
  var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  var formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
const colors = ["#FB579A", "#2435EB", "#47CFFF", "#8541EC"];

export const assignRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};
export const clearLocalStorage = () => {
  localStorage.removeItem("authLogin");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("sipDetails");
  localStorage.removeItem("isExtension");
  localStorage.removeItem('theme');
  document.documentElement.classList.remove("dark");
  // localStorage.setItem('theme','light');
};
export const removeEmptyStringValues = (data) => {
  return Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value !== "")
  );
};

export const TimeOptions = [
  // { label: "12:00 AM", value: "00:00" },
  { label: "1:00 AM", value: "01:00" },
  { label: "2:00 AM", value: "02:00" },
  { label: "3:00 AM", value: "03:00" },
  { label: "4:00 AM", value: "04:00" },
  { label: "5:00 AM", value: "05:00" },
  { label: "6:00 AM", value: "06:00" },
  { label: "7:00 AM", value: "07:00" },
  { label: "8:00 AM", value: "08:00" },
  { label: "9:00 AM", value: "09:00" },
  { label: "10:00 AM", value: "10:00" },
  { label: "11:00 AM", value: "11:00" },
  { label: "12:00 PM", value: "12:00" },
  { label: "1:00 PM", value: "13:00" },
  { label: "2:00 PM", value: "14:00" },
  { label: "3:00 PM", value: "15:00" },
  { label: "4:00 PM", value: "16:00" },
  { label: "5:00 PM", value: "17:00" },
  { label: "6:00 PM", value: "18:00" },
  { label: "7:00 PM", value: "19:00" },
  { label: "8:00 PM", value: "20:00" },
  { label: "9:00 PM", value: "21:00" },
  { label: "10:00 PM", value: "22:00" },
  { label: "11:00 PM", value: "23:00" },
];

export const customStylesTeam = {
  control: (provided) => ({
    ...provided,
    height: "45px",
    borderColor: "#ECEDF2", // Same as border-gray-100
    borderWidth: "1px",
    borderRadius: "0.5rem", // Rounded-lg
    backgroundColor: "inherit",
    padding: "0 0.5rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: "rgb(76 95 239)", // Same as active:border-ipurple
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgba(156, 163, 175, 1)", // Same as placeholder:text-gray-300
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "inherit",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10, // Ensure menu is above other elements
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "inherit" : "inherit", // Change color on hover
    color: state.isSelected ? "inherit" : "inherit", // Ensure the color remains as it is when selected
  }),
  input: (provided) => ({
    ...provided,
    border: "none",
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
};

export const customStylesProfile = {
  control: (provided) => ({
    ...provided,
    height: "55px",
    borderColor: "#ECEDF2", // Same as border-gray-100
    borderWidth: "1px",
    borderRadius: "0.5rem", // Rounded-lg
    backgroundColor: "inherit",
    padding: "0 0.5rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: "rgb(76 95 239)", // Same as active:border-ipurple
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgba(156, 163, 175, 1)", // Same as placeholder:text-gray-300
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "inherit",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10, // Ensure menu is above other elements
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "inherit" : "inherit", // Change color on hover
    color: state.isSelected ? "inherit" : "inherit", // Ensure the color remains as it is when selected
  }),
  input: (provided) => ({
    ...provided,
    border: "none",
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
};

export const reactSelectcustomStyles = {
  control: (provided) => ({
    ...provided,
    height: "55px",
    borderColor: "rgba(229, 231, 235, 1)", // Same as border-gray-100
    borderWidth: "2px",
    borderRadius: "0.5rem", // Rounded-lg
    backgroundColor: "inherit",
    padding: "0 0.5rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: "rgb(76 95 239)", // Same as active:border-ipurple
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "rgba(156, 163, 175, 1)", // Same as placeholder:text-gray-300
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "inherit",
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10, // Ensure menu is above other elements
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "inherit" : "inherit", // Change color on hover
    color: state.isSelected ? "inherit" : "inherit", // Ensure the color remains as it is when selected
  }),
  input: (provided) => ({
    ...provided,
    border: "none",
  }),
};
