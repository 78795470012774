import round from "../../assets/logo.webp";

export default function AssistantMessage(props) {
  const { AssistanceMessage } = props || {};
  return (
    <div className="flex flex-row items-center justify-start w-full py-0.5 text-white ">
      <div className="flex max-w-[45%] gap-[10px]">
        <img
          src={round}
          className="w-[30px] h-[30px] border-[1.5px] rounded-full object-contain"
          alt="button"
        />
        <p className="flex-1 w-full p-3 text-sm rounded-b-lg rounded-tr-lg bg-gradient-to-r from-indigo-600 to-purple-700  break-word overflow-auto">

          {AssistanceMessage} 
        </p>
      </div>
    </div>
  );
}
