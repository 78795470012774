import NotificationsDropdown from "./NotificationsDropdown";
import { getDayTimeDiffDetails } from "../../utils/TimeDetails";
import PropTypes from "prop-types";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/logo.svg';

NotificationsCard.propTypes = {
  notifcationDetails: PropTypes.object,
  handleIsReadable: PropTypes.func.isRequired,
  handleArchived: PropTypes.func.isRequired,
};

export default function NotificationsCard(props) {
  const { notifcationDetails, handleIsReadable, handleArchived } = props;
  const navigate = useNavigate();
  const businessName = notifcationDetails?.description?.replace(
    notifcationDetails?.business?.name,
    `<span class="!font-semibold">${notifcationDetails?.business?.name}</span>`
  );

  const handleNotification = async (data, event) => {
    if (event.target.closest(".notifications-dropdown")) {
      return;
    }

    if (!data?.is_read) {
      await handleIsReadable(data?.id, true);
    }


    if (data?.ticket_id) {
      navigate(`/tickets/${data?.ticket_id}`);
    } else if (
      data?.business_id ||
      data?.payload?.new_params?.business_id ||
      data?.payload?.business_id
    ) {
      const id =
        data?.business_id ||
        data?.payload?.new_params?.business_id ||
        data?.payload?.business_id;
      navigate(`/business/${id}`);
    }
  };

  return (
    <div
      className={
        !notifcationDetails?.is_read
          ? `w-full relative flex items-center h-[60px] dark:bg-darkModeMain bg-lightBg dark:hover:bg-darkModeBG hover:cursor-pointer text-sm border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder justify-between`
          : `w-full relative flex items-center h-[60px] dark:bg-darkModeMain hover:bg-lightBg dark:hover:bg-darkModeBG hover:cursor-pointer text-sm border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder justify-between`
      }
      onClick={(event) => handleNotification(notifcationDetails, event)}
    >
      <div className="flex items-center">
        {/* <div className="relative">
          {notifcationDetails?.is_read === false && (
            <div className="absolute -top-1 -right-1 rounded-full bg-ipink w-[12px] h-[12px] border-[1px] border-gray-100 border-white"></div>
          )}
          <DirectoryImageInitials
            imageUrl={
              notifcationDetails?.business?.name
                ? notifcationDetails?.business?.upload?.file_path
                : logo
            }
            alt={notifcationDetails?.business?.name}
            name={notifcationDetails?.business?.name}
            boxColor={notifcationDetails?.business?.business_color?.toLowerCase()}
            className="!w-[40px] !min-w-[40px] !h-[40px] flex items-center justify-center rounded-lg dark:border-darkModeBorder !border-[1px]"
          />
        </div> */}
        <div className="text-[14px] pl-5">
          <div
            dangerouslySetInnerHTML={{
              __html: businessName,
            }}
          />
        </div>
      </div>

      <div className="relative flex items-center justify-end w-1/6 gap-3 pr-2">
        <p className="font-normal text-[13px] text-[#140F2640] dark:text-neutral-500">
          {getDayTimeDiffDetails(notifcationDetails?.created_at) || ""}
        </p>
        <div className="notifications-dropdown" onClick={(event) => event.stopPropagation()}>
          <NotificationsDropdown
            notifcationDetails={notifcationDetails}
            handleUnReadable={handleIsReadable}
            handleArchived={handleArchived}
          />
        </div>
      </div>
    </div>
  );
}
