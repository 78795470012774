import { create } from "zustand";
import AxiosApi from "../api/requestInterceptor";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const useUserStore = create((set) => ({
  userDetails: null,
  onboardData: null,
  error: null,

  getUserDetails: async (profileId) => {
    try {
      const response = await AxiosApi.get(
        `${API_BASE_URL}profiles/${profileId}`
      );
      set({ userDetails: response, error: null });
      return response;
    } catch (error) {
      console.error("Error fetching bidoata", error);
      set({ error });
    }
  },

  updateUserData: async (data) => {
    try {
      const response = await AxiosApi.put(`${API_BASE_URL}users`, data);
      set({ userDetails: response.data, error: null });
    } catch (error) {
      console.error("Error", error);
      set({ error });
    }
  },

  fetchOnBoard: async () => {
    try {
      const response = await AxiosApi.get(`${API_BASE_URL}auth/profile`);

      const onboarding_seen = response?.data?.data?.onboarding_seen; // Direct access

      set({ onboardData: onboarding_seen, error: null });
      return response;
    } catch (error) {
      console.error("Error fetching profile:", error);
      set({ onboardData: null, error: error.message || "An error occurred" }); // Update state
    }
  },
}));

export default useUserStore;
