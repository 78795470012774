import React from 'react'

export default function RecordVoiceView({onRecordButtonClick}) {
  return (
    <div className='flex flex-col items-center justify-center w-full scrollable-container h-full overflow-y-auto'>
      <div className='relative w-1/2 h-1/3'>
          <div className='absolute w-[83px] flex h-[55px] rounded-b-xl rounded-r-xl text-white justify-center items-center bg-gradient-to-r font-semibold from-purple-400  to-blue-400 text-[16px] '>
            Hello
          </div>
          <div className='absolute right-0 top-20 w-[240px] flex h-[55px] rounded-b-xl rounded-l-xl text-white justify-center items-center bg-gradient-to-r font-semibold from-[#8442EC]  to-[#445FF5] text-[16px] '>
            Please give me a minute
          </div>
      </div>
      <div className='flex flex-col items-center w-1/2 -mt-16 text-sm text-center h-1/3'>
        <h1 className='text-[20px] font-semibold pb-5'>Record Your Voice</h1>
        <p className='leading-loose '>Record yourself speaking so that we can make sure to get you on with a representative and keep them waiting if you haven&apos;t joined the call yet.</p>
        <button
          onClick={onRecordButtonClick}
          className='w-[340px] font-semibold h-[45px] uppercase bg-ipurple text-white text-[13px] rounded-full m-8 hover:border-2 hover:border-ipurple hover:bg-inherit hover:text-ipurple'>Record now</button>
      </div>
    </div>
  )
}
