import { Toaster } from "react-hot-toast";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { Suspense, lazy } from "react";
import Dashboard from "../pages/Dashboard";
import SingleTicketContainer from "../components/tickets-tab/SingleTicketContainer";
import SettingsContainer from "../components/settings/SettingsContainer";
import CallsContainer from "../components/calls/CallsContainer";
import BusinessProfileContainer from "../components/business-profile/BusinessProfileContainer";

const Auth = lazy(() => import("../pages/auth-landing/AuthLanding"));
const Home = lazy(() => import("../pages/Home"));

const RegisterPersonal = lazy(() =>
  import("../pages/register-personal/RegisterPersonal")
);
const ForgotPassword = lazy(() =>
  import("../pages/forgot-password/ForgotPassword")
);
import PageNotFound from "../pages/other/PageNotFound";
const UnAuthorized = lazy(() => import("../pages/other/UnAuthorized"));
import Loader from "../components/atom/loader/Loader";
import ChatContainer from "../components/aichat/ChatContainer";
import {
  ProtectedRoles,
  ProtectedRolesTicket,
  ProtectedRoute,
  ProtectedRouteCheck,
} from "./ProtectedRoutes";
import NotificationsContainer from "../components/Notifications/NotificationsContainer";
import CreateEditProfileContainer from "../components/CreateEditProfile/CreateEditProfileContainer";
import EditExistingContainer from "../components/CreateEditProfile/EditExistingContainer";
import CreateTicket from "../components/tickets-tab/CreateTicket";
const ProfessionalLogin = lazy(() =>
  import("../pages/login/ProfessionalLogin")
);
const TeamInfo = lazy(() => import("../pages/team-info/TeamInfo"));
const Teammembers = lazy(() => import("../pages/team-info/TeamMembers"));
const Teammembersstatus = lazy(() =>
  import("../pages/team-info/TeamMemberStatus")
);

const ConfirmPhoneNumber = lazy(() =>
  import("../components/auth/forgot-password/ConfirmPhoneNumber")
);
const DirectoryContainer = lazy(() =>
  import("../components/directory/DirectoryContainer")
);
const ProfileContainer = lazy(() =>
  import("../components/profile/ProfileContainer")
);
const TicketsContainer = lazy(() =>
  import("../components/tickets-tab/TicketsContainer")
);



const RouteFile = () => {
  return (
    <BrowserRouter basename="/">
      <Toaster />
      <Routes>
        <Route
          path="/register-professional"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRouteCheck>
                <ProfessionalLogin />
              </ProtectedRouteCheck>
            </Suspense>
          }
        />
        <Route
          path="/register-personal"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRouteCheck>
                <RegisterPersonal />
              </ProtectedRouteCheck>
            </Suspense>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRouteCheck>
                <ForgotPassword />
              </ProtectedRouteCheck>
            </Suspense>
          }
        />
        <Route
          path="/verify-otp"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRouteCheck>
                <ConfirmPhoneNumber />
              </ProtectedRouteCheck>
            </Suspense>
          }
        />
        <Route
          path="/auth-landing"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRouteCheck>
                <Auth />
              </ProtectedRouteCheck>
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRouteCheck>
                <Home />
              </ProtectedRouteCheck>
            </Suspense>
          }
        />
        {/* <Route
          path="/*"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRouteCheck>
                <PageNotFound />
              </ProtectedRouteCheck>
            </Suspense>
          }
        /> */}
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="*" element={<PageNotFound />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route
            exact
            path="/directory/search"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <DirectoryContainer />
                  </Suspense>
                }
              />
            }
          />
          <Route
            exact
            path="/directory/suggested"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <DirectoryContainer />
                  </Suspense>
                }
              />
            }
          />
          <Route
            exact
            path="/directory/saved"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <DirectoryContainer />
                  </Suspense>
                }
              />
            }
          />
          <Route
            exact
            path="/directory/recent"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <DirectoryContainer />
                  </Suspense>
                }
              />
            }
          />

          <Route
            exact
            path="/business/:businessid"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <BusinessProfileContainer />
                  </Suspense>
                }
              />
            }
          />

          <Route
            path="/tickets/create-ticket"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <CreateTicket />
                  </Suspense>
                }
              />
            }
          />

          <Route
            path="/tickets"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <TicketsContainer />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/create-business"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <CreateEditProfileContainer />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/edit-business/:businessid"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <EditExistingContainer />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <NotificationsContainer />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/team-info"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <TeamInfo />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/team-member"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <Teammembers />
                  </Suspense>
                }
              />
            }
          />

          <Route
            path="/settings"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <SettingsContainer />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <ProfileContainer />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <ChatContainer />
                  </Suspense>
                }
              />
            }
          />

          <Route
            path="/calls/recent"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <CallsContainer />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/calls/scheduled"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <CallsContainer />
                  </Suspense>
                }
              />
            }
          />

          <Route
            path="/business/call"
            element={
              <ProtectedRolesTicket
                element={
                  <Suspense fallback={<Loader />}>
                    <SingleTicketContainer />
                  </Suspense>
                }
              />
            }
          />

          <Route
            path="/tickets/:ticketId"
            element={
              <ProtectedRolesTicket
                element={
                  <Suspense fallback={<Loader />}>
                    <SingleTicketContainer />
                  </Suspense>
                }
              />
            }
          />

          <Route
            path="/business-profile/:businessName"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <BusinessProfileContainer />
                  </Suspense>
                }
              />
            }
          />
          {/* <Route
            path="/page-not-found-404"
            element={
              <Suspense fallback={<Loader />}>
                <PageNotFound />
              </Suspense>
            }
          /> */}
          <Route
            path="/unauthorize-access"
            element={
              <ProtectedRoles
                element={
                  <Suspense fallback={<Loader />}>
                    <UnAuthorized />
                  </Suspense>
                }
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteFile;
