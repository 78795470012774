import React from 'react';
import { FaRegUser } from "react-icons/fa";
import { PiCake, PiPhoneFill } from "react-icons/pi";
import { LuMail } from "react-icons/lu";
import { IoBusiness } from "react-icons/io5";
import { BsPassport } from "react-icons/bs";
import { PiSignature } from "react-icons/pi";
import { PiIdentificationCard } from "react-icons/pi";
import { CgFileDocument } from "react-icons/cg";
import { HiOutlineIdentification } from "react-icons/hi";
import { BiSolidEdit } from "react-icons/bi";
import { HiOutlinePlus } from "react-icons/hi2";
import {  IoChevronDown } from 'react-icons/io5'


export default function IdentitiesAccordian({ identity, isOpen, onToggle }) {
    return (
        <div
        className={`relative flex flex-col w-full ${
          isOpen ? 'mb-[470px] lg:mb-[350px]' : '' // Adjust the margin when the accordion is open
        }`}
        key={identity.id}> 
        <div className=' px-6 w-full text-sm flex justify-between items-center dark:hover:bg-darkModeBG  dark:active:bg-darkModeBG hover:bg-lightBg border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-[60px]'>
            <p className='text-sm font-semibold'>{identity.title}</p>
            <div className='flex items-center gap-3 p-4'>
                <IoChevronDown onClick={onToggle} size={20} className='-mr-2' />
          </div>
          {isOpen && (
    <div className='absolute top-[58px] right-0 w-full border-y-[1px] dark:border-darkModeBorder'>
        <div className='w-full p-4 flex min-h-[350px] flex-wrap gap-4 bg-lightBg z-40 dark:bg-darkModeBG dark:border-darkModeBorder border-b border-gray-100-[1px] border-gray-100'>
                <div className='w-[195px] h-[65px] rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-white flex dark:bg-darkModeMain justify-between p-6'>
                            <div className='flex items-center gap-3 text-xs font-semibold'>
                                <div className='border-[1px]  border-gray-100  p-1 rounded text-ipurple border-ipurple'>
                                <FaRegUser size={10} />
                                </div>
                                    <p>Bio</p>
                                </div>
                                <p className='text-xs text-gray-400'>1</p>
                        </div>
                        <div className='w-[195px] h-[65px] rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-white flex dark:bg-darkModeMain justify-between p-6'>
                            <div className='flex items-center gap-3 text-xs font-semibold'>
                                <div className=' text-ipurple'>
                                <PiCake size={20} />
                                </div>
                                    <p>Date of Birth</p>
                                </div>
                                <p className='text-xs text-gray-400'>1</p>
                        </div>
                        <div className='w-[195px] h-[65px] rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-white dark:bg-darkModeMain flex  justify-between p-6'>
                            <div className='flex items-center gap-3 text-xs font-semibold'>
                                <div className='border-[1px]  border-gray-100   rounded text-ipurple border-ipurple'>
                                <PiPhoneFill size={15} />
                                </div>
                                    <p>Phones</p>
                                </div>
                                <p className='text-xs text-gray-400'>1</p>
                        </div>
                        <div className='w-[195px] h-[65px] rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-white dark:bg-darkModeMain flex  justify-between p-6'>
                            <div className='flex items-center gap-3 text-xs font-semibold'>
                                <div className=' text-ipurple'>
                                <LuMail size={20} />
                                </div>
                                    <p>Emails</p>
                                </div>
                                <p className='text-xs text-gray-400'>1</p>
                        </div>
                        <div className='w-[195px] h-[65px] rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-white flex  dark:bg-darkModeMain justify-between p-6'>
                            <div className='flex items-center gap-3 text-xs font-semibold'>
                                <div className=' text-ipurple'>
                                <IoBusiness size={20} />
                                </div>
                                    <p>Addresses</p>
                                </div>
                                <p className='text-xs text-gray-400'>1</p>
                        </div>
                        <div className='w-[195px] h-[65px] rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-white flex dark:bg-darkModeMain justify-between p-6'>
                            <div className='flex items-center gap-3 text-xs font-semibold'>
                                <div className=' text-ipurple e'>
                                <BsPassport size={20} />
                                </div>
                                    <p>Passport & ID</p>
                                </div>
                                <p className='text-xs text-gray-400'>1</p>
                        </div>
                        <div className='w-[195px] h-[65px] rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-white flex dark:bg-darkModeMain justify-between p-6'>
                            <div className='flex items-center gap-3 text-xs font-semibold'>
                                <div className=' text-ipurple'>
                                <PiSignature size={20} />
                                </div>
                                    <p>Signature</p>
                                </div>
                                <p className='text-xs text-gray-400'>1</p>
                        </div>
                        <div className='flex flex-col '>
                        <p className='p-4  text-sm font-semibold'>Add more</p>
                        <div className='flex items-center flex-wrap gap-4'>
            <div className='w-[195px] h-[65px] rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-ipurple text-white flex items-center justify-between p-4'>
                    <div className='flex items-center gap-3 text-xs font-semibold'>
                        <div className='text-white '>
                            <PiIdentificationCard size={20} />
                        </div>
                        <p>Driver License</p>
                        </div>
                        <HiOutlinePlus size={20} className=''/>
                    </div>
                    <div className='w-[195px] h-[65px]  rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-ipurple text-white flex items-center justify-between p-4'>
                    <div className='flex items-center gap-3 text-xs font-semibold'>
                        <div className='text-white '>
                            <CgFileDocument size={20} />
                        </div>
                        <p>Documents</p>
                        </div>
                        <HiOutlinePlus size={20} className=''/>
                    </div>
                    <div className='w-[195px] h-[65px] rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-ipurple text-white flex items-center justify-between p-4'>
                    <div className='flex items-center gap-3 text-xs font-semibold'>
                        <div className='text-white '>
                            <HiOutlineIdentification size={20} />
                        </div>
                        <p>Social Security</p>
                        </div>
                        <HiOutlinePlus size={20} className=''/>
                    </div>
                    <div className='w-[195px] h-[65px]  rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder bg-ipurple text-white flex items-center justify-between p-4'>
                    <div className='flex items-center gap-3 text-xs font-semibold'>
                        <div className='text-white '>
                            <BiSolidEdit size={20} />
                        </div>
                        <p>Custom</p>
                        </div>
                        <HiOutlinePlus size={20} className=''/>
                    </div>
                    </div>
                    </div>
            </div>
           
                    
            </div>
            
        )}
      </div>
    </div>
  );
}
