import React from 'react'



export default function ReactSelector({onClose}) {
  let emojiArray=[{id:1,icon:"😡"},
  {id:2,icon:"😔"},
  {id:3,icon:"😐"},
  {id:4,icon:"🙂"},
  {id:5,icon:"😍"}
]
  return (
    <div className='w-[295px] mt-1 flex justify-center items-center gap-6 text-3xl h-[50px] rounded-full bg-white dark:bg-darkModeMain shadow-black shadow-xl'>
        {emojiArray?.map(({icon,id})=>(<button onClick={onClose} className='active:text-4xl' key={id} id={id}>{icon}</button>))}
    </div>
  )
}
