import { useState } from "react";
import { IoAdd } from "react-icons/io5";
import AddContactView from "./AddButtonPopup/AddContactView";
import ContactsCard from "../business-profile/ContactsCard";
import PropTypes from "prop-types";
import EditContactPopup from "../business-profile/EditContactPopup";
Contacts.propTypes = {
  formik: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
export default function Contacts(props) {
  const { formik, data } = props;
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [editContact, setEditContact] = useState({});

  const handleContactClick = () => {
    setIsAddPopupOpen(true);
  };

  const handleClose = () => {
    setIsAddPopupOpen(false);
    setEditIndex("");
    setEditContact({});
  };

  return (
    <>
      <div className="min-h-[60px] max-h-[60px] justify-between flex items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
        <h1 className="px-4 text-sm font-semibold uppercase">Contacts</h1>

        {isAddPopupOpen && (
          <EditContactPopup
            onClose={() => {
              setIsAddPopupOpen(false);
              handleClose();
            }}
            business_contacts={formik.values?.business_contacts}
            formik={formik}
            showOpt={true}
            editIndex={editIndex}
            editContact={editContact}
            setIsAddPopupOpen={() => {
              setIsAddPopupOpen(true);
            }}
            setEditIndex={(index) => setEditIndex(index)}
            setEditContact={(data) => {
              setEditContact(data);
            }}
            {...props}
          />
        )}

        <button
          onClick={handleContactClick}
          className="w-[180px] h-[45px] mr-2 uppercase rounded-full lack dark:border-white flex justify-center items-center gap-2 text-[13px] font-[600] dark:hover:bg-white dark:hover:text-black"
        >
          <IoAdd size={22} className="bg-black text-white border rounded-xl" />
          Add contact
        </button>
      </div>

      {/* <div className="pt-4 flex flex-wrap"> */}
      <ContactsCard
        {...props}
        business_contacts={formik.values?.business_contacts}
        formik={formik}
        showOpt={true}
        editIndex={editIndex}
        editContact={editContact}
        setIsAddPopupOpen={() => {
          setIsAddPopupOpen(true);
        }}
        setEditIndex={(index) => setEditIndex(index)}
        setEditContact={(data) => {
          setEditContact(data);
        }}
      />
      {/* </div> */}
    </>
  );
}
