import React from "react";

function PrivacyPolicySettingView() {
  return (
    // padding p-5 now p-8
<div className="flex flex-col w-full p-8 space-y-7 text-sm scrollable-container-hide h-full overflow-y-auto bg-white dark:bg-darkModeBG dark:text-white">
      <div className="flex items-center w-full gap-10">
        <h2 className="font-semibold">Last Updated: May 1, 2024</h2>
      </div>

      <section>
        <p>
          This Privacy Policy describes the data protection practices of iAllo
          Inc., (“<span className="font-medium">iAllo</span>,” “<span className="font-medium">we</span>,” “
          <span className="font-medium">us</span>,” or “<span className="font-medium">our</span>”). This Privacy Policy
          applies to information that we collect and use about you when you
          access or use the iAllo website, mobile application, browser extension
          or other online or mobile service that links to or otherwise presents
          this Privacy Policy to you. We refer to these products and services
          collectively as the “<span className="font-medium">Services</span>.”
        </p>
      </section>

      <section>
        <p>
          PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR INFORMATION. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT USE THIS SERVICES.
        </p>
      </section>

      {/* Information We Collect */}
      <section className="flex flex-col gap-4">
        <h3 className="font-semibold text-base">Information We Collect</h3>
        <p>
          We collect information about you through the means discussed below.
          Please note that we need certain types of information so that we can
          provide the Services to you. If you do not provide us with such
          information, or ask us to delete it, you may no longer be able to
          access or use our Services.
        </p>

        <ol className="list-decimal list-inside flex flex-col gap-4  ">
          {/* INFORMATION YOU PROVIDE TO US */}
          <li className=" font-semibold">INFORMATION YOU PROVIDE TO US</li>

          <article className="flex flex-col gap-4">
            <p className="flex flex-col gap-4">
              We collect a variety of information that you provide directly to
              us. For example, we collect information from you through:
              <ul className="list-disc list-inside flex flex-col gap-1.5">
                <li>The Services you use or processing your orders</li>

                <li>
                  Requests or questions you submit to us via online forms,
                  email, or otherwise
                </li>

                <li>Your participation in sweepstakes, contests, or surveys</li>

                <li>Any reviews that you submit about the Services</li>

                <li>Account registration and administration of your account</li>

                <li>Uploads or posts to the Services</li>

                <li>Requests for customer support and technical assistance</li>

                <li>
                  Any information you provide and save to the “vault” in the app
                </li>
              </ul>
            </p>

            {/* Information about you */}
            <h3 className="font-semibold ">Information about you</h3>
            <p>
              While parts of the Services may not require you to provide any
              information that can directly identify you by name (such as if you
              choose to browse the website without logging in), the specific
              types of information we collect will depend upon the Services you
              use, how you use them, and the information you choose to provide.
              The types of data we collect directly from you includes:
            </p>
            <ul className="list-disc list-inside   flex flex-col gap-1.5">
              <li>Email address</li>

              <li>Name</li>

              <li>Phone number</li>

              <li>Log-in credentials, if you create a iAllo account</li>

              <li>
                Billing information, such as shipping address of a gift card
                recipient, credit or debit card number, verification number, zip
                code, and expiration date
              </li>

              <li>Information about purchases or other transactions with us</li>

              <li>
                Information about your customer service interactions with us
              </li>

              <li>
                Demographic information such as your gender or other information
                you choose to provide as part of your iAllo profile
              </li>

              <li>Occupation</li>

              <li>Call Data</li>

              <li>Business views</li>

              <li>
                User-generated content you provide to us, such as when you
                comment on content on the Services, respond to a survey request,
                review a class, or participate in the public forums
              </li>

              <li>
                Any other information you choose to directly provide to us in
                connection with your use of the Services
              </li>
            </ul>

            {/* Information about others */}
            <h3 className="font-semibold ">Information about others</h3>
            <p>
              If you request that your purchase be provided to someone other
              than yourself (such as a gift recipient), we use the information
              you provide about the other person to fulfill the shipment.
            </p>
          </article>

          {/* INFORMATION WE COLLECT THROUGH AUTOMATED MEANS */}
          <li className=" font-semibold">
            INFORMATION WE COLLECT THROUGH AUTOMATED MEANS
          </li>

          <article className="flex flex-col gap-2">
            <p>
              When you use our Services, we collect certain information as
              described in this Section. As discussed further below, we and our
              service providers (which are third party companies that work on
              our behalf) may use a variety of technologies, including cookies
              and similar tools, to assist in collecting this information.
            </p>

            <h3 className="font-semibold ">Websites and Browser Extension</h3>
            <p>
              When you use our website and browser extension, we collect and
              analyze information such as your IP address, browser types,
              browser language, operating system, software and hardware
              attributes (including device IDs) referring and exit pages and
              URLs, the number of clicks, pages viewed and the order of those
              pages, date and time of use, content watched, total minutes
              watched, error logs, and other similar information about how you
              use the website.
            </p>

            <h3 className="font-semibold ">Mobile Applications</h3>
            <p>
              When you use a iAllo mobile application or software (“
              <span className="font-medium">app</span>“), we automatically receive certain
              information about the mobile phone, tablet, or computer used to
              access the app, including device identifiers, IP address,
              operating system, version, Internet service provider, browser
              type, domain name and other similar information, whether and when
              you update the app, date and time of use, content watched, total
              minutes watched, error logs, and other similar information about
              how you use the app.
            </p>

            <h3 className="font-semibold ">Location Information</h3>
            <p>
              When you use the Services, we and our service providers may
              automatically collect general location information (e.g., IP
              address, city/state and/or postal code associated with an IP
              address, city/state) from your computer or mobile device.
            </p>
          </article>

          {/* INFORMATION WE COLLECT FROM SOCIAL MEDIA AND OTHER CONTENT PLATFORMS */}
          <li className=" font-semibold">
            INFORMATION WE COLLECT FROM SOCIAL MEDIA AND OTHER CONTENT PLATFORMS
          </li>

          <article className="flex flex-col gap-2">
            If you access the Services through a third-party connection or
            log-in (e.g., through a social network like Facebook or Twitter),
            you may allow us to have access to and store certain information
            from your social network profile. This can include your name,
            gender, profile picture, your “likes” and check-ins, and your list
            of friends, depending on your settings on such services. If you do
            not wish to have this information disclosed, do not use a social
            networking connection to access the Services. For a description of
            how social networking sites handle your information, please refer to
            their privacy policies and terms of use, which may permit you to
            modify your privacy settings. You may also have the option of
            posting your Services activities to social networking services when
            you access content through the Services (for example, you may post
            to Facebook that you enrolled in a class on the Service); you
            acknowledge that if you choose to use this feature, your friends,
            followers and subscribers on any social networking services you have
            enabled will be able to view such activity.
          </article>

          {/* INFORMATION WE COLLECT FROM OTHERS */}

          <li className=" font-semibold">INFORMATION WE COLLECT FROM OTHERS</li>

          <article className="flex flex-col gap-2">
            We may receive additional information such as demographic and
            statistical information from third parties, such as business
            partners, marketers, researchers, analysts, and other parties that
            we may attribute to you based on your assignment to certain
            statistical groups. We use this information to supplement the
            information that we collect directly from you in order to derive
            your possible interests and to provide more relevant experiences for
            you and improve our products, analytics, and advertising.
          </article>
        </ol>
      </section>

      {/* How We Use Your Information */}

      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">How We Use Your Information</h3>
        <p>
          We, or our service providers, use your information for various
          purposes depending on the types of information we have collected from
          and about you, in order to:
        </p>
        <ul className="list-disc list-inside   flex flex-col gap-1.5">
          <li>Provide the Services</li>
          <ul className="list-disc list-inside  ">
            <li>
              Complete a purchase or provide the Services you have requested,
              including invoicing and accounting
            </li>
          </ul>

          <li>Communicate with you and provide support</li>
          <ul className="list-disc list-inside   flex flex-col gap-2">
            <li>
              Respond to your request for information and provide you with more
              effective and efficient customer service
            </li>

            <li>
              Provide you with updates and information about classes in which
              you have enrolled
            </li>
          </ul>

          <li>Marketing and content customization</li>
          <ul className="list-disc list-inside   flex flex-col gap-2">
            <li>
              Contact you by email, postal mail, or phone regarding iAllo and
              third-party products, services, surveys, research studies,
              promotions, special events and other subjects that we think may be
              of interest to you
            </li>
            <li>
              Customize the advertising and content you see on the Services
            </li>
          </ul>

          <li>Business operations</li>
          <ul className="list-disc list-inside   flex flex-col gap-2">
            <li>
              Help us better understand your interests and needs, and improve
              the Services, including through research and reports, and test and
              create new products, features, and services
            </li>

            <li>
              Secure our websites and applications, and resolve app crashes and
              other issues being reported
            </li>
          </ul>

          <li>Legal Purposes</li>
          <ul className="list-disc list-inside   flex flex-col gap-2">
            <li>
              Comply with any procedures, laws, and regulations which apply to
              us where it is necessary for our legitimate interests or the
              legitimate interests of others
            </li>

            <li>
              Establish, exercise, or defend our legal rights where it is
              necessary for our legitimate interests or the legitimate interests
              of others
            </li>
          </ul>
        </ul>

        <h3 className="font-semibold ">Combined Information</h3>
        <p>
          For the purposes discussed in this Privacy Policy, we may combine the
          information that we collect through the Services with information that
          we receive from other sources, both online and offline, and use such
          combined information in accordance with this Privacy Policy.
        </p>

        <h3 className="font-semibold ">Aggregate/Anonymous Data</h3>
        <p>
          We may aggregate and/or anonymize any information collected through
          the Services so that such information can no longer be linked to you
          or your device. We may use such information for any purpose, including
          without limitation for research and marketing purposes, and may also
          disclose such data to any third parties, including advertisers,
          promotional partners, and sponsors.
        </p>
      </section>

      {/* Cookies and Similar Technologies */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">
          Cookies and Similar Technologies
        </h3>
        <p>
          To collect the information in the “Information We Collect Through
          Automated Means” section above, we and our service providers use
          Internet server logs, cookies, tracking pixels, and other similar
          tracking technologies. We use these technologies in order to offer you
          a more tailored experience in the future, by understanding and
          remembering your particular browsing preferences. Cookies are small
          text files that are placed on your computer or mobile device when you
          visit a site that enable us to: (i) recognize your computer; (ii)
          store your preferences and settings; (iii) understand the web pages of
          the Services you have visited; (iv) enhance your user experience by
          delivering and measuring the effectiveness of content and advertising
          tailored to your interests; (v) perform searches and analytics; and
          (vi) assist with security and administrative functions. Some cookies
          are placed in your browser cache while those associated with Flash
          technologies are stored with your Adobe Flash Player files.
        </p>

        <p>
          As we adopt additional technologies, we may also gather information
          through other methods. Please note that you can change your settings
          to notify you when a cookie is being set or updated or to block
          cookies altogether. Please consult the “Help” section of your browser
          for more information (e.g., Internet Explorer; Google Chrome; Mozilla
          Firefox; or Apple Safari). You can also manage the use of Flash
          technologies, including flash cookies and local storage objects, with
          the Flash management tools available at Adobe’s website. Please note
          that by blocking, disabling, or managing any or all cookies, you may
          not have access to certain features or offerings of the Services. For
          more information about our use of cookies, please see our{" "}
          <a
            href="https://iallo.io/cookies-policy/"
            className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </a>
          .
        </p>
      </section>

      {/* Online Analytics and Advertising */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">
          Online Analytics and Advertising
        </h3>
        <ol className="list-decimal list-inside flex flex-col gap-4  ">
          <li className="font-bold">ANALYTICS</li>
          <p>
            We may use third-party web analytics services (such as those of
            Google Analytics) on our Services to collect and analyze usage
            information through cookies and similar tools; engage in auditing,
            research, or reporting; assist with fraud prevention; and provide
            certain features to you. To prevent Google Analytics from using your
            information for analytics, you may install the Google Analytics
            Opt-out Browser Add-on by clicking here. If you receive email from
            us, we may use certain analytics tools, such as clear GIFs to
            capture data such as when you open our message or click on any links
            or banners our email contains. This data allows us to gauge the
            effectiveness of our communications and marketing campaigns.
          </p>

          <li className="font-bold">ANALYTICS</li>
          <p>
            The Services may integrate third-party advertising technologies that
            allow for the delivery of relevant content and advertising on the
            Services, as well as on other websites you visit and other
            applications you use. The ads may be based on various factors such
            as the content of the page you are visiting, information you enter
            such as your age and gender, your searches, demographic data,
            user-generated content, and other information we collect from you.
            These ads may be based on your current activity or your activity
            over time and across other websites and online services and may be
            tailored to your interests.
          </p>

          <p>
            Third parties may also place cookies or other tracking technologies
            on your computer, mobile phone, or other device to collect
            information about you as discussed above. These third parties (e.g.,
            ad networks and ad servers such as Google Analytics, DoubleClick and
            others) may also serve tailored ads to you as you use the Internet
            and Internet-connected applications, and access their own cookies or
            other tracking technologies on your computer, mobile phone, or other
            device you use to access the Services to assist in this activity.
          </p>

          <p>
            We neither have access to, nor does this Privacy Policy govern, the
            use of cookies or other tracking technologies that may be placed on
            your device you use to access the Services by such non-affiliated
            third parties. If you are interested in more information about
            tailored browser advertising and how you can generally control
            cookies from being put on your computer to deliver tailored
            advertising, you may visit the{" "}
            <a
              href="http://optout.networkadvertising.org/?c=1#!/"
              className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              Network Advertising Initiative’s Consumer Opt-Out link
            </a>
            , the{" "}
            <a
              href="http://optout.aboutads.info/?c=2#!/"
              className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              Digital Advertising Alliance’s Consumer Opt-Out link
            </a>
            , or{" "}
            <a
              href="http://www.youronlinechoices.eu/"
              className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              Your Online Choices
            </a>{" "}
            to opt-out of receiving tailored advertising from companies that
            participate in those programs. To opt out of Google Analytics for
            display advertising or customize Google display network ads, visit
            the{" "}
            <a
              href="https://adssettings.google.com/"
              className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Ads Settings page
            </a>
            . We do not control these opt-out links or whether any particular
            company chooses to participate in these opt-out programs. We are not
            responsible for any choices you make using these mechanisms or the
            continued availability or accuracy of these mechanisms. For
            additional ways to out of targeted advertising activities under
            applicable law, see the “Your Rights and Choices” section below.
            Please note that if you exercise the choices above, you will still
            see advertising when you use the Services, but it will not be
            tailored to you based on your online behavior over time.
          </p>

          <li className="font-bold">ANALYTICS</li>
          <p>
            We may use third-party web analytics services (such as those of
            Google Analytics) on our Services to collect and analyze usage
            information through cookies and similar tools; engage in auditing,
            research, or reporting; assist with fraud prevention; and provide
            certain features to you. To prevent Google Analytics from using your
            information for analytics, you may install the Google Analytics
            Opt-out Browser Add-on by clicking{" "}
            <a
              href="https://allaboutdnt.com/"
              className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . If you receive email from us, we may use certain analytics tools,
            such as clear GIFs to capture data such as when you open our message
            or click on any links or banners our email contains. This data
            allows us to gauge the effectiveness of our communications and
            marketing campaigns.
          </p>
        </ol>
      </section>

      {/* How We Disclose Your Information */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">
          How We Disclose Your Information
        </h3>

        <p className="flex flex-col gap-4">
          iAllo will disclose your information in the following ways:
          <article className="  flex flex-col gap-4">
            <strong>Affiliates and Subsidiaries</strong>
            <p>
              We may disclose information we collect within the iAllo family of
              companies to deliver products and services to you, ensure a
              consistent level of service, and enhance our products, services, and
              your customer experience.
            </p>
            <strong>Service Providers</strong>
            <p>
              We provide access to or disclose your information to select third
              parties who perform services on our behalf. They have access to
              perform these services but are prohibited from using your
              information for other purposes. They provide a variety of services
              to us, including billing, sales, marketing, product content and
              features, advertising, analytics, research, customer service, data
              storage, security, fraud prevention, payment processing, and legal
              services.
            </p>
            <strong>Protection of iAllo and Others</strong>
            <p>
              By using the Services, you acknowledge and agree that we may access,
              retain and disclose the information we collect and maintain about
              you if required to do so by law or in a good faith belief that such
              access, retention or disclosure is reasonably necessary to: (a)
              comply with legal process (e.g. a subpoena or court order); (b)
              enforce our Terms of Service, this Privacy Policy, or other
              contracts with you, including investigation of potential violations
              thereof; (c) respond to claims that any content violates the rights
              of third parties; (d) respond to your requests for customer service;
              and/or (e) protect the rights, property or personal safety of iAllo,
              its agents and affiliates, its users and/or the public. This
              includes exchanging information with other companies and
              organizations for fraud protection, and spam/malware prevention, and
              similar purposes.
            </p>
            <strong>Business Transfers</strong>
            <p>
              As we continue to develop our business, we may buy, merge, or
              partner with other companies. In such transactions (including in
              contemplation of such transactions), user information may be among
              the transferred assets. If a portion or all of our assets are sold
              or transferred to a third-party, customer information (including
              your email address) would likely be one of the transferred business
              assets. If such transfer is subject to additional mandatory
              restrictions under applicable laws, we will comply with such
              restrictions.
            </p>
            <strong>Public Forums</strong>
            <p>
              Certain features of our Services make it possible for you to post
              comments publicly that other users can see. Any information that you
              submit through such features is not confidential, and we may use it
              for any purpose (including in testimonials or other marketing
              materials). Any information you post openly in these ways will be
              available to the public at large and potentially accessible through
              third-party search engines. Accordingly, please take care when using
              these features.
            </p>
            <strong>Aggregate/Anonymous Information</strong>
            <p>
              From time to time, we may disclose Aggregate/Anonymous Information
              about use of the Services, such as by publishing a report on usage
              trends. The disclosure of such data is unrestricted.
            </p>
          </article>
        </p>
      </section>

      {/* Retention of Your Information */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">
          Retention of Your Information
        </h3>

        <p>
          We keep your information for no longer than necessary for the purposes
          for which it is processed. The length of time for which we retain
          information depends on the purposes for which we collected and use it
          and/or as required to comply with applicable laws.
        </p>
      </section>

      {/* How We Protect Your Information */}
      <section className="flex flex-col gap-4">
        <h3 className="font-semibold text-base">
          How We Protect Your Information
        </h3>

        <p>
          At iAllo Inc., the security of your information is paramount to us. We
          implement a comprehensive set of technical, administrative, and
          physical security measures designed to protect your personal data from
          unauthorized access, disclosure, alteration, and destruction.
        </p>

        <p>
          <em>Technical Safeguards</em>: We use encryption technologies to
          secure data during transmission over the Internet and while stored in
          our systems. Secure Socket Layer (SSL) technology is employed on our
          websites and apps to protect your information as it is transmitted to
          us. Access to your data is restricted to authorized personnel only,
          and we regularly review our technology to ensure it complies with
          industry standards.
        </p>

        <p>
          <em>Administrative Safeguards</em>: Our employees are trained on the
          importance of maintaining the confidentiality and security of your
          information. Access to personal data is strictly limited to personnel
          who need access to perform their job functions, and sensitive
          information is handled with utmost care.
        </p>

        <p>
          <em>Physical Safeguards</em>: We protect the physical security of our
          facilities and data centers by utilizing access controls and
          monitoring technologies. Only authorized personnel are allowed access
          to areas where personal information is stored, and we ensure that
          adequate measures are in place to prevent unauthorized physical
          access.
        </p>

        <p>
          <em>Ongoing Review and Testing</em>: We continuously assess our
          security practices and protocols to adapt to new threats and
          challenges. Regular security audits and penetration testing are
          conducted by independent security firms to help identify and remediate
          potential vulnerabilities.
        </p>

        <p>
          Despite our efforts, no method of transmission over the Internet or
          method of electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your personal information, we
          cannot guarantee its absolute security. We encourage you to take steps
          to protect your personal information online, including choosing strong
          passwords, enabling two-factor authentication where available, and not
          sharing your passwords with anyone.
        </p>
      </section>

      {/* Third Party Links and Features */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">
          Third Party Links and Features
        </h3>

        <p>
          The Services contain links to third-party websites such as social
          media sites, and also contain third-party plug-ins (such as the
          Facebook “like” button and Twitter “follow” button). If you choose to
          use these sites or features, you may disclose your information not
          just to those third-parties, but also to their users and the public
          more generally depending on how their services function. We are not
          responsible for the content or practices of those websites or
          services. The collection, use, and disclosure of your information will
          be subject to the privacy policies of the third party websites or
          services, and not this Privacy Policy. We urge you to read the privacy
          and security policies of these third-parties.
        </p>
      </section>

      {/* Children’s Privacy */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">Children’s Privacy</h3>

        <p>
          The Services are intended for general audiences and not for children
          under the age of 16. If we become aware that we have inadvertently
          collected “personal information” (as defined by the United States
          Children’s Online Privacy Protection Act) from children under the age
          of 16 without valid parental consent, we will take reasonable steps to
          delete it as soon as possible. We do not knowingly process data of EU
          residents under the age of 16 without parental consent. If we become
          aware that we have collected data from an EU resident under the age of
          16 without parental consent, we will take reasonable steps to delete
          it as soon as possible. We also comply with other age restrictions and
          requirements in accordance with applicable local laws.
        </p>
      </section>

      {/* Your Rights and Choices */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">Your Rights and Choices</h3>
        <ol className="list-decimal list-inside flex flex-col gap-4  ">
          {/* INFORMATION YOU PROVIDE TO US */}
          <li className=" font-semibold">GENERAL RIGHTS</li>

          <article className="flex flex-col gap-4">
            <ul className="list-disc list-inside   flex flex-col gap-1.5">
              <li>
                Inform you about the categories of personal information we
                collect or disclose about you; the categories of sources of such
                information; the business or commercial purpose for collecting
                your personal information; and the categories of third parties
                with whom we disclose personal information.
              </li>

              <li>
                Provide you access to and/or a copy of certain personal
                information we hold about you.
              </li>

              <li>Correct or update personal information we hold about you.</li>

              <li>Delete certain personal information we have about you.</li>

              <li>Object to or restrict processing of your information</li>

              <li>
                Revoke your consent for the processing of your information
              </li>

              <li>
                Transfer your information to a third-party provider of services
              </li>

              <li>
                Opt out of the processing of your personal information for
                purposes of profiling in furtherance of decisions that produce
                legal or similarly significant effects, if applicable.
              </li>

              <li>Limit the use of Sensitive personal information</li>

              <li>
                Non-discrimination – You have the right not to be subject to
                discriminatory treatment for exercising your rights under
                certain laws
              </li>
            </ul>

            <p>
              You also have the right to opt out of “sales” of your information
              and “sharing/use of your information for targeted advertising” as
              described below in subsection 3.
            </p>
            <p>
              As provided in applicable law, you also have the right to not be
              discriminated against for exercising your rights. Please note that
              certain information may be exempt from such requests under
              applicable law. For example, we need to retain certain information
              to provide our services to you. We also need to take reasonable
              steps to verify your identity before responding to a request,
              which may include, at a minimum, depending on the sensitivity of
              the information you are requesting and the type of request you are
              making, verifying your name and email address. You are also
              permitted to designate an authorized agent to submit certain
              requests on your behalf. In order for an authorized agent to be
              verified, you must provide the authorized agent with signed,
              written permission to make such requests or a power of attorney.
              We may also follow up with you to verify your identity before
              processing the authorized agent’s request. If you would like
              further information regarding your legal rights or would like to
              exercise any of them, please submit a privacy request by emailing
              us at{" "}
              <a
                href="mailto:hello@iallo.io"
                className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
              >
                hello@iallo.io
              </a>
              .
            </p>
            <p>
              Depending on applicable law, you may have the right to appeal our
              decision to deny your request, if applicable. If we deny your
              request, we will provide you with information on how to appeal the
              decision, if applicable, in our communications with you.
            </p>
          </article>

          <li className=" font-semibold">OPTING OUT OF MARKETING EMAILS</li>

          <article>
            You can unsubscribe from our marketing emails via the unsubscribe
            link provided in the emails. Please note that it may take us some
            time, consistent with our legal obligations, to process your
            request. Even if you opt out from receiving marketing messages from
            us, you will continue to receive administrative messages from us,
            such as order confirmations, updates to our policies and practices,
            or other communications regarding our relationship or transactions
            with you.
          </article>

          <li className=" font-semibold">
            NOTICE OF RIGHT TO OPT OUT OF SALES OF PERSONAL INFORMATION AND
            PROCESSING/SHARING OF PERSONAL INFORMATION FOR TARGETED SDVERTISING
            PURPOSES
          </li>

          <article className="flex flex-col gap-4">
            <p>
              As explained in the “Online Analytics and Advertising” section
              above, we provide certain information to third-party advertising
              providers for targeted advertising purposes, so that we can
              provide you with more relevant and tailored ads regarding our
              services, and use analytics partners to assist us in analyzing use
              of our services and our user/customer base. The disclosure of your
              information to these third parties to assist us in providing these
              services may be considered a “sale” of personal information under
              applicable law, or, the processing/sharing of your information for
              targeted advertising purposes.
            </p>

            <p>
              If you would like to opt out of our online disclosure such as
              through cookie and pixel technology of your information for
              purposes that could be considered “sales” for those third parties’
              own commercial purposes, or “sharing” for purposes of targeted
              advertising, please submit a sale opt-out request by emailing us
              at{" "}
              <a
                href="mailto:hello@iallo.io"
                className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
              >
                hello@iallo.io
              </a>
              . Please note that we do not knowingly sell the personal
              information of minors under 16 years of age without
              legally-required affirmative authorization.
            </p>

            <p>
              Please note that if you have a legally-recognized browser-based
              opt out preference signal turned on via your device browser, we
              recognize such preference in accordance with applicable law.
            </p>
          </article>
        </ol>
      </section>

      {/* Legal Bases for Use of Your Information */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">
          Legal Bases for Use of Your Information
        </h3>
        <p>
          The legal bases for using your information as set out in this Privacy
          Policy are as follows:
        </p>

        <ul className="list-disc list-inside   flex flex-col gap-1.5">
          <li>
            Where use of your information is necessary to perform our
            obligations under a contract with you (for example, to comply with:
            the terms of service of our websites which you accept by browsing
            the websites/registering; and/or our contract to provide our
            Services to you);
          </li>

          <li>
            Where use of your information is necessary for our legitimate
            interests or the legitimate interests of others (for example, to
            provide security for our website and applications; operate our
            business and our Services; make and receive payments; comply with
            legal requirements and defend our legal rights; prevent fraud and to
            know the customer to whom we are providing Services);
          </li>

          <li>
            Where we are required to process information in accordance with an
            EU Member State legal obligation; or
          </li>

          <li>
            Where we have your consent, in accordance with applicable law.
          </li>
        </ul>
      </section>

      {/* International Transfer */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">International Transfer</h3>
        <p>
          Your information, including personal data collected in the European
          Economic Area (“EEA”), United Kingdom (“UK”), and Switzerland, may be
          transferred to, stored, and processed by us in our facilities in the
          United States and in other countries where we operate. This includes
          transferring your data to third-party service providers who perform
          functions on our behalf.
        </p>

        <p>
          iAllo commits to resolve complaints about your privacy and our
          collection or use of your “personal data” as defined by the General
          Data Protection Regulation (GDPR). European Union or Swiss citizens
          with inquiries or complaints regarding this privacy policy should
          first contact iAllo at{" "}
          <a
            href="mailto:hello@iallo.io"
            className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
          >
            hello@iallo.io
          </a>
          .
        </p>
        <p>
          If you are located in the European Union or other regions with laws
          governing data collection and use that may differ from U.S. law,
          please note that you are transferring information to a country and
          jurisdiction that does not have the same data protection laws as your
          jurisdiction. As described above, we also may subcontract the
          processing of your data to, or otherwise disclose your data to, other
          members within the iAllo group of companies, service providers, and
          business partners in countries other than your country of residence,
          including the United States, in accordance with applicable law. Such
          third parties may be engaged in, among other things, the provision of
          Services to you, the processing of transactions and/or the provision
          of support services. By providing us with your information, you
          acknowledge any such transfer, storage or use. iAllo will take
          reasonable and appropriate steps necessary to ensure that any third
          party who is acting as a “data processor” under GDPR is processing the
          personal data we entrust to them in a manner that is consistent with
          the level of protection set forth by us in this Privacy Policy.
        </p>
        <p>
          If applicable, you may make a complaint to the data protection
          supervisory authority in the country where you reside. Alternatively
          you may seek a remedy through local courts if you believe your rights
          have been breached. We may ask you to verify your identity in order to
          help us respond efficiently to your request.
        </p>
        <p>
          By using our Services, you understand and consent to the processing of
          your information in the United States and other countries where we
          operate, where you may not have the same rights and protections as you
          do under local law.
        </p>
      </section>

      {/* California Privacy Rights */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">California Privacy Rights</h3>
        <p>
          Consumers residing in California are afforded certain additional
          rights with respect to their personal information under the California
          Consumer Privacy Act of 2018 (“CCPA”) as amended by the California
          Privacy Rights Act of 2020 (“CPRA”). If you are a California resident,
          these terms and conditions apply to you.
        </p>

        <em>Categories of Personal Information Collected.</em>

        <p>
          We collect the personal information detailed in the “Collection of
          Information” section above. This information includes the following
          categories of personal information as defined under California law:
        </p>

        <ul className="list-disc list-inside   flex flex-col gap-1.5">
          <li>Identifiers</li>
          <li>
            Personal Information described in California Section 1798.80(e)
          </li>
          <li>
            Characteristics of protected classifications under California or
            federal law
          </li>
          <li>Commercial Information</li>
          <li>Internet or other electronic activity information</li>
          <li>Geolocation data</li>
          <li>
            Audio, electronic, visual, thermal, olfactory, or similar
            information Inferences
          </li>
        </ul>

        <em>
          Categories of Personal Information Disclosed for Business Purposes.
        </em>

        <p>
          The personal information we process varies based upon our relationship
          and interactions with you. We may disclose the categories of personal
          information described above for the business purposes outlined in “Use
          of Information” section above. The recipients to whom we may disclose
          your personal information (and that may have received such information
          during the last 12-months) for business purposes include: our
          affiliates and subsidiaries; order processing, fulfillment, shipping,
          and logistics vendors; payment processors and financial institutions;
          analytics and research vendors; information technology vendors; fraud
          prevention and security vendors; vendors supporting legal, compliance,
          accounting, audits and other internal functions; and certain marketing
          and advertising vendors.
        </p>

        <em>Categories of Personal Information Shared with Third Parties.</em>

        <p>
          We share your personal information with third parties (such as
          marketing and advertising networks and social platforms) for
          behavioral advertising or with other third-party partners in
          accordance with your privacy settings and/or consent. These types of
          disclosures of your personal information may be deemed a “sale” or
          “sharing” of your personal information under California privacy laws.
          California privacy laws define a “sale” as disclosing or making
          available personal information to a third party in exchange for
          monetary or other valuable consideration, and “sharing” broadly
          includes disclosing or making available personal information to a
          third party for purposes of cross-context behavioral advertising or
          with affiliates and business partners. iAllo does not disclose, sell,
          share, or rent your personal information to third parties in exchange
          for money or compensation. Further, we do not “sell” or “share”
          sensitive personal information, nor do we “sell” or “share” any
          personal information about individuals who we know are under the age
          of 16. Below is an overview of categories of third parties with whom
          we share your information and the categories of personal information
          they receive:
        </p>

        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table
                  className="min-w-full border border-neutral-200 text-start text-sm font-light text-surface dark:border-white/10 dark:text-white">
                  <thead
                    className="border-b border-neutral-200 font-medium dark:border-white/10">
                    <tr>
                      <th
                        scope="col"
                        className="border-e border-neutral-200 px-6 py-4 dark:border-white/10">
                        <strong>Categories of Third Parties</strong>
                      </th>
                      <th
                        scope="col"
                        className="border-e border-neutral-200 px-6 py-4 dark:border-white/10">
                        <strong>Categories of Personal Information</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b border-neutral-200 dark:border-white/10">
                      <td
                        className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium dark:border-white/10">
                        Advertising and Social Platforms and Networks
                      </td>
                      <td
                        className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 dark:border-white/10">
                        <ul className="list-disc list-inside">
                          <li>Identifiers</li>
                          <li>Device information</li>
                          <li>Online Activity and Browsing Information</li>
                          <li>Inferences</li>
                        </ul>
                      </td>
                    </tr>
                    <tr className="border-b border-neutral-200 dark:border-white/10">
                      <td
                        className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 font-medium dark:border-white/10">
                        Third Party Partners Connected to Your account<br/> (in accordance with your privacy settings and/or your consent)
                      </td>
                      <td
                        className="whitespace-nowrap border-e border-neutral-200 px-6 py-4 dark:border-white/10">
                        <ol className="list-disc list-inside">
                          <li>Identifiers</li>
                          <li>Commercial Information</li>
                          <li>Inferences</li>
                        </ol>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        <strong>CCPA/CPRA Choices and Control.</strong>

        <p>
          California residents have the following rights with respect to their
          personal information:
        </p>

        <ul className="list-disc list-inside flex flex-col gap-1.5">
          <li>To know what personal information is collected</li>
          <li>To access personal information</li>
          <li>To correct personal information</li>
          <li>To delete personal information</li>
          <li>To opt-out of the “sale” of personal information</li>
          <li>
            To opt-out of “sharing” of personal information for cross-context
            behavioral advertising
          </li>
          <li>To limit the use of sensitive personal information</li>
          <li>To non-discrimination</li>
        </ul>

        <p>
          These rights are further detailed in the Your Rights and Choices
          section above. California residents may exercise their privacy rights
          by following the instructions below.
        </p>

        <em>Submitting a CCPA/CPRA Request</em>

        <p>
          Only you or a person registered with the California Secretary of State
          that you authorize to act on your behalf, may make a request related
          to your personal information. You may also make a verifiable consumer
          request on behalf of your minor child.
        </p>

        <ul className="list-disc list-inside flex flex-col gap-2">
          The verifiable consumer request must:
          <li className=" ">
            Provide sufficient information that allows us to reasonably verify
            you are the person about whom we collected personal information or
            an authorized representative of a California resident who wishes to
            make a request.
          </li>
        </ul>

        <p>
          We may not be able to respond to your request or provide you with
          personal information if we cannot verify your identity or authority to
          make the request and confirm the personal information relates to you.
          Making a verifiable consumer request does not require you to create an
          account with us. We will ask you for the email information that you
          used to interact with us or sign up for a loyalty, email or to make
          purchases to help verify your identity or authority to make the
          request and confirm that your information is in our system.
          Information provided to submit a request will only be used for request
          purposes.
        </p>

        <ul className="list-disc list-inside flex flex-col gap-2">
          California consumers may make requests by:
          <li className=" ">
            Emailing your request to{" "}
            <a
              href="mailto:hello@iallo.io"
              className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
            >
              hello@iallo.io
            </a>
            . In the subject line of your email, please write “CCPA Rights
            Request”.
          </li>
        </ul>

        <strong>Notice of Financial Incentive Programs.</strong>
        <p>
          We offer consumers the ability to become a member, which provides
          certain perks, rewards, and exclusive offers. We may also provide
          consumers with price discounts and coupons, as well as access to
          programs, such as sweepstakes, contest, or other similar promotional
          campaigns. These programs may be considered “financial incentives”
          programs under California law.
        </p>
        <p>
          For our programs, follow the directions provided with the program
          announcement to learn the terms of such programs and opt-in.
        </p>
        <p>
          When you sign up for one of these programs, we may ask you to provide
          your contact details (such as email address and/or phone number),
          collect information about your activities on our Site and your
          purchases, and draw inferences about your preferences, and collect
          other categories of personal information about you. The value of your
          personal information to us is reasonably related to the value of the
          perks, rewards, exclusive offers and discounts, or other benefits that
          are provided as part of the applicable program. We estimate the value
          of personal information by considering factors, including but not
          limited to, expenses related to the collection, storage, and retention
          of personal information in the context of the program and the expenses
          related to the administration of the program. The overall value of
          personal information to us is depends on what offerings you take
          advantage of and whether you opt-out of any offerings.
        </p>

        <ul className="list-disc list-inside flex flex-col gap-2">
          Your participation in any financial incentive program is optional, and
          you may withdraw from the program at any time:
          <li className=" ">
            For marketing-based incentives, you may unsubscribe by following the
            instructions in the communication.
          </li>
        </ul>

        <p>
          For membership-based incentives, you may delete your account through
          your account settings or via email to{" "}
          <a
            href="mailto:hello@iallo.io"
            className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
          >
            hello@iallo.io
          </a>
          . Please use the include “Financial Incentive Program Opt-Out” in your
          request.
        </p>
      </section>

      {/* Shine the Light Notice Rights */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">
          Shine the Light Notice Rights
        </h3>
        <p>
          Under California’s “Shine the Light” law (Cal. Civ. Code § 1798.83),
          California residents who provide us certain personal information are
          entitled to request and obtain from us, free of charge, information
          about the personal information (if any) we have shared with third
          parties for their own direct marketing use. Such requests may be made
          once per calendar year for information about any relevant third-party
          sharing in the prior calendar year. To submit a “Shine the Light”
          request, contact us through the contact information in the Contact
          section below, and include in your request a current California
          address and your attestation that you are a California resident.
        </p>
      </section>

      {/* Changes to our Privacy Policy */}
      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">
          Changes to our Privacy Policy
        </h3>
        <p>
          We reserve the right to amend this Privacy Policy at any time to
          reflect changes in the law, our data collection and use practices, the
          features of our Services, or advances in technology. We will make the
          revised Privacy Policy accessible through the Services, so you should
          review the Privacy Policy periodically. You can know if the Privacy
          Policy has changed since the last time you reviewed it by checking the
          “Date of Last Revision” included at the beginning of the document. If
          we make a material change to the Policy, you will be provided with
          appropriate notice in accordance with legal requirements. By
          continuing to use the Services, you are confirming that you have read
          and understood the latest version of this Privacy Policy.
        </p>
      </section>

      <section className="flex flex-col gap-2">
        <h3 className="font-semibold text-base">Contact Information</h3>
        <p>
          Please feel free to contact us if you have any questions about iAllo’s
          Privacy Policy or the information practices of the Services.
        </p>
        <p>
          You may contact us as follows: <br />
          iAllo Inc. <br />
          <a
            href="mailto:hello@iallo.io"
            className="text-blue-500 hover:text-black transition duration-300 ease-in-out"
          >
            hello@iallo.io
          </a>
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicySettingView;
