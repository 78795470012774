import React from 'react'
import { MdOutlineLaptop } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { RiMacFill } from "react-icons/ri";
import { MdPhoneIphone } from "react-icons/md";

const data = [
    {
    id: 1,
    icon:  MdOutlineLaptop,
    device: 'MacBook Pro 13',
    browser: 'Google Chrome',
    ip: '101.0.4951.54',
    location: 'Santa Monica, US',
    when: '1 day ago'
    },
    {
    id: 2,
    icon:  RiMacFill,
    device: 'iMac 2020',
    browser: 'Google Chrome',
    ip: '101.0.4951.54',
    location: 'Santa Monica, US',
    when: '1 day ago',
    },
    {
    id: 3,
    icon:  MdPhoneIphone,
    device: 'iPhone 11 Pro',
    browser: 'Google Chrome',
    ip: '101.0.4951.54',
    location: 'Santa Monica, US',
    when: '1 day ago',
    },
]

export default function BrowserExtensionCard() {
  return (
    <div className='flex flex-col w-full'>
        {data.map((item) => (
    <div key={item.id} className='w-full flex justify-between  border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-[101px] text-sm'>
        <div className='flex flex-col justify-center px-4 -mt-4'>
            <div className='flex items-center gap-3'>
            <div className='w-[32px] min-w-[32px] mt-4 text-white h-[32px] min-h-[32px] bg-black rounded-lg flex items-center justify-center'>
            {React.createElement(item.icon, { size: 19 })}
            </div>
            <p className='font-semibold '>{item.device}</p>
            </div>
            <div className='flex flex-col px-[43px] -mt-3'>

                <p className='text-[13px]'>{item.browser} -  <span> {item.ip}</span></p>
                <p className='text-gray-400'>{item.location} - <span> {item.when}</span></p>
            </div>
        </div>
        <IoCloseOutline size={25} className='m-4 hover:cursor-pointer' />
    </div>


))}
    
    </div>
  )
}
