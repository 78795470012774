import ReactDOM from "react-dom";
import ResultsContainer, {
  renderNoResultsMessage,
} from "../../search-results/ResultsContainer";
import SearchList from "../../search-results/SearchList";
import { TopNavSearchService } from "../../../services/search/topNav/TopNavSearchService";
import { AiChatApi } from "../../../services/chat/AiChat";
import { IoCloseOutline } from "react-icons/io5";
import Loader from "../../atom/loader/Loader";
const SearchModal = ({ closeModal, chatId }) => {
  const { updateAiChat } = AiChatApi((state) => state);
  const { loadingSearch, topNavSearchDetailsData, loadingSearchData } =
    TopNavSearchService((state) => state);

  const { data } = topNavSearchDetailsData;

  return ReactDOM.createPortal(
    <div className="absolute flex flex-col items-center justify-center w-full h-screen text-sm">
      <div className="fixed w-full h-screen bg-[#140f26a6] opacity-60"></div>
      <div className="relative w-[700px] max-[700px]:w-[400px] flex flex-col pb-4 border-[1px]  border-gray-100  dark:border-darkModeBorder  min-h-[495px] bg-white rounded-lg dark:bg-darkModeMain z-50">
        <div className="w-full h-[60px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex justify-between items-center">
          <h1 className="px-6 font-semibold uppercase">Search Result</h1>
          <IoCloseOutline
            onClick={() => closeModal(false)}
            size={25}
            className="mx-4 hover:cursor-pointer"
          />
        </div>
        <div className="overflow-auto h-[480px]">
          {loadingSearchData ? (
            <>{<Loader />}</>
          ) : (
            <>
              {data?.national_businesses?.length != 0 && (
                <div className="full">
                  <div className="w-full flex justify-between items-center pt-3 pb-1">
                    <h3 className="  text-[17px] px-6 font-bold">
                      Top Results
                    </h3>
                  </div>
                  <SearchList
                    businessDetails={data?.national_businesses}
                    selectedMarkerId={null}
                    previousPage="AiChat"
                    setSelectedMarkerId={(data) => {
                      const serachData =
                        data?.business_type == "google"
                          ? {
                              google_place_id: data?.id,
                            }
                          : {
                              business_id: data?.id,
                            };
                      updateAiChat(chatId, serachData).then((data) => {
                        closeModal(false);
                      });
                    }}
                  />
                </div>
              )}
              {data?.unsaved_businesses?.length != 0 ? (
                <div className="full">
                  <div className="w-full flex justify-between items-center pt-3 pb-1">
                    <h3 className="  text-[17px] px-6 font-bold">
                      All Results
                    </h3>
                    <p className="px-6 py-1">{`${
                      data?.unsaved_businesses?.length || 0
                    } results`}</p>
                  </div>{" "}
                  <div className="">
                    <SearchList
                      previousPage="AiChat"
                      businessDetails={data?.unsaved_businesses}
                      selectedMarkerId={null}
                      setSelectedMarkerId={(data) => {
                        const serachData =
                          data?.business_type == "google"
                            ? {
                                google_place_id: data?.id,
                              }
                            : {
                                business_id: data?.id,
                              };
                        updateAiChat(chatId, serachData).then((data) => {
                          closeModal(false);
                        });
                        //refresh the chat
                        //call on number and visit the website
                      }}
                    />
                  </div>
                </div>
              ) : (
                renderNoResultsMessage()
              )}
            </>
          )}
        </div>
      </div>

      {/* Only display this section if there are any filtered businesses. */}
    </div>,
    document.getElementById("portal-root")
  );
};

export default SearchModal;
