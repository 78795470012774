import { BiSearch, BiPlus } from "react-icons/bi";
import empty from "../../assets/laptop.svg";
import { useSearch } from "../../context/SearchContext";
import { useNavigate } from "react-router-dom";
import { getAccountType } from "../../routes/ProtectedRoutes";

const accountType = getAccountType();
const EmptySearchScreen = (props) => {
  const navigate = useNavigate();
  let { activePage, pageName, activeTab, navigatePage } = props || {};
  const { inputRef } = useSearch();
  const getMessage = (activeTab) => {
    switch (activeTab) {
      case "all":
        return "When you interact with the businesses, it will show tickets here.";
      case "ticket":
        return "Create a ticket, it will show tickets here.";
      case "open":
        return "When a ticket is opened it will show up here.";
      case "closed":
        return "When a ticket is closed it will show up here.";
      case "scheduled":
        return "When a call is scheduled it will show up here.";
      default:
        return "When you interact with the business, it will show up here.";
    }
  };
  const errorMessage = getMessage(activeTab);

  const headingMessage = (activeTab) => {
    switch (activeTab) {
      case "all":
        return " You have no tickets.";
      case "ticket":
        return " You have no tickets.";
      case "open":
        return "You have no open tickets.";
      case "closed":
        return "  You have no closed tickets.";
      case "scheduled":
        return "You have no scheduled tickets. ";
      default:
        return `You have no ${activePage} business `;
    }
  };
  const headErrorMessage = headingMessage(activeTab);

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-[600px] p-12 overflow-hidden">
      <img src={empty} alt="Empty" width={200} height={193} className="pt-40" />
      <h1 className="p-2 text-lg font-bold">
        {headErrorMessage}
        {pageName ? pageName + "." : ""}
      </h1>
      <p className="p-2">{errorMessage} </p>
      <button
        className="flex items-center justify-center gap-3 px-12 py-3 my-3 text-sm text-white uppercase rounded-full bg-ipurple"
        onClick={() => {
          if (accountType === "PROFESSIONAL") {
            navigate("/tickets/create-ticket");
          } else if (accountType === "PERSONAL") {
            if (navigatePage) {
              navigatePage("AI");
            } else if (inputRef.current) {
              inputRef.current.focus();
            }
          }
        }}
      >
        <BiPlus size={20} />
        Create a Ticket
      </button>
    </div>
  );
};

export default EmptySearchScreen;
