import { IoIosClose } from 'react-icons/io';

export default function PlaylistCard() {
  return (
    <div className='w-full'>
      <div className='flex items-center justify-between dark:bg-darkModeBG bg-white h-[75px] w-full border-b border-gray-100 dark:border-darkModeBorder'>
        <div className='flex items-center gap-3 p-5'>
          <div className='w-[45px] h-[45px] min-w-[45px] min-h-[45px] overflow-hidden rounded-md bg-gray-200 dark:bg-darkModeBorder'></div>
          <p className='text-black dark:text-white'>Apple Music R&B</p>
        </div>
        <IoIosClose size={30} className='m-5 text-black dark:text-white' />
      </div>

      <div className='flex items-center justify-between dark:bg-darkModeBG bg-white h-[75px] w-full border-b border-gray-100 dark:border-darkModeBorder'>
        <div className='flex items-center gap-3 p-5'>
          <div className='w-[45px] h-[45px] min-w-[45px] min-h-[45px] overflow-hidden rounded-md bg-gray-200 dark:bg-darkModeBorder'></div>
          <p className='text-black dark:text-white'>Playlist Placeholder</p>
        </div>
        <IoIosClose size={30} className='m-5 text-black dark:text-white' />
      </div>

      <div className='flex items-center justify-between dark:bg-darkModeBG bg-white h-[75px] w-full border-b border-gray-100 dark:border-darkModeBorder'>
        <div className='flex items-center gap-3 p-5'>
          <div className='w-[45px] h-[45px] min-w-[45px] min-h-[45px] overflow-hidden rounded-md bg-gray-200 dark:bg-darkModeBorder'></div>
          <p className='text-black dark:text-white'>Playlist Placeholder</p>
        </div>
        <IoIosClose size={30} className='m-5 text-black dark:text-white' />
      </div>
    </div>
  );
}
