import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackBtnIcon } from "../icons/Icons";
import { HOME_PAGE } from "../../utils/constants";
import { useSearch } from "../../context/SearchContext";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import { professionFlow } from "../../utils/constants";

const BackBtn = ({ pageType, view, setView }) => {
  const { getProfileDetails } = AuthPersonalPhone((state) => state);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setSearchViewToggle } = useSearch();
  const handleBack = async () => {
    //if user is entering personalInfo then on hitting back button a popup will appear to confirm
    if (view === "PersonalInfo") {
      const confirmLeave = window.confirm(
        "All the progress will be lost. Are you sure you want to go back?",
      );
      if (!confirmLeave) return;
      navigate("/");
      return;
    }
    if (view === "EnterEmailOTP") {
      setView("PersonalInfoPersonal");
      await localStorage.removeItem("authLogin");
    }

    // If the current pathname is the root, replace the current URL with HOME_PAGE,
    // otherwise navigate back one step.
    // Call the provided onBack function if it exist
    if (view === "EnterEmailOTP") {
      setView("PersonalInfoPersonal");
      getProfileDetails();
    } else if (view === "PersonalInfoPersonal") {
      setView("PhoneInput");
      navigate("/");
    } else if (view === "EnterPasswordLogin") {
      setView("PhoneInput");
      navigate("/");
    } else if (view === professionFlow?.otpConfirm) {
      setView(professionFlow?.personalInfo);
    } else {
      pathname === "/"
        ? (window.location.href = HOME_PAGE)
        : window.history.back();
    }
  };

  return (
    <>
      <div
        className="text-sm font-semibold flex items-center cursor-pointer dark:text-white"
        onClick={() =>
          pageType == "Search" ? setSearchViewToggle(false) : handleBack()
        }
      >
        <BackBtnIcon
          height={"30px"}
          width={"30px"}
          className="dark:fill-white"
        />

        <span className="pl-2">Back</span>
      </div>
    </>
  );
};

export default BackBtn;
