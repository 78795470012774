import { useEffect, useRef, useState } from "react";

import { CallDetailsService } from "../../../services/calls/CallDetailsService";
import { TicketsDetails } from "../../../services/tickets/TicketDetails";
import { KeypadIcon } from "../../icons/Icons";
import { CallIcon } from "../../icons/CallIcon";
import DirectoryImageInitials from "../../atom/directoryImageInitials";
import RoundButton from "../../../assets/roundwidebutton.webp";
import { getUserName, userDetails } from "../../../routes/ProtectedRoutes";
import { transcriptionDropdown } from "../../../utils/TimeDetails";
import InfiniteScroll from "react-infinite-scroll-component";
import { VITE_API_PAGE_LIMIT } from "../../../utils/constants";
import { PropTypes } from "prop-types";
import { HiChevronUpDown } from "react-icons/hi2";
import ScrollingLoader from "../../atom/loader/scrollingLoader";
import { SearchIcon } from "../../icons/SearchIcon";
import { IoIosClose, IoIosSearch } from "react-icons/io";
import noAvatar from "../../../assets/icons/noAvatar.svg";

TranscriptView.propTypes = {
  businessData: PropTypes.object,
};

export default function TranscriptView(props) {
  let { businessData } = props;
  const [activeTab, setActiveTab] = useState("All");
  const [transcriptionTime, setTranscriptionTime] = useState("");
  const currentPage = useRef(1);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectOpt, setSelectOpt] = useState("0");
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const userName = getUserName();
  const userDetailsProfile = userDetails();
  const dropdownRef = useRef(null);
  const {
    listAllTranscriptsDetailsApi,
    listAllTranscriptsDetails,
    loadingCallDetails,
    fetchNewTranscript,
    newTranscripts,
    transcriptLastPage,
    clearTranscriptsState,
    lastTimePage,
  } = CallDetailsService((state) => state);

  let transcriptsData = listAllTranscriptsDetails;

  const { listSingleTicketDetails } = TicketsDetails((state) => state);

  let { data } = listSingleTicketDetails || {};

  let callHistoryId = data?.call_history[0]?.id;
  const [currentTimePage, setCurrentTimePage] = useState(1);

  useEffect(() => {
    let clearTranscript = async () => {
      clearTranscriptsState();
      getTranscriptsApi();
    };
    clearTranscript();
  }, [callHistoryId, transcriptionTime]);

  const handleNewTranscript = () => {
    fetchNewTranscript({
      page: currentTimePage,
      business_id: businessData ? businessData?.id : "",
    }).then((data) => {
      setCurrentTimePage(currentTimePage + 1);
    });
  };

  useEffect(() => {
    if (businessData?.id) {
      handleNewTranscript();
    }
  }, []);

  const mergeMessages = (messages) => {
    const allMsgs = [];
    let mergedMessage = "";

    messages.forEach((message, index) => {
      if (message.speaker === "agent" || message.speaker === "user") {
        // Merge consecutive agent messages
        mergedMessage += message.message_text.trim();

        const nextMessage = messages[index + 1];
        // Check if the next message is also from same speaker
        if (nextMessage && nextMessage.speaker === message.speaker) {
          mergedMessage += "\n"; // Add a space between merged messages
        } else {
          // Push the merged text message when next message is not from same speaker
          allMsgs.push({
            ...message,
            message_text: mergedMessage,
          });
          // Reset the merged message for next iteration
          mergedMessage = "";
        }
      } else {
        // Push system messages directly
        allMsgs.push(message);
      }
    });

    return allMsgs;
  };

  let transcriptData = mergeMessages(transcriptsData || []);

  let getTranscriptsApi = () => {
    if (transcriptionTime || callHistoryId) {
      let userData = {
        callHistoryId: transcriptionTime || callHistoryId,
        page: currentPage.current,
        limit: VITE_API_PAGE_LIMIT,
      };

      listAllTranscriptsDetailsApi(userData).then((data) => {
        currentPage.current = parseInt(data?.meta?.current_page || 0) + 1;
      });
    }
  };

  const toggleSelect = () => {
    newTranscripts?.length != 0 && setIsOpen(!isOpen);
  };
  const handleSearchClick = () => {
    setIsInputOpen((prevState) => !prevState);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div className="flex flex-col w-full h-[calc(100dvh-340px)] ">
      <div className="flex overflow-y-auto flex-col w-full border-[1px] border-gray-100  dark:border-darkModeBorder  bg-white rounded-lg dark:bg-darkModeMain">
        <div className="flex items-center justify-between gap-6 p-4 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-[60px]">
          <div className="flex items-center gap-4 py-4 text-sm">
            <p className="text-sm font-bold uppercase">Transcript</p>
          </div>
          <div className="flex justify-center items-center">
            <span className="flex items-center px-2 text-[13px]">
              Select Transcript
            </span>
            <div className="border rounded-full relative h-[34px] min-w-[130px] max-w-[250px] flex items-center justify-between mr-2 ml-2">
              <div
                onClick={toggleSelect}
                className="px-2 cursor-pointer flex justify-between items-center w-full"
              >
                <p className="font-semibold text-[13px] items-center">
                  {selectedValue
                    ? transcriptionDropdown(selectedValue)
                    : newTranscripts?.length != 0
                      ? transcriptionDropdown(newTranscripts[0]?.created_at)
                      : "Select time"}
                </p>
                <HiChevronUpDown size={18} className="ml-1" />
              </div>

              {isOpen ? (
                <div
                  id="scrollableDropdown"
                  ref={dropdownRef}
                  className="h-[15rem] overflow-y-auto top-10 absolute z-[50]"
                >
                  <InfiniteScroll
                    dataLength={newTranscripts?.length}
                    next={handleNewTranscript}
                    hasMore={currentTimePage <= lastTimePage}
                    scrollableTarget="scrollableDropdown"
                    scrollThreshold={0.5}
                    loader={loadingCallDetails ? <ScrollingLoader /> : <></>}
                  >
                    <div className="px-3 border rounded options bg-white dark:bg-darkModeMain">
                      {newTranscripts?.map((option, index) => (
                        <div
                          key={option.id}
                          className={`${
                            selectOpt == index ? "dark:hover:bg-gray-700" : ""
                          }  my-1 px-1 hover:bg-blue-200 dark:hover:bg-gray-700 rounded py-1`}
                          onClick={() => {
                            currentPage.current = 1;
                            setSelectedValue(option?.created_at);
                            setTranscriptionTime(option.call_history_id);
                            setIsOpen(false);
                            setSelectOpt(index);
                          }}
                        >
                          {transcriptionDropdown(option?.created_at)}
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="inline-block m-3  h-[27px] min-h-[0.5em] w-0.5 self-stretch bg-gray-100 dark:bg-darkModeBorder"></div>
            <div className="mx-2">
              {!isInputOpen && (
                <button onClick={handleSearchClick} className="pr-4">
                  <IoIosSearch size={20} />
                </button>
              )}
              {isInputOpen && (
                <div className="relative flex items-center w-[400px] z-30">
                  <SearchIcon className="w-[25px] absolute left-2" />
                  <input
                    type="text"
                    id="ticket-search"
                    autoComplete="off"
                    placeholder="Search Transcript"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full  h-[45px] border-2 border-gray-100 dark:border-darkModeBorder bg-inherit rounded-lg px-10 placeholder:text-sm focus-within:ring focus-within:ring-ipurple"
                  />

                  <IoIosClose
                    onClick={handleSearchClick}
                    size={25}
                    className="absolute right-2"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          id="scrollableDivTranscript"
          className="overflow-y-auto h-[calc(100dvh-350px)]"
        >
          <InfiniteScroll
            dataLength={transcriptData?.length}
            next={getTranscriptsApi}
            hasMore={currentPage.current <= transcriptLastPage}
            scrollableTarget="scrollableDivTranscript"
            scrollThreshold={0.5}
            loader={loadingCallDetails ? <ScrollingLoader /> : <></>}
          >
            <div className="flex flex-col px-4 space-y-4 py-2 text-sm justify-start">
              {Array?.isArray(transcriptData) &&
                transcriptData?.length != 0 &&
                transcriptData?.map((x) =>
                  x?.speaker == "agent" ? (
                    <div key={x?.id}>
                      <div className="flex items-center gap-2">
                        <DirectoryImageInitials
                          imageUrl={businessData?.upload?.file_path || ""}
                          alt={businessData?.name}
                          name={
                            businessData?.name ? businessData?.name : "Agent"
                          }
                          boxColor={businessData?.business_color?.toLowerCase()}
                          className={`!w-[15px] !h-[15px] min-w-[24px] min-h-[24px] rounded-md border-none text-sm`}
                        />
                        <p className="font-semibold">Agent</p>
                      </div>

                      <div className="font-bold pl-[28px]">
                        {" "}
                        {x?.message_text.split("\n")?.map((x, index) => (
                          <p className="p-2" key={index}>
                            {x}
                          </p>
                        ))}
                      </div>
                    </div>
                  ) : x?.speaker == "user" ? (
                    <div className="items-start min-w-[1400px]  dark:bg-darkModeBG min-h-[75px] py-2 flex text-sm justify-start -ml-[27px]">
                      <div className="flex items-start mx-6 mt-[4px]">
                        {userDetailsProfile?.upload?.file_path ? (
                          <img
                            src={userDetailsProfile.upload.file_path}
                            className="rounded-full w-6 h-6 ml-[6px]"
                            alt="Avatar"
                          />
                        ) : (
                          <img
                            className="h-6 w-6 rounded-full ml-[6px]"
                            src={noAvatar}
                            alt="No Avatar"
                          />
                        )}
                        <div className="mr-2 ">
                          <div className="flex items-center ">
                            <p className="font-bold ml-[9px]">{userName}</p>

                          </div>
                          <div className="px-1 py-2 text-justify  text-sm flex mr-4 flex-col mb-[2px]">
                            {" "}
                            {x?.message_text.split("\n")?.map((x, index) => (
                              <p
                                className="text-left  mb-[2px] font-bold ml-1"

                                key={index}
                              >
                                {x}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : x?.speaker == "system" ? (
                    <div className="flex items-center gap-2 mt-3 -mb-1 font-bold text-[14px]">
                      {x?.message_type == "call-started" ||
                      x?.message_type == "call-ended" ||
                      x?.message_type == "call-hold" ||
                      x?.message_type == "call-connected" ||
                      x?.message_type == "call-human-connected" ||
                      x?.message_type == "call-ivr" ||
                      x?.message_type == "call-music" ||
                      x?.message_type == "call-no-answer" ||
                      x?.message_type == "call-failed" ||
                      x?.message_type == "call-busy" ? (
                        <CallIcon className="w-[25px] text-black " />
                      ) : x?.message_type == "call-dial" ? (
                        <KeypadIcon className="w-[25px] text-black dark:text-white " />
                      ) : x?.message_type == "call-ended" ? (
                        <CallIcon className="w-[25px] text-black " />
                      ) : null}
                      <p>{x?.message_text}</p>
                    </div>
                  ) : (
                    <div className="font-semibold">{x?.message_text}</div>
                  ),
                )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
