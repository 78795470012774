import React from 'react'
import {FaApple, FaSpotify, FaAudible} from 'react-icons/fa'

export default function ConnectMusicLibrary() {
  return (
    <div className='flex flex-col w-full px-6'>
       <h1 className='text-[16px] font-semibold'>Connect Your Music Library</h1>
      <p className='py-3'>Replace the default on-hold music with your own albums, playlists or audiobooks.</p>
      <div className='flex items-center w-full gap-4 my-2'>
        <button className='w-1/3  h-[45px] rounded-full bg-black hover:font-bold text-white  text-[12px] uppercase flex gap-3 justify-center items-center'>
          <FaApple size={20} color='white' />
          <p>Connect Apple Music</p>
        </button>
        <button className='w-1/3  h-[45px] rounded-full bg-black text-white hover:font-bold text-[12px] uppercase flex gap-3 justify-center items-center'>
          <FaSpotify size={20} color='#00DA5A' />
          <p>Connect Spotify</p>
        </button>
        <button className='w-1/3 h-[45px] rounded-full bg-black text-white hover:font-bold text-[12px] uppercase flex gap-3 justify-center items-center'>
          <FaAudible size={20} className='text-orange-400' />
          <p>Connect Audible</p>
        </button>
      </div>
    </div>
  )
}
