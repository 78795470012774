import { useEffect, useState } from "react";
import TopNavBar from "./components/navigation/TopNavBar";
import Subnav from "./components/navigation/Subnav";
import Sidebar from "./components/navigation/Sidebar";
import { useSearch } from "./context/SearchContext";
import { SidebarVisibilityProvider } from "./context/SidebarVisibilityContext";
import SearchContainer from "./components/search-results/SearchContainer";
import { useLocation, useNavigate } from "react-router-dom";
import ExtensionInstallationFooter from "./components/navigation/Footer";

export default function RootLayout(props) {
  const { children } = props || {};
  const { searchViewToggle, setSearchViewToggle } = useSearch(); // Using the context to get the current search term
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleActiveContainerChange = (containerName) => {
    setSearchViewToggle(false);
  };
  const location = useLocation();
  const [extensionInstalled, setExtensionInstalled] = useState(false);

  useEffect(() => {
    window.addEventListener("isExtensionUpdated", (event) => {
      const isExtensionInstalled =
        localStorage.getItem("isExtension") === "true";
      setExtensionInstalled(isExtensionInstalled);
    });
  }, []); // Run once on mount

  useEffect(() => {
    if (location.state === "search") {
      const showSearch = async () => {
        await setSearchViewToggle(true);
        await navigate(pathname, { replace: true });
      };
      showSearch();
    }
  }, [location, pathname, navigate, setSearchViewToggle]);

  return (
    <SidebarVisibilityProvider>
      <div
        className={
          pathname === "/dashboard"
            ? "!bg-white z-40 flex flex-col justify-between w-full min-h-screen font-sans dark:bg-darkModeBG"
            : "bg-ibluebg z-40 flex flex-col justify-between w-full min-h-screen font-sans dark:bg-darkModeBG"
        }
      >
        <div id="portal-root" className="z-[9999] w-full h-full"></div>
        <TopNavBar onActiveContainerChange={handleActiveContainerChange} />
        <Sidebar />
        {/* Conditionally render the footer if the extension is not installed */}
        {!extensionInstalled && (
          <ExtensionInstallationFooter
            setExtensionInstalled={setExtensionInstalled}
          />
        )}
        {searchViewToggle ? (
          <>
            <SearchContainer />
          </>
        ) : (
          <div className="h-full dark:bg-darkModeBG">{children}</div>
        )}
      </div>
    </SidebarVisibilityProvider>
  );
}
