import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { LuClock } from "react-icons/lu";

const TimePicker = (props) => {
  let { onClose, scheduledTime, confirmSelectedDate, callType } = props || {};
  const dropdownRef = useRef(null);
  const hoursRef = useRef(null);

  const [hour, setHour] = useState(
    dayjs(scheduledTime).hour() % 12 || 12 // Convert 24-hour to 12-hour format
  );
  const [minute, setMinute] = useState(dayjs(scheduledTime)?.minute());
  const [meridian, setMeridian] = useState(
    dayjs(scheduledTime).hour() >= 12 ? "PM" : "AM"
  );

  const handleConfirm = () => {
    const formattedTime = `${formatNumber(hour % 12 || 12)}:${formatNumber(
      minute
    )} ${meridian}`;
    onClose(formattedTime); // Pass the formatted time back to the parent component
  };
  const adjustHours = (increment) => {
    if (!hoursRef.current) {
      hoursRef.current = dayjs(scheduledTime).hour();
    }

    let newHour = hoursRef.current
      ? hoursRef.current + increment
      : hour + increment;

    if (newHour >= 24) newHour = 0;
    if (newHour < 0) newHour = 23;
    hoursRef.current = newHour;
    setHour(newHour);

    setMeridian(newHour >= 12 ? "PM" : "AM");
  };

  const adjustMinutes = (increment, option) => {
    let newMinute =
      parseInt(minute) + parseInt(option == "increase" ? increment : -1);
    if (newMinute >= 60) newMinute = 0;
    if (newMinute < 0) newMinute = 59;
    setMinute(newMinute);
  };

  const toggleMeridian = () => {
    setMeridian(meridian === "AM" ? "PM" : "AM");
    // setHour(meridian !== "AM" ? parseInt(hour) + 12 : Math.abs(parseInt(hour) - 12));
  };

  const formatNumber = (number) => number.toString().padStart(2, "0");
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleConfirm();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div
      className="w-[430px] pb-2 shadow-black shadow-xl flex items-center justify-center flex-col bg-white dark:bg-darkModeMain border-[1px]  border-gray-100  border-gray-200 dark:border-darkModeBorder rounded-lg text-sm"
      ref={dropdownRef}
    >
      <div className="w-full h-[55px] border-b border-gray-100-[1px] border-gray-100 bg-[#4c5fef0c] dark:border-darkModeBorder flex items-center justify-between text-ipurple rounded-t-lg">
        <h1 className="px-4 font-semibold">{`${formatNumber(
          hour % 12 || 12
        )}:${formatNumber(minute)} ${meridian}`}</h1>
        <LuClock size={22} className="mx-4" />
      </div>

      <div className="flex justify-center items-center w-full text-[17px] gap-8 mt-4">
        <div className="flex flex-col items-center space-y-3">
          <button onClick={() => adjustHours(1)}>
            <IoChevronUp size={25} />
          </button>
          <time>{formatNumber(hour % 12 || 12)}</time>
          <button onClick={() => adjustHours(-1)}>
            <IoChevronDown size={25} />
          </button>
        </div>
        <p className="text-2xl">:</p>
        <div className="flex flex-col items-center space-y-3">
          <button onClick={() => adjustMinutes(1, "increase")}>
            <IoChevronUp size={25} />
          </button>
          <time>{formatNumber(minute)}</time>
          <button onClick={() => adjustMinutes(-1, "decrease")}>
            <IoChevronDown size={25} />
          </button>
        </div>
        <div className="flex flex-col items-center space-y-3">
          <button onClick={toggleMeridian}>
            <IoChevronUp size={25} />
          </button>
          <p>{meridian}</p>
          <button onClick={toggleMeridian}>
            <IoChevronDown size={25} />
          </button>
        </div>
      </div>
      <button
        className={`w-2/3 py-2 my-4 font-semibold mb-8 rounded-full  bg-ipurple text-white`}
        onClick={handleConfirm}
      >
        {"Confirm Time"}
      </button>
    </div>
  );
};

export default TimePicker;
