import PropTypes from "prop-types";
Checkbox.propTypes = {
  actionResults: PropTypes.array.isRequired,
  allResults: PropTypes.array.isRequired,
  actionSavedResults: PropTypes.array.isRequired,
  savedBusiness: PropTypes.array.isRequired,
  actionRecentResults: PropTypes.array.isRequired,
  recentBusiness: PropTypes.array.isRequired,
};

export default function Checkbox({
  actionResults,
  allResults,
  actionSavedResults,
  savedBusiness,
  actionRecentResults,
  recentBusiness,
}) {
  return (
    <fieldset>
      <div className="space-y-2">
        <div className="relative flex items-start">
          <div className="flex items-center h-6">
            <input
              id="allResults"
              aria-describedby="allResults-description"
              name="allResults"
              type="checkbox"
              checked={allResults}
              onChange={() => actionResults(!allResults)}
              className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label
              htmlFor="allResults"
              className="font-medium text-gray-900 dark:text-neutral-100"
            >
              All results
            </label>{" "}
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex items-center h-6">
            <input
              id="savedBusiness"
              aria-describedby="savedBusiness-description"
              name="savedBusiness"
              type="checkbox"
              checked={savedBusiness}
              onChange={() => actionSavedResults(!savedBusiness)}
              className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label
              htmlFor="savedBusiness"
              className="font-medium dark:text-neutral-100 text-gray-900"
            >
              Saved Businesses
            </label>{" "}
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex items-center h-6">
            <input
              id="recentBusiness"
              aria-describedby="recentBusiness-description"
              name="recentBusiness"
              type="checkbox"
              checked={recentBusiness}
              onChange={() => actionRecentResults(!recentBusiness)}
              className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label
              htmlFor="recentBusiness"
              className="font-medium dark:text-neutral-100 text-gray-900"
            >
              Recent
            </label>{" "}
          </div>
        </div>
      </div>
    </fieldset>
  );
}
