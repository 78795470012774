import ReactGoogleMap from "./ReactGoogleMap";
import { TopNavSearchService } from "../../services/search/topNav/TopNavSearchService";
import { LiaLocationArrowSolid } from "react-icons/lia";
import { IoExpand } from "react-icons/io5";

// eslint-disable-next-line react/prop-types
export default function MapContainer({
  selectedMarkerId,
  setSelectedMarkerId,
  setTriggerApi,
}) {
  const { topNavSearchDetailsData } = TopNavSearchService((state) => state);
  const { data } = topNavSearchDetailsData;

  return (
    <div className="relative flex items-center justify-center object-cover w-full h-full ">
      <ReactGoogleMap
        data={data}
        selectedMarkerId={selectedMarkerId}
        setSelectedMarkerId={(data) => setSelectedMarkerId(data)}
        setTriggerCenterMap={(data) => setTriggerApi(data)}
      />
    </div>
  );
}
