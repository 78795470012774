import { IoOptionsOutline } from "react-icons/io5";
import ActivityCard from "../ActivityCard";
import CallHistoryCard from "../CallHistoryCard";
import { TicketsDetails } from "../../../services/tickets/TicketDetails";
import { useEffect, useRef, useState } from "react";

export default function ActivityView() {
  const {
    listSingleTicketDetails,
    listTicketActivitesDetailsApi,
    listTicketActivitesDetails,
    listCallHistoryBusinessDetailsApi,
    listCallHistoryBusinessDetails,
    historyLastPage,
  } = TicketsDetails((state) => state);

  const [setActivitiesDetails] = useState([]);

  let [currentPage, setCurrentPage] = useState(1);

  let { data } = listSingleTicketDetails || {};

  let ticket_id = data?.id;
  let business_id = data?.business_id;
  let ticketActivitiesdata = listTicketActivitesDetails?.data;
  let getActivityDetailsApi = () => {
    if (ticket_id) {
      listTicketActivitesDetailsApi({
        ticket_id: ticket_id,
      }).then((data) => {
        if (data?.data && data?.data?.length != 0) {
          setActivitiesDetails(data?.data || []);
        }
      });
    }
  };
  useEffect(() => {
    getActivityDetailsApi();
  }, [ticket_id]);

  let getCallHistoryBusinessDetailsApi = () => {
    if (ticket_id) {
      listCallHistoryBusinessDetailsApi({
        ticket_id: ticket_id,
        business_id: business_id,
        page: currentPage,
      }).then(() => {
        setCurrentPage(currentPage + 1);
      });
    }
  };
  useEffect(() => {
    getCallHistoryBusinessDetailsApi();
  }, [ticket_id]);

  const divRef = useRef(null);
  const handleScroll = () => {
    const container = divRef?.current;
    if (
      container?.scrollHeight - container?.scrollTop ===
        container?.clientHeight &&
      currentPage <= historyLastPage
    ) {
      getCallHistoryBusinessDetailsApi(currentPage + 1);
    }
  };

  useEffect(() => {
    divRef?.current?.addEventListener("scroll", handleScroll);

    return () => {
      divRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage]);

  return (
    <div className="flex items-center w-full gap-3 ">
      <div className="hidden h-[600px] overflow-y-scroll flex-col w-1/2 lg:w-2/3 my-2 border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg bg-white dark:bg-darkModeMain">
        <div className="flex justify-between items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-[60px] w-full">
          <p className="p-6 text-sm font-semibold uppercase">Activity</p>
          <IoOptionsOutline size={20} className="mr-6" />
        </div>
        <ActivityCard
          ticketActivitiesdata={ticketActivitiesdata}
          ticketId={ticket_id}
        />
      </div>
      <div
        ref={divRef}
        onScroll={handleScroll}
        className="flex flex-col w-full my-2 mb-8 overflow-y-scroll"
      >
        <div className="hidden  items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-[60px] min-h-[60px] w-full">
          <p className="hidden px-6 text-sm font-semibold uppercase ">
            Call History
          </p>
        </div>
        <CallHistoryCard
          ticketCallHistoryDetailsData={listCallHistoryBusinessDetails}
        />
      </div>
    </div>
  );
}
