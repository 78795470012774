import React from 'react'
import NotificationsContainer from '../../Notifications/NotificationsContainer'

export default function InboxView() {
  return (
    <div className='w-full -mt-2'>
      <NotificationsContainer />
    </div>
  )
}
