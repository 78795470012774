import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

import timeZoneData from "../../utils/time-zone/timeZone.json";

export default function TimezoneSelector(props) {
  // Default to Pacific Time
  let { onClose, onTimezoneSelect, timezone } = props || {};
  const [selectedTimezone, setSelectedTimezone] = useState(timezone);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSelectTimezone = (data) => {
    setSelectedTimezone(data?.text);

    onTimezoneSelect({
      name: data?.text,
      offset: data?.offset,
    });
    onClose();
  };

  const filteredTimezones =
    timeZoneData?.length === 0
      ? timeZoneData
      : timeZoneData?.filter((zone) =>
          zone.text?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );

  return (
    <div className="w-[470px] relative h-[610px] bg-white dark:bg-darkModeMain rounded-lg flex flex-col border-[1px]  border-gray-100  dark:border-darkModeBorder">
      <div className="fixed w-[470px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-[60px] min-h-[60px]">
        <div className="flex items-center justify-between w-full p-4">
          <p className="font-semibold uppercase">Select Timezone</p>
          <IoClose
            onClick={onClose}
            size={25}
            className="hover:cursor-pointer"
          />
        </div>
      </div>
      <div className="w-full h-[60px] items-center border-b border-gray-100-[1px] border-gray-100   dark:border-darkModeBorder mt-[60px] relative flex">
        <input
          type="text"
          placeholder="Type to search"
          className="w-full bg-inherit h-[50px] px-10 placeholder:text-gray-400 text-sm border-none rounded focus:ring-ipurple"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <IoIosSearch
          className="absolute left-3 top-[18px] text-gray-400"
          size={20}
        />
      </div>
      <div className="flex font-semibold overflow-y-scroll grow flex-col text-sm items-center w-full divide-y-[1px] dark:divide-darkModeBG">
        {timeZoneData?.length !== 0 &&
          filteredTimezones?.map((data, index) => (
            <button
              key={index}
              className={`w-full text-inherit px-4 flex justify-between items-center min-h-[60px] hover:bg-lightBg dark:hover:bg-darkModeBG ${
                selectedTimezone === data?.text
                  ? "bg-lightBg dark:bg-darkModeBG"
                  : ""
              }`}
              onClick={() => handleSelectTimezone(data)}
            >
              <p>
                {`${data.text}`}{" "}
                {/* <span>{data.name?.split("/").pop().replace(/_/g, " ")}</span> */}
              </p>
              {selectedTimezone === data.text && (
                <FaCheck size={20} className="text-ipurple" />
              )}
            </button>
          ))}
      </div>
    </div>
  );
}
