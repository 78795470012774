import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import locationMarker from "../../../src/assets/locationMarker.svg";
import { TopNavSearchService } from "../../services/search/topNav/TopNavSearchService";
import SearchCard from "./SearchCard";

const ReactGoogleMap = (props) => {
  const {
    data,
    locationDataBusinessProfile,
    setSelectedMarkerId,
    selectedMarkerId,
    setTriggerCenterMap,
  } = props || {};

  const { getLocationDetails } = TopNavSearchService((state) => state);
  const [map, setMap] = useState(null);
  const businessDetails = data?.unsaved_businesses || data;
  const { mySearchData, locationDetailsData } = TopNavSearchService(
    (state) => state
  );
  const [selectedMarkerDetails, setSelectedMarkerDetails] = useState({});
  const { lat, lng } = mySearchData || {};

  const mapContainerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );

  const center = useMemo(
    () => ({
      lat: locationDetailsData?.lat || lat,
      lng: locationDetailsData?.lng || lng,
    }),
    [locationDetailsData, lat, lng]
  );

  const handleCenterMap = useCallback(async () => {
    if (map) {
      const center = await map.getCenter();
      const lat = center.lat();
      const lng = center.lng();

      await getLocationDetails({
        lat: lat,
        lng: lng,
      });
      await setTriggerCenterMap(true);
    }
  }, [map, getLocationDetails, setTriggerCenterMap]);

  useEffect(() => {
    setSelectedMarkerDetails(
      businessDetails?.filter(
        (data) => data?.business?.id === selectedMarkerId
      )?.[0]?.business || {}
    );
  }, [businessDetails, selectedMarkerId]);

  const infoWindowRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        infoWindowRef.current &&
        !infoWindowRef.current.contains(event.target)
      ) {
        setSelectedMarkerId(null); // Replace with appropriate function
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [setSelectedMarkerId]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const encodeSVG = useCallback((rawSvgString) => {
    const symbols = /[\r\n%#()<>?\[\\\]^`{|}]/g;

    rawSvgString = rawSvgString
      .replace(/'/g, '"')
      .replace(/>\s+</g, "><")
      .replace(/\s{2,}/g, " ");

    return (
      "data:image/svg+xml;utf-8," +
      rawSvgString.replace(symbols, encodeURIComponent)
    );
  }, []);

  return (
    <>
      <button
        className="absolute z-50 shadow-black px-4 text-[10px] h-[35px] tracking-wide top-0 mt-5 flex justify-center font-semibold items-center bg-white dark:bg-darkModeMain rounded-full shadow-lg"
        onClick={handleCenterMap}
      >
        SEARCH IN THIS AREA
      </button>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={13}
        center={center}
        options={{
          styles: [
            {
              featureType: "poi.business",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
        onLoad={onLoad}
      >
        {Array.isArray(businessDetails) &&
          businessDetails.length !== 0 &&
          businessDetails.map(({ business }) => (
            <Marker
              key={business.id}
              position={{
                lat: business?.business_locations?.[0]?.lat,
                lng: business?.business_locations?.[0]?.lng,
              }}
              icon={{
                url: locationMarker,
                scaledSize: new window.google.maps.Size(36, 36),
                anchor: new window.google.maps.Point(18, 18),
              }}
              onClick={() => {
                setSelectedMarkerId(business.id);
              }}
            />
          ))}
        {Object.keys(selectedMarkerDetails).length !== 0 &&
          selectedMarkerDetails.business_locations?.[0]?.lat &&
          Array.isArray(selectedMarkerDetails.business_locations) && (
            <InfoWindow
              options={{
                zIndex: 9999,
                pixelOffset: new window.google.maps.Size(0, -20),
              }}
              position={{
                lat: selectedMarkerDetails.business_locations[0].lat,
                lng: selectedMarkerDetails.business_locations[0].lng,
              }}
              disableAutoPan={false}
            >
              <div ref={infoWindowRef}>
                <SearchCard
                  key={selectedMarkerDetails.id}
                  business={selectedMarkerDetails}
                  pageType="map"
                  selectedMarkerId={selectedMarkerDetails.id}
                  setSelectedMarkerId={() => {}}
                />
              </div>
            </InfoWindow>
          )}
      </GoogleMap>
    </>
  );
};

export default ReactGoogleMap;
