import React from "react";
import ReactDOM from "react-dom/client";
import CallPopup from "../calls/CallPopup";
export const checkExtensionStatus = () => {
  const extensionStatus = localStorage.getItem("isExtension");

  setTimeout(() => {
    if (
      extensionStatus === false ||
      extensionStatus === undefined ||
      !extensionStatus
    ) {
      const portalRoot = document.getElementById("portal-root");

      if (portalRoot) {
        const root = ReactDOM.createRoot(portalRoot);
        const closePopup = () => {
          root.unmount();
        };
        root.render(<CallPopup onClose={closePopup} />);
      }
    }
  }, 500);
};
