import BusinessScore from "../directory/BusinessScore";
import { BsFillBookmarkFill, BsBookmark } from "react-icons/bs";
import { LuMapPin } from "react-icons/lu";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearch } from "../../context/SearchContext";
import { TopNavSearchService } from "../../services/search/topNav/TopNavSearchService";
import { getCurrentLocation } from "../../utils/constants";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import { sendMixpanelEvent } from "../../services/mixpanelService";

const SearchCard = (props) => {
  const [businessCardData, setBusinessCardData] = useState({});
  const { business, selectedMarkerId, setSelectedMarkerId, previousPage } =
    props || {};

  const [locationDetail, setLocationDetail] = useState({ lat: "", long: "" });
  const navigate = useNavigate();

  const { setSearchViewToggle } = useSearch();
  useEffect(() => {
    setBusinessCardData(business || {});
  }, [business]);

  const businessUrl = businessCardData?.business_contacts?.filter(
    (option) => option.account_type?.name == "Website"
  );
  const { distance, address } = businessCardData?.business_locations?.[0] || [];
  const { savedBusinessApi, unsavedBusinessApi, fetchBusinessDetailsApi } =
    DirectoryDetails((state) => state);

  const { topNavDirectorySearchApi, locationDetailsData } = TopNavSearchService(
    (state) => state
  );
  const { searchTerm, inputValueRef } = useSearch();
  const handleUnsaved = async (businessId) => {
    sendMixpanelEvent("Business Unsaved", { Type: "All Results" });
    if (businessId?.business_type == "google") {
      await unsavedBusinessApi({
        google_place_id: businessId?.id,
      });
    } else {
      await unsavedBusinessApi({
        business_id: businessId?.id,
      });
    }
    await setBusinessCardData((prev) => ({
      ...prev,
      saved_business_id: false,
    }));
  };

  useEffect(() => {
    getCurrentLocation(
      (lat, lon) => {
        setLocationDetail({ lat: lat, lng: lon });
      },
      (error) => {
        // console.error("Error getting location", error);
      }
    );
  }, []);
  const handlesaved = async (businessData) => {
    sendMixpanelEvent("Business Saved", { Type: "All Results" });
    if (businessData?.business_type == "google") {
      await savedBusinessApi({
        google_place_id: businessData?.id,
      });
    } else {
      savedBusinessApi({
        business_id: businessData?.id,
      });
    }
    setBusinessCardData((prev) => ({ ...prev, saved_business_id: true }));
  };
  const markerRef = useRef(null);

  useEffect(() => {
    if (selectedMarkerId === business?.id) {
      markerRef.current?.focus();
    }
  }, [selectedMarkerId, business?.id]);
  return (
    <div
      ref={markerRef}
      tabIndex={-1}
      className={`${
        selectedMarkerId == business?.id
          ? `bg-lightBg border-[1px]  border-gray-100  text-sm w-full h-[95px] px-4 flex justify-center items-center hover:bg-lightBg  !cursor-default dark:border-darkModeBorder overflow-hidden rounded-lg ${
              props?.pageType != "map" ? "m-1" : "m-0 h-[93px] !w-[380px]"
            }`
          : `border-[1px]  border-gray-100 bg-white text-sm w-full h-[95px] px-4 flex justify-center dark:bg-darkModeBG items-center hover:bg-lightBg !cursor-default dark:border-darkModeBorder overflow-hidden rounded-lg ${
              props?.pageType != "map" ? "m-1" : "m-0 !w-[380px]"
            }`
      }`}
      onClick={() => {
        if (previousPage == "AiChat") {
          setSelectedMarkerId(business);
        } else if (business?.business_type == "google") {
          fetchBusinessDetailsApi({
            googlePlaceId: business?.id,
          }).then((response) => {
            if (response?.data?.id) {
              // window.open(`/business/${response?.data?.id}`, "_blank");
              setSearchViewToggle(false);

              navigate(`/business/${response?.data?.id}`, {
                state: "fromSearch",
              });
            }
          });
        } else {
          // window.open(`/business/${business?.id}`, "_blank");
          setSearchViewToggle(false);

          navigate(`/business/${business?.id}`, {
            state: "fromSearch",
          });
          sendMixpanelEvent("Business Details Viewed");
        }
      }}
    >
      <div
        className={
          props?.pageType == "map"
            ? "flex items-center justify-between w-full h-full "
            : "flex items-center justify-between w-full"
        }
      >
        <div
          className={
            props?.pageType
              ? "flex flex-row items-center gap-4 text-sm h-full"
              : "flex flex-row items-center gap-4 text-sm"
          }
        >
          <div
            onClick={(e) => {
              sendMixpanelEvent("Business Details Viewed");
              e.stopPropagation();
              setSelectedMarkerId(business?.id);
            }}
          >
            {businessCardData?.upload?.file_path ||
            businessCardData?.icon_url ? (
              <img
                src={
                  businessCardData?.upload?.file_path ||
                  businessCardData?.icon_url ||
                  ""
                }
                alt={businessCardData?.name || "NA"}
                className=" w-[45px] min-w-[45px] h-[45px] flex items-center justify-center rounded-lg dark:border-darkModeBorder border-[1px] object-contain"
              />
            ) : businessCardData ? (
              <DirectoryImageInitials
                imageUrl={businessCardData?.upload?.file_path}
                alt={businessCardData?.name}
                name={businessCardData?.name}
                boxColor={businessCardData?.business_color?.toLowerCase()}
                className={`  !w-[45px] !min-w-[45px] !h-[45px] flex items-center justify-center rounded-lg dark:border-darkModeBorder !border-[1px]`}
              />
            ) : (
              <div className="w-[45px] min-w-[45px] h-[45px] text-ipurple flex justify-center bg-[#4c5fef1d] items-center rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder">
                <LuMapPin size={25} />
              </div>
            )}
          </div>
          <div className="flex flex-col ">
            <h3
              className={`text-sm font-bold  ${
                props?.pageType != "map"
                  ? "max-w-[400px] truncate"
                  : "max-w-[150px]  text-pretty truncate"
              } cursor-pointer`}
            >
              {businessCardData?.name || ""}
            </h3>

            {distance || address ? (
              <div
                className={
                  props?.pageType == "map"
                    ? "flex text-[12px] flex-row gap-2 pt-1  truncate max-w-[210px] "
                    : "flex text-[12px] flex-row gap-2 pt-1  truncate  "
                }
              >
                {distance && (
                  <p
                    className={
                      props?.pageType == "map" && "font-semibold w-[50px] "
                    }
                  >{`${distance.toFixed(1)}mi -`}</p>
                )}
                {address && (
                  <p
                    className={
                      props?.pageType != "map"
                        ? "truncate w-[420px] max-[1300px]:w-[200px] custom-m:w-[205px] custom-l:w-[400px] custom-2xl:w-[550px] overflow-hidden"
                        : "truncate w-[200px] max-[1300px]:w-[200px] overflow-hidden"
                    }
                  >
                    {address}
                  </p>
                )}
              </div>
            ) : (
              Array?.isArray(businessUrl)?.length != 0 && (
                <div className="w-[250px] truncate overflow-hidden">
                  {businessUrl?.[0]?.account_value || ""}
                </div>
              )
            )}
          </div>
        </div>
        <div
          className={
            props?.pageType == "map"
              ? "flex flex-row  justify-end gap-1 ml-2 cursor-pointer h-full mt-14"
              : "flex flex-row items-center justify-end gap-1 ml-2 cursor-pointer"
          }
        >
          <BusinessScore
            rating={businessCardData?.rating || 0}
            styleClass={props?.pageType == "map" ? "relative flex gap-1" : ""}
            iconClass={props?.pageType == "map" ? 15 : 20}
          />
          {businessCardData?.saved_business_id ? (
            <BsFillBookmarkFill
              size={props?.pageType == "map" ? 15 : 25}
              color="#4C5FEF "
              className="min-w-[25px]"
              onClick={(e) => {
                e.stopPropagation();
                handleUnsaved(businessCardData);
              }}
            />
          ) : (
            <BsBookmark
              className="min-w-[25px]"
              size={props?.pageType == "map" ? 15 : 25}
              color="dark:text-white black"
              onClick={(e) => {
                e.stopPropagation();
                handlesaved(businessCardData);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default SearchCard;
