// Import necessary dependencies
import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";
import { BASE_URL, VITE_API_PAGE_LIMIT } from "../../utils/constants";
import { apiFilterVallidation } from "../../utils/api/apiFilter";
import callAxios from "../../api/requestIntercetorForm";
import { uniqueTicketNotes } from "../../utils/validate/NumberValidation";

// Create a Zustand store for Tickets Details
export const TicketsDetails = create((set, get) => ({
  // Initial state variables
  listAllTicketDetails: [], // Holds details of a list all ticket details
  listSingleTicketDetails: [], // Holds details of a list single ticket details
  listBusinessLocationsDetails: [], // Holds details of a list business locations details
  listTicketNotesDetails: [], // Holds details of a list ticket notes details
  listTicketActivitesDetails: {}, // Holds details of a list ticket activites details
  listCallHistoryBusinessDetails: [], // Holds details of a call history business details
  ticketDetailsLastPage: 100, // Holds details of a ticket details last page
  noteDetailsLastPage: "",
  historyLastPage: "", // Holds details of a call history last page
  totalNotesCount: "",
  totalPinnedNotesCount: "",
  listNotesDetails: {},
  loadingTicketDetails: false, // Indicates whether data is currently being loaded
  error: null, // Holds any error that may occur during data fetching or processing
  loadinglistNotesDetailsApi: false,
  loadingTicketNotes: false,
  loadingDeleteNotes: false,
  loadingCreateTicketDetails: false,
  loadingUpdateTicketDetails: false,
  loadingImageUpload: false,
  listTicketsMetaData: {},
  createTicketData: {},
  loadingCreateTicket: false,

  // Async function to create get all ticket details
  listAllTicketDetailsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingTicketDetails: true });
      let {
        page,
        business_id,
        ticket_status,
        start_date,
        end_date,
        tag_id,
        call_type,
        keyword,
        sort_order,
        scheduled,
        business_name,
      } = userData;

      let urlArray = [
        {
          name: "page",
          value: page,
        },
        {
          name: "business_id",
          value: business_id,
        },
        {
          name: "ticket_status",
          value: ticket_status,
        },
        {
          name: "start_date",
          value: start_date,
        },
        {
          name: "end_date",
          value: end_date,
        },
        {
          name: "tag_id",
          value: tag_id,
        },
        {
          name: "call_type",
          value: call_type,
        },
        {
          name: "keyword",
          value: keyword,
        },

        {
          name: "scheduled",
          value: scheduled,
        },
        {
          name: "sort_order",
          value: sort_order,
        },
        {
          name: "limit",
          value: VITE_API_PAGE_LIMIT,
        },
        {
          name: "business_name",
          value: business_name,
        },
      ];

      let filteredURL = apiFilterVallidation(urlArray, `${BASE_URL}tickets?`);
      const response = await AxiosApi.get(filteredURL);
      // Destructure response data
      // let { success, message } = response?.data || { message: "error" };
      let { success, message, data } = response?.data || { message: "error" };
      let { last_page } = response?.data?.meta || {};
      if (success) {
        // If the request is successful, update the state with the response data
        set((state) => ({
          loadingTicketDetails: false,
          ticketDetailsLastPage: last_page || 100,
          listTicketsMetaData: response?.data.meta,
          listAllTicketDetails: [...state.listAllTicketDetails, ...data].filter(
            // Filter out duplicates based on the 'id' property
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          ),
        }));

        return response?.data;
      } else {
        set(() => ({
          ticketDetailsLastPage: last_page || 100,
        }));
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingTicketDetails: false, error: err });
      return err;
    }
  },

  // Async function to create get data for single ticket by id
  listSingleTicketDetailsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingTicketDetails: true });
      let ticketId = userData;

      // Make a GET request to list  ticket
      const response = await AxiosApi.get(
        `${BASE_URL}tickets/${ticketId}`,
        userData
      );
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingTicketDetails: false,
          listSingleTicketDetails: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingTicketDetails: false, error: err });
      return err;
    }
  },

  // Async function to create get data for list business locations api
  listBusinessLocationsDetailsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingTicketDetails: true });
      let businessId = userData;
      // Make a GET request to list business locations
      const response = await AxiosApi.get(
        `${BASE_URL}/businesses/${businessId}/locations`,
        userData
      );
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingTicketDetails: false,
          listBusinessLocationsDetails: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingTicketDetails: false, error: err });
      return err;
    }
  },

  createTicketApi: async (userData) => {
    try {
      set({ loadingCreateTicket: true });

      const response = await AxiosApi.post(`${BASE_URL}tickets`, {
        business_id: userData?.business_id,
        urgent_rating: userData?.rating,
        name: userData?.name,
        description: userData?.note,
      });

      let { success, message } = response?.data || { message: "error" };
      if (success) {
        set({
          loadingCreateTicket: false,
          createTicketData: response?.data,
        });

        return response?.data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loadingCreateTicket: false, error: err });
      return err;
    }
  },

  // Async function to create get data for list tickets notes api
  listTicketNotesDetailsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      // set({ loadingTicketDetails: true });

      let {
        business_id,
        keyword,
        note_type,
        sort_by,
        sort_order,
        page,
        limit,
        is_pinned,
        attachments,
      } = userData;

      let urlArray = [
        {
          name: "keyword",
          value: keyword,
        },
        {
          name: "note_type",
          value: note_type,
        },
        {
          name: "sort_by",
          value: sort_by,
        },
        {
          name: "sort_order",
          value: sort_order,
        },
        {
          name: "page",
          value: page,
        },

        {
          name: "limit",
          value: limit,
        },
        {
          name: "is_pinned",
          value: is_pinned,
        },
        {
          name: "attachments",
          value: attachments,
        },
      ];

      // Make a GET request to list  list tickets notes

      let filteredURL = apiFilterVallidation(
        urlArray,
        `${BASE_URL}businesses/${business_id}/notes?`
      );

      const response = await AxiosApi.get(filteredURL);

      // Destructure response data

      let { success, message, data, meta } = response?.data || {
        message: "error",
      };
      let { last_page, pinned_count, total } = meta || {};

      if (success && data && Array?.isArray(data)) {
        // If the request is successful, update the state with the response data
        set((state) => ({
          // loadingTicketDetails: false,
          noteDetailsLastPage: last_page,
          totalNotesCount: total,
          totalPinnedNotesCount: pinned_count,
          listTicketNotesDetails: uniqueTicketNotes(
            userData?.newRecord
              ? [...data, ...state.listTicketNotesDetails]
              : [...state.listTicketNotesDetails, ...data]
          ),
        }));
        // set(() => ({
        //   loadingTicketDetails: false,
        // }));
        return data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingTicketDetails: false, error: err });
      return err;
    }
  },

  // Async function to create get data for ticket activities deatils api

  listTicketActivitesDetailsApi: async (ticketData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingTicketDetails: true });
      let { ticket_id } = ticketData;

      // Make a GET request to list  ticket activites details

      const response = await AxiosApi.get(
        `${BASE_URL}tickets/${ticket_id}/log
          `,
        ticketData
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        // const prevListTicketActivityArray = get().listTicketActivitesDetails;
        set({
          loadingTicketDetails: false,
          listTicketActivitesDetails: response?.data,
        });

        // If the request is successful, update the state with the response data

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingTicketDetails: false, error: err });
      return err;
    }
  },

  // Async function to create get data for call history business details api

  listCallHistoryBusinessDetailsApi: async (userData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingTicketDetails: true });

      let {
        business_id,
        call_sid,
        stream_sid,
        recording_url,
        page,
        keyword,
        ticket_id,
      } = userData;

      let urlArray = [
        {
          name: "business_id",
          value: business_id,
        },
        {
          name: "call_sid",
          value: call_sid,
        },
        {
          name: "stream_sid",
          value: stream_sid,
        },
        {
          name: "recording_url",
          value: recording_url,
        },
        {
          name: "page",
          value: page || 1,
        },
        {
          name: "limit",
          value: VITE_API_PAGE_LIMIT,
        },
        {
          name: "keyword",
          value: keyword,
        },

        {
          name: "ticket_id",
          value: ticket_id,
        },
      ];

      let filteredURL = apiFilterVallidation(
        urlArray,
        `${BASE_URL}call-history?`
      );

      const response = await AxiosApi.get(filteredURL);

      let { success, message, data } = response?.data || { message: "error" };
      let { last_page } = response?.data?.meta || {};

      if (success) {
        // If the request is successful, update the state with the response data
        set((state) => ({
          loadingTicketDetails: false,
          historyLastPage: last_page,
          listCallHistoryBusinessDetails: [
            ...state.listCallHistoryBusinessDetails,
            ...data,
          ].filter(
            // Filter out duplicates based on the 'id' property
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          ),
        }));

        return data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingTicketDetails: false, error: err });
      return err;
    }
  },

  // Async function to create get data for notes details api

  listNotesDetailsApi: async (notesData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadinglistNotesDetailsApi: true });
      let { notes_id, business_id } = notesData;

      // Make a GET request  get data for notes details api

      const response = await AxiosApi.get(
        `${BASE_URL}businesses/${business_id}/notes/${notes_id}
            `
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data

        set({
          loadinglistNotesDetailsApi: false,
          listNotesDetails: response?.data,
        });

        // If the request is successful, update the state with the response data

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadinglistNotesDetailsApi: false, error: err });
      return err;
    }
  },

  createBusinessNotesApi: (notesData) => {
    try {
      // Set loading to true while waiting for the API response

      set({ loadingTicketDetails: true });
      let { business_id } = notesData;
      // Make a POST request to save business details
      const response = AxiosApi.post(
        `${BASE_URL}/businesses/${business_id}/notes`,
        notesData
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingTicketDetails: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingTicketDetails: false, error: err });
      return err;
    }
  },

  createNotesAttachementTextApi: (business_id, data) => {
    const formData = new FormData();
    data.files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("note_type", data.note_type);
    formData.append("title", data.title);
    formData.append("description", data.description);

    try {
      // Set loading to true while waiting for the API response
      if (data?.files) {
        set({ loadingImageUpload: true });
      }
      set({ loadingTicketDetails: true });

      // Make a POST request to save business details
      const response = callAxios.post(
        `${BASE_URL}/businesses/${business_id}/notes/attach-file`,
        formData
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingTicketDetails: false,
          loadingImageUpload: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({
        loadingTicketDetails: false,
        loadingImageUpload: false,
        error: err,
      });
      return err;
    }
  },
  createNotesTextApi: async (businessId, data) => {
    try {
      // Set loading to true while waiting for the API response

      set({ loadingTicketNotes: true });

      // Make a POST request to save business details
      return await AxiosApi.post(
        `${BASE_URL}businesses/${businessId}/notes`,
        data
      ).then((data) => {
        if (data?.data?.success) {
          const noteData = get().listTicketNotesDetails?.filter(
            (data) => data?.id != "new note"
          );

          set({
            loadingTicketNotes: false,
            listTicketNotesDetails: [data?.data?.data, ...noteData],
          });
          return data;
        } else {
          throw "error";
        }
      });
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingTicketNotes: false, error: err });
      return err;
    }
  },

  notesPinnedApi: async (notesData) => {
    try {
      // Set loading to true while waiting for the API response

      set({ loadingPinnedTicket: true });
      let { businessId, noteId, pinnedStatus } = notesData;

      // Make a POST request to save business details
      const response = await AxiosApi.put(
        `${BASE_URL}/businesses/${businessId}/notes/${noteId}`,
        pinnedStatus
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data

        set((state) => ({
          loadingPinnedTicket: false,
          totalPinnedNotesCount: pinnedStatus?.is_pinned
            ? parseInt(state.totalPinnedNotesCount) + 1
            : parseInt(state.totalPinnedNotesCount) - 1,
          listNotesDetails:
            state.listNotesDetails?.data?.id == notesData?.noteId
              ? {
                  ...state.listNotesDetails,
                  data: {
                    ...state.listNotesDetails?.data,
                    is_pinned: notesData?.pinnedStatus?.is_pinned,
                  },
                }
              : { ...state.listNotesDetails },
          listTicketNotesDetails: state.listTicketNotesDetails?.map((data) => {
            if (data?.id == notesData?.noteId) {
              return { ...data, is_pinned: notesData?.pinnedStatus?.is_pinned };
            } else {
              return { ...data };
            }
          }),
        }));
        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error

      return err;
    }
  },

  // Async function to update the ticket  Change Archive ticket text to Close ticket
  ticketUpdateCallsDetailsApi: async ({ id, data }) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingTicketDetails: true });
      // Make a PUT request to update the ticket
      const response = await AxiosApi.put(`${BASE_URL}tickets/${id}`, data);
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingTicketDetails: false,
          ticketUpdateCallsDetails: response?.data,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingTicketDetails: false, error: err });
      return err;
    }
  },

  deleteNotesApi: async ({ businessId, noteId }) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingDeleteNotes: true });

      const response = await AxiosApi.delete(
        `${BASE_URL}/businesses/${businessId}/notes/${noteId}`
      );
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingDeleteNotes: false,
        });

        set((state) => ({
          loadingDeleteNotes: false,
          listTicketNotesDetails: state.listTicketNotesDetails?.filter(
            (data) => data?.id != noteId
          ),
          totalNotesCount:
            state.totalNotesCount > 0 ? state.totalNotesCount - 1 : 0,
        }));
        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingDeleteNotes: false, error: err });
      return err;
    }
  },

  updateNotesApi: async (businessId, noteId, data) => {
    try {
      // Set loading to true while waiting for the API response
      let { title, delete_upload_ids, description, files } = data;
      const formData = new FormData();

      formData.append("title", title);

      formData.append(
        "delete_upload_ids",
        delete_upload_ids ? delete_upload_ids : ""
      );

      formData.append("description", description);

      files?.forEach((file) => {
        formData.append("files", file);
      });
      if (data?.files) {
        set({ loadingImageUpload: true });
      }
      set({ loadingCreateTicketDetails: true });

      const response = await callAxios
        .put(`${BASE_URL}businesses/${businessId}/notes/${noteId}`, formData)
        .then((resData) => {
          if (resData?.data?.success) {
            set((state) => ({
              listTicketNotesDetails: state.listTicketNotesDetails?.map(
                (listData) => {
                  if (listData?.id == noteId) {
                    return resData?.data?.data;
                  } else {
                    return { ...listData };
                  }
                }
              ),
              listNotesDetails: resData?.data?.data,
              loadingImageUpload: false,
            }));
          }
        });

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingCreateTicketDetails: false,
          loadingImageUpload: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({
        loadingCreateTicketDetails: false,
        error: err,
        loadingImageUpload: false,
      });
      return err;
    }
  },

  updateticketApi: async (data) => {
    try {
      // Set loading to true while waiting for the API response
      let { ticket_id, ticketData } = data;

      set({ loadingUpdateTicketDetails: true });

      const response = await callAxios.put(
        `${BASE_URL}tickets/${ticket_id}`,
        ticketData
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingUpdateTicketDetails: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingUpdateTicketDetails: false, error: err });
      return err;
    }
  },
  // listTicketNotesDetails
  modifyNotesData: async (data) => {
    const selectedId = data?.id != "new note" ? data?.id : null;
    if (selectedId) {
      set((state) => ({
        listTicketNotesDetails: state.listTicketNotesDetails?.map(
          (ticketData) => {
            if (ticketData?.id == selectedId) {
              return {
                ...ticketData,
                description: data?.description,
                title: data?.title,
              };
            } else {
              return { ...ticketData };
            }
          }
        ),
      }));
    } else {
      const dummyNote = [
        {
          calendar_ts: null,
          deleted: false,
          deleted_at: null,
          deleted_by: null,
          description: data?.description,
          id: "new note",
          is_pinned: false,
          model: "Business",
          note_type: "text",
          title: data?.title,
        },
      ];

      let getTicket = get().listTicketNotesDetails?.filter(
        (data) => data?.id == "new note"
      )?.[0];

      if (getTicket) {
        set((state) => ({
          listTicketNotesDetails: state.listTicketNotesDetails?.map(
            (ticketData) => {
              if (ticketData?.id == data?.id) {
                return {
                  ...ticketData,
                  description: data?.description,
                  title: data?.title,
                };
              } else {
                return { ...ticketData };
              }
            }
          ),
        }));
      } else {
        set((state) => ({
          listTicketNotesDetails: [
            ...dummyNote,
            ...state.listTicketNotesDetails,
          ],
        }));
      }
    }
  },

  multipleTicketUpdateApi: async (ticketData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingTicketDetails: true });

      const response = await callAxios.post(
        `${BASE_URL}tickets/update`,
        ticketData
      );

      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loadingTicketDetails: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingTicketDetails: false, error: err });
      return err;
    }
  },

  clearState: () =>
    set({
      // listSingleTicketDetails: [],
      listCallHistoryBusinessDetails: [],
      listTicketActivitesDetails: {},
      listAllTicketDetails: [],
      ticketDetailsLastPage: "",
      listTicketNotesDetails: [],
      noteDetailsLastPage: "",
    }),
  modifyListAllTicketsData: (data) =>
    set({
      listAllTicketDetails: data || [],
    }),
  clearSelectNote: () =>
    set({
      listNotesDetails: {},
    }),
  clearNotesCount: () => set({ totalNotesCount: 0 }),
}));
