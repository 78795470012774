import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import Loader from "../atom/loader/Loader";
import BusinessCard from "./BusinessCard";

const BusCardList = (data) => {
  let { businesses, onCardClick, onCardApiCall, selectedTab } = data;
  const { loadingBusinessDetails } = DirectoryDetails((state) => state);
  return (
    <div>
      <div className="flex flex-wrap justify-">
        {businesses?.length != 0 &&
          businesses?.map((business) => (
            <BusinessCard
              key={business?.business.id}
              business={business?.business}
              onCardClick={onCardClick} // Passing the onCardClick handler
              onCardApiCall={onCardApiCall}
              activeFilter={selectedTab}
            />
          ))}
      </div>
    </div>
  );
};
export default BusCardList;
