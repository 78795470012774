import React from "react";
import BrowserExtensionCard from "../BrowserExtensionCard";
import { sendMixpanelEvent } from "../../../services/mixpanelService";

export default function BroswerExtensionView() {
  return (
    <div className="flex flex-col w-full text-sm scrollable-container h-full overflow-y-auto border border-gray-300 rounded">
      <p className="px-4 py-5">
        You can link up to 5 devices with iAllo Browser Extension installed to
        your account.
      </p>
      <div className="flex items-center justify-between w-full px-4">
        <button
          onClick={() => {
            sendMixpanelEvent("Browser Extension Linked Successfully");
          }}
          className="w-[345px] h-[45px] rounded-full bg-ipurple text-white uppercase my-3 hover:bg-inherit hover:border-ipurple hover:border-2 hover:text-ipurple"
        >
          Link with browser extension
        </button>
        <button className="w-[220px] h-[45px] bg-inherit rounded-full border-2 border-b border-gray-100lack dark:border-white uppercase hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black ">
          Unlink all devices
        </button>
      </div>
      <p className="px-4  h-[60px] flex items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder text-[16px] font-semibold">
        Active Sessions
      </p>
      <BrowserExtensionCard />
    </div>
  );
}
