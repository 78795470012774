import BusinessScore from "./BusinessScore";
import { BsFillBookmarkFill, BsBookmark } from "react-icons/bs";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import { useNavigate } from "react-router-dom";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import { useEffect, useState } from "react";

export default function BusinessCard(props) {
  let { business, onCardApiCall } = props || {};
  const { savedBusinessApi, unsavedBusinessApi, resetBusinessDeatils } =
    DirectoryDetails((state) => state);
  const [businessCardData, setBusinessCardData] = useState({});

  useEffect(() => {
    setBusinessCardData(business || {});
  }, [business]);

  let navigate = useNavigate();

  const businessData = {
    /* ...business, */
    logoSrc:
      businessCardData?.logoSrc /* upload: { file_path: businessCardData?.logoSrc },  */,
    siteUrl: businessCardData?.siteUrl || "#",
    business_contacts: [
      {
        account_type: { name: "Website" },
        account_value: businessCardData?.siteUrl,
      },
    ],
    rating: businessCardData?.score,
    id: businessCardData?.id,
    name: businessCardData?.name,
    saved_business_id: businessCardData?.isSaved,
  };

  let businessUrl = businessData.business_contacts?.filter(
    (option) => option.account_type?.name == "Website"
  );

  const handleUnsaved = async (businessId) => {
    setBusinessCardData((prev) => ({ ...prev, saved_business_id: false }));
    unsavedBusinessApi({ business_id: businessId });
    await resetBusinessDeatils();
    setTimeout(() => {
      onCardApiCall();
    }, 300);
  };
  const handlesaved = async (businessId) => {
    savedBusinessApi({
      business_id: businessId,
      // google_place_id: googlePlaceId?googlePlaceId:"",
    });
    setBusinessCardData((prev) => ({ ...prev, saved_business_id: true }));
    await resetBusinessDeatils();
    setTimeout(() => {
      onCardApiCall();
    }, 300);
  };

  return (
    <div
      onClick={() => {
        navigate(`/business/${businessCardData?.id}`);
      }}
      className="hover:cursor-pointer justify-center items-center relative flex flex-col border-[1px] !mx-[10px] border-gray-100  dark:border-darkModeBorder max-w-[410px] min-w-[300px] w-[369px] h-[85px] min-h-[85px] max-h-[100px] rounded-lg mt-6  overflow-hidden mx-1 custom-m:max-w-[436px] custom-l:w-[454px] custom-l:max-w-[422px] custom-xl:w-[454px] custom-xl:max-w-[480px] custom-2xl:w-[520px] custom-2xl:max-w-[432px]"
    >
      <div className="absolute flex items-center justify-center gap-2 right-3 top-3">
        <BusinessScore rating={businessCardData?.average_review_rating} />

        {businessCardData?.saved_business_id ? (
          <BsFillBookmarkFill
            size={18}
            className="min-w-[18px]"
            color="#4C5FEF"
            onClick={(e) => {
              e.stopPropagation();
              handleUnsaved(businessCardData?.id);
            }}
          />
        ) : (
          <BsBookmark
            className="min-w-[18px]"
            size={18}
            color="white"
            onClick={(e) => {
              e.stopPropagation();
              handlesaved(
                businessCardData?.id,
                businessCardData?.google_place_id
              );
            }}
          />
        )}
      </div>
      <div className="absolute flex items-center overflow-hidden text-sm rounded bottom-3 left-1 ">
        <DirectoryImageInitials
          imageUrl={businessCardData?.upload?.file_path}
          alt={businessCardData?.name}
          name={businessCardData?.name}
          boxColor={businessCardData?.business_color?.toLowerCase()}
          className={`max-w-[45px] max-h-[45px] m-2 border-none rounded-md object-cover`}
        />

        <div className="flex flex-col pl-1   max-w-[250px] overflow-hidden">
          <h3 className="text-sm font-bold truncate max-[1300px]:max-w-[150px]">
            {businessCardData?.name}
          </h3>

          <p className=" mr-2 text-[12px] line-clamp-1">
            {/* 1562 E Wood Street, Los Angeles */}
            {businessCardData?.business_locations?.[0]?.address || ""}
          </p>
          <p className=" mr-2 text-[12px] line-clamp-1 text-[#6C7B8A]">
            {businessCardData?.category?.name || ""}
          </p>
          {businessUrl?.length != 0 && (
            <a
              href={businessUrl?.[0]?.account_value}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[12px]"
            >
              {businessUrl?.[0]?.account_value}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
