import RootLayout from "../RootLayout";
import { SearchProvider } from "../context/SearchContext";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import Subnav from "../components/navigation/Subnav";
import { validate as isValidUUID } from "uuid";
import { clearLocalStorage } from "../utils/common";

export const getToken = () => {
  return localStorage.getItem("authLogin");
};

export const userDetails = () => {
  let tokenDetails = localStorage.getItem("userDetails");
  if (tokenDetails) {
    return JSON.parse(tokenDetails);
  }
};

export const useAuth = () => {
  const tokenData = getToken();
  const { loggedIn } = tokenData ? { loggedIn: true } : { loggedIn: false };
  return loggedIn;
};

export const logOut = (LogoutApi, navigate) => {
  LogoutApi()
    .then(() => {
      navigate("/");
      clearLocalStorage();
    })
    .catch(() => {
      navigate("/");
    });
};
export const getUserName = () => {
  const userDetailsProfile = userDetails();
  return (
    (userDetailsProfile?.first_name || "User") +
    " " +
    (userDetailsProfile?.last_name || "")
  );
};

export const getUserId = () => {
  const userDetailsProfile = userDetails();
  return userDetailsProfile?.id;
};

export const getUserImage = () => {
  const userDetailsProfile = userDetails();
  return userDetailsProfile?.profileImage;
};

export const getAccountType = () => {
  const userDetailsProfile = userDetails();
  return userDetailsProfile?.signed_up_as || null;
};

export const getCompanyId = () => {
  const userDetailsProfile = userDetails();
  return userDetailsProfile?.company_id || userDetailsProfile?.company_employee?.company?.id;
};

/**
 * A universal function to verify access to protected routes on the Routes page.
 */
export const ProtectedRouteCheck = (props) => {
  let { children } = props || {};
  const isAuth = useAuth();
  return isAuth ? (
    <Navigate to="/directory/search" />
  ) : (
    <AuthLayout>{children}</AuthLayout>
  );
};

export const ProtectedRoute = () => {
  const isAuth = useAuth();
  const { pathname } = useLocation();

  return isAuth ? (
    <SearchProvider>
      <RootLayout>
        <div
          style={{ height: "calc(100vh - 100px)" }}
          className={
            pathname == "/dashboard"
              ? " pb-2  overflow-x-hidden flex flex-col  pt-0 mt-0  pr-8 dark:bg-darkModeBG"
              : " pb-2   overflow-x-hidden flex flex-col pt-0 mt-0 pl-[65px] dark:bg-darkModeBG"
          }
        >
          <Outlet />
        </div>
      </RootLayout>
    </SearchProvider>
  ) : (
    <Navigate to="/" />
  );
};

export const ProtectedRoles = ({ element }) => {
  const { businessid, ticketId } = useParams();

  const isAuth = useAuth();
  const uuId = businessid ? businessid : ticketId;

  if (uuId && !isValidUUID(uuId)) {
    return <Navigate to="*" replace />;
  }
  if (isAuth) {
    return (
      <div className="-mt-5">
        <div className="pl-4 ">
          <Subnav />
        </div>
        <div className="ml-8 mr-4 mt-12 ">{element}</div>
      </div>
    );
  }
  return <Navigate to="*" replace />;
};

export const ProtectedRolesTicket = ({ element }) => {
  const { businessid, ticketId } = useParams();

  const isAuth = useAuth();
  const uuId = businessid ? businessid : ticketId;

  if (uuId && !isValidUUID(uuId)) {
    return <Navigate to="*" replace />;
  }
  if (isAuth) {
    return (
      <div className="-mt-5">
        <div className="pl-4">
          <Subnav />
        </div>
        <div className="ml-4 mt-14">{element}</div>
      </div>
    );
  }
  return <Navigate to="*" replace />;
};
