import { IoMapOutline } from "react-icons/io5";

function LocationCard(props) {
  let { businessLocationsData } = props || {};
  return (
    <div>
      {businessLocationsData?.length !== 0 &&
        businessLocationsData?.map((data) => (
          <div
            key={data?.id}
            className="w-full text-sm bg-white z-30 rounded-b-lg dark:bg-darkModeMain flex justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder p-4 items-center"
          >
            <div className="flex flex-col space-y-1">
              <p className="font-semibold">{data?.address}</p>
              <p>
                {data?.state} {data?.zip_code}
              </p>
              <br></br>
            </div>
            <div className="flex items-center gap-2">
              {/* <p>3 miles</p> */}
              <IoMapOutline size={20} />
            </div>
          </div>
        ))}
    </div>
  );
}
export default LocationCard;
