
export const DirectoryIcon = ({className}) => {
    return (



<svg className={`svg-icon ${className}`} width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

    <g id="Icons/Directory" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M7.25,7 C6.015625,7 5,8.015625 5,9.25 L5,20.75 C5,21.984375 6.015625,23 7.25,23 L22.75,23 C23.984375,23 25,21.984375 25,20.75 L25,11.75 C25,10.515625 23.984375,9.5 22.75,9.5 L15.0214845,9.5 L12.7871095,7.636719 C12.292969,7.2265625 11.669922,7 11.0253905,7 L7.25,7 Z M7.25,8.5 L11.0253905,8.5 C11.3183595,8.5 11.6015625,8.6035155 11.826172,8.7890625 L13.578125,10.25 L11.826172,11.7109375 C11.6015625,11.8964845 11.3183595,12 11.0253905,12 L6.5,12 L6.5,9.25 C6.5,8.826172 6.826172,8.5 7.25,8.5 Z M15.0214845,11 L22.75,11 C23.173828,11 23.5,11.326172 23.5,11.75 L23.5,20.75 C23.5,21.173828 23.173828,21.5 22.75,21.5 L7.25,21.5 C6.826172,21.5 6.5,21.173828 6.5,20.75 L6.5,13.5 L11.0253905,13.5 C11.669922,13.5 12.292969,13.2734375 12.7871095,12.861328 L15.0214845,11 Z" id="Shape" fill="currentColor" fillRule="none"></path>
    </g>
</svg>

)
}