import create from "zustand";
import callAxios from "../api/requestIntercetorForm";
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const useFeedbackStore = create((set) => ({
  selectedRating: null,
  feedback: "",
  filePreviews: [],
  isFormComplete: false,
  isSubmitted: false,
  errorMessage: "",

  setSelectedRating: (rating) => set({ selectedRating: rating }),
  setFeedback: (feedback) => set({ feedback }),
  setFilePreviews: (filePreviews) => set({ filePreviews }),
  setIsFormComplete: (isFormComplete) => set({ isFormComplete }),
  setIsSubmitted: (isSubmitted) => set({ isSubmitted }),
  setErrorMessage: (errorMessage) => set({ errorMessage }),

  handleAttachFile: async (formData) => {
    try {
      const response = await callAxios.post(
        `${API_BASE_URL}feedbacks`,
        formData,
        {}
      );

      const { success, message } = response.data || { message: "error" };

      if (success) {
        set({ isSubmitted: true });
      } else {
        throw new Error(message);
      }
    } catch (err) {
      set({
        errorMessage: "Failed to submit feedback. Please try again later.",
      });
      console.error(
        "Error submitting feedback:",
        err.response?.data || err.message
      );
    }
  },
}));
