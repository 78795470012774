import dayjs from "dayjs";
import { newUUID } from "jssip/lib/Utils";
import { useState, useEffect } from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

const Calendar = ({
  filter,
  setFilter,
  onClose,
  selectedDate,
  setSelectedDate,
  type,
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  useEffect(() => {
    switch (filter) {
      case "today":
        setSelectedDate(new Date());
        break;
      case "week":
        let startOfWeek = new Date();
        if (type == "endMonth") {
          startOfWeek.setDate(new Date().getDate());
          setSelectedDate(startOfWeek);
        } else {
          startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
          setSelectedDate(startOfWeek);
        }
        break;
      case "month":
        // Logic to set to start of the month
        let startOfMonth = new Date();

        if (type == "endMonth") {
          startOfMonth;
          setSelectedDate(startOfMonth);
        } else {
          startOfMonth.setDate(1);
          setSelectedDate(startOfMonth);
        }
        break;
      case "year":
        // Logic to set to start of the month
        let startOfYear = new Date();
        if (type == "endMonth") {
          startOfYear.setFullYear(new Date()?.getFullYear());
          startOfYear.setMonth(new Date()?.getMonth());
          startOfYear.setDate(new Date()?.getDate());
          setSelectedDate(startOfYear);
        } else {
          startOfYear.setFullYear(new Date()?.getFullYear());
          startOfYear.setMonth("00");
          startOfYear.setDate("01");
          setSelectedDate(startOfYear);
        }
        break;
      default:
        // No filter or reset
        // setSelectedDate(new Date());
        break;
    }
  }, [filter]);

  const prevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() - 1))
    );
  };

  const nextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() + 1))
    );
  };

  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].map(
    (day, index) => (
      <div
        key={index}
        className="flex items-center justify-center w-full p-4 uppercase text-[13px]"
      >
        {day}
      </div>
    )
  );

  const getDaysInMonth = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysArray = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const isPast = date > new Date(new Date().setHours(0, 0, 0, 0));
      const isSelected =
        selectedDate && selectedDate.toDateString() === date.toDateString();
      daysArray.push(
        <button
          key={day}
          className={` rounded-full w-[45px]  h-[45px] flex ${
            isPast
              ? " hover:white dark:hover:bg-neutral-800  "
              : "hover:bg-gray-100 dark:hover:bg-darkModeBG active:bg-ipurple"
          } ${isSelected ? "bg-ipurple text-white hover:bg-ipurple" : ""}`}
          onClick={() => {
            !isPast && setSelectedDate(date);
            setFilter();
          }}
        >
          <time
            className={`flex items-center justify-center mx-auto rounded-full h-[45px] w-[45px]`}
          >
            {day}
          </time>
        </button>
      );
    }
    let lastMonthArr = [];
    for (let i = 0; i <= dayjs(currentMonth).startOf("month").day() - 1; i++) {
      lastMonthArr.push(i);
    }

    let fullCalendar =
      lastMonthArr
        ?.map(() => (
          <button key={newUUID()}>
            <time
              className={`flex items-center justify-center mx-auto rounded-full h-[45px] w-[45px]`}
            ></time>
          </button>
        ))
        ?.concat(daysArray) || daysArray;
    return fullCalendar;
  };

  return (
    <div
      className={`absolute flex flex-col items-center justify-center w-[380px] p-4 pt-6 text-center bg-white border-2 rounded-lg dark:bg-darkModeMain dark:border-darkModeBorder  top-60 ${
        type == "endMonth" ? "-right-10" : "-left-40"
      }`}
    >
      <div className="flex items-center justify-between w-2/3 mb-4">
        <button onClick={prevMonth} className="hover:text-gray-500">
          <GoChevronLeft size={30} />
        </button>
        <div className="text-sm font-semibold">
          {currentMonth.toLocaleString("default", { month: "long" })}{" "}
          {currentMonth.getFullYear()}
        </div>
        <button onClick={nextMonth} className="hover:text-gray-500">
          <GoChevronRight size={30} />
        </button>
      </div>
      <div className="grid w-full grid-cols-7 text-xs text-gray-400">
        {daysOfWeek}
      </div>
      <div className="grid w-full grid-cols-7 gap-1 ">{getDaysInMonth()}</div>
      <button
        onClick={onClose}
        className="w-full m-2 h-[35px]  uppercase  rounded-full bg-ipurple text-white"
      >
        Apply
      </button>
    </div>
  );
};

export default Calendar;
