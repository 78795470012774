/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import { SlLocationPin } from "react-icons/sl";
import UpdateBusDetails from "./edit-views/UpdateBusDetails";
import { BusinessIcon, Trash } from "../icons/Icons";
import { CallIcon } from "../icons/CallIcon";
import ReportPopup from "../business-profile/ReportPopup";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import Contacts from "./Contacts";
import UpdateLocationHoursView from "./edit-views/UpdateLocationHoursView";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import { BusinessService } from "../../services/bussiness/BusinessService";
import toast from "react-hot-toast";
import * as yup from "yup";
import Loader from "../atom/loader/Loader";
import { removeEmptyStringValues } from "../../utils/common";
import DeleteBusinessPopup from "../business-profile/DeleteBusinessPopup";

export default function EditExistingContainer() {
  const [activeView, setActiveView] = useState("BusinessDetails");
  const { businessid } = useParams();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const navigate = useNavigate();
  const {
    updateBusinessApi,
    uploadBusinessImage,
    categoryResp,
    businessLoading,
  } = BusinessService((state) => state);
  const initialValues = {
    name: "",
    chat_id: "",
    category_id: "",
    additional_category_id: "",
    is_private: false,
    business_contacts: [],
    business_locations: [],
  };
  const { fetchBusinessDetailsApi, fetchBusinessDetails } = DirectoryDetails(
    (state) => state,
  );

  const { data } = fetchBusinessDetails;
  let getBusinessDetails = () => {
    fetchBusinessDetailsApi({
      businessId: businessid,
    });
  };
  useEffect(() => {
    getBusinessDetails();
  }, [businessid]);

  useEffect(() => {
    if (Object?.keys(data || {})?.length != 0) {
      const businessLocation = data.business_locations;
      formik.setFieldValue("name", data?.name);
      formik.setFieldValue("chat_id", data?.chat_id);
      formik.setFieldValue("category_id", data?.category_id || "");
      formik.setFieldValue(
        "additional_category_id",
        data?.additional_category_id || "",
      );

      formik.setFieldValue("is_private", data?.is_private);
      formik.setFieldValue("business_contacts", data?.business_contacts);
      formik.setFieldValue("business_locations", businessLocation);
    }
  }, [fetchBusinessDetails]);

  const [showReportPopup, setShowReportPopup] = useState(false);

  const handleClose = () => {
    setShowReportPopup(false);
    setShowDeletePopup(false);
  };

  const busDetailsRef = useRef(null);
  const contactsRef = useRef(null);
  const locationHoursRef = useRef(null);

  const scrollToView = (ref, viewName) => {
    ref.current.scrollIntoView({ behavior: "smooth" });

    setActiveView(viewName);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleSubmit = () => {
    let imageData = formik.values?.file;
    const formDetails = new FormData();
    formDetails.append("file", imageData);
    let formikDetails = { ...formik.values };
    delete formikDetails?.business_working_days;

    // Create a Set from the ids in formik.values.business_contact for efficient lookup
    const formikIds = new Set(
      formik.values.business_contacts.map((item) => item.id),
    );

    // Map through data.business_contacts and add the deleted property
    const updatedBusinessContacts =
      Array?.isArray(data.business_contacts) &&
      data.business_contacts?.length != 0
        ? data.business_contacts.map((item) => {
            return {
              ...item,
              deleted: !formikIds.has(item.id), // Set deleted: true if the id is not found
            };
          })
        : [];

    // Combine the updated contacts with those from formik.values.business_contact
    const combinedArray = [
      ...updatedBusinessContacts,
      ...formik.values.business_contacts.filter(
        (item) => !data.business_contacts.some((data) => data.id === item.id),
      ),
    ];

    // Map through data.business_locations and add the deleted property
    const updatedCalendar =
      Array.isArray(data.business_locations) &&
      data.business_locations.length !== 0
        ? data.business_locations.map((dataItem) => {
            // check if item exist in formik
            const formikItem = formik.values.business_locations.find(
              (formikItem) => formikItem.id === dataItem.id,
            );

            // Mark as deleted if it doesn't exist in formik
            return formikItem
              ? { ...formikItem }
              : { ...dataItem, deleted: true };
          })
        : [];

    // Combine the updated locations with those from formik.values.business_locations
    const calendarArray = [
      ...updatedCalendar,
      ...formik.values.business_locations.filter(
        (item) => !data.business_locations.some((data) => data.id === item.id),
      ),
    ];

    const {
      category_name,
      category_id,
      additional_category_id,
      additional_category_name,
      ...restValues
    } = formik.values;

    let formikData = {
      ...restValues,
      business_contacts: combinedArray?.map(
        ({
          account_type,
          business_id,
          deleted_at,
          deleted_by,
          created_by,
          updated_by,
          created_at,
          updated_at,
          business_update_request_count,
          my_business_update_request_count,
          saved,
          user_id,
          ...rest
        }) => ({
          ...rest,
          call_avg_minutes: rest?.call_avg_minutes || 0,
        }),
      ),
      business_locations: calendarArray?.map(
        ({
          business_id,
          google_working_days,
          business_working_days,
          ...rest
        }) => ({
          ...rest,
          business_working_days: business_working_days?.map(
            ({ timezone, google_working_days, ...rest }) => ({
              ...rest,
            }),
          ),
        }),
      ),
      ...(categoryResp?.data?.filter(
        (data) =>
          data?.id ==
          (additional_category_name || additional_category_id || ""),
      )?.length > 0
        ? {
            additional_category_id:
              additional_category_name || additional_category_id || "",
          }
        : {
            additional_category_name:
              additional_category_name || additional_category_id || "",
          }),
      ...(categoryResp?.data?.filter(
        (data) => data?.id == (category_name || category_id),
      )?.length > 0
        ? { category_id: category_name || category_id }
        : {
            category_name: category_name || category_id || "",
          }),
    };

    delete formikData?.file;

    updateBusinessApi({
      id: fetchBusinessDetails?.data?.id,
      data: removeEmptyStringValues(formikData),
    }).then(async (data) => {
      if (data?.success == false) {
        toast.error(data?.message);
      } else if (data?.success == true) {
        if (formik.values?.file) {
          await uploadBusinessImage(
            fetchBusinessDetails?.data?.id,
            formDetails,
          );
        }
        await navigate(`/business/${businessid}`);
        toast.success("Business created successfully");
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const schema = yup.object().shape({
    name: yup
      .string()
      .min(1, "Please enter the min 3 letter")
      .max(50, "Please enter the max 50 letter")
      .required("This field is required."),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const buttonClass = (viewName) =>
    `w-full pl-4 overflow-hidden flex items-center min-h-[60px] h-[60px] font-semibold gap-2 ${
      activeView === viewName
        ? "bg-lightBg dark:bg-darkModeBG text-ipurple overflow-hidden"
        : ""
    } hover:bg-lightBg dark:hover:bg-darkModeBG overflow-hidden min-h-[60px] h-[60px]`;

  const onDeleteClick = () => {
    setShowDeletePopup(true);
  };

  return (
    <>
      {" "}
      {businessLoading && <Loader />}
      <div className="fixed top-0 bottom-0 left-0 right-0 flex w-full gap-4 py-8 pl-[100px] overflow-hidden text-sm">
        <div
          style={{ height: "calc(100vh - 180px)" }}
          className="flex w-full gap-4 mt-[126px]"
        >
          <div className="w-1/3 flex max-h-[263px] flex-col bg-white dark:bg-darkModeMain rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder">
            <div className="flex items-center min-h-[80px] border-b border-gray-100-[1px]  dark:border-darkModeBorder w-full pl-3">
              <DirectoryImageInitials
                imageUrl={data?.upload?.file_path}
                alt={data?.name}
                name={data?.name}
                boxColor={data?.business_color?.toLowerCase()}
                className={"!w-[40px] !h-[40px]"}
              />
              <h1 className="px-4 text-sm font-semibold uppercase">
                {fetchBusinessDetails?.data?.name || "Business Name"}
              </h1>
            </div>
            <div className=" flex flex-col divide-y-[1px] dark:divide-darkModeBorder">
              <button
                onClick={() => scrollToView(busDetailsRef, "BusinessDetails")}
                className={buttonClass("BusinessDetails")}
              >
                {" "}
                <div className="w-[30px]">
                  <BusinessIcon
                    className={
                      activeView === "BusinessDetails"
                        ? "!text-ipurple"
                        : "!text-black dark:text-white"
                    }
                  />
                </div>
                <p
                  className={
                    activeView === "BusinessDetails"
                      ? "text-ipurple w-[200px] flex justify-start"
                      : "w-[200px] flex justify-start"
                  }
                >
                  Business Details
                </p>
              </button>

              <button
                onClick={() => scrollToView(contactsRef, "Contacts")}
                className={buttonClass("Contacts") + "pl-0"}
              >
                {" "}
                <div className="w-[30px]">
                  <CallIcon
                    className={
                      activeView === "Contacts"
                        ? "!text-ipurple"
                        : "!text-black dark:text-white"
                    }
                  />
                </div>
                <p
                  className={
                    activeView === "Contacts"
                      ? "text-ipurple w-[200px] flex justify-start"
                      : "w-[200px] flex justify-start"
                  }
                >
                  Contacts
                </p>
              </button>

              <button
                onClick={() => scrollToView(locationHoursRef, "LocationHours")}
                className={buttonClass("LocationHours")}
              >
                <div className="w-[30px]">
                  <SlLocationPin
                    size={18}
                    className={
                      activeView === "LocationHours"
                        ? "!text-ipurple"
                        : "!text-black dark:text-white"
                    }
                  />
                </div>
                <p
                  className={
                    activeView === "LocationHours"
                      ? "text-ipurple w-[200px] flex justify-start"
                      : "w-[200px] flex justify-start"
                  }
                >
                  Location & Working Hours
                </p>
              </button>
            </div>
          </div>

          <div className="flex flex-col w-2/3 mr-4 overflow-x-hidden overflow-y-scroll ">
            <div className="z-20 flex items-center w-full mr-4 overflow-hidden "></div>
            <div className="flex flex-col space-y-4 ">
              <div
                ref={busDetailsRef}
                className="w-full bg-white rounded-lg border-[1px]  border-gray-100  dark:bg-darkModeMain dark:border-darkModeBorder"
              >
                <div className="min-h-[60px] max-h-[60px] flex items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
                  <h1 className="px-4 text-sm font-semibold uppercase">
                    Update Business Details
                  </h1>
                </div>
                <UpdateBusDetails
                  formik={formik}
                  handleChange={handleChange}
                  data={data}
                />
              </div>
              <div
                ref={contactsRef}
                className="w-full bg-white rounded-lg border-[1px]  border-gray-100  dark:bg-darkModeMain dark:border-darkModeBorder"
              >
                <Contacts
                  formik={formik}
                  handleChange={handleChange}
                  data={data}
                />
              </div>
              <div ref={locationHoursRef}>
                <UpdateLocationHoursView
                  formik={formik}
                  handleChange={handleChange}
                  apiData={data}
                />
              </div>
            </div>
          </div>
          {showReportPopup && <ReportPopup onClose={handleClose} />}
        </div>
        <div className="flex items-center justify-between w-full absolute bottom-0 h-[85px] border-t-[1px] bg-white dark:bg-darkModeMain dark:border-darkModeBorder -ml-8 ">
          <button className="mx-8 w-[220px] rounded-full h-[45px] border-2 border-b border-gray-100lack dark:border-white hover:bg-black dark:hover:bg-white hover:text-white dark:hover:text-black uppercase font-semibold">
            Cancel
          </button>
          <button className="mr-28 w-[220px] uppercase font-semibold h-[45px] rounded-full bg-ipurple text-white hover:bg-inherit hover:border-2 hover:border-ipurple hover:text-ipurple">
            Save
          </button>
        </div>
        <div className="flex items-center justify-between w-full absolute bottom-0 h-[85px] border-t-[1px] bg-white dark:bg-darkModeMain dark:border-darkModeBorder -ml-8">
          <button
            className="flex items-center gap-2 mx-8 w-[220px] h-[45px] font-semibold"
            onClick={onDeleteClick}
          >
            <Trash className="w-[22px] h-[20px]  dark:text-white" />
            <p className="text-gray-700 dark:text-gray-300">Delete Business</p>
          </button>

          <div className="flex gap-1">
            <button
              className="mx-8 w-[220px] rounded-full h-[45px] border-2 border-b border-gray-100 dark:border-white hover:bg-black dark:hover:bg-white hover:text-white dark:hover:text-black uppercase font-semibold"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              className="mr-28 w-[220px] uppercase font-semibold h-[45px] rounded-full bg-ipurple text-white hover:bg-inherit hover:border-2 hover:border-ipurple hover:text-ipurple"
              onClick={formik.handleSubmit}
              disabled={businessLoading ? true : false}
            >
              Update
            </button>
          </div>
        </div>
      </div>
      {showDeletePopup && (
        <DeleteBusinessPopup businessid={data?.id} onClose={handleClose} />
      )}
    </>
  );
}
