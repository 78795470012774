import logo from "../../assets/logo.webp";
import { useLocation, useNavigate } from "react-router-dom";
import BackBtn from "../atom/BackBtn";
import PropTypes from 'prop-types'

TopNav.propTypes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
};

export default function TopNav({view,setView}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="flex items-center w-full h-[80px] border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
      <div className="w-1/5">
        {
          <div className="ml-8 p-2 border border-b border-gray-100-[1px] border-gray-100 w-[105px] rounded-lg">
            <BackBtn view={view} setView={setView} />
          </div>
        }
      </div>
      {pathname != "/" && (
        <div className="justify-center items-center bg-white dark:bg-darkModeMain flex   z-[60] w-full  ">
          <div>
            <img
              src={logo}
              alt="logo"
              width={100}
              className=""
              draggable={false}
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      )}
      <div className="w-1/5"> &nbsp;</div>
    </div>
  );
}
