import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { MAP_KEYS, locationDetails } from "../../utils/constants";
import locationMarker from "../../../src/assets/locationMarker.svg";

const ReactGoogleMapBusinessProfile = (props) => {
  const [map, setMap] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const locationData = locationDetails();
  const { locationDataBusinessProfile } = props || {};

  const latitudeData =
    locationDataBusinessProfile?.business_locations?.[0]?.lat ||
    locationData?.lat;
  const longitudeData =
    locationDataBusinessProfile?.business_locations?.[0]?.lng ||
    locationData?.long;

  const businessName = locationDataBusinessProfile?.name;
  const businessLocation =
    locationDataBusinessProfile?.business_locations || [];

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };

  const center = {
    lat: Number(latitudeData),
    lng: Number(longitudeData),
  };

  const mapOptions = {
    zoom: 13,
    styles: [
      {
        featureType: "poi.business",
      },
    ],
  };

  useEffect(() => {
    if (window.google?.maps && !isMapLoaded) {
      setIsMapLoaded(true);
    }
  }, [isMapLoaded, window.google]);

  useEffect(() => {
    if (isMapLoaded) {
      businessLocation.forEach((location) => {
        new window.google.maps.Marker({
          position: { lat: Number(location.lat), lng: Number(location.lng) },
          map,
          title: businessName,
          icon: locationMarker,
        });
      });
    }
  }, [isMapLoaded, map, businessLocation, businessName, window.google?.maps]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {isMapLoaded && window.google?.maps ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={center}
          options={mapOptions}
          onLoad={(map) => setMap(map)}
        >
          {/* Child components like markers can be rendered here if needed */}
        </GoogleMap>
      ) : (
        <p>Loading map...</p>
      )}
    </div>
  );
};

export default ReactGoogleMapBusinessProfile;
