import { useEffect, useState } from "react";
import { PiArrowElbowDownRight } from "react-icons/pi";
import { MdVerified } from "react-icons/md";
import { LiaChevronUpSolid } from "react-icons/lia";
import { FaRegSmile } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import { getTimeDiffDetails } from "../../utils/TimeDetails";
import ReactSelector from "./ReactSelector";
import { IoStar } from "react-icons/io5";
import ReplyForm from "./ReplyForm";
import DirectoryImageInitials from "../atom/directoryImageInitials";
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollingLoader from "../atom/loader/scrollingLoader";

export default function ReviewsCard() {
  const [openReplyFormReviewId, setOpenReplyFormReviewId] = useState(null);

  const {
    listAllReviewsApi,
    fetchBusinessDetails,
    reviewDataList,
    lastReviewPage,
    loadingBusinessDetails,
  } = DirectoryDetails((state) => state);

  const handleReplyClick = (reviewId) => {
    setOpenReplyFormReviewId((current) =>
      current === reviewId ? null : reviewId
    );
  };

  const [showReactSelectorIndex, setShowReactSelectorIndex] = useState(null);
  const [showMoreMessage, setShowMoreMessage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  let { data } = fetchBusinessDetails || {};
  let business_id = data?.id;
  const handleShowReactSelectorClick = (index) => {
    setShowReactSelectorIndex(index);
  };

  const handleShowMore = (index) => {
    setShowMoreMessage((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  let getReviewApi = () => {
    if (business_id) {
      listAllReviewsApi({
        businessId: business_id,
        page: 1,
      }).then(() => {
        setCurrentPage(currentPage + 1);
      });
    }
  };
  useEffect(() => {
    getReviewApi();
  }, [business_id]);

  let profileName = (data) =>
    data?.user?.first_name + " " + data?.user?.last_name;

  return (
    <div
      id="scrollableReviewDiv"
      className=" w-full  overflow-y-auto overflow-x-hidden text-sm rounded-b-lg "
    >
      <InfiniteScroll
        dataLength={reviewDataList?.length}
        next={getReviewApi}
        hasMore={currentPage <= lastReviewPage}
        className="w-full"
        scrollThreshold={0.5}
        scrollableTarget="scrollableReviewDiv"
        loader={loadingBusinessDetails ? <ScrollingLoader /> : <></>}
      >
        {reviewDataList?.length !== 0 &&
          reviewDataList?.map((review, index) => (
            <div
              key={review?.id}
              className={`flex flex-col w-full text-sm  ${
                openReplyFormReviewId === review.id
                  ? "bg-lightBg dark:bg-darkModeBG "
                  : "bg-white dark:bg-darkModeMain"
              }`}
            >
              <div className=" w-full flex flex-col  px-4 py-1 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
                <div className="flex items-center justify-between w-full p-2">
                  <div className="flex items-center gap-2 pt-3 text-sm">
                    <DirectoryImageInitials
                      imageUrl={review?.user?.upload?.file_path}
                      alt={profileName(review)}
                      name={profileName(review)}
                      boxColor={data?.business_color?.toLowerCase()}
                      className={`rounded-full h-10 w-10 overflow-hidden`}
                    />

                    <p className="font-semibold">
                      {`${
                        review.user.first_name ? review.user.first_name : ""
                      } ${
                        review.user.middle_name ? review.user.middle_name : ""
                      } ${review.user.last_name ? review.user.last_name : ""}`}
                    </p>
                    <p className="px-2 text-gray-400 pt-0.5">
                      {getTimeDiffDetails(review?.updated_at)}
                    </p>
                    {review.is_verified && (
                      <div className="flex gap-2 px-4 mt-1">
                        <MdVerified color="#48CFFF" size={18} />
                        <p className="text-[11px] uppercase">Verified Review</p>
                      </div>
                    )}
                  </div>

                  {(review.rating || review.rating == 0) && (
                    <div className="flex items-center gap-2 px-2">
                      <div className="flex items-center gap-1">
                        {[...Array(Math.floor(review.rating))].map((_, i) => (
                          <IoStar key={i} size={18} className="text-ipink" />
                        ))}
                        {[...Array(5 - Math.floor(review.rating))].map(
                          (_, i) => (
                            <IoStar
                              key={Math.floor(review.rating) + i}
                              size={18}
                              className="text-gray-200 dark:text-darkModeBorder"
                            />
                          )
                        )}
                      </div>
                      <p className="text-[13px] hidden font-semibold">
                        {review?.rating}
                      </p>
                    </div>
                  )}
                </div>

                <p className="px-4 py-3 max-w-[90%]">
                  {showMoreMessage[index]
                    ? review?.review_text
                    : review?.review_text?.trim()?.substring(0, 250)}
                  {review?.review_text?.trim()?.length > 250 && (
                    <span onClick={() => handleShowMore(index)}>
                      {showMoreMessage[index] ? "Show Less" : "Show More"}
                    </span>
                  )}
                </p>

                <div className="flex items-center justify-between p-4">
                  <div className="relative flex items-center gap-2">
                    <button
                      onClick={() => handleReplyClick(review.id)}
                      className={`flex items-center justify-center gap-1 ml-2 ${
                        openReplyFormReviewId === review.id
                          ? "text-ipurple"
                          : "text-inherit"
                      }`}
                    >
                      <PiArrowElbowDownRight size={18} />
                      <p>
                        {" "}
                        {openReplyFormReviewId === review.id
                          ? "Hide Replies"
                          : "Reply"}{" "}
                      </p>
                    </button>
                    <button
                      className="flex items-center justify-center gap-1 ml-2"
                      onClick={() => handleReplyClick(review.id)}
                    >
                      {<LiaChevronUpSolid size={18} />}

                      <p>
                        {review.shares}

                        {review?.review_comments_count == 0
                          ? ""
                          : review?.review_comments_count}
                      </p>
                    </button>

                    <button
                      className="items-center justify-center hidden gap-1 ml-2"
                      onClick={() => handleShowReactSelectorClick(index)}
                    >
                      <FaRegSmile size={18} className="mr-1" />
                      React
                    </button>
                    {showReactSelectorIndex === index && (
                      <div className="absolute top-full left-28">
                        <ReactSelector
                          onClose={() => setShowReactSelectorIndex(null)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="relative hidden gap-2 pr-10">
                    <div className="w-[26px]  h-[26px] z-30 rounded-full bg-ipurple flex items-center justify-center ">
                      <AiFillLike color="white" />
                    </div>
                    <div className=" absolute left-[20px] text-[26px] z-20 h-[24px] rounded-full  flex items-center justify-center ">
                      😍
                    </div>
                    <div className=" absolute left-10 text-[26px] h-[24px] rounded-full  flex items-center justify-center ">
                      😂
                    </div>
                  </div>
                </div>
              </div>
              {openReplyFormReviewId === review.id && (
                <ReplyForm
                  onSubmit={() => setOpenReplyFormReviewId(null)}
                  reviewId={review?.id}
                />
              )}
            </div>
          ))}
      </InfiniteScroll>
    </div>
  );
}
