export const TicketIcon = ({className}) => {
   return (



<svg className={`svg-icon ${className}`} width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    {/* <title>Icons/Ticket</title> */}
    <g id="Icons/Ticket" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M13.03125,4 C11.7392578,4 10.671387,4.97109375 10.5285643,6.2 L8.53125,6.2 C7.14257812,6.2 6,7.3171875 6,8.675 L6,23.525 C6,24.8828125 7.14257812,26 8.53125,26 L21.46875,26 C22.8574219,26 24,24.8828125 24,23.525 L24,8.675 C24,7.3171875 22.8574219,6.2 21.46875,6.2 L19.4714357,6.2 C19.328613,4.97109375 18.2607422,4 16.96875,4 L13.03125,4 Z M13.03125,5.65 L16.96875,5.65 C17.4455565,5.65 17.8125,6.0087892 17.8125,6.475 C17.8125,6.9412108 17.4455565,7.3 16.96875,7.3 L13.03125,7.3 C12.5544435,7.3 12.1875,6.9412108 12.1875,6.475 C12.1875,6.0087892 12.5544435,5.65 13.03125,5.65 Z M8.53125,7.85 L10.9328612,7.85 C11.3898924,8.51171875 12.1611328,8.95 13.03125,8.95 L16.96875,8.95 C17.8388672,8.95 18.6101076,8.51171875 19.0671388,7.85 L21.46875,7.85 C21.9455565,7.85 22.3125,8.2087892 22.3125,8.675 L22.3125,23.525 C22.3125,23.9912108 21.9455565,24.35 21.46875,24.35 L8.53125,24.35 C8.0544435,24.35 7.6875,23.9912108 7.6875,23.525 L7.6875,8.675 C7.6875,8.2087892 8.0544435,7.85 8.53125,7.85 Z" id="Combined-Shape" fill="currentColor" fillRule="nonzero"></path>
    </g>
</svg>

)
}