import { useState, useEffect, useRef } from "react";
import { IoAddSharp } from "react-icons/io5";
import BusCardList from "./BusCardList";
import BusinessProfileContainer from "../business-profile/BusinessProfileContainer";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import { useNavigate } from "react-router-dom";
import DirectoryLanding from "./DirectoryLanding";
import InfiniteScroll from "react-infinite-scroll-component";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import { getUserImage, userDetails } from "../../routes/ProtectedRoutes";
import toast from "react-hot-toast";
import ScrollingLoader from "../atom/loader/scrollingLoader";
import { validate as isValidUUID } from "uuid";
import Loader from "../atom/loader/Loader";
import { sendMixpanelEvent } from "../../services/mixpanelService";

export default function DirectoryContainer() {
  const { getProfileDetails, sipAuthLoginApi } = AuthPersonalPhone(
    (state) => state
  );
  const getPageFilter = (x) => {
    switch (x) {
      case "recent":
        return "recent";
      case "saved":
        return "saved";
      case "suggested":
        return "suggested";
      default:
        return "search";
    }
  };

  const [activeFilter, setActiveFilter] = useState(
    getPageFilter(location?.pathname?.split("/directory/")?.[1] || "search")
  );

  const [activeBusinessId, setActiveBusinessId] = useState(null);

  useEffect(() => {
    getProfileDetails()
      .then(async (response) => {
        if (typeof response != "string") {
          await userDetails();
          await getUserImage();
          if (!localStorage.getItem("sipDetails")) {
            await sipAuthLoginApi();
          }
        } else {
          toast.error(response);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);
  const [lastPage, setLastPage] = useState("");
  const [savedBusiness, setSavedBusiness] = useState([]);
  const [recentBusiness, setRecentBusiness] = useState([]);
  const [suggestedBusiness, setSuggestedbusiness] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  let [currentPage, setCurrentPage] = useState(1);

  let navigate = useNavigate();

  const {
    recentBusinessDetailsApi,
    savedBusinessDetailsApi,
    suggestedBusinessDetailsApi,
    fetchBusinessDetailsApi,
    recentBusinessDetails,
    savedBusinessDetails,
    suggestedBusinessDetails,
    loadingBusinessDetails,
    recentBusinessLastPage,
    savedBusienssLastPage,
    suggestedBusinessLastPage,
    resetBusinessDeatils,
    loadingBusinessDirectory,
  } = DirectoryDetails((state) => state);
  useEffect(() => {
    const event = new Event("triggerExtension");

    // Dispatch the event on the window object
    window.dispatchEvent(event);
  }, []);
  useEffect(() => {
    if (savedBusinessDetails.length > 0) {
      setSavedBusiness(savedBusinessDetails);
      setLastPage(savedBusienssLastPage);
    } else if (recentBusinessDetails.length > 0) {
      setRecentBusiness(recentBusinessDetails);
      setLastPage(recentBusinessLastPage);
    } else if (suggestedBusinessDetails.length > 0) {
      setSuggestedbusiness(suggestedBusinessDetails);
      setLastPage(suggestedBusinessLastPage);
    }
  }, [recentBusinessDetails, suggestedBusinessDetails, savedBusinessDetails]);

  let getDirectoryDetailsApi = () => {
    if (activeFilter == "recent") {
      recentBusinessDetailsApi({
        page: currentPage,
      }).then(() => {
        setLoadingState(false);
        setCurrentPage(currentPage + 1);
      });
    } else if (activeFilter == "suggested") {
      suggestedBusinessDetailsApi({
        page: currentPage,
      }).then(() => {
        setLoadingState(false);
        setCurrentPage(currentPage + 1);
      });
    } else if (activeFilter == "saved") {
      savedBusinessDetailsApi({
        page: currentPage,
      }).then(() => {
        setLoadingState(false);
        setCurrentPage(currentPage + 1);
      });
    }
  };

  const handleSavedTrigger = () => {
    if (activeFilter == "recent") {
      recentBusinessDetailsApi({
        page: 1,
      });
    } else if (activeFilter == "suggested") {
      suggestedBusinessDetailsApi({
        page: 1,
      });
    } else {
      savedBusinessDetailsApi({
        page: 1,
      });
    }
  };

  useEffect(() => {
    getDirectoryDetailsApi();
  }, [activeFilter]);
  const scrollableDivRef = useRef(null);
  const handleSavedClick = () => {
    sendMixpanelEvent("Saved Business Viewed");
    const newFilter = "saved";

    setActiveFilter(newFilter);
    navigate(`/directory/${newFilter}`);
    setCurrentPage(1);
    handleScrollToTop();
  };

  const handleRecentClick = () => {
    sendMixpanelEvent("Recent Business Viewed");
    window.scrollTo(0, 0);
    const newFilter = "recent";
    setActiveFilter(newFilter);
    navigate(`/directory/${newFilter}`);
    setCurrentPage(1);
    handleScrollToTop();
  };

  const handleSuggestedClick = () => {
    sendMixpanelEvent("Suggested Business Viewed");
    window.scrollTo(0, 0);
    const newFilter = "suggested";
    setActiveFilter(newFilter);
    navigate(`/directory/${newFilter}`);
    setCurrentPage(1);
    handleScrollToTop();
  };
  const handleSearchClick = () => {
    window.scrollTo(0, 0);
    const newFilter = "search";
    setActiveFilter(newFilter);
    navigate(`/directory/${newFilter}`);
    setCurrentPage(1);
    handleScrollToTop();
  };
  const handleOpenSearch = () => {};
  const onBusinessCardClick = async (businessId) => {
    if (!isValidUUID(businessId)) {
      return navigate("/page-not-found-404");
    }
    await fetchBusinessDetailsApi({ businessId: businessId })
      .then((data) => {
        if (
          data?.response?.data?.message?.includes(
            "invalid input syntax for type uuid:"
          )
        ) {
          navigate("/page-not-found-404");
        }
      })
      .catch(() => {});
    await setActiveBusinessId(businessId);
  };

  const handleAddBusinessClick = () => {
    sendMixpanelEvent("Add Business Initiated");
    navigate("/create-business");
  };

  useEffect(() => {
    setCurrentPage(1);
    resetBusinessDeatils();
  }, [activeFilter]);
  const handleScrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0;
    }
  };

  return (
    <div>
      <div className="fixed flex flex-col items-center justify-center w-full pr-[110px] directory bg-lightBg dark:bg-darkModeBG  ">
        {activeBusinessId ? (
          <BusinessProfileContainer businessId={activeBusinessId} />
        ) : (
          <div
            style={{ height: "calc(100vh - 170px)" }}
            className="box-border relative flex flex-col w-full w-max-[1320px] mx-2 my-6 bg-white rounded-lg dark:bg-darkModeMain bg-default dark:border-darkModeBorder"
          >
            <div className="w-full border-[1px] border-gray-100 justify-center rounded-t-lg dark:border-darkModeBorder h-[60px] flex items-center p-5 gap-2 justify-between">
              <div
                id="step-5"
                className="flex flex-row items-center gap-[15px]"
              >
                <h3 className="text-sm font-semibold uppercase">Directory</h3>
                <div className="inline-block border-l-[1px] h-[25px] self-stretch bg-gray-200 dark:bg-darkModeMain opacity-100 dark:opacity-50"></div>
                <nav className="">
                  <ul className="flex flex-row items-center gap-[15px] text-sm text-muted">
                    <li
                      className={`${
                        activeFilter === "search"
                          ? "text-indigo-500 font-semibold"
                          : "hover:text-indigo-500"
                      }`}
                    >
                      <button onClick={handleSearchClick}>Search</button>
                    </li>
                    <li
                      className={`${
                        activeFilter === "saved"
                          ? "text-indigo-500 font-semibold"
                          : "hover:text-indigo-500"
                      }`}
                    >
                      <button onClick={handleSavedClick}>Saved</button>
                    </li>
                    <li
                      className={`${
                        activeFilter === "recent"
                          ? "text-indigo-500 font-semibold"
                          : "hover:text-indigo-500"
                      }`}
                    >
                      <button onClick={handleRecentClick}>Recent</button>
                    </li>
                    <li
                      className={`${
                        activeFilter === "suggested"
                          ? "text-indigo-500 font-semibold"
                          : "hover:text-indigo-500"
                      }`}
                    >
                      <button onClick={handleSuggestedClick}>Suggested</button>
                    </li>
                  </ul>
                </nav>
              </div>
              <button
                id="step-8"
                onClick={(e) => {
                  handleAddBusinessClick(e);
                }}
                className="flex items-center gap-2 px-2"
              >
                <IoAddSharp size={20} />
                <p className="text-sm font-semibold">Add Business</p>
              </button>
            </div>
            {loadingState && activeFilter != "search" ? (
              <div className="flex justify-center items-center">
                <Loader />
              </div>
            ) : (
              <>
                {activeFilter === "search" ? (
                  <DirectoryLanding />
                ) : (
                  <div
                    ref={scrollableDivRef}
                    className="flex flex-col !min-h-[200px] bg-white overflow-auto dark:bg-darkModeMain overflow-y-scroll-auto w-full h-full border-[1px]  border-gray-100  dark:border-darkModeBorder border-t-transparent rounded-b-lg space-y-2 "
                    id="scrollableDivSearch"
                  >
                    <InfiniteScroll
                      dataLength={
                        activeFilter == "saved"
                          ? savedBusiness?.length
                          : activeFilter == "recent"
                          ? recentBusiness?.length
                          : activeFilter == "suggested"
                          ? suggestedBusiness?.length
                          : 0
                      }
                      next={getDirectoryDetailsApi}
                      hasMore={currentPage <= lastPage}
                      scrollableTarget="scrollableDivSearch"
                      scrollThreshold={0.5}
                      className=""
                      loader={
                        loadingBusinessDetails ? <ScrollingLoader /> : <></>
                      }
                    >
                      <BusCardList
                        businesses={
                          activeFilter == "saved"
                            ? savedBusiness
                            : activeFilter == "recent"
                            ? recentBusiness
                            : activeFilter == "suggested"
                            ? suggestedBusiness
                            : []
                        }
                        onCardClick={onBusinessCardClick}
                        onCardApiCall={() => handleSavedTrigger()}
                        selectedTab={activeFilter}
                      />
                    </InfiniteScroll>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
