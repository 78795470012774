import { TECollapse } from "tw-elements-react";
import CategoriesCheckbox from "./CategoriesCheckbox";
import { useSearch } from "../../../../context/SearchContext";
import { isEqual } from "lodash";

export default function CategoriesAccordian(props) {
  let { data, locationCategory, setLocationCategory, collapseShow } =
    props || [];

  const { filters, setFilters } = useSearch();

  const handleCategoryChange = (selectedCategories) => {
    if (!isEqual(selectedCategories, filters.category)) {
      setFilters({ ...filters, category: selectedCategories });
    }
  };

  return (
    <div className=" overflow-y-auto ">
      <div className=" dark:bg-darkModeMain ">
        <TECollapse
          show={collapseShow}
          className="accordionFilter !mt-0 !rounded-b-none !shadow-none"
        >
          <div className="px-5 py-4">
            <CategoriesCheckbox
              categoryArr={data}
              onFilterChange={handleCategoryChange}
              setSelectedCategories={setLocationCategory}
              selectedCategories={locationCategory}
            />
          </div>
        </TECollapse>
      </div>
    </div>
  );
}
