import { useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import { SettingService } from "../../services/setting/SettingService";
import { logOut } from "../../routes/ProtectedRoutes";
import { useNavigate } from "react-router-dom";
import { PhoneNumberValidation } from "../../utils/validate/NumberValidation";
import { handleTextPaste } from "../../utils/common";

const ConfirmCodeInput = (props) => {
  let {
    countryCode,
    phoneNumber,
    setActiveSettingsView,
    sendEmail,
    setSendEmail,
  } = props || {};
  const [code, setCode] = useState(new Array(6).fill(""));
  const [resendButton, setResendButton] = useState(false);
  const {
    changePhoneNumberApi,
    confirmChangeNumberApi,
    confirmEmailApi,
    verifyChangeEmailApi,
  } = SettingService((state) => state);

  const { getProfileDetails, LogoutApi } = AuthPersonalPhone((state) => state);

  const [remainingTime, setRemainingTime] = useState(90);
  const navigate = useNavigate();

  //ResendVerifyConfirmationCode

  useEffect(() => {
    if (!resendButton) {
      setTimeout(() => {
        setResendButton(true);
      }, 90000);
    }
  }, [resendButton]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (remainingTime == -1) {
        /* empty */
      } else {
        setRemainingTime((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [remainingTime]);

  const inputRefs = useRef(new Array(6).fill(null));

  const handleChange = (element, index) => {
    const newCode = [...code];
    newCode[index] = element.value;
    setCode(newCode);
    if (element.value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleNextClick = () => {
    if (sendEmail && code.every((digit) => digit.trim() !== "")) {
      confirmEmailApi({
        new_email: sendEmail,
        confirmation_code: code.join(""),
      }).then(async (data) => {
        if (data.success == true) {
          await getProfileDetails();
          setActiveSettingsView("changeEmail");
          toast.success("Email updated successfully");
          setSendEmail("");
        }
      });
    } else {
      if (code.every((digit) => digit.trim() !== "")) {
        const confirmationCode = code.join("");
        confirmChangeNumberApi({
          country_code: countryCode,
          phone_number: phoneNumber,
          confirmation_code: confirmationCode,
        }).then((data) => {
          if (data.success == true) {
            toast.success(
              "Phone number updated successfully. Please sign in again."
            );
            logOut(LogoutApi, navigate);
          } else {
            toast.error(data.message);
          }
        });
      }
    }
  };
  const resendVerficationCode = () => {
    if (sendEmail) {
      verifyChangeEmailApi({ new_email: sendEmail });
    } else {
      const verificationData = {
        country_code: countryCode,
        phone_number: PhoneNumberValidation(phoneNumber),
      };
      changePhoneNumberApi(verificationData)
        .then(() => {
          toast.success("SMS successfully sent");
        })
        .catch(() => {
          toast.error("Error during verification");
        });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <p className="font-light w-[261px] text-center pb-4 pt-2">
        A 6-digit confirmation code has been sent to{" "}
        <span className="font-semibold">
          {countryCode}-{phoneNumber}
        </span>{" "}
        via SMS.
      </p>

      <div className="flex justify-center space-x-2">
        {code.map((singleDigit, index) => (
          <input
            ref={(el) => (inputRefs.current[index] = el)}
            className="w-12 text-lg text-center text-black border-[1px]  border-gray-100  border-gray-300 rounded-lg h-14"
            type="text"
            key={index}
            maxLength={1}
            value={singleDigit}
            onChange={(e) => handleChange(e.target, index)}
            onFocus={(e) => e.target.select()}
            onPaste={(event) => {
              handleTextPaste(event, code, setCode);
            }}
          />
        ))}
      </div>
      <p className="text-sm pt-8 font-thin text-center ">
        Resend confirmation code
      </p>
      {resendButton ? (
        <p
          className="text-sm font-bold text-center underline pb-4 pt-2 cursor-pointer"
          onClick={() => {
            setRemainingTime(90);
            setResendButton(false);
            resendVerficationCode();
          }}
        >
          Resend
        </p>
      ) : (
        <p className="font-bold py-2">
          {" "}
          {remainingTime < 60
            ? `${remainingTime} seconds`
            : `1:${remainingTime - 60} minutes`}
        </p>
      )}

      <div className="w-full pb-6 flex flex-col justify-center items-center">
        <button
          className={
            code.every((digit) => digit.trim() !== "")
              ? "rounded-full hover:font-bold uppercase w-[90%] bg-ipurple px-4 py-2.5 my-2 text-sm  text-white shadow-sm font-semibold"
              : "rounded-full hover:font-bold uppercase w-[90%] bg-gray-400 px-4 py-2.5 my-2 text-sm  text-white shadow-sm font-semibold "
          }
          onClick={handleNextClick}
        >
          Next
        </button>
        <button
          className="rounded-full hover:font-bold uppercase w-[90%] my-2 py-2.5 px-4 font-semibold bg-white text-black border-b border-gray-100lack border-[1px]  border-gray-100  text-sm  shadow-sm"
          onClick={() => {
            if (sendEmail) {
              setActiveSettingsView("changeEmail");
            } else {
              setActiveSettingsView("changePhone");
            }
          }}
        >
          Cancel
        </button>
      </div>

      <div className="flex flex-row py-4 items-center justify-center w-full space-x-2">
        {/* <p className="text-sm font-thin"> Can&apos;t receive SMS? </p>
        <p className="text-sm font-semibold cursor-pointer">
          {" "}
          Request a call to verify
        </p> */}
      </div>
    </div>
  );
};

export default ConfirmCodeInput;
