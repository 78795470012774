import { useRef, useState } from "react";
import round from "../../assets/roundbutton.webp";
import { sipCallParams } from "../../utils/DeviceDetails/SipDetails";
import IalloLogo from "../icons/IalloLogo";
import RescheduleCallModal from "../calls/RescheduleCallModal";
import dayjs from "dayjs";
import ContactBusinessCard from "./ContactBusinessCard";
import { checkExtensionStatus } from "../atom/checkExtension";
import { useSearch } from "../../context/SearchContext";
import UserMessage from "./UserMessage";
import AssistantMessage from "./AssistantMessage";
export default function AfterCallMessage(props) {
  const {
    chatMessage,
    setInputValue,
    handleSubmit,
    chatRecord,
    initiateSocketChat,
    chatDetailsLocally,
    setChatDetailsLocally,
  } = props || {};

  const { setSearchViewToggle } = useSearch();
  const [showRescheduleCallModal, setShowRescheduleCallModal] = useState(false);
  const selectedScheduledTime = useRef("");
  const selectedMessageId = useRef(null);
  // const [selectedMessageId, setSelectedMessageId] = useState(null);
  const btnClassName =
    "h-[45px] w-[305px] rounded-full border-[1px]  border-gray-100 text-[12px] font-semibold";
  const btnClassNameMap =
    "h-[45px] w-[305px] rounded-full border-[1px]  border-gray-100 text-[12px] font-semibold";
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const emojis = ["😡", "😔", "😐", "🙂", "😍"];
  const handleEmojiClick = (value, index) => {
    setSelectedEmoji(index);
    initiateSocketChat(value);
  };

  function callNow() {
    const pno = chatMessage.message_json?.phone_number;
    if (!pno) return;
    const sipCallData = sipCallParams(pno, {
      business_id: chatMessage.message_json?.business_id,
      chat_id: chatMessage.chat_id,
      ticket_id: chatRecord?.tickets?.id,
    });
    window.postMessage(sipCallData, "*");
  }
  const customCall = (data) => {
    window.postMessage(data, "*");
    checkExtensionStatus();
  };
  const answeredFunc = (msgData) => {
    const selectId = msgData?.id || msgData;
    const localData = chatDetailsLocally?.map((chatDetailsData) => {
      if (chatDetailsData?.id == selectId) {
        return {
          ...chatDetailsData,
          message_json: { ...chatDetailsData?.message_json, answered: true },
        };
      } else {
        return {
          ...chatDetailsData,
        };
      }
    });
    setChatDetailsLocally(localData);
  };
  // Send user input to socket
  const socketReply = (selectedOpt, msgText, messageJson, msgId) => {
    selectedMessageId.current = msgId;
    if (messageJson?.id == "update_search_text" && selectedOpt == "1") {
      initiateSocketChat(1);
      answeredFunc(msgId);
    } else if (messageJson?.id == "redirect_to_search" && selectedOpt == "1") {
      setSearchViewToggle(true);
    } else if (
      msgText == "Set a reminder" ||
      msgText == "Schedule a callback"
    ) {
      if (messageJson?.start_time && messageJson?.end_time) {
        selectedScheduledTime.current = messageJson?.start_time;
        setShowRescheduleCallModal(true);
      } else if (messageJson?.start_time) {
        initiateSocketChat(selectedOpt);
      } else {
        selectedScheduledTime.current = "";
        setShowRescheduleCallModal(true);
      }
    } else if (
      (messageJson?.id == "customer_must_check_email" && selectedOpt == "1") ||
      (messageJson?.id == "agent_must_send_email" && selectedOpt == "1") ||
      (messageJson?.id == "customer_must_send_email" && selectedOpt == "1")
    ) {
      setShowRescheduleCallModal(true);
      initiateSocketChat(selectedOpt);
      answeredFunc(msgId);
    } else if (messageJson?.id == "call_another_number" && selectedOpt == "1") {
      initiateSocketChat(1);
      answeredFunc(msgId);
      const sipCallData = sipCallParams(messageJson?.other_business_contact);
      customCall(sipCallData);
    } else {
      initiateSocketChat(selectedOpt);
      answeredFunc(msgId);
    }
  };

  const renderButtons = (
    messageType,
    messageJson,
    btnClassName,
    selectedMsgId
  ) => {
    if (messageType === "call_back_now" || messageType === "show_call_button") {
      return (
        <div className="w-full flex items-center justify-center relative">
          <div className="w-[450px] h-[150px] items-center justify-center flex  flex-col bg-white dark:hover:bg-darkModeBG  rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder">
            <div className="absolute  -top-[18px]">
              <IalloLogo
                styleClass="!w-[35px] !h-[35px] absolute"
                iconWidth="27px"
                iconHeight="13px"
              />
            </div>
            <div className="h-[1px]"></div>
            <button
              className={`${btnClassName} bg-ipurple hover:bg-[#5A70F9] text-white`}
              onClick={() => callNow()}
            >
              CALL NOW
            </button>
          </div>{" "}
        </div>
      );
    } else if (
      messageType === "question" &&
      [
        "write_notes",
        "agent_must_send_email",
        "customer_query_solved",
        "call_end_business_review",
        "customer_must_call_back_later",
        "agent_must_call_back_later",
        "customer_must_check_email",
        "customer_must_send_email",
        "alternate_business_contact_input",
        "call_another_number",
        "ask_search",
        "redirect_to_search",
        "update_search_text",
      ].includes(messageJson.id)
    ) {
      return (
        <div className="w-full">
          <AssistantMessage AssistanceMessage={chatMessage.message_text} />
          {!messageJson.answered ? (
            <div className="w-full flex items-center justify-center relative mt-6 ">
              <div className="w-[450px] h-[20dvh] items-center justify-evenly flex  flex-col bg-white dark:hover:bg-darkModeBG  rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder">
                <div className="absolute  -top-[18px]">
                  <IalloLogo
                    styleClass="!w-[35px] !h-[35px] absolute"
                    iconWidth="27px"
                    iconHeight="13px"
                  />
                </div>
                <div className="h-[1px]"></div>
                {messageJson?.options?.map((option, index) => (
                  <button
                    key={index}
                    className={`${btnClassNameMap} bg-ipurple hover:bg-[#5A70F9] text-white`}
                    onClick={() =>
                      socketReply(
                        option.value,
                        option.title,
                        messageJson,
                        selectedMsgId
                      )
                    }
                  >
                    {option.title}
                  </button>
                ))}
              </div>{" "}
            </div>
          ) : (
            messageJson?.answered &&
            messageJson?.start_time && (
              <UserMessage
                userMessage={dayjs(messageJson?.start_time).format(
                  "YYYY-MM-DD hh:mm a"
                )}
              />
            )
          )}
        </div>
      );
    } else if (messageJson?.id == "call_pop_up") {
      return messageJson.answered ? (
        <p className="flex-1 w-full p-4  ml-2 text-sm rounded-b-lg rounded-tr-lg bg-gradient-to-r from-indigo-600 to-purple-700 overflow-wrap break-word">
          {chatMessage.message_text}
        </p>
      ) : (
        <div className="flex justify-center w-full">
          <ContactBusinessCard
            userData={messageJson}
            handleCancelBtn={() => {
              initiateSocketChat(3);
              answeredFunc(selectedMsgId);
              // handleSubmit(3);
              // setInputValue(3);
            }}
            handleCallNow={() => {
              let currentGivenTime = dayjs();
              currentGivenTime = currentGivenTime.add(5, "minutes");
              const dates = dayjs(currentGivenTime)
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss");
              initiateSocketChat(2);
              initiateSocketChat(dates + ".000Z");
              answeredFunc(selectedMsgId);
            }}
            callInitiate={() => {
              initiateSocketChat(1);
              answeredFunc(selectedMsgId);
              // handleSubmit(1);
              // setInputValue(1);
            }}
            setShowRescheduleCallModal={() => setShowRescheduleCallModal(true)}
          />
        </div>
      );
    } else if (messageJson?.id == "leave_a_review") {
      return (
        <div className="w-full">
          <AssistantMessage AssistanceMessage={chatMessage.message_text} />

          <div className="w-full flex justify-center">
            <div className="w-[40%] h-[150px] items-center relative justify-evenly flex  flex-col bg-white dark:hover:bg-darkModeBG  rounded-lg border-[1px]  border-gray-100  dark:border-darkModeBorder">
              <div className="absolute  -top-[18px]">
                <IalloLogo
                  styleClass="!w-[35px] !h-[35px] absolute"
                  iconWidth="27px"
                  iconHeight="13px"
                />
              </div>
              <div className="flex items-center gap-4 px-5 ">
                {messageJson?.options?.map((option, index) => (
                  <button
                    key={index}
                    className={`w-[60px] h-[60px] text-[33px] flex justify-center items-center rounded-lg border-0 bg-lightBg dark:border-darkModeBorder border-neutral-100 transition transform hover:-translate-y-3 ${
                      messageJson.answered &&
                      (index === selectedEmoji ||
                        index + 1 === messageJson.answer)
                        ? "bg-ipurple"
                        : ""
                    }`}
                    onClick={() => handleEmojiClick(option.value, index)}
                  >
                    {emojis[index]}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <AssistantMessage AssistanceMessage={chatMessage.message_text} />;
    }
  };

  return (
    <>
      <div className="flex flex-row items-center justify-start w-full py-1 text-white">
        {renderButtons(
          chatMessage.message_type,
          chatMessage.message_json,
          btnClassName,
          chatMessage
        )}
      </div>

      {showRescheduleCallModal && (
        <RescheduleCallModal
          pageType="AIchat"
          onClose={(data) => {
            setShowRescheduleCallModal(false);
            initiateSocketChat(data);
            answeredFunc(selectedMessageId.current);
            // handleSubmit(data);
            // setInputValue(data);
          }}
          closeModal={() => {
            setShowRescheduleCallModal(false);
          }}
          selectedScheduledTime={selectedScheduledTime.current}
          setSelectedScheduledTime={(data) => {
            selectedScheduledTime.current = data;
          }}
          businessId={""}
          scheduledTime={
            selectedScheduledTime.current?.split(" ")[0]
              ? dayjs(selectedScheduledTime.current?.split(" ")[0])
                  .set(
                    "hour",
                    selectedScheduledTime.current?.split(" ")[1]?.split(":")[0]
                  )
                  .set(
                    "minute",
                    selectedScheduledTime.current?.split(" ")[1]?.split(":")[1]
                  )
              : new Date().setMinutes(new Date().getMinutes() + 5)
          }
          showSchedule={true}
        />
      )}
    </>
  );
}
