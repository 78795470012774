import { useState } from "react";
import ThemeSwitcher from "../ThemeSwitcher";
import { FaChevronRight } from "react-icons/fa6";
import ChangeEmailView from "./views/ChangeEmailView";
import ChangePhoneView from "./views/ChangePhoneView";
import SecurityView from "./views/SecurityView";
import BroswerExtensionView from "./views/BroswerExtensionView";
import ChatCallSettingsView from "./views/ChatCallSettingsView";
import NotificationsView from "./views/NotificationsView";
import LanguageSettingsView from "./views/LanguageSettingsView";
import RecordVoiceView from "./views/RecordVoiceView";
import ReplaceMusicView from "./views/ReplaceMusicView";
import DeleteAccountView from "./views/DeleteAccountView";
import PhoneValidate from "./PhoneValidate";
import PhoneNumberUpdated from "./views/PhoneNumberUpdated";
import PinCode from "./PinCode";
import RecordVoice from "./views/RecordVoice";
import ClearHistoryModal from "./ClearHistoryModal";

import AccountDeleteModal from "./AccountDeleteModal";
import {
  SettingApps,
  CallOutcome,
  Clear,
  Code,
  Condition,
  Email,
  Language,
  Mobile,
  Music,
  Notification,
  Privacy,
  Transcript,
  Trash,
} from "../icons/Icons";
import PrivacyPolicySettingView from "./views/PrivacyPolicySettingView";
import TermsAndConditionsSettingsView from "./views/TermsAndConditionsSettingsView";
import { sendMixpanelEvent } from "../../services/mixpanelService";

export default function SettingsContainer() {
  const [activeSettingsView, setActiveSettingsView] = useState("changePhone");
  const [sendPhone, setSendPhone] = useState("");
  const [sendCode, setSendCode] = useState("");
  const [sendEmail, setSendEmail] = useState("");

  const handleSettingsViewChange = (viewName) => {
    setActiveSettingsView(viewName);
  };

  const showPhoneValidate = () => {
    setActiveSettingsView("phoneValidate");
  };

  const showPhoneNumberUpdated = () => {
    setActiveSettingsView("phoneNumberUpdated");
  };

  const showPinCode = () => {
    setActiveSettingsView("pinCode");
  };

  const [isClearHistoryModalOpen, setIsClearHistoryModalOpen] = useState(false);

  const handleShowClearHistoryModal = () => {
    setIsClearHistoryModalOpen(true);
  };
  const handleCloseClearHistoryModal = () => {
    setIsClearHistoryModalOpen(!isClearHistoryModalOpen);
  };

  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);

  const handleShowDeleteAccountModal = () => {
    setIsDeleteAccountModalOpen(true);
  };
  const handleCloseDeleteAccountModal = () => {
    setIsDeleteAccountModalOpen(!isDeleteAccountModalOpen);
  };

  const [isConfirmDeleteAccountModalOpen, setIsConfirmDeleteAccountModalOpen] =
    useState(false);

  const handleShowConfirmDeleteAccountModal = () => {
    setIsConfirmDeleteAccountModalOpen(true);
  };

  const handleCloseConfirmDeleteAccountModal = () => {
    setIsConfirmDeleteAccountModalOpen(!isConfirmDeleteAccountModalOpen);
    setIsDeleteAccountModalOpen(false);
  };

  const getViewDisplayName = (viewName) => {
    const viewDisplayNames = {
      changePhone: "Change Phone Number",
      changeEmail: "Change Email",
      security: "Security",
      browserExtension: "Browser Extension",
      chatCall: "Call & Chat Settings",
      notifications: "Notifications",
      language: "Language",
      recordVoice: "Record Your Voice",
      replaceMusic: "Replace On-Hold Music",
      clearHistory: "Clear Search History",
      deleteAccount: "Delete Account",
      phoneValidate: "Phone Validation",
      pinCode: "PIN Code",
      recordVoiceComponent: "Record your voice",
      privacyPolicy: "Privacy Policy",
      termsAndCondition: "Terms & Condition",
    };
    return viewDisplayNames[viewName] || "";
  };

  return (
    <div className="flex w-full text-sm ">
      {isClearHistoryModalOpen && (
        <ClearHistoryModal onClose={handleCloseClearHistoryModal} />
      )}

      {isConfirmDeleteAccountModalOpen && (
        <AccountDeleteModal onClose={handleCloseConfirmDeleteAccountModal} />
      )}
      <div className="w-full min-w-4xl !h-[calc(100dvh-170px)]  bg-white border-[1px] border-gray-100  dark:border-darkModeBorder rounded-lg dark:bg-darkModeMain mt-6 mx-auto  overflow-x-hidden">
        <div className="flex h-full">
          <div className="w-1/3 border-r-[1px] border-gray-100 dark:border-darkModeBorder flex flex-col scrollable-container-hide h-full overflow-y-auto rounded ">
            {/* scrollable-container-hide -- class to hide scrollbar */}
            <div className="h-[60px] flex items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
              <p className="p-5 font-bold uppercase">Account & Security</p>
            </div>
            <nav>
              <ul>
                <li
                  onClick={() => handleSettingsViewChange("changePhone")}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder
                                ${
                                  activeSettingsView === "changePhone"
                                    ? "bg-lightBg dark:bg-darkModeBG"
                                    : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                                } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-ipurple">
                      <Mobile color="white" className="p-[3.75px]" />
                    </div>
                    <p>Change Phone Number</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>
                <li
                  onClick={() => {
                    sendMixpanelEvent("Change Email Initiated");
                    handleSettingsViewChange("changeEmail");
                  }}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "changeEmail"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-ipurple">
                      <Email color="white" className="p-[3.75px]" />
                    </div>
                    <p>Change Email</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>
                <li
                  onClick={() => {
                    {
                      sendMixpanelEvent("Security Viewed");
                      handleSettingsViewChange("security");
                    }
                  }}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "security"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-ipurple">
                      <Code color="white" className="p-[3.75px]" />
                    </div>
                    <p>Security</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>
              </ul>
            </nav>
            <p className=" p-5 font-semibold uppercase w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
              App Settings
            </p>
            <nav>
              <ul>
                <li
                  onClick={() => {
                    handleSettingsViewChange("browserExtension");
                    sendMixpanelEvent("Browser Extension Linkage Initiated");
                  }}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "browserExtension"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-ipink">
                      <SettingApps color="white" className="p-[3.75px]" />
                    </div>
                    <p>Browser Extension</p>
                  </div>
                  <FaChevronRight size={20} className="hidden mr-4" />
                  <div className="bg-gradient-to-r mr-3 from-blue-300 via-teal-400 to-teal-300 text-[10px] font-semibold uppercase flex justify-center items-center text-white rounded-full w-[70px] h-[30px]">
                    Soon
                  </div>
                </li>
                <li
                  onClick={() => {
                    sendMixpanelEvent("Call Settings Viewed");
                    handleSettingsViewChange("chatCall");
                  }}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "chatCall"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-ipink">
                      <CallOutcome color="white" className="p-[2.63px]" />
                    </div>
                    <p>Call & Chat Settings</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>
                <li
                  onClick={() => {
                    sendMixpanelEvent("Replace on hold music viewed");
                    handleSettingsViewChange("replaceMusic");
                  }}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "replaceMusic"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-ipink">
                      <Music color="white" className="p-[3.75px]" />
                    </div>
                    <p>Replace On-Hold Music</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>
                <li
                  onClick={() => handleSettingsViewChange("recordVoice")}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "recordVoice"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-ipink">
                      <Transcript
                        color="white"
                        className="p-[3.75px]"
                        size={20}
                      />
                    </div>
                    <p>Record Your Voice</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>
                <li
                  onClick={() => handleSettingsViewChange("notifications")}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "notifications"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-ipink">
                      <Notification color="white" className="p-[3.75px]" />
                    </div>
                    <p>Notifications</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>
                <li
                  onClick={() => handleSettingsViewChange("language")}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "language"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-ipink">
                      <Language color="white" className="p-[3.75px]" />
                    </div>
                    <p>Language</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>
              </ul>
            </nav>
            <p className="p-5 font-semibold uppercase w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
              Privacy
            </p>
            <nav>
              <ul>
                <li
                  // onClick={handleShowClearHistoryModal}
                  onClick={() => handleSettingsViewChange("privacyPolicy")}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "privacyPolicy"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-[#2E398F]">
                      <Privacy className="p-[3.75px]" color="white" />
                    </div>
                    <p>Privacy Policy</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>
                <li
                  // onClick={handleShowDeleteAccountModal}
                  onClick={() => handleSettingsViewChange("termsAndCondition")}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "termsAndCondition"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-[#2E398F]">
                      <Condition className="p-[3.75px]" color="white" />
                    </div>
                    <p>Term & Conditions</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>

                <li
                  onClick={handleShowClearHistoryModal}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "clearHistory"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-[#2E398F]">
                      <Clear className="p-[3.75px]" color="white" />
                    </div>
                    <p>Clear Search History</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>

                <li
                  onClick={handleShowConfirmDeleteAccountModal}
                  className={`h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder ${
                    activeSettingsView === "deleteAccount"
                      ? "bg-lightBg dark:bg-darkModeBG"
                      : "hover:bg-lightBg dark:hover:bg-darkModeBG"
                  } hover:cursor-pointer`}
                >
                  <div className="flex items-center gap-4 p-3 ml-2 font-semibold">
                    <div className="flex items-center justify-center rounded-md bg-[#2E398F]">
                      <Trash className="p-[3.75px]" color="white" />
                    </div>
                    <p>Delete Account</p>
                  </div>
                  <FaChevronRight size={20} className="mr-4" />
                </li>
              </ul>
            </nav>
          </div>

          <div className="flex flex-col w-2/3 ">
            <div className="h-[60px] flex items-center justify-between border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
              <p className="p-8 font-bold uppercase">
                {" "}
                {getViewDisplayName(activeSettingsView)}{" "}
              </p>
            </div>
            {activeSettingsView === "changeEmail" && (
              <ChangeEmailView
                setActiveSettingsView={setActiveSettingsView}
                setSendEmail={setSendEmail}
              />
            )}
            {activeSettingsView === "security" && (
              <SecurityView onShowPinCode={showPinCode} />
            )}
            {activeSettingsView === "browserExtension" && (
              <BroswerExtensionView />
            )}
            {activeSettingsView === "chatCall" && <ChatCallSettingsView />}
            {activeSettingsView === "notifications" && <NotificationsView />}
            {activeSettingsView === "language" && <LanguageSettingsView />}
            {activeSettingsView === "recordVoice" && (
              <RecordVoiceView
                onRecordButtonClick={() =>
                  handleSettingsViewChange("recordVoiceComponent")
                }
              />
            )}
            {activeSettingsView === "privacyPolicy" && (
              <PrivacyPolicySettingView />
            )}

            {activeSettingsView === "termsAndCondition" && (
              <TermsAndConditionsSettingsView />
            )}

            {activeSettingsView === "replaceMusic" && <ReplaceMusicView />}

            {activeSettingsView === "deleteAccount" && <DeleteAccountView />}
            {activeSettingsView === "changePhone" && (
              <ChangePhoneView
                onShowPhoneValidate={showPhoneValidate}
                setSendPhone={setSendPhone}
                setSendCode={setSendCode}
              />
            )}
            {activeSettingsView === "phoneValidate" && (
              <PhoneValidate
                onShowPhoneNumberUpdated={showPhoneNumberUpdated}
                sendPhone={sendPhone}
                sendCode={sendCode}
                sendEmail={sendEmail}
                setSendEmail={setSendEmail}
                setActiveSettingsView={setActiveSettingsView}
              />
            )}
            {activeSettingsView === "phoneNumberUpdated" && (
              <PhoneNumberUpdated />
            )}
            {activeSettingsView === "pinCode" && (
              <PinCode setActiveSettingsView={setActiveSettingsView} />
            )}
            {activeSettingsView === "recordVoiceComponent" && <RecordVoice />}
          </div>
        </div>
      </div>
    </div>
  );
}
