import { useState, useEffect, useRef } from "react";
import { MdOutlineWhatsapp, MdVerified } from "react-icons/md";
import { TbLockCancel } from "react-icons/tb";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdOutlineAccessTime } from "react-icons/md";
import { LuPhone, LuMail } from "react-icons/lu";
import { TbBrandMessenger } from "react-icons/tb";
import { PiChatTeardropText } from "react-icons/pi";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import { BsChatText } from "react-icons/bs";
import ContactCardCallClick from "./ContactCardCallClick";
import {
  formatInternationalPhoneNumber,
  truncateString,
} from "../../utils/numberFormat";
import { useLocation, useNavigate } from "react-router-dom";
import { CallIcon } from "../icons/CallIcon";
import Dropdown from "../CreateEditProfile/DropdownEditRemove";
import PropTypes from "prop-types";
import { FiInstagram } from "react-icons/fi";
import { PrivateLockIcon, Globe } from "../icons/Icons";
import { FaFacebookSquare } from "react-icons/fa";
import { TiSocialSkypeOutline } from "react-icons/ti";

ContactsCard.propTypes = {
  onClose: PropTypes.func,
  formik: PropTypes.any,
  setEditIndex: PropTypes.any,
  setEditContact: PropTypes.any,
  setIsAddPopupOpen: PropTypes.any,
  business_contacts: PropTypes.any,
  showOpt: PropTypes.bool,
};

export const optionIcon = {
  Phone: CallIcon,
  Service: LuPhone,
  Mail: LuMail,
  Livechat: PiChatTeardropText,
  FacebookMessenger: TbBrandMessenger,
  Website: Globe,
  Email: LuMail,
  Instagram: FiInstagram,
  Facebook: FaFacebookSquare,
  Skype: TiSocialSkypeOutline,
  Whatsapp: MdOutlineWhatsapp,
};

export default function ContactsCard(props) {
  const {
    business_contacts,
    formik,
    setIsAddPopupOpen,
    setEditContact,
    setEditIndex,
    showOpt,
  } = props;
  const { fetchBusinessDetails } = DirectoryDetails((state) => state);
  let businessId = fetchBusinessDetails?.data?.id;

  let contactData = business_contacts
    ? business_contacts
    : fetchBusinessDetails?.data?.business_contacts;

  const [contactDetails, setContactDetails] = useState([]);
  const [contactDetailsShow, setContactDetailsShow] = useState({});
  const activeContact = useRef(1);

  const [isDropdownOpen, setIsDropdownOpen] = useState(null);

  const handleDropdownClick = (index) => {
    setIsDropdownOpen((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const [isContactPopupOpen, setIsContactPopupOpen] = useState(true);
  const location = useLocation();

  let navigate = useNavigate();
  const handleLiveChatClick = (data) => {
    let businessId = data?.id;

    navigate(`/chat`, {
      state: {
        business_id: businessId,
        message_text: "I want to contact this business",
        page: "businessProfile",
        hitCreateChatAPi: true,
      },
    });
  };

  const handleContactPopupClick = (contactId, contact) => {
    activeContact.current = contactId;
    if (contact?.name === "Website") {
      handleWebsite(contact);
    }
    if (contact?.name === "LiveChat") {
      handleLiveChatClick(contact);
    }
    if (contact?.name === "Phone") {
      setIsContactPopupOpen(activeContact.current === contactId ? true : false);

      setContactDetailsShow(contact);
    }
  };

  const handleClose = () => {
    setIsContactPopupOpen(false);
  };

  useEffect(() => {
    setContactDetails(
      contactData?.length !== 0 &&
        contactData?.map((contacts) => {
          const formattedContact = {
            id: contacts?.id || "",
            name: contacts?.account_type?.name || contacts?.title || "",
            title: contacts?.title || contacts?.account_type?.name || "",
            icon:
              optionIcon[contacts?.account_type?.name || contacts?.icon] || "",
            contact: contacts?.account_value || "",
            hasTime: contacts?.is_hasTime || contacts?.contact || "",
            isVerified: contacts?.is_verified || false,
            isLocked: contacts?.is_locked || false,
            isPrivate: contacts?.is_private || false,
          };
          if (formattedContact?.name === "Phone") {
            formattedContact.contact = formatInternationalPhoneNumber(
              formattedContact?.contact,
            );
          }
          return formattedContact;
        }),
    );
  }, [contactData]);

  const handleRemove = (index) => {
    const newArray = [...formik.values.business_contacts];

    newArray.splice(index - 1, 1);

    formik.setFieldValue("business_contacts", newArray);
  };

  const handleWebsite = ({ contact }) => {
    window.open(contact, "_blank");
  };

  const handleEdit = (data, index) => {
    setEditContact(data);
    setEditIndex(index);
    setIsAddPopupOpen();
  };

  return (
    contactDetails?.length && (
      <div className={"pt-4 flex flex-wrap w-full gap-2.5 px-4 mb-4"}>
        {contactDetails?.length !== 0 &&
          Array?.isArray(contactDetails) &&
          contactDetails?.slice().map((contact, index) => {
            const ids = index + 1;
            return (
              <div
                key={ids}
                className={
                  location?.pathname?.includes("/edit-business")
                    ? "relative w-[440px]"
                    : "relative w-full"
                }
              >
                <button
                  onClick={() => {
                    if (
                      contact?.name === "Website" ||
                      contact?.name === "Phone" ||
                      (contact?.name === "LiveChat" &&
                        contact?.contact === "Live Chat")
                    ) {
                      handleContactPopupClick(ids, contact);
                    }
                  }}
                  key={ids}
                  className={
                    index === 0
                      ? "min-h-[70px] w-full flex justify-between items-center px-3 hover:bg-lightBg dark:hover:bg-darkModeBG rounded-lg border-[1px] border-gray-100 dark:border-darkModeBorder"
                      : "min-h-[70px] w-full flex justify-between items-center px-3 hover:bg-lightBg dark:hover:bg-darkModeBG rounded-lg border-[1px] border-gray-100 dark:border-darkModeBorder"
                  }
                >
                  <div className="flex items-center w-full ">
                    <div className="flex flex-col text-sm w-full py-2 ">
                      <div className="flex">
                        <div className="pr-3 flex items-center">
                          {contact?.name === "Live Chat" ? (
                            <PiChatTeardropText
                              size={26}
                              className={"text-black dark:text-white"}
                            />
                          ) : (
                            contact?.icon && (
                              <contact.icon
                                size={26}
                                className={"text-black dark:text-white"}
                              />
                            )
                          )}
                          {contact?.isLocked && (
                            <TbLockCancel
                              size={26}
                              className={"!text-black dark:text-white"}
                            />
                          )}
                        </div>
                        {contact?.name === "Website" ? (
                          <div>
                            <div className="flex gap-2 flex-col pr-2 w-auto">
                              <p className="self-start capitalize truncate line-clamp-2">
                                {contact?.contact !== "Live Chat"
                                  ? contact?.title || contact?.name
                                  : "Live Chat"}
                              </p>
                              <p className="font-bold w-full text-wrap text-left break-all flex">
                                {contact?.contact}
                                {contact?.isVerified && (
                                  <div className="flex items-center">
                                    <MdVerified
                                      color="#48CFFF"
                                      size={20}
                                      className=""
                                    />
                                  </div>
                                )}{" "}
                                {contact?.isPrivate && (
                                  <div className="flex items-center pl-2">
                                    <PrivateLockIcon size={24} />
                                  </div>
                                )}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center justify-start gap-2 flex-col pr-3">
                            <p className="self-start capitalize">
                              {contact?.contact !== "Live Chat"
                                ? contact?.title || contact?.name
                                : "Live Chat"}
                            </p>
                            <p className="font-bold truncate w-full flex items-center">
                              {contact?.contact}{" "}
                              {contact?.isVerified && (
                                <div className="flex items-center pl-2">
                                  <MdVerified color="#48CFFF" size={20} />
                                </div>
                              )}{" "}
                              {contact?.isPrivate && (
                                <div className="flex items-center">
                                  <PrivateLockIcon size={24} />
                                </div>
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    {showOpt &&
                      contact?.name !== "LiveChat" &&
                      contact?.contact !== "Live Chat" && (
                        <div className="w-[22rem] flex justify-end">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDropdownClick(ids);
                            }}
                          >
                            <BiDotsVerticalRounded
                              size={20}
                              color={isDropdownOpen === ids ? "#5A70F9" : ""}
                            />
                          </button>

                          <div className="flex justify-end absolute top-12 right-0">
                            {isDropdownOpen === ids && (
                              <Dropdown
                                handleRemove={() => {
                                  setIsDropdownOpen(-1);
                                  handleRemove(ids);
                                }}
                                handleEdit={() => {
                                  setIsDropdownOpen(-1);
                                  handleEdit(contact, ids);
                                }}
                                closeDropdown={() => {
                                  setIsDropdownOpen(-1);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </button>

                {location?.pathname?.split("/")[1] === "business" &&
                  isContactPopupOpen &&
                  contact?.name === "Phone" &&
                  activeContact.current === ids && (
                    <ContactCardCallClick
                      onClose={handleClose}
                      businessId={businessId}
                      businessDetails={fetchBusinessDetails}
                      contactDetailsShow={contactDetailsShow}
                    />
                  )}
              </div>
            );
          })}
      </div>
    )
  );
}
