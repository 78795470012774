import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";

// Define the shape of your filter state
type FilterState = {
  category: string[]; // Changed to an array to accommodate multiple categories
  // ...other filter types you might have
};

// Define the shape of the context data
type SearchContextType = {
  searchTerm: string;
  selectedLocation: string;
  locationDetails: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setSelectedLocation: Dispatch<SetStateAction<string>>;
  filters: FilterState;
  setFilters: Dispatch<SetStateAction<FilterState>>;
  dashboardFilters: string;
  setDashboardFilters: Dispatch<SetStateAction<FilterState>>;
  searchViewToggle: boolean;
  setSearchViewToggle: Dispatch<SetStateAction<boolean>>;
  isDropdownVisible: boolean; // New state for dropdown visibility
  showDropdown: () => void; // New function to show dropdown
  hideDropdown: () => void;
  inputRef: null; // Updated to use React.MutableRefObject<null>
};

// Set up the default context data
const defaultState: SearchContextType = {
  searchTerm: "",
  locationDetailsRef: "",
  setSearchTerm: () => { },
  dashboardFilters: "",
  setDashboardFilters: () => { },
  selectedLocation: "",
  setSelectedLocation: () => { },
  filters: {
    category: [], // Initialize as an empty array
    // ...initialize other filters if necessary
  },
  setFilters: () => { },
  searchViewToggle: false,
  setSearchViewToggle: () => { },
  inputRef: null,
  isDropdownVisible: false,
  showDropdown: function (): void {
    throw new Error("Function not implemented.");
  },
  hideDropdown: function (): void {
    throw new Error("Function not implemented.");
  },
};

// Create the context
export const SearchContext = createContext<SearchContextType>(defaultState);

// Hook to use the context in a React component
export function useSearch() {
  return useContext(SearchContext);
}

// Component to provide the context to its children
export function SearchProvider({ children }: { children: React.ReactNode }) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dashboardFilters, setDashboardFilters] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<string>("");

  const [searchViewToggle, setSearchViewToggle] = useState<boolean>(false);
  // Initialize the filters state with default values
  const [filters, setFilters] = useState<FilterState>({
    category: [], // Empty array as default
    // ...default values for other filters
  });
  const inputRef: any = useRef(null); // Updated to use useRef with null as the initial value
  const inputValueRef: any = useRef(null);

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false); // New state for dropdown visibility
  // In SearchProvider component inside SearchContext
  const locationDetailsRef: any = useRef(null)
  const showDropdown = () => setIsDropdownVisible(true);
  const hideDropdown = () => setIsDropdownVisible(false);
  const clearSearch = () => {
    setSearchTerm("");
    setSelectedLocation("");
    inputRef.current = ""
  }

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        selectedLocation,
        setSelectedLocation,
        filters,
        setFilters,
        searchViewToggle,
        setSearchViewToggle,
        inputRef,
        inputValueRef,
        isDropdownVisible, // Include the new state in the context
        showDropdown, // Include the new function to show dropdown
        hideDropdown, // Include the new function to hide dropdown
        setIsDropdownVisible,
        dashboardFilters,
        setDashboardFilters,
        locationDetailsRef,
        clearSearch
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
