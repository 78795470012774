import sms from '../../assets/Sms.svg'
import ConfirmCodeInput from './ConfirmCodeInput'

export default function PhoneValidate({onShowPhoneNumberUpdated,sendCode,sendPhone,setActiveSettingsView,sendEmail, setSendEmail}) {
    

  return (
    <div className='w-full flex flex-col justify-center items-center h-full'>
      <img src={sms} alt='SMS' width={60} height={60} />
      <h1 className='text-[18px] font-bold p-4'>PIN code</h1>
     <div>
        <ConfirmCodeInput onConfirm={onShowPhoneNumberUpdated}countryCode={sendCode} phoneNumber={sendPhone}setActiveSettingsView={setActiveSettingsView} sendEmail={sendEmail} setSendEmail={setSendEmail} />
      </div>
    </div>
  )
}