import React from 'react'
import { FaBookmark } from 'react-icons/fa';
import { FiShare, FiPlus } from 'react-icons/fi';
import { IoArrowBack } from "react-icons/io5";
import { MdVerified } from 'react-icons/md';
import { PiChatCircleTextLight } from "react-icons/pi";
import verizon from '../../../assets/Verizon.webp'
import { CgClose } from "react-icons/cg";
import { AiOutlinePaperClip } from 'react-icons/ai';
import TicketCard from '../TicketCard';
import businesses from '../../../data/businesses';

export default function NewTicketCall({ onTicketClick }) {
    const openBusinesses = businesses.filter(business => business.ticketStatus === 'Open')
  return (
    <div className='flex flex-col w-full h-screen pr-6 text-sm bg-lightBg dark:bg-darkModeBG'>
      <div className='w-full m-4 pb-8 bg-white dark:bg-darkModeMain rounded-lg border-[1px]  border-gray-100   dark:border-darkModeBorder'>
        <div className='w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-[60px] flex justify-between items-center'>
            <div className='flex items-center gap-4 p-4'>
                <button className='flex items-center gap-2'> <IoArrowBack className='mr-1' size={18} /> Back</button>
                <div className='w-[1.5px] h-[20px] bg-neutral-200'></div>
                <p className='font-semibold uppercase'>New ticket</p>
            </div>
            <PiChatCircleTextLight className='mr-4' size={25} />
        </div>
            <div className='h-[70px] w-full flex justify-between items-center px-6 py-10 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder'>
                    <div className='flex items-center gap-2'>
                        <img src={verizon} alt='Verizon' width={40} height={40} />
                        <p className='font-semibold'>Verizon</p>
                        <MdVerified size={16} color='#48CFFF' />
                    </div>
                    <div className='flex items-center gap-2'>
                        <FiShare size={20} />
                        <FaBookmark size={20} color='#4C5FEF' />
                    </div>
            </div>
            <div className='flex flex-col w-full p-6 space-y-3'>
                <p className='font-semibold'>Tags</p>
                <div className='flex items-center gap-3'>
                    <button className='text-ipurple border-[1px]  border-gray-100  rounded border-ipurple bg-[#4c5fef12] flex py-2 px-4 items-center justify-center gap-1'> Mobile <CgClose /> </button>
                    <button className='text-ipurple border-[1px]  border-gray-100  rounded border-ipurple bg-[#4c5fef12] flex py-2 px-4 items-center justify-center gap-1'>Billings & payments <CgClose /></button>
                    <button className='text-ipurple border-[1px]  border-gray-100  rounded border-ipurple bg-[#4c5fef12] flex py-2 px-4 items-center justify-center gap-1'>My account <CgClose /></button>
                    <button className='rounded-full w-[25px] h-[25px] bg-ipurple text-white flex items-center justify-center '><FiPlus size={16} /></button>
                </div>
            </div>
            <p className='p-6 font-bold'>What&apos;s your issue?</p>
            <input
                className='w-[97%] text-sm h-[110px] mx-6 rounded border-[1px]  border-gray-100  border-neutral-100 dark:border-neutral-900'
                type='text'
                placeholder='Start typing here...'
            />
            <button className='w-[97%] m-6 h-[60px] bg-lightBg rounded-lg border-dashed border-[#ebeff2] border-[1px]  border-gray-100  dark:border-darkModeBorder flex justify-center items-center gap-2'>
                <AiOutlinePaperClip size={25} />
                <p className='text-sm font-semibold underline'>Choose a file or drag it here</p>
            </button>
            <div className='flex items-center gap-4 px-6'>
                <button className='w-[240px] h-[45px] flex justify-center items-center bg-ipurple rounded-full uppercase text-white'>Call</button>
                <button className='w-[240px] h-[45px] flex justify-center items-center bg-inherit rounded-full uppercase border-[1px]  border-gray-100  border-b border-gray-100lack dark:border-white'>Call without ticket</button>
            </div>
      </div>
      <div className='w-full m-4 bg-white dark:bg-darkModeMain rounded-lg border-[1px]  border-gray-100   dark:border-darkModeBorder'>
        <div className='w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-[60px] flex items-center'>
            <p className='p-6 font-semibold uppercase'>Open tickets</p>
        </div>
        {openBusinesses.map((businessItem, index) => (
              <TicketCard key={businessItem.id} ticket={businessItem} onTicketClick={() => onTicketClick(businessItem)} showLogo={true} />
              ))}
      </div>
    </div>
  )
}
