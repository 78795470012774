import { useState } from "react";
import LocationHoursCard from "./LocationHoursCard";
import ReactMap from "../tickets-tab/ReactMap";

export default function LocationsHoursContainer(props) {
  const [locationData, setLocationData] = useState(null);

  const updateLocationData = (data) => {
    setLocationData(data);
  };
  return (
    <div className="w-full flex h-auto text-sm flex-col bg-white dark:bg-darkModeMain rounded-lg border-[1px] border-gray-100 dark:border-darkModeBorder">
      <div className="w-full border-b border-gray-100 flex items-center dark:border-darkModeBorder justify-between h-[60px] min-h-[60px]">
        <h1 className="m-4 font-semibold uppercase">Locations & Hours</h1>
      </div>
      <div className="flex justify-start w-full p-4 py-3 overflow-x-hidden">
        <div className="w-1/3 max-[1600px]:w-1/2 pr-2 pl-2 flex flex-col overflow-y-auto max-h-[400px]">
          <LocationHoursCard {...props} />
        </div>

        {locationData?.business_locations?.length != 0 && (
          <div className="relative w-2/3 max-[1600px]:w-1/7 pl-2 pr-1 rounded-lg overflow-hidden flex justify-center items-center">
            <div className="flex items-center justify-center w-full h-full overflow-hidden rounded-lg object-fit">
              <ReactMap {...props} setLocationData={updateLocationData} />
            </div>
            {/* <LocationPins /> */}
            {/* <button className="absolute shadow-black w-[35px] h-[35px] top-4 right-8 flex justify-center items-center bg-white dark:bg-darkModeMain rounded-full shadow-lg">
              <IoExpand size={20} />
            </button>
            <button className="absolute shadow-black w-[35px] h-[35px] bottom-4 right-8 flex justify-center items-center bg-white dark:bg-darkModeMain rounded-full shadow-lg">
              <LiaLocationArrowSolid size={22} />
            </button> */}
          </div>
        )}
      </div>
    </div>
  );
}
