export const SearchIcon = ({ className }) => {
  return (
    <svg
      className={`svg-icon ${className}`}
      width="35px"
      height="35px"
      viewBox="0 0 35 35"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons/Search</title>
      <g
        id="Icons/Search"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect id="safe-area" x="0" y="0" width="35" height="35"></rect>
        <path
          d="M26.9611702,28 C27.5867021,28 28,27.524236 28,26.9599579 C28,26.6833509 27.9106383,26.4288725 27.7095745,26.2297155 L22.0462766,20.6090622 C23.2191489,19.170706 23.9117021,17.3561644 23.9117021,15.3756586 C23.9117021,10.7729189 20.1138298,7 15.4558511,7 C10.8090426,7 7,10.7618546 7,15.3756586 C7,19.9894626 10.7978723,23.7513172 15.4558511,23.7513172 C17.3659574,23.7513172 19.1196809,23.1206533 20.5382979,22.0584826 L26.2239362,27.7012645 C26.4138298,27.8893572 26.6819149,28 26.9611702,28 Z M15.4558511,22.3572181 C11.5909574,22.3572181 8.41861702,19.2038988 8.41861702,15.3756586 C8.41861702,11.5584826 11.5909574,8.40516333 15.4558511,8.40516333 C19.3207447,8.40516333 22.4930851,11.5584826 22.4930851,15.3756586 C22.4930851,19.2038988 19.3207447,22.3572181 15.4558511,22.3572181 Z"
          id="Search-Icon"
          fill="currentColor"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const LocationPin = ({ width, height }) => {
  return (
    <svg
      width={width || "30px"}
      height={height || "30px"}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons/Location Pin</title>
      <g
        id="Icons/Location-Pin"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M15,4 C10.0388326,4 6,8.00625155 6,12.9273975 C6,15.1366821 6.81686594,17.1623727 8.15900735,18.7203595 L8.16624547,18.7285648 C8.16624547,18.7285648 11.9951748,24.0620039 13.3579963,25.352292 C14.2710249,26.2159027 15.7279412,26.2159027 16.6409697,25.352292 C18.1950826,23.8825123 21.8347885,18.7265136 21.8347885,18.7265136 L21.8368566,18.7234366 C23.1831341,17.1623727 24,15.1366821 24,12.9273975 C24,8.00625155 19.9611674,4 15,4 Z M15,5.57542309 C19.1029412,5.57542309 22.4117647,8.85755453 22.4117647,12.9273975 C22.4117647,14.7520575 21.7417279,16.4105595 20.6322381,17.6967446 C20.625,17.7049499 16.9005056,22.9296686 15.5449219,24.2127769 C15.2305837,24.5102202 14.7683824,24.5102202 14.4540441,24.2127769 C13.3207721,23.1399303 9.37810209,17.7100785 9.36672794,17.6967446 C8.25827206,16.4095339 7.58823529,14.7520575 7.58823529,12.9273975 C7.58823529,8.85755453 10.8970588,5.57542309 15,5.57542309 Z M15,9.77655133 C14.0073529,9.77655133 13.162569,10.1745098 12.6197149,10.7796526 C12.0768611,11.3847957 11.8235294,12.1612249 11.8235294,12.9273975 C11.8235294,13.6935701 12.0768611,14.4699993 12.6197149,15.0751424 C13.162569,15.6802853 14.0073529,16.0782437 15,16.0782437 C15.9926471,16.0782437 16.837431,15.6802853 17.3802851,15.0751424 C17.9231389,14.4699993 18.1764706,13.6935701 18.1764706,12.9273975 C18.1764706,12.1612249 17.9231389,11.3847957 17.3802851,10.7796526 C16.837431,10.1745098 15.9926471,9.77655133 15,9.77655133 Z M15,11.3519744 C15.5955882,11.3519744 15.9419808,11.5447996 16.1932444,11.8258323 C16.4445082,12.1068646 16.5882353,12.5120028 16.5882353,12.9273975 C16.5882353,13.3427922 16.4445082,13.7479304 16.1932444,14.0289627 C15.9419808,14.3099954 15.5955882,14.5028206 15,14.5028206 C14.4044118,14.5028206 14.0580192,14.3099954 13.8067556,14.0289627 C13.5554918,13.7479304 13.4117647,13.3427922 13.4117647,12.9273975 C13.4117647,12.5120028 13.5554918,12.1068646 13.8067556,11.8258323 C14.0580192,11.5447996 14.4044118,11.3519744 15,11.3519744 Z"
          className="fill-black dark:fill-white"
        ></path>
      </g>
    </svg>
  );
};
