import { useEffect, useRef, useState } from "react";
import { GoArrowUp } from "react-icons/go";
import { CiFaceSmile } from "react-icons/ci";
import { BsMic } from "react-icons/bs";
import Avatar from "../Avatar";
import ReviewReplies from "./ReviewReplies";
import { userDetails } from "../../routes/ProtectedRoutes";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
import EmojiPicker from "emoji-picker-react";

export default function ReplyForm(props) {
  const { createCommentApi, listReviewCommentsApi, loadingComments } =
    DirectoryDetails((state) => state);
  const emojiPickerRef = useRef(null);

  let { reviewId, handleSubmit } = props || {};

  const [showReactSelect, setShowReactSelect] = useState(false);
  const [commentReply, setCommentReply] = useState("");

  const handleChange = async (event) => {
    event.preventDefault();
    const newReview = event.target.value;
    setCommentReply(newReview);
  };

  const postCommentFun = async () => {
    await createCommentApi({
      review_id: reviewId,

      description: commentReply,
    });
    setCommentReply("");
    await listReviewCommentsApi({
      reviewId: reviewId,
    });
    setShowReactSelect(false);
  };
  const handleAddReview = async (e) => {
    if (commentReply.length == 0) {
      return;
    }
    e.preventDefault();
    postCommentFun();
  };

  const handleReactClick = () => {
    setShowReactSelect(!showReactSelect);
  };

  const handleAddEmoji = (emoji) => {
    setCommentReply((prevMessage) => prevMessage + emoji.emoji);
  };

  const { ProfileDetails } = AuthPersonalPhone((state) => state);

  const userDetailsProfile = userDetails();

  useEffect(() => {
    userDetails();
  }, [ProfileDetails]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowReactSelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="flex flex-col p-6 space-y-2 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder">
      <ReviewReplies reviewId={reviewId} />
      <form className="relative  w-full pb-4 text-sm bg-lightBg dark:bg-darkModeBG">
        <div className="relative w-full flex items-center ">
          <div className="mt-1 ml-12 flex items-center">
            <Avatar pathSrc={userDetailsProfile?.upload?.file_path} />
          </div>

          <div className="w-full flex items-center dark:bg-darkModeMain w-full text-sm font-semibold  items-end rounded-md min-h-[55px] border-ipurple border-[1.5px]">
            <input
              type="text"
              name="reply"
              id="reply"
              value={commentReply}
              onChange={handleChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  setShowReactSelect(false);
                  postCommentFun();
                }
              }}
              className="w-[80%] border border-none outline-none focus:outline-none focus:border-transparent focus:ring-0"
            />

            <div className="w-[20%] flex items-center justify-end gap-2 pr-2">
              <button onClick={(e) => e.preventDefault()}>
                <BsMic size={20} />
              </button>

              <button
                type="button"
                onClick={handleReactClick}
                className="text-center"
              >
                <CiFaceSmile size={28} />
              </button>

              {showReactSelect ? (
                <div
                  className="absolute bottom-[60px] width-[50px]"
                  ref={emojiPickerRef}
                >
                  <EmojiPicker onEmojiClick={handleAddEmoji} />{" "}
                </div>
              ) : (
                ""
              )}

              <div>
                <GoArrowUp
                  size={20}
                  onClick={(e) => {
                    if (!loadingComments) {
                      setShowReactSelect(false);
                      handleAddReview(e);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
