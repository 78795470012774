import React, { useEffect, useRef } from "react";
import { CopyIcon, EmailIcon } from "../icons/Icons";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import toast from "react-hot-toast";

export default function ShareButtonDropdown({ handleBtn }) {
  const handleCopyLink = () => {
    window.navigator.clipboard.writeText(window.location?.href);
    toast.success("Link Copied");
    handleBtn();
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleBtn();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div className="absolute right-8 top-[30px] w-[220px] flex flex-col text-[12px] bg-white z-20 dark:bg-darkModeMain border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg divide-y-[1px] dark:divide-darkModeBorder ">
      <button
        className="w-full min-h-[40px] flex items-center px-3 gap-1.5 font-semibold hover:bg-lightBg dark:hover:bg-darkModeBG rounded-t-lg"
        onClick={handleCopyLink}
        ref={dropdownRef}
      >
        <CopyIcon className="w-[20px] h-[20px]" />
        <p>Copy link</p>
      </button>
      <button className="w-full min-h-[40px] flex items-center px-3 gap-1.5 font-semibold hover:bg-lightBg dark:hover:bg-darkModeBG">
        <FaFacebookF size={15} className="" />
        <p>Share on Facebook</p>
      </button>
      <button className="w-full min-h-[40px] flex items-center px-3 gap-1.5 font-semibold hover:bg-lightBg dark:hover:bg-darkModeBG">
        <FaXTwitter size={16} className="" />
        <p>Share on X</p>
      </button>
      <button className="w-full min-h-[40px] flex items-center px-3 gap-1.5 font-semibold hover:bg-lightBg dark:hover:bg-darkModeBG rounded-b-lg">
        <EmailIcon className="w-[20px] h-[20px] text-black dark:text-white" />
        <p>Share via email</p>
      </button>
    </div>
  );
}
