import { useState } from "react";
import TicketsContainer from "../../tickets-tab/TicketsContainer";

export default function TicketsView(props) {
  let { business_id } = props || {};
  const [selectedTicket, setSelectedTicket] = useState(null);

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
    setActiveContainer("singleTicket"); // Change view to single ticket
    setShowTicketCallStatus(false); // Reset call status when a new ticket is selected
  };

  return (
    <div className="w-full -mt-6">
      <TicketsContainer
        businessId={business_id}
        onTicketClick={handleTicketClick}
      />
    </div>
  );
}
