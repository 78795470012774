import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { VITE_API_PAGE_LIMIT } from "../../utils/constants";
import { BusinessService } from "../../services/bussiness/BusinessService";
import PropTypes from "prop-types";
import { useTheme } from "../ThemeContext";

Category.propTypes = {
  handleChange: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
};

export default function Category(props) {
  const { theme } = useTheme(); // Destructure theme from useTheme hook
  const { handleChange, formik } = props;
  const { businessCategoryApi, categoryResp } = BusinessService(
    (state) => state
  );
  const [categories, setCategories] = useState([]);
  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [additionalCategory, setAdditionalCategory] = useState(null);

  useEffect(() => {
    if (Array?.isArray(categoryResp?.data) && categoryResp?.data?.length != 0) {
      setPrimaryCategory(
        categoryResp?.data
          ?.filter((data) => data?.id == formik?.values?.category_id)
          ?.map((categoryItem) => ({
            value: categoryItem.id,
            label: categoryItem.name,
          })) || []
      );
      setAdditionalCategory(
        categoryResp?.data
          ?.filter((data) => data?.id == formik?.values?.additional_category_id)
          ?.map((categoryItem) => ({
            value: categoryItem.id,
            label: categoryItem.name,
          })) || []
      );
    }
  }, [
    categoryResp,
    formik?.values?.additional_category_id,
    formik?.values?.category_id,
  ]);
  useEffect(() => {
    businessCategoryApi({
      limit: VITE_API_PAGE_LIMIT,
      page: 1,
    });
  }, []);

  useEffect(() => {
    if (categoryResp?.data) {
      const options = categoryResp.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCategories([{ value: "", label: "None" }].concat(options));
    }
  }, [categoryResp]);

  const handlePrimaryCategoryChange = (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__) {
      // If the user creates a new category, add it to the categories list
      const newCategory = {
        value: selectedOption.value,
        label: selectedOption.label,
      };
      setCategories((prevCategories) => [...prevCategories, newCategory]);
    }

    setPrimaryCategory(selectedOption);
    formik.setFieldValue(
      "category_name",
      selectedOption ? selectedOption.value : ""
    );

    if (additionalCategory?.value === selectedOption?.value) {
      setAdditionalCategory(null);
      formik.setFieldValue("additional_category_id", "");
    }
  };

  const handleAdditionalCategoryChange = (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__) {
      const newCategory = {
        value: selectedOption.value,
        label: selectedOption.label,
      };
      setCategories((prevCategories) => [...prevCategories, newCategory]);
    }

    setAdditionalCategory(selectedOption);
    formik.setFieldValue(
      "additional_category_name",
      selectedOption ? selectedOption.value : ""
    );
  };

  // Filter out the primary category from the additional category options
  const filteredCategories = primaryCategory
    ? categories.filter((category) => category.value !== primaryCategory.value)
    : categories;

  // Define custom styles based on theme
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "55px",
      borderColor: theme === "dark" ? "#36383D" : "rgba(229, 231, 235, 1)",
      backgroundColor: theme === "dark" ? "#232529" : "white",
      color: theme === "dark" ? "white" : "inherit",
      borderWidth: "2px",
      borderRadius: "0.5rem",
      padding: "0 0.5rem",
      boxShadow: "none",
      "&:hover": {
        borderColor:
          theme === "dark" ? "var(--darkModeBorderHover)" : "rgb(76 95 239)",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color:
        theme === "dark"
          ? "rgba(156, 163, 175, 0.7)"
          : "rgba(156, 163, 175, 1)",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme === "dark" ? "white" : "inherit",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme === "dark" ? "#232529" : "white",
      color: theme === "dark" ? "white" : "black",
      zIndex: 9999,
      maxWidth: "300px",
      borderRadius: "0.5rem",
      fontWeight: "bold",
    }),
    option: (provided, state) => ({
      ...provided,
      maxWidth: "300px",
      backgroundColor: state.isFocused
        ? theme === "dark"
          ? "rgba(255, 255, 255, 0.1)"
          : "rgb(249, 251, 254)"
        : theme === "dark"
        ? "#1B1D21"
        : "inherit",
      color: state.isSelected
        ? theme === "dark"
          ? "white"
          : "black"
        : provided.color,
      borderBottom: "1px solid rgba(229, 231, 235, 1)",
      "&:last-child": {
        borderBottom: "none",
      },
      "&:hover": {
        backgroundColor: theme === "dark" ? "rgb(76 95 239)" : "rgb(76 95 239)",
        color: "white",
      },
    }),
    input: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: theme === "dark" ? "white" : "inherit",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
  };

  return (
    <div className="flex flex-col w-full text-sm">
      <div className="flex flex-col w-full py-2 space-y-3 text-sm">
        <label htmlFor="primary-category">Primary Category *</label>
        <CreatableSelect
          id="primary-category"
          name="category_id"
          value={primaryCategory}
          onChange={handlePrimaryCategoryChange}
          options={categories}
          styles={customStyles}
          isClearable={false}
          isSearchable
          placeholder="Choose or Add a Primary Category"
        />
        {formik?.errors?.category_id && (
          <p className="text-danger">{formik?.errors?.category_id}</p>
        )}
      </div>
      <div className="flex flex-col w-full py-4 space-y-3 text-sm">
        <label htmlFor="additional-category">Additional Category</label>

        <CreatableSelect
          id="additional-category"
          name="additional_category_id"
          value={additionalCategory}
          onChange={handleAdditionalCategoryChange}
          options={filteredCategories} // Use filtered categories here
          styles={customStyles}
          isClearable={false}
          isSearchable
          placeholder="Choose an Additional Category"
          className="basic-single dark:bg-darkModeMain dark:text-white dark:border-darkModeBorder "
          classNamePrefix="select"
        />
        {formik?.errors?.additional_category_id && (
          <p className="text-danger">
            {formik?.errors?.additional_category_id}
          </p>
        )}
      </div>
    </div>
  );
}
