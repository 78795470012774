const Loader = ({ type }) => {
  return (
    <div
      className={
        type == "chat"
          ? "flex items-center justify-center w-full gap-2 mt-2 overflow-hidden"
          : "flex items-center justify-center w-full gap-2 mt-28 overflow-hidden"
      }
    >
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
};
export default Loader;
