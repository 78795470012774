// Import necessary dependencies
import { create } from "zustand";
import AxiosApi from "../../api/requestInterceptor";
import { BASE_URL, VITE_API_PAGE_LIMIT } from "../../utils/constants";
import { apiPostFilter } from "../../utils/api/apiPostFilter";
import callAxios from "../../api/requestIntercetorForm";
import { apiFilterVallidation } from "../../utils/api/apiFilter";

// Create a Zustand store for AiChatApi
export const AiChatApi = create((set) => ({
  // Initial state variables
  ChatDetails: {}, // Holds details of a chat
  ChatDetailsData: [], // Holds an array of chat details
  BusinessesChatDetails: {}, // Holds an business api Data
  getShowChatDetails: {}, // Holds an show chat details
  aiChatListShow: [],
  getlistChats: [], // Holds an list chats
  listChatLastPage: "", // Holds an last page number of list chata
  aiChatMetaData: {},
  aiChatListShowLastPage: "",
  loading: false, // Indicates whether data is currently being loaded
  loadingChat: false,
  loadingAiChat: false,
  error: null, // Holds any error that may occur during data fetching or processing

  // Async function to create a new AI chat
  CreateAiChat: async (userData) => {
    let filteredUserData = apiPostFilter(userData);
    try {
      // Set loading to true while waiting for the API response

      set({ loading: true });

      // Make a POST request to create a new chat

      const response = await AxiosApi.post(
        `${BASE_URL}chats`,
        filteredUserData
      );

      // Destructure response data
      let { success, message } = response?.data || {};

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loading: false,
          ChatDetails: response?.data,
          ChatDetailsData: response?.data?.data || [],
        });

        return response?.data?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loading: false, error: err });
      return err;
    }
  },
  // Async function to get Business Details in AI Chat
  GetBusinessChatData: async ({ id }) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loading: true });

      // Make a POST request to Business Details Api
      const response = await AxiosApi.get(`${BASE_URL}businesses/${id}`);

      // Destructure response data
      let { success, message } = response?.data || {};

      if (success) {
        // If the request is successful, update the state with the response data
        set({ loading: false, BusinessesChatDetails: response?.data });

        return response?.data?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loading: false, error: err });
      return err;
    }
  },

  // Async function to get show chat api
  getShowChatDetailsApi: async ({ id }) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingChat: true });

      // Make a get show chat api
      const response = await AxiosApi.get(`${BASE_URL}chats/${id}`);
      // Destructure response data
      let { success, message } = response?.data || {};

      if (success) {
        // If the request is successful, update the state with the response data
        set({ loadingChat: false, getShowChatDetails: response?.data });

        return response?.data?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingChat: false, error: err });
      return err;
    }
  },
  // Async function to get show chat api
  updateAiChat: async (businessId, businessData) => {
    try {
      // Set loading to true while waiting for the API response
      set({ loadingChat: true });

      // Make a get show chat api
      const response = await AxiosApi.put(
        `${BASE_URL}chats/${businessId}`,
        businessData
      );
      // Destructure response data
      let { success, message } = response?.data || {};

      if (success) {
        // If the request is successful, update the state with the response data
        // set({ loadingChat: false, getShowChatDetails: response?.data });

        return response?.data?.message;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      // set({ loadingChat: false, error: err });
      return err;
    }
  },
  // Async function to get chat list api

  getlistChatsApi: async (userData) => {
    let { page, perpage, business_id } = userData;

    try {
      // Set loading to true while waiting for the API response
      set({ loading: true });
      // Make a get get chat list api
      const response = await AxiosApi.get(
        `${BASE_URL}chats?page=${page}&limit=${perpage}&business_id=${business_id}`
      );

      let { success, message, data } = response?.data || { message: "error" };

      let { last_page } = response?.data?.meta || {};

      if (success) {
        // If the request is successful, update the state with the response data
        set((state) => ({
          loading: false,
          listChatLastPage: last_page,
          getlistChats: [...state.getlistChats, ...data].filter(
            // Filter out duplicates based on the 'id' property
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          ),
        }));
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loading: false, error: err });
      return err;
    }
  },

  getAIChatList: async (userData) => {
    let { page, business_id, deleted } = userData;

    try {
      // Set loading to true while waiting for the API response
      set({ loadingAiChat: true });
      let urlArray = [
        {
          name: "page",
          value: page,
        },
        {
          name: "limit",
          value: VITE_API_PAGE_LIMIT,
        },
        {
          name: "business_id",
          value: business_id,
        },
        {
          name: "deleted",
          value: deleted,
        },
      ];

      let filteredURL = apiFilterVallidation(urlArray, `${BASE_URL}chats?`);
      // Make a get get chat list api
      const response = await AxiosApi.get(filteredURL);

      let { success, message, data } = response?.data || { message: "error" };

      let { last_page, current_page, archived_count } =
        response?.data?.meta || {};

      if (success) {
        // If the request is successful, update the state with the response data
        set((state) => ({
          loadingAiChat: false,
          aiChatListShowLastPage: deleted ? archived_count : last_page,
          aiChatMetaData: response?.data?.meta,
          aiChatListShow: [...state.aiChatListShow, ...data].filter(
            // Filter out duplicates based on the 'id' property
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          ),
        }));
        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loadingAiChat: false, error: err });
      return err;
    }
  },
  createAttachImagesToChatApi: async (chatId, data) => {
    const formData = new FormData();

    data.files.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("note_type", data.note_type);

    formData.append("title", data.title);

    formData.append("description", data.description);

    try {
      set({ loading: true });

      const response = await callAxios.post(
        `${BASE_URL}chats/${chatId}/notes/attach-file`,
        formData
      );

      let { success, message } = response?.data || { message: "error" };

      if (success) {
        set({
          loading: false,
        });

        return response?.data;
      } else {
        throw message;
      }
    } catch (err) {
      set({ loading: false, error: err });
      return err;
    }
  },

  removeFileApi: async (chatId, noteId, deleteId) => {
    try {
      set({ loading: true });

      const response = await AxiosApi.delete(
        `${BASE_URL}chats/${chatId}/notes/${noteId}/delete-file/${deleteId}
        `
      );
      // Destructure response data
      let { success, message } = response?.data || { message: "error" };

      if (success) {
        // If the request is successful, update the state with the response data
        set({
          loading: false,
        });

        return response?.data;
      } else {
        // If the request is not successful, throw an error
        throw message;
      }
    } catch (err) {
      // If an error occurs during the API call, update the state with the error
      set({ loading: false, error: err });
      return err;
    }
  },

  clearState: () =>
    set({
      getlistChats: [],
      listChatLastPage: "",
      getShowChatDetails: {},
      ChatDetails: {},
      ChatDetailsData: [],
      // ChatDetails: {},
    }),
  clearAiChatState: () => {
    set({
      aiChatListShow: [],
      aiChatListShowLastPage: "",
    });
  },
  clearDetailState: () => {
    set({
      getShowChatDetails: [],
    });
  },
}));
