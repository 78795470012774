import { IoClipboardOutline } from "react-icons/io5";
import getScheduledTimeDisplay from "../../utils/TimeDetails";
import { useNavigate } from "react-router-dom";
import { TicketsDetails } from "../../services/tickets/TicketDetails";
import { CallDetailsService } from "../../services/calls/CallDetailsService";
import round from "../../assets/logo.webp";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
export default function TicketBusinessCard(props) {
  const navigate = useNavigate();
  const { clearState } = TicketsDetails((state) => state);
  const { clearTranscriptsState } = CallDetailsService((state) => state);
  let { data } = props || {};

  let ticketData = data?.message_json;

  const handleTicketClick = async (ticketId) => {
    await clearState();
    await clearTranscriptsState();
    navigate(`/tickets/${ticketId}`, {
      state: {
        ticketId: ticketId,
      },
    });
  };

  return (
    <div className="flex flex-row items-center justify-start w-full  text-white ">
      <div className="flex max-w-[45%] gap-2">
        <img
          src={round}
          className="w-[30px] h-[30px] border-[1.5px] rounded-full object-contain"
          alt="button"
        />
        <div
          onClick={() => {
            handleTicketClick(ticketData?.ticket_id);
          }}
          className="flex underline flex-row items-center text-white  w-fit py-3 pl-3 pr-4 max-w-[375px] justify-between text-sm border  border-[1px]  border-gray-100]  rounded-b-lg rounded-tr-lg bg-gradient-to-r from-indigo-600 to-purple-700"
        >
          {" "}
          <div className="flex items-center">
            <IoClipboardOutline className="mr-2" size={20} />
            <div>
              <h3 className="text-sm">{ticketData?.ticket_name}</h3>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="text-black max-w-[45%] border-solid border-2 rounded-lg px-2 py-4">
        <div className="flex justify-between">
          <div className="flex">
            <div className="w-12 rounded-lg">
              <img src="https://play-lh.googleusercontent.com/emmbClh_hm0WpWZqJ0X59B8Pz1mKoB9HVLkYMktxhGE6_-30SdGoa-BmYW73RJ8MGZQ" alt="img not found" />
            </div>
            <div>
              <p className="font-semibold">IMG_1553.JPG</p>
              <p className="text-sm">20,Jun at 10:00</p>
            </div>
          </div>
          <div className="flex items-center">

            <MdOutlineDownloadForOffline size={25} />
            <PiDotsThreeVerticalBold size={25} className="ml-2" />
          </div>
        </div>
      </div> */}
    </div>
  );
}
