import { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getDayTimeDiffDetails } from "../../utils/TimeDetails";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../../utils/numberFormat";
import { AiChatApi } from "../../services/chat/AiChat";

export default function AIChatCards(props) {
  const { data, handleChat, chatId } = props || "";
  const { getShowChatDetails, getShowChatDetailsApi } = AiChatApi(
    (state) => state
  );
  const dropdownRef = useRef(null);
  const [viewTicket, setViewTicket] = useState(false);
  let navigate = useNavigate();
  const handleViewTicketClick = (_, activeFilter) => {
    getShowChatDetailsApi({
      id: chatId,
    }).then(async (data) => {
      const ticketId = await getShowChatDetails?.chat?.business
        ?.latest_open_ticket_id;
      await navigate(`/tickets/${ticketId}`, {
        state: {
          ticketId: ticketId,
          activeFilter: activeFilter,
        },
      });
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setViewTicket(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <>
      <div>
        {data?.length > 0
          ? data?.map((data, index) => (
              <div
                className={`${
                  chatId == data?.id ? "bg-lightBg" : ""
                }hover:bg-lightBg !cursor-default  w-full flex-col justify-center items-center divide-y-[1px] dark:divide-darkModeBG border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder safari-border-right`}
                key={index}
              >
                <button
                  className="w-full h-[70px] min-h-[70px] hover:bg-lightBg dark:hover:bg-darkModeBG items-center"
                  onClick={() => {
                    handleChat(data);
                  }}
                >
                  <div className="flex items-center justify-between w-full ">
                    <div className="flex flex-col px-5">
                      <p className="font-semibold line-clamp-1 text-sm text-left">
                        {data?.chat_messages?.[0]?.message_type == "call_ended"
                          ? data?.business?.name
                          : data?.chat_messages?.[0]?.message_text
                          ? data?.chat_messages?.[0]?.message_text
                          : data?.business?.name}
                      </p>
                      <p className="text-gray-400 text-left text-[13px]">
                        {getDayTimeDiffDetails(data?.created_at) ||
                          getDayTimeDiffDetails(data?.last_message_at)}
                      </p>
                    </div>
                    <div className="relative z-20 m-[10px] ">
                      <BsThreeDotsVertical
                        size={18}
                        onClick={() => {
                          setViewTicket(index);
                        }}
                      />
                      {viewTicket === index ? (
                        <div
                          onClick={() => {
                            handleViewTicketClick(data, data?.status);
                          }}
                          ref={dropdownRef}
                          className=" border-[1px] cursor-pointer border-gray-100 dark:bg-darkModeBG  dark:border-darkModeBorder absolute p-2 w-[150px] text-xs font-semibold bg-white rounded-lg shadow top-5 right-2 line-clamp-1"
                        >
                          View Ticket
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </button>
              </div>
            ))
          : ""}
      </div>
    </>
  );
}
