import ReactDOM from "react-dom";
import { ClearHistoryIcon } from "../icons/Icons";
import axios from "axios";

import { handleClearSearchHistory } from "../../services/searchHistoryService";
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export default function ClearHistoryModal({ onClose }) {
 


  return ReactDOM.createPortal(
    <div className="absolute top-0 z-[60] flex items-center justify-center text-sm w-full h-screen overflow-hidden">
      <div className="fixed w-full h-screen bg-black opacity-90"></div>
      <div className="w-[580px] h-[250px] z-40 bg-white rounded-lg dark:bg-darkModeMain flex flex-col justify-center items-center space-y-3">
        <ClearHistoryIcon className="text-ipurple w-[55px] h-[55px]" />
        <h1 className="font-bold text-[20px]">Clear Search History</h1>
        <p>
          Do you want to clear your search history? This action cannot be
          undone.
        </p>
        <div className="flex items-center gap-4 pt-4">
          <button
            onClick={onClose}
            className="w-[260px] h-[45px] border-[1px]  border-gray-100  border-b border-gray-100lack dark:border-white rounded-full uppercase font-[500] hover:font-bold"
          >
            Cancel
          </button>
          <button
            className="w-[260px] h-[45px] rounded-full uppercase font-[500] bg-black text-white hover:font-bold"

            onClick={() => {
              handleClearSearchHistory();
              onClose(); // Call onClose after clearing history
            }}
          >
            Clear Search History
          </button>
          

        </div>
      </div>
    </div>,

    document.getElementById("portal-root")
  );
}
