import React from 'react'
import { FaApple } from 'react-icons/fa'
import { IoShuffle, IoQrCodeOutline } from "react-icons/io5";
import PlaylistCard from './PlaylistCard';

export default function ConnectedService() {
  return (
    <div className='flex flex-col w-full py-6 text-sm'>
      <h1 className='px-6 text-[16px] font-semibold'>Connected Service</h1>
      <div className='flex items-center justify-between w-full'>
        <div className='flex px-6 py-4'>
            <FaApple size={30} />
            <p className='text-2xl font-semibold'>Music</p>
        </div>
        <div className='flex items-center gap-2 px-6'>
            <IoShuffle size={25} />
            <p>Shuffle</p>
            <div className='relative w-[45px] h-[25px] rounded-full bg-gray-200 dark:bg-darkModeBG'>
                <div className='h-[21px] w-[21px] rounded-full bg-ipurple absolute right-0.5 top-0.5'></div>
            </div>
        </div>
      </div>
      <PlaylistCard />
      <div className='flex items-center justify-between w-full p-6'>
        <button className='flex font-semibold justify-center items-center gap-2 uppercase rounded-full hover: hover:bg-inherit bg-ipurple h-[45px] w-[345px] text-white hover:border-2 hover:border-ipurple hover:text-ipurple text-[12px]'>
            <IoQrCodeOutline />
            <p>Add album or playlist</p>
        </button>
        <button className='w-[300px] font-semibold h-[45px] uppercase text-[12px] border-b border-gray-100lack dark:border-white border-2 hover:bg-black rounded-full hover:text-white dark:hover:bg-white dark:hover:text-black'>
            Disconnect from <span>Apple Music</span>
        </button>
      </div>
    </div>
  )
}
