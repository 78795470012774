import { useEffect, useRef, useState } from "react";
import { IoIosClose, IoIosSearch } from "react-icons/io";
import { SearchIcon } from "../../icons/SearchIcon";
import { InfoIcon } from "../../icons/InfoIcon";
import RescheduleCallModal from "../../calls/RescheduleCallModal";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  CallFlowIcon,
  CallJokerIcon,
  CallRenameIcon,
  CallTrashIcon,
} from "../../icons/CallIcon";
import RenameCallFlow from "./callFlow/RenameCallFlow";
import CallFlowModal from "./callFlow/CallFlowModal";
import RemoveCallFlow from "./callFlow/RemoveCallFlow";
import { CallFlowService } from "../../../services/calls/CallFlowService";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollingLoader from "../../atom/loader/scrollingLoader";
import SavedCallFlowModal from "../../settings/views/SavedCallFlowModal";

const CallFlow = ({ business_id }) => {
  const dropdownRef = useRef(null);
  const [showRescheduleCallModal, setShowRescheduleCallModal] = useState(false);
  const selectedName = useRef("");
  const currentPageRef = useRef(1);
  const { listCallFlowApi, deleteCallFlowApi, listCallFlowList, loadingList } =
    CallFlowService();
  const [selectedOpt, setSelectedOpt] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showOptionsInfo, setShowOptionsInfo] = useState({
    type: "info",
    show: false,
    id: null,
  });
  const [isInputOpen, setIsInputOpen] = useState(false);
  const handleSearchClick = () => setIsInputOpen(!isInputOpen);
  const [showModal, setShowModal] = useState(false);
  const onClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptionsInfo({
          type: null,
          show: false,
          id: null,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const callFlowListApi = () => {
    listCallFlowApi({
      businessId: business_id,
      page: currentPageRef.current,
    }).then((data) => {
      if (data?.meta?.current_page) {
        currentPageRef.current = data?.meta?.current_page + 1;
      }
    });
  };

  useEffect(() => {
    if (business_id) {
      callFlowListApi();
    }
  }, [business_id]);

  return (
    <div
      className="w-full h-full  overflow-auto bg-white border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg dark:bg-darkModeMain"
      id="callFlowid"
    >
      {listCallFlowList?.data?.length != 0 && listCallFlowList?.data ? (
        <InfiniteScroll
          dataLength={listCallFlowList?.data?.length || 0}
          next={callFlowListApi}
          hasMore={currentPageRef?.current <= listCallFlowList?.meta?.last_page}
          scrollableTarget="callFlowid"
          scrollThreshold={0.5}
          style={{ overflow: "unset" }}
          loader={loadingList ? <ScrollingLoader /> : <></>}
        >
          <div>
            <div className="bg-white border-[1px]  border-gray-100 h-[60px] flex justify-between items-center px-4">
              <div className="font-bold text-[14px]">Call Flows</div>
              <div>
                {!isInputOpen && (
                  <button onClick={handleSearchClick} className="">
                    <IoIosSearch size={20} />
                  </button>
                )}
                {isInputOpen && (
                  <div className="relative flex items-center w-[400px] z-30">
                    <SearchIcon className="w-[25px] absolute left-2" />
                    <input
                      type="text"
                      id="ticket-search"
                      placeholder="Search Call Flow"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full  h-[45px] border-2 border-gray-100 dark:border-darkModeBorder bg-inherit rounded-lg px-10 placeholder:text-sm focus-within:ring focus-within:ring-ipurple focus-within:border-0"
                    />

                    <IoIosClose
                      onClick={handleSearchClick}
                      size={25}
                      className="absolute right-2"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="px-4">
              {" "}
              <div className="pt-3">
                iAllo will use your saved process to connect you to the
                representative even faster. You can save a new flow after the
                call with the business.
              </div>
              <div className="pt-2 flex flex-wrap">
                {listCallFlowList?.data?.map((data) => (
                  <div
                    className="border-[1px]  border-gray-100 h-[70px] w-[630px] px-2 rounded-xl m-3 relative"
                    key={data?.id}
                    onClick={() => setShowModal(true)}
                  >
                    <div className="py-2 flex justify-between items-center h-full">
                      <div className="flex items-center">
                        <CallFlowIcon className="border-[1px] p-[0.3rem] border-gray-100 ml-2 rounded-md" />

                        <div className="pl-4 text-[14px] font-semibold">
                          {data?.name}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div
                          onClick={() => {
                            setShowOptionsInfo({
                              type: "info",
                              show: !showOptionsInfo?.show,
                              id: data?.id,
                            });
                          }}
                          className="cursor-pointer"
                        >
                          <InfoIcon
                            className={
                              data?.id == showOptionsInfo?.id &&
                              showOptionsInfo?.show &&
                              showOptionsInfo?.type == "info"
                                ? "text-[#4C5FEF]"
                                : ""
                            }
                          />
                        </div>
                        <BsThreeDotsVertical
                          size={18}
                          className="cursor-pointer"
                          color={
                            data?.id == showOptionsInfo?.id &&
                            showOptionsInfo?.show &&
                            showOptionsInfo?.type != "info"
                              ? "#4C5FEF"
                              : ""
                          }
                          onClick={() => {
                            selectedName.current = data;
                            setShowOptionsInfo({
                              type: "threedot",
                              show: !showOptionsInfo?.show,
                              id: data?.id,
                            });
                          }}
                        />
                      </div>
                    </div>{" "}
                    <div className="absolute right-4 top-4 !z-50">
                      {showOptionsInfo?.show &&
                        data?.id == showOptionsInfo?.id &&
                        showOptionsInfo?.type == "info" && (
                          <div
                            className="font-semibold  p-3 z-10 text-[13px] mt-[35px] w-[320px] h-[150px] divide-y-[1px] dark:divide-darkModeBorder flex flex-col justify-center items-center rounded-lg bg-white dark:bg-darkModeMain border-[1px]  border-gray-100  dark:border-darkModeBorder"
                            ref={dropdownRef}
                          >
                            <ul className="font-normal">
                              {" "}
                              <li className="py-1 text-[13px]">
                                <span className="font-semibold"> Press 1</span>{" "}
                                to speak with a representative
                              </li>
                              <li className="py-1 text-[13px]">
                                <span className="font-semibold">Press 2</span>{" "}
                                for Provider Services
                              </li>
                              <li className="py-1 text-[13px]">
                                <span className="font-semibold">Press 3 </span>
                                for {selectedName?.current?.name} Issues
                              </li>
                              <li className="py-1 text-[13px]">
                                Enter your provider number:{" "}
                                <span className="font-semibold">575347 </span>
                              </li>
                            </ul>
                          </div>
                        )}
                      {showOptionsInfo?.show &&
                        data?.id == showOptionsInfo?.id &&
                        showOptionsInfo?.type == "threedot" && (
                          <div
                            className="font-semibold  p-3 z-10 text-[13px] text-left mt-[35px] w-[270px]  divide-y-[1px] dark:divide-darkModeBorder flex flex-col justify-center rounded-lg bg-white dark:bg-darkModeMain border-[1px]  border-gray-100  dark:border-darkModeBorder"
                            ref={dropdownRef}
                          >
                            <ul className=" divide-y-[1px] divide-gray-100 dark:divide-darkModeBorder w-full text-[13px] font-semibold">
                              <li>
                                <button
                                  className="flex w-full h-[30px] items-center px-2 hover:bg-lightBg dark:text-white dark:hover:bg-darkModeBG"
                                  onClick={() => {
                                    setSelectedOpt("rename");
                                    setShowOptionsInfo({
                                      type: "",
                                      show: false,
                                      id: null,
                                    });
                                  }}
                                >
                                  <CallRenameIcon className="w-[22px] h-[22px]" />
                                  <span className="pl-2">Rename</span>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="flex w-full h-[30px] items-center px-2 hover:bg-lightBg dark:text-white dark:hover:bg-darkModeBG"
                                  onClick={() => {
                                    setSelectedOpt("remove");
                                    setShowOptionsInfo({
                                      type: "",
                                      show: false,
                                      id: null,
                                    });
                                  }}
                                >
                                  {" "}
                                  <CallTrashIcon className="w-[22px] h-[22px]" />
                                  <span className="pl-2">Remove</span>
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                    </div>
                  </div>
                ))}
                {selectedOpt == "rename" ? (
                  <RenameCallFlow
                    handleClose={() => setSelectedOpt("")}
                    selectedData={selectedName.current}
                  />
                ) : selectedOpt == "remove" ? (
                  <RemoveCallFlow
                    handleClose={() => setSelectedOpt("")}
                    modalTitle={selectedName?.current?.name || ""}
                    handleRemove={() => {
                      deleteCallFlowApi(selectedName.current?.id).then(
                        (data) => {
                          if (data?.success) {
                            setSelectedOpt("");
                            toast.success("Call deleted successfully");
                          }
                        }
                      );
                    }}
                  />
                ) : (
                  selectedOpt == "Reimbursement" && (
                    <CallFlowModal
                      showRescheduleCallModal={showRescheduleCallModal}
                      setShowRescheduleCallModal={(data) => {
                        setShowRescheduleCallModal(data);
                        setSelectedOpt(null);
                      }}
                      selectedData={selectedName.current}
                      handleClose={() => setSelectedOpt("")}
                    />
                  )
                )}
              </div>
            </div>
          </div>{" "}
        </InfiniteScroll>
      ) : (
        <div className="flex justify-center h-full">
          <div className="w-[767px] text-center flex flex-col justify-evenly py-6 ">
            <div className="flex justify-center">
              <CallJokerIcon />
            </div>
            <p className="font-bold text-[18px] ">Call Flows</p>
            <div className="">
              iAllo will use your saved process to connect you to the
              representative even faster. You can save a new flow after the call
              with the business.
            </div>
          </div>
        </div>
      )}
      <div className="fixed z-30 w-full  top-[125px]">
        {showRescheduleCallModal && (
          <RescheduleCallModal
            onClose={() => setShowRescheduleCallModal(false)}
            ticketId={""}
            businessId={""}
            scheduledTime={""}
            closeModal={() => {
              setShowRescheduleCallModal(false);
            }}
            // setSingleTicketData={setSingleTicketData}
            getScheduleCallData={(data) => {
              // getSingleTicketDetailsApi();
              // setSingleTicketData({
              //   ...singleTicketData,
              //   call_requests: singleTicketData?.call_requests?.map(
              //     (response) => ({
              //       ...response,
              //       call_at_ts: data?.call_at_ts,
              //     })
              //   ),
              // });
            }}
            // singleTicketData={singleTicketData}
            setSelectedScheduledTime={() => {}}
          />
        )}
      </div>
      {showModal && <SavedCallFlowModal onClose={onClose} />}
    </div>
  );
};
export default CallFlow;
