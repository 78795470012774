
import ReactDOM from 'react-dom';
import { IoIosAdd, IoIosClose } from 'react-icons/io';
import PeopleCard from './PeopleCard';
import { RxInfoCircled } from "react-icons/rx";

export default function PeoplePopup({onClose}) {
  
  return ReactDOM.createPortal (
    (
    <div className="absolute top-0 z-[60] flex items-center justify-center w-full h-screen overflow-hidden text-sm">
      <div className="fixed w-full h-screen bg-black opacity-90"></div>
      <div className="w-[820px] h-[830px] relative z-40 bg-white m-4 rounded-lg dark:bg-darkModeMain flex flex-col justify-between items-center space-y-4">
        <div className='w-full border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder flex justify-between items-center h-[60px]'>
        <div className='flex items-center gap-3 p-4 text-sm'>
            <h1 className='font-semibold uppercase'>People (<span>4</span>)</h1>
            <div className='w-[2px] h-[20px] bg-gray-100 mx-2 dark:bg-darkModeBG'></div>
            <p className='font-semibold text-ipurple'>All <span>7</span></p>
            <p>Agents <span>2</span></p>
            <p>Members <span>2</span></p>
            <p>Pending <span>3</span></p>
        </div>
        <button onClick={onClose}>
        <IoIosClose size={35} className='m-4' />
        </button>
        </div>
        <div className='overflow-hidden w-full absolute top-[45px] py-4 flex items-center justify-center min-h-[80px] gap-4 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder'>
            <input 
                type='text'
                placeholder='Enter email, phone number or select people'
                className='bg-inherit min-w-[250px] max-w-[450px]  flex grow h-[55px] ml-4 rounded-lg border-gray-100 dark:border-darkModeBorder text-sm line-clamp-1 overflow-hidden p-3'
            />
            <button className='min-w-[160px] h-[45px] rounded-full bg-ipurple uppercase text-white hover:border-2 hover:border-ipurple hover:text-ipurple hover:bg-inherit'>Invite</button>
            <div className='w-[2px] h-[45px] bg-gray-100 dark:bg-darkModeBG'></div>
            <button className='min-w-[160px] h-[45px] mr-4 uppercase flex items-center justify-center border-2 border-b border-gray-100lack dark:border-white hover:bg-black gap-1 dark:hover:bg-white rounded-full hover:text-white dark:hover:text-black'><IoIosAdd size={22}  />  Add Agent</button>
        </div>
        <div className='absolute top-[135px] w-full'>
        <PeopleCard />
        </div>
        
        <div className='w-full flex items-center border-t-[1px] text-[13px] dark:border-darkModeBorder h-[55px]'>
        <RxInfoCircled size={20} className='mx-4' />
        <p>Invited people will be able to interact with the ticket, make calls, respon in chat and more.</p>
        </div>



         

        </div>
      </div>
   
    ),
    document.getElementById('portal-root')
  );
}
