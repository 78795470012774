import dayjs from "dayjs";

export const DateTimeFormat = (dateTime) => {
  const formattedDate = dayjs(dateTime).local().format("MMM DD, YYYY h:mm A");
  return formattedDate;
};
export const noteDateFormat = "DD, MMM [at] HH:mm";
export const DateTimeFormatUtc = (dateTime) => {
  const parsedDate = new Date(dateTime);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[parsedDate.getUTCMonth()];
  const day = parsedDate.getUTCDate();
  const year = parsedDate.getUTCFullYear();

  let hours = parsedDate.getUTCHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Adjusting 0 to 12 for 12-hour format
  const minutes = parsedDate.getUTCMinutes();
  const formattedTime = `${month} ${day}, ${year} - ${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}${ampm}`;
  return formattedTime;
};

export const callDurationFormat = (time) => {
  time = Number(time);
  let h = Math.floor(time / 3600);
  let m = Math.floor((time % 3600) / 60);
  let s = Math.floor((time % 3600) % 60);

  let formattedTime =
    (h < 10 ? "0" : "") +
    h +
    ":" +
    (m < 10 ? "0" : "") +
    m +
    ":" +
    (s < 10 ? "0" : "") +
    s;

  return formattedTime;
};

export const formatTimeTo12Hour = (time) => {
  const [hours, minutes, seconds] = time.split(":");
  const formattedTime = dayjs()
    .local()
    .set("hour", hours)
    .set("minute", minutes)
    .set("second", seconds)
    .format("h:mm A");

  return formattedTime;
};


export const convertSecondsToReadableFormat=(seconds)=> {
  if (seconds < 60) {
    return `${seconds} sec`;
  } else {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} sec`;
  }
}