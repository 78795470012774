import React, { useState, useRef } from "react";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { SlReload } from "react-icons/sl";
import { IoCloseOutline } from "react-icons/io5";
import { AuthPersonalPhone } from "../../services/auth/personal/AuthPersonal";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

InvitedDropdown.propTypes = {
  inviteeDetails: PropTypes.object.isRequired,
  onCancelInvite: PropTypes.func.isRequired,
};

export default function InvitedDropdown(props) {
  const { inviteeDetails, onCancelInvite } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const { resendInviteFriendsApi, inviteFriendsUpdateApi } = AuthPersonalPhone(
    (state) => state,
  );

  // call resend invite api
  const handleResendInvite = () => {
    if (inviteeDetails?.id) {
      resendInviteFriendsApi(inviteeDetails.id)
        .then(({ success, message }) => {
          if (success) {
            toast.success(message);
            toggleDropdown();
          } else {
            console.error("Error while re-sending invite:", message);
            toast.error(message);
          }
        })
        .catch((error) => {
          console.error("Unexpected error:", error);
          toast.error("Something went wrong. Please try again!");
        });
    }
  };

  // cancel invite
  const handleCancelInvite = () => {
    if (inviteeDetails?.id) {
      inviteFriendsUpdateApi(inviteeDetails.id, { status: "CANCELLED" })
        .then(({ success, message }) => {
          if (success) {
            toast.success("Invitation cancelled Successfully!");
            toggleDropdown();
            onCancelInvite();
          } else {
            console.error("Error while cancelling invite:", message);
            toast.error(message);
          }
        })
        .catch((error) => {
          console.error("Unexpected error:", error);
          toast.error("Something went wrong. Please try again!");
        });
    }
  };

  return (
    <div className="relative flex">
      <a
        href="#"
        className="flex items-center  text-[12px] transition duration-150 ease-in-out cursor-pointer"
        onClick={toggleDropdown}
      >
        <PiDotsThreeVerticalBold size={25} />
      </a>

      {isOpen && (
        <div className="w-[220px] z-30 flex flex-col absolute right-[0px] shadow-md top-[35px] rounded-lg h-[80px] bg-white dark:bg-darkModeMain border-[1px]  border-gray-100  dark:border-darkModeBorder">
          <div
            className="flex gap-3 items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-1/2 w-full rounded-t-lg hover:bg-lightBg dark:hover:bg-darkModeBG hover:cursor-pointer"
            onClick={handleResendInvite}
          >
            <SlReload size={20} className="ml-2" />
            <p className="font-semibold text-[12px]">Re-send invite</p>
          </div>
          <div
            className="flex gap-3 items-center border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder h-1/2 w-full rounded-b-lg  hover:bg-lightBg dark:hover:bg-darkModeBG hover:cursor-pointer"
            onClick={handleCancelInvite}
          >
            <IoCloseOutline size={18} className="ml-2" />
            <p className="font-semibold text-[12px]">Cancel invite</p>
          </div>
        </div>
      )}
    </div>
  );
}
