
import { AiFillQuestionCircle, AiOutlineSearch, AiOutlinePaperClip } from 'react-icons/ai';
import { RiAttachment2, RiCustomerService2Fill } from 'react-icons/ri';
import {BiStore} from 'react-icons/bi'
import {BsClipboard} from 'react-icons/bs'
import { BusinessContactIcon, SupportIcon } from '../icons/Icons';

export default function App() {
  const options = [
    {
      id: 1,
      icon: <BsClipboard className='w-[16px] h-[25px] ' />,
      descrip: 'Tell me about your issue and specify the name of the business.',
      style: { background: 'linear-gradient(to top, #FF6A61, #FFC387)' }, // Inline style for gradient
   
    },
    {
      id: 2,
      icon: <BusinessContactIcon  className='w-[24px] h-[23px]'/>,
      descrip: 'Ask me to search for business contacts or the best contact channel.',
      style: { background: 'linear-gradient(to top, #5C00FF, #4C99EF)' }, 
    },
    {
      id: 3,
      icon: <RiAttachment2 className='w-[19px] h-[24px]' />,
      descrip: 'Attach the receipt of a business to make it faster to resolve your issue.',
      style: { background: 'linear-gradient(to top, #23BAFF, #23BAFF)' }, 
    },
    {
      id: 4,
      icon: <SupportIcon className={"text-white w-[24px] h-[24px]"} />,
      descrip: 'Ask me to connect you with real person for any business.',
      style: { background: 'linear-gradient(to top, #FF6A61, #FF879F)' }, 
    },
  ];

  return (
    <div className="w-full pl-5 pt-5 space-y-[15px] ">
      {options.map(option => (
        <div key={option.id} className='flex items-center w-full gap-4 text-sm'>
          <button
            aria-current="true"
            type="button"
            style={option.style} // Apply inline style
           
            className={`flex text-white items-center justify-center cursor-pointer rounded-md ${option.className} w-[30px] h-[30px]`}
          >
            {option.icon}
          </button>
          <p className="">{option.descrip}</p>
        </div>
      ))}
    </div>
  );
}