import { useEffect, useRef, useState } from "react";
import UserMessage from "./UserMessage";
import AssistantMessage from "./AssistantMessage";
import RequestCallCard from "./RequestCallCard";
import Loader from "../atom/loader/Loader";
import TicketBusinessCard from "./ticketBusinessCard";
import round from "../../assets/logo.webp";

import AttachmentMessage from "./AttachmentMessage";
import dayjs from "dayjs";
import AfterCallMessage from "./AfterCallMessage";
import "../../components/atom/loader/loader.css";
import { useSearch } from "../../context/SearchContext";
import { TopNavSearchService } from "../../services/search/topNav/TopNavSearchService";
import {
  DEFAULT_LOCATION_DISTANCE,
  getCurrentLocation,
} from "../../utils/constants";
import { DateTimeFormatUtc } from "../../utils/DateTimeFormat";
import SearchChatMessage from "./chatFlow/SearchChatMessage";
import { useLocation } from "react-router-dom";
export default function MessageList(props) {
  const {
    chatDetailsLocally,
    setChatDetailsLocally,
    loader,
    temporaryMessage,
    message_text,
    selectedScheduledTime,
    setSelectedScheduledTime,
    chatId,
    setCalling,
    initiateSocketChat,
    setIsModalOpen,
    page,
    chatName,
    cardData,
    chatRecord,
    businessIdStatusTab,
    hitShowChatApi,
    handleSubmit,
    setInputValue,
    pageType,
    joinSocket,
  } = props || {};
  const { topNavDirectorySearchApi } = TopNavSearchService((state) => state);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const { setSearchViewToggle, setSearchTerm } = useSearch();
  const refArea = useRef();
  const location = useLocation();

  useEffect(() => {}, [chatDetailsLocally]);

  function isDateFormat(str) {
    // Regular expression for YYYY-MM-DDTHH:MM:SS.mmmZ format
    var dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    return dateFormatRegex.test(str);
  }
  useEffect(() => {
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [loader]);
  useEffect(() => {
    getCurrentLocation(
      (lat, lon) => {
        setLatitude(lat);
        setLongitude(lon);
      },
      (error) => {
        // console.error("Error getting location", error);
      }
    );
  }, []);
  const handleShowResult = (data) => {
    setSearchTerm("");
    setSearchViewToggle(true);
    topNavDirectorySearchApi({
      LocationDistance: DEFAULT_LOCATION_DISTANCE,
      business_id: data?.business_id,
      keyword: data?.business_id ? "" : data?.search_text,
      locationLat: latitude || "",
      locationLong: longitude || "",
    });
  };
  const btnClassName =
    "h-[45px] w-[305px] rounded-full border-[1px]  border-gray-100 text-[12px] font-semibold";

  return (
    <div
      className="flex flex-col p-2 ml-4 mt-2 space-y-4 overflow-x-hidden overflow-y-auto"
      ref={refArea}
    >
      {chatDetailsLocally?.length == 0 ? (
        <AssistantMessage
          AssistanceMessage={
            location?.pathname?.split("/")[1] == "business" ||
            message_text == "Request Call" ||
            pageType == "AI_CHAT"
              ? "To request a call, tell me your issue."
              : message_text && page == "businessProfile"
              ? message_text
              : "Hi, How may i help you?"
          }
        />
      ) : Array?.isArray(chatDetailsLocally) ? (
        chatDetailsLocally?.map((data, index) => {
          return (
            <div id="ChatView" key={index}>
              {data?.message_type == "assistant" ? (
                <>
                  <AssistantMessage
                    AssistanceMessage={
                      isDateFormat(data?.message_text)
                        ? dayjs(data?.message_text)
                            .local()
                            .format("MMMM DD, YYYY - hh:mm A")
                        : data?.message_text
                    }
                    message_text={message_text ? message_text : ""}
                  />
                  {data?.message_json?.id == "search" && (
                    <div className="w-full flex justify-center">
                      <button
                        className={`${btnClassName} border-[1px]  border-black mt-3 justify-center`}
                        onClick={() => handleShowResult(data?.message_json)}
                      >
                        Show Results
                      </button>
                    </div>
                  )}
                </>
              ) : data?.message_type == "user" ||
                (data?.message_type == "answer" &&
                  data?.message_json.id != "leave_a_review") ? (
                <UserMessage
                  userMessage={
                    isDateFormat(data?.message_text)
                      ? dayjs(data?.message_text)
                          .local()
                          .format("MMMM DD, YYYY - hh:mm A")
                      : data?.message_text || ""
                  }
                  selectedScheduledTime={selectedScheduledTime}
                />
              ) : data?.message_type == "search" ? (
                <SearchChatMessage
                  chatMessage={data}
                  initiateSocketChat={(data) => {
                    if (data == "initiateChat") {
                      joinSocket();
                    } else {
                      initiateSocketChat(data);
                    }
                  }}
                />
              ) : data?.message_type == "attachment" ? (
                <AttachmentMessage
                  data={data}
                  hitShowChatApi={hitShowChatApi}
                />
              ) : data?.message_type == "ticket" ? (
                <TicketBusinessCard data={data} />
              ) : data?.message_type == "question" &&
                data?.message_json.id == "schedule_request_call" ? (
                // data?.message_type == "search" ? (
                <>
                  <RequestCallCard
                    selectedScheduledTime={selectedScheduledTime}
                    setSelectedScheduledTime={setSelectedScheduledTime}
                    chatId={chatId}
                    data={data}
                    chatDetailsLocally={chatDetailsLocally}
                    setCalling={setCalling}
                    setChatDetailsLocally={(data) =>
                      setChatDetailsLocally(data)
                    }
                    businessIdStatusTab={businessIdStatusTab}
                    initiateSocketChat={(data) => initiateSocketChat(data)}
                  />
                </>
              ) : data?.message_type == "question" ||
                data?.message_type == "call_done" ||
                data?.message_type == "call_back_now" ? (
                <AfterCallMessage
                  handleSubmit={handleSubmit}
                  setInputValue={setInputValue}
                  chatMessage={data}
                  chatRecord={chatRecord}
                  chatDetailsLocally={chatDetailsLocally}
                  setChatDetailsLocally={(data) => setChatDetailsLocally(data)}
                  initiateSocketChat={(data) => initiateSocketChat(data)}
                />
              ) : (
                // : data?.message_type ? (
                //   <div>{data?.message_type}</div>
                // )
                <></>
              )}
            </div>
          );
        })
      ) : (
        <></>
      )}
      {loader && (
        <>
          {" "}
          <UserMessage
            userMessage={
              isDateFormat(temporaryMessage)
                ? DateTimeFormatUtc(temporaryMessage)
                : temporaryMessage
            }
            message_text={message_text}
          />
          <div className="flex max-w-[15%] ">
            <img
              src={round}
              className="w-9 h-9 border-[1.5px] rounded-full object-contain"
              alt="button"
            />
            <p className="flex items-center justify-center w-full p-0 text-sm ml-2 rounded-b-lg rounded-tr-lg bg-gradient-to-r from-indigo-600 to-purple-700 overflow-wrap break-word">
              {/* <div className="col-3 "> */}
              <div className="dot-falling"></div>
              {/* </div> */}
            </p>
          </div>
        </>
      )}
    </div>
  );
}
