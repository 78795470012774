import AxiosApi from "../api/requestInterceptor";
import { BASE_URL, VITE_API_PAGE_LIMIT } from "../utils/constants";

export const deleteAccountApi = async () => {
    try {
      const response = await AxiosApi.post(`${BASE_URL}auth/delete-account`);

      let { success } = response?.data || { message: "error" };
      localStorage.removeItem("authLogin");
      window.location.href = "/login";
      return response?.data;
    } 
    catch (error) {
        console.error("error")
    }
}