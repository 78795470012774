
import React, { createContext, useContext, useState } from 'react';

const SidebarVisibilityContext = createContext();

export const useSidebarVisibility = () => useContext(SidebarVisibilityContext);

export const SidebarVisibilityProvider = ({ children }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => setIsSidebarVisible(prev => !prev);

  return (
    <SidebarVisibilityContext.Provider value={{ isSidebarVisible, toggleSidebar }}>
      {children}
    </SidebarVisibilityContext.Provider>
  );
};
