
import Verizon from '../assets/Verizon.webp';
import amex from '../assets/amex.webp';
import apple from '../assets/Apple.webp';
import etsy from '../assets/Etsy.webp';
import fedex from '../assets/Fedex.webp';

const businesses = [
    {
        id: 1,
         logoSrc: Verizon,
         name: 'Verizon',
         siteUrl: "https://www.verizon.com",
         score: '4.5',
         isSaved: true,
         isRecent: false,
         isSuggested: false,
         distance: '0.3 mi',
         address: '900 Kenneth Cir.',
         isTopResult: "true",
         category: 'Energy & Utilities',
         ticketMessage: 'LTE Network Settings',
         ticketDate: 'Jan 7, 2023-05:45 PM',
         ticketNumber: '#8199412',
         ticketStatus: 'Current'
      },
      {
         id: 2,
         logoSrc: amex,
         name: 'American Express',
         siteUrl: "https://www.americanexpress.com",
         score: '4.8',
         isSaved: true,
         isRecent: true,
         isSuggested: false,
         distance: '2.3 mi',
         address: '920 Hobart St.',
         isTopResult: "false",
         category: 'Fincancial Services',
         ticketMessage: 'Text messages in airplane mode',
         ticketDate: 'Jan 8, 2023-02:45 PM',
         ticketNumber: '#8199413',
         ticketStatus: 'Open'
     },
     {
         id: 3,
         logoSrc: apple,
         name: 'Apple',
         siteUrl: "https://wwww.apple.com",
         score: '4.4',
         isSaved: false,
         isRecent: true,
         isSuggested: false,
         distance: '',
         address: '',
         isTopResult: "true",
         category: 'Energy & Utilities',
         ticketMessage: 'LTE Network Settings',
         ticketDate: 'Jan 9, 2023-09:45 PM',
         ticketNumber: '#8199414',
         ticketStatus: 'Open'
     },
     {
         id: 4,
         logoSrc: etsy,
         name: 'Etsy',
         siteUrl: "https://www.etsy.com",
         score: '4.2',
         isSaved: true,
         isRecent: true,
         isSuggested: false,
         distance: '12.4 mi',
         address: '3111 Doctors Dr.',
         isTopResult: "true",
         category: 'E-commerce',
         ticketMessage: 'LTE Network Settings',
         ticketDate: 'Jan 10, 2023-05:20 AM',
         ticketNumber: '#8199415',
         ticketStatus: 'Closed'

     },
     {
         id: 5,
         logoSrc: fedex,
         name: 'FedEx',
         siteUrl: "https://www.fedex.com",
         score: '4.7',
         isSaved: false,
         isRecent: true,
         isSuggested: false,
         distance: '5.9 mi',
         address: '8500 Beverly Ave.',
         isTopResult: "false",
         category: 'Engergy & Utilities',
         ticketMessage: 'Can I use a SIM card when traveling in Italy',
         ticketDate: 'Jan 11, 2023-06:45 PM',
         ticketNumber: '#8199416',
         ticketStatus: 'Closed'
     },
];

export default businesses;