export const ValidateNumberOnly = (number) => {
  return number?.replace(/[^0-9.]/g, "");
};

export const NumberValidation = (number) => {
  return number
    ?.replace(/\D/g, "")
    ?.replace(/(\d{3})(\d{3})(\d{3})/, "($1) $2-$3");
};

export const UsPhoneNumberValidation = (number) => {
  return number
    ?.replace(/\D/g, "")
    ?.replace(/(\d{3})(\d{3})(\d{3})/, "($1) $2-$3");
};
export const PhoneNumberValidation = (number) => {
  return (
    number &&
    number
      ?.replaceAll("(", "")
      ?.replaceAll(")", "")
      ?.replaceAll(" ", "")
      ?.replaceAll("-", "")
  );
};

export const uniqueTicketNotes =(dataArray)=> dataArray.reduce((accumulator, currentItem) => {
  // Check if there's already an item with the same id in the accumulator
  const existingItemIndex = accumulator.findIndex(item => item.id === currentItem.id);

  // If not found, add the current item to the accumulator
  if (existingItemIndex === -1) {
    accumulator.push(currentItem);
  }

  return accumulator;
}, []);
