import React from 'react'
import { FaApple, FaSpotify, FaAudible } from "react-icons/fa";
import ConnectMusicLibrary from '../ConnectMusicLibrary';
import ConnectedService from '../ConnectedService';

export default function ReplaceMusicView() {
  return (
    <div className='flex flex-col w-full py-5 text-sm scrollable-container-hide h-full overflow-y-auto'>
      <p className='px-6 pb-6'>
        Choose your favorite music genres so that iAllo Station plays music you like instead of boring on-hold music.
      </p>
      <div className='max-w-[650px] flex flex-wrap gap-3 items-center text-[13px] pb-10 px-6'>
        <button className='h-[40px] px-4 bg-ipurple rounded-md text-white hover:font-bold'>Rock</button>
        <button className='h-[40px] px-4 bg-ipurple rounded-md text-white hover:font-bold'>Metal</button>
        <button className='h-[40px] px-4 bg-ipurple rounded-md text-white hover:font-bold'>Classic</button>
        <button className='h-[40px] px-4 shadow-xl rounded-md dark:border-[1px]  border-gray-100  dark:border-darkModeBorder hover:font-bold' >Jazz</button>
        <button className='h-[40px] px-4 shadow-xl rounded-md dark:border-[1px]  border-gray-100  dark:border-darkModeBorder hover:font-bold' >Country</button>
        <button className='h-[40px] px-4 shadow-xl rounded-md dark:border-[1px]  border-gray-100  dark:border-darkModeBorder hover:font-bold' >EDM</button>
        <button className='h-[40px] px-4 shadow-xl rounded-md dark:border-[1px]  border-gray-100  dark:border-darkModeBorder hover:font-bold' >Reggae</button>
        <button className='h-[40px] px-4 shadow-xl rounded-md dark:border-[1px]  border-gray-100  dark:border-darkModeBorder hover:font-bold' >90s</button>
        <button className='h-[40px] px-4 shadow-xl rounded-md dark:border-[1px]  border-gray-100  dark:border-darkModeBorder hover:font-bold' >Latin</button>
        <button className='h-[40px] px-4 shadow-xl rounded-md dark:border-[1px]  border-gray-100  dark:border-darkModeBorder hover:font-bold'  >Soul</button>
        <button className='h-[40px] px-4 shadow-xl rounded-md dark:border-[1px]  border-gray-100  dark:border-darkModeBorder hover:font-bold' >Hip-Hop</button>
      </div>
      {/* Render ConnectMusicLibrary if use has none connected or ConnectedService if user has one connected  */}
    <ConnectMusicLibrary />
    <ConnectedService />
    </div>
  )
}
