import ReactDOM from "react-dom";
import { BsArrowRight } from "react-icons/bs";
import { IoClipboardOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function Dropdown(props) {
  const { ticketId, setIsTicketClosed, status, setIsDropdownOpen } =
    props || {};
  const navigate = useNavigate();

  const handleTicketClicks = (ticketId) => {
    setIsDropdownOpen(false);
    const newTab = window.open(`/tickets/${ticketId}`, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      navigate(`/tickets/${ticketId}`, {
        state: {
          ticketId: ticketId,
        },
      });
    }
  };

  return (
    <div
      className="z-[999] bg-white rounded-lg dark:border-slate-900 shadow  dark:bg-darkModeBG hover:dark:bg-darkModeMain "
      onClick={(e) => e.stopPropagation()}
    >
      <button className="hover:bg-lightBg w-full dark:hover:bg-darkModeBG flex pl-4 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder items-center py-1">
        <BsArrowRight size={20}/>
        <span
          onClick={() => handleTicketClicks(ticketId)}
          className="block px-2 py-2 text-sm cursor-pointer text-darkModeBG dark:text-neutral-200"
        >
          Open in a new tab
        </span>
      </button>
      {status == "open" ? (
        <div
          className="hover:bg-lightBg dark:hover:bg-darkModeBG flex pl-4 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder items-center py-1"
          onClick={() => {
            setIsTicketClosed(false);
          }}
        >
          <IoClipboardOutline size={20} fill="#646465" />
          <span className=" w-full block px-4 py-2 text-sm cursor-pointer text-darkModeBG dark:text-neutral-200">
            Close ticket
          </span>
        </div>
      ) : (
        <div
          className="hover:bg-lightBg dark:hover:bg-darkModeBG flex pl-4 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder items-center py-1"
          onClick={() => {
            setIsTicketClosed(true);
          }}
        >
          <IoClipboardOutline size={20} />
          <span className="block px-4 py-2 text-sm cursor-pointer text-darkModeBG dark:text-neutral-200">
            Re-Open Ticket
          </span>
        </div>
      )}
      {/* <div className="hover:bg-lightBg dark:hover:bg-darkModeBG flex pl-4 border-b border-gray-100-[1px] border-gray-100 dark:border-darkModeBorder items-center py-1">
        <HiOutlineArchiveBox size={20} />
        <span
          className="block px-4 py-2 text-sm cursor-pointer text-darkModeBG dark:text-neutral-200"
        >
          Archive ticket
        </span>
      </div> */}
    </div>
  );
}
