import { useEffect, useCallback } from "react";
import { debounce } from "lodash";
import CheckBoxDetails from "../../../molecules/InputDetails/CheckBoxDetails"
const CategoriesCheckbox = (props) => {
  let {
    onFilterChange,
    categoryArr,
    setSelectedCategories,
    selectedCategories,
  } = props || {};

  // Function to handle when a checkbox is toggled
  const handleCategoryChange = (category) => {
    setSelectedCategories(
      selectedCategories.includes(category)
        ? selectedCategories.filter((cat) => cat !== category)
        : [...selectedCategories, category]
    );
  };

  const handleCategoryChangeDebounced = useCallback(
    debounce((selectedCategories) => {
      onFilterChange(selectedCategories);
    }, 300),
    [onFilterChange]
  );

  useEffect(() => {
    handleCategoryChangeDebounced(selectedCategories);
  }, [selectedCategories, handleCategoryChangeDebounced]);

  return (
    <fieldset>
      <div className="space-y-5">
        {/* Map over unique categories to create checkboxes */}
        {categoryArr?.length != 0 &&
          categoryArr?.map((category, index) => (
            <div key={index} className="relative flex items-start">
              <CheckBoxDetails
                index={index}
                id={category?.id}
                name={category?.name}
                checked={selectedCategories?.includes(category?.id)}
                onChange={() => handleCategoryChange(category?.id)}
              />
            </div>
          ))}
      </div>
    </fieldset>
  );
};

export default CategoriesCheckbox;
