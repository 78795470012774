import ContactOptions from "./ContactOptions";
import { IoCloseOutline } from "react-icons/io5";
import logo from "../../assets/Iallo.webp";
import { useState, useEffect } from "react";

export default function ContactCardCallClick(props) {
  const { onClose, businessId, businessDetails, contactDetailsShow } =
    props || {};
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (contactDetailsShow) {
      setShouldRender(true);
      setTimeout(() => setIsVisible(true), 10); // Delay to allow render before opacity change
    } else {
      setIsVisible(false);
      setTimeout(() => setShouldRender(false), 300); // Match duration of transition
    }
  }, [contactDetailsShow]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setShouldRender(false);
      onClose();
    }, 300); // Match duration of transition
  };

  return shouldRender ? (
    <div
      className={`border-[1px] border-gray-100 dark:border-darkModeBorder -mt-1.5 relative justify-center z-40 items-center flex flex-col w-full bg-white min-h-[414px] rounded-b-lg dark:bg-darkModeMain transition-all duration-300 ${
        isVisible ? "opacity-100 scale-100" : "opacity-0 scale-95"
      }`}
    >
      <button onClick={handleClose}>
        <IoCloseOutline size={25} className="absolute top-4 right-4" />
      </button>

      <div className="flex flex-col items-center justify-center w-full h-full p-4 text-center">
        <img src={logo} width={120} height={55} alt="iallo logo" className="" />
        <h1 className="font-bold text-[18px] p-4 max-w-[250px] -mt-3 leading-relaxed">
          Do you want to make the call or should I?
        </h1>
        <ContactOptions
          businessId={businessId}
          businessDetails={businessDetails}
          contactDetails={contactDetailsShow}
        />
      </div>
    </div>
  ) : null;
}
