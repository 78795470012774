import ReactDOM from "react-dom";
import { IoClipboardOutline } from "react-icons/io5";

export default function CustomModal({
  handleApiCall,
  onClose,
  isTicketClosed,
  icon,
  title,
  description,
  closeText,
}) {
  return ReactDOM.createPortal(
    <div className="absolute top-0 z-[60] flex items-center justify-center w-full h-screen overflow-hidden">
      <div className="fixed -top-20 w-[200%]  h-[200%] bg-black -left-10 opacity-90"></div>
      <div className="w-[580px] h-[250px] z-40 bg-white rounded-lg dark:bg-darkModeMain flex flex-col justify-center items-center space-y-4">
        {icon}
        <h1 className="text-[20px] font-bold ">{title}</h1>
        <p className="text-sm">{description}</p>
        <div className="flex gap-4 pt-2">
          <button
            onClick={onClose}
            className="w-[260px] h-[45px] rounded-full bg-white border-2 text-black border-b border-gray-100lack uppercase text-sm hover:font-bold hover:border-gray-300 "
          >
            Cancel
          </button>
          <button
            onClick={() => handleApiCall()}
            className={`w-[260px] h-[45px] rounded-full uppercase text-sm hover:border-2  hover:border-ipurple hover:font-bold bg-black text-white hover:bg-ipurple hover:text-white`}
          >
            {closeText}
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("portal-root")
  );
}
