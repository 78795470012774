import { useEffect, useRef, useState } from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { CalendarIcon } from "../icons/Icons";

const CalendarPicker = (props) => {
  const { onClose, scheduledTime, activeComponent } = props || {};

  const initialDate = scheduledTime ? new Date(scheduledTime) : new Date();
  const [currentMonth, setCurrentMonth] = useState(initialDate);
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [yearDropdownVisible, setYearDropdownVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(initialDate.getFullYear());

  const calendarRef = useRef(null);

  const prevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1),
    );
  };

  const nextMonth = () => {
    if (activeComponent === "Birthday") {
      setSelectedDate("");
    }

    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1),
    );
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setCurrentMonth(new Date(year, currentMonth.getMonth(), 1));
    setSelectedDate(
      new Date(year, currentMonth.getMonth(), selectedDate.getDate()),
    );
    setYearDropdownVisible(false);
  };

  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].map(
    (day, index) => (
      <div
        key={index}
        className="flex items-center justify-center w-full p-4 uppercase text-[13px]"
      >
        {day}
      </div>
    ),
  );

  const getDaysInMonth = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const startDay = new Date(year, month, 1).getDay();
    const daysArray = [];

    for (let i = 0; i < startDay; i++) {
      daysArray.push(
        <div
          key={`blank-${i}`}
          className="rounded-full w-[45px] h-[45px] flex"
        />,
      );
    }

    const today = new Date(new Date().setHours(0, 0, 0, 0));

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const isFuture = date > today;
      const isSelected =
        selectedDate && selectedDate.toDateString() === date.toDateString();

      // Determine if the date should be disabled based on activeComponent
      const disableDate = activeComponent === "Birthday" && isFuture;

      daysArray.push(
        <button
          key={day}
          className={`rounded-full w-[45px] h-[45px] flex ${
            disableDate
              ? "opacity-20 cursor-not-allowed" // Style for disabled dates
              : "hover:bg-gray-100 dark:hover:bg-darkModeBG active:bg-ipurple"
          } ${isSelected ? "bg-ipurple text-white hover:bg-ipurple" : ""}`}
          disabled={disableDate}
          onClick={() => !disableDate && setSelectedDate(date)}
        >
          <time className="flex items-center justify-center mx-auto rounded-full h-[45px] w-[45px]">
            {day}
          </time>
        </button>,
      );
    }

    return daysArray;
  };

  const handleConfirm = () => {
    const finalDate = new Date(
      selectedYear,
      currentMonth.getMonth(),
      selectedDate.getDate(),
    );

    onClose(finalDate);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedDate, onClose]);

  return (
    <div
      className="text-center flex flex-col w-[430px] border-[1px] border-gray-100 bg-white rounded-lg dark:bg-darkModeMain shadow-black shadow-xl items-center justify-center"
      ref={calendarRef}
    >
      <div className="flex items-center justify-between w-full h-[55px] mb-6 rounded-t-lg text-ipurple bg-[#4c5fef0c] border-[1px] border-gray-100">
        <p className="p-4 text-sm font-semibold">
          {selectedDate
            ? selectedDate.toLocaleDateString("default", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })
            : "Select a date"}
        </p>
        <CalendarIcon className="mr-2 w-[25px]" />
      </div>

      <div className="flex items-center justify-between w-2/3 mb-4">
        <button onClick={prevMonth} className="hover:text-gray-500">
          <GoChevronLeft size={30} />
        </button>

        <div className="flex flex-col items-center relative">
          {yearDropdownVisible && (
            <div className="flex flex-col items-center">
              <div className="flex justify-between mb-2">
                <button
                  className="hover:text-gray-500"
                  onClick={() => setSelectedYear(selectedYear - 10)}
                >
                  <GoChevronLeft />
                </button>
                <div className="flex flex-col items-center">
                  <div className="grid grid-cols-3 gap-2 p-2 bg-white border border-gray-300 dark:bg-darkModeMain  rounded-md shadow-lg">
                    {Array.from({ length: 10 }, (_, index) => {
                      const yearOption = selectedYear + index;
                      return (
                        <button
                          key={yearOption}
                          className={`rounded-md p-2 hover:bg-gray-100 hover:dark:bg-darkModeBG dark:bg-darkModeMain ${
                            yearOption === selectedYear
                              ? "bg-ipurple text-white dark:bg-ipurple"
                              : ""
                          }`}
                          onClick={() => handleYearChange(yearOption)}
                        >
                          {yearOption}
                        </button>
                      );
                    })}
                  </div>
                </div>
                <button
                  className="hover:text-gray-500"
                  onClick={() => setSelectedYear(selectedYear + 10)}
                >
                  <GoChevronRight />
                </button>
              </div>
            </div>
          )}
        </div>
        {!yearDropdownVisible && (
          <div
            className="year cursor-pointer text-lg font-semibold mb-2"
            onClick={() => setYearDropdownVisible(!yearDropdownVisible)}
          >
            {currentMonth.toLocaleString("default", { month: "long" })}{" "}
            {selectedDate && selectedDate.getDate()},{" "}
            {currentMonth.getFullYear()}
          </div>
        )}

        <button onClick={nextMonth} className="hover:text-gray-500">
          <GoChevronRight size={30} />
        </button>
      </div>

      <div className="grid grid-cols-7 w-[85%] mb-2 text-xs text-gray-400">
        {daysOfWeek}
      </div>
      <div className="grid w-[85%] grid-cols-7 gap-1 mb-4">
        {getDaysInMonth()}
      </div>
      <button
        onClick={handleConfirm}
        className={`rounded-full mb-4 py-2 w-3/4 text-sm font-semibold ${
          selectedDate &&
          (activeComponent === "Birthday"
            ? new Date(selectedDate) <= new Date()
            : true) // For other components, allow the button to be enabled as long as selectedDate exists
            ? "bg-ipurple text-white"
            : "bg-gray-200 text-gray-500"
        }`}
        disabled={
          !selectedDate ||
          (activeComponent === "Birthday" &&
            new Date(selectedDate) > new Date()) // Disable future dates for Birthday
        }
      >
        {selectedDate ? "Confirm Date" : "Select a Date"}
      </button>
    </div>
  );
};

export default CalendarPicker;
