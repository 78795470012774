import { useEffect, useRef, useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { PrivateLockIcon } from "../../icons/Icons";
import ScrollDropdown from "../ScrollDropdown";
import CountryCodeInput from "../../Inputs/CountryCodeInput";
import { BusinessService } from "../../../services/bussiness/BusinessService";
import {
  isValidPhoneNumber,
  parsePhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js";
import {
  PhoneNumberValidation,
  UsPhoneNumberValidation,
} from "../../../utils/validate/NumberValidation";
import { useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import { DirectoryDetails } from "../../../services/directory/DirectoryDetails";
import { getUserId } from "../../../routes/ProtectedRoutes";
import { IoClose } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { sendMixpanelEvent } from "../../../services/mixpanelService";
AddContactView.propTypes = {
  onClose: PropTypes.func,
  formik: PropTypes.object.any,
  data: PropTypes.any,
  editContact: PropTypes.object,
  editIndex: PropTypes.any,
  pageType: PropTypes.any,
  onCloseHandleSubmit: PropTypes.any,
};

export default function AddContactView(props) {
  const {
    onClose,
    formik,
    data,
    editContact,
    editIndex,
    pageType,
    onCloseHandleSubmit,
  } = props;
  const { addContactApi, businessLoading } = BusinessService((state) => state);
  const [isScrollDropdownOpen, setIsScrollDropdownOpen] = useState(false);
  const [isPrivacyDropdownOpen, setIsPrivacyDropdownOpen] = useState(false);
  const [contactPrivacy, setContactPrivacy] = useState(
    !editContact?.isPrivate ? "Public Contact" : "Private Contact",
  );
  const { businessContactTypeApi, contactTypeResp } = BusinessService(
    (state) => state,
  );
  const location = useLocation();
  const dropdownRef = useRef(null);
  const { fetchBusinessDetails, fetchBusinessDetailsApi } = DirectoryDetails(
    (state) => state,
  );
  const [contactType, setContactType] = useState({ id: "", value: "" });
  const [countryCode, setCountryCode] = useState("+1");

  useEffect(() => {
    let optionArray = Array?.isArray(contactTypeResp?.data)
      ? contactTypeResp?.data
      : [];
    if (Object?.keys(editContact || {})?.length != 0) {
      try {
        const nationalNumber = parsePhoneNumber(
          editContact?.contact[0] == "+"
            ? editContact?.contact
            : "+" + editContact?.contact,
        );

        if (nationalNumber?.countryCallingCode) {
          setCountryCode("+" + nationalNumber?.countryCallingCode);
        }
        if (nationalNumber?.nationalNumber) {
          formikValue.setFieldValue(
            "phoneNumber",
            UsPhoneNumberValidation(nationalNumber?.nationalNumber),
          );
        }
      } catch (err) {
        formikValue.setFieldValue("phoneNumber", editContact?.contact);
      }

      if (editContact?.id) {
        setContactType(
          optionArray?.filter((data) => data?.id == editContact?.id)?.[0] ||
            optionArray?.[0],
        );
      } else {
        setContactType(optionArray?.[0]);
      }
      formikValue.setFieldValue(
        "contactTitle",
        editContact.title || editContact.name,
      );
      formikValue.setFieldValue("contactTypeformik", editContact.contact);
      formikValue.setFieldValue(
        "is_private",
        editContact.isPrivate === false ? "Public Contact" : "Private Contact",
      );
    } else {
      setContactType(optionArray?.[0]);
    }
  }, [editContact, contactTypeResp]);

  useEffect(() => {
    businessContactTypeApi();
  }, []);

  const handleIsScrollDropdownOpen = () => {
    setIsScrollDropdownOpen(!isScrollDropdownOpen);
  };
  const togglePrivacyDropdown = () => {
    setIsPrivacyDropdownOpen(!isPrivacyDropdownOpen);
  };

  const selectContactPrivacy = (privacyOption) => {
    setContactPrivacy(privacyOption);
    setIsPrivacyDropdownOpen(false);
  };

  const schema = yup.object().shape({
    contactTitle: yup
      .string()
      .min(1, "Please enter the min 3 letter")
      .max(50, "Please enter the max 50 letter")
      .required("This field is required."),
    phoneNumber: yup
      .string()
      .test(
        "is-valid-phone-number",
        "Invalid phone number",
        (value, context) => {
          if (contactType?.name === "Phone") {
            if (!countryCode || !value) {
              return false;
            }
            const phoneNumber = parsePhoneNumberFromString(countryCode + value);
            return parsePhoneNumber(
              countryCode + value,
              phoneNumber?.country,
            ).isValid() === true
              ? true
              : false;
          } else {
            return context?.from[0]?.value?.contactTypeformik ? true : false;
          }
        },
      ),

    contactTypeformik: yup
      .string()
      .nullable()
      .test({
        name: "contactTypeformik",
        test: function (value, context) {
          if (contactType?.name !== "Phone" && !value) {
            return this.createError({
              message: "This field is required.",
              path: "contactTypeformik",
            });
          } else {
            return true;
          } // Validation passes if the condition is not met or if the phone number is provided
        },
      }),
    //
  });

  const handleSubmit = async () => {
    const contactData = {
      title: formikValue?.values?.contactTitle,
      account_type_id: contactType?.id,
      account_value:
        contactType?.name == "Phone" || contactType?.name == ""
          ? countryCode +
            PhoneNumberValidation(formikValue?.values?.phoneNumber)
          : formikValue?.values?.contactTypeformik,
      is_verified: false,

      is_private: contactPrivacy == "Private Contact" ? true : false,
      account_type: { name: contactType?.name },
    };

    if (location?.pathname?.split("/")[1] == "business") {
      const user_id = getUserId();
      const contactDataEdit = {
        title: formikValue?.values?.contactTitle,
        account_type_id: contactType?.id,
        account_value:
          contactType?.name == "Phone" || contactType?.name == ""
            ? countryCode +
              PhoneNumberValidation(formikValue?.values?.phoneNumber)
            : formikValue?.values?.contactTypeformik,
        is_verified: false,
        call_avg_minutes: 0,
        user_id: user_id,
        is_private: contactPrivacy == "Private Contact" ? true : false,
      };

      addContactApi(fetchBusinessDetails?.data?.id, contactDataEdit).then(
        (data) => {
          fetchBusinessDetailsApi({
            businessId: fetchBusinessDetails?.data?.id,
          });
          sendMixpanelEvent("Business Details Saved", { Type: "Phone" });
          onClose();
        },
      );
    } else if (editIndex === "" || typeof editIndex !== "number") {
      await formik.setFieldValue("business_contacts", [
        ...formik.values.business_contacts,
        contactData,
      ]);

      if (onCloseHandleSubmit) {
        onCloseHandleSubmit();
      } else {
        onClose();
      }
    } else {
      const editedIndex = editIndex - 1;
      const formikData = formik.values.business_contacts?.map(
        (businessContactsData, index) => {
          if (editedIndex == 0 && index == 0) {
            return contactData;
          } else if (index == editedIndex) {
            return contactData;
          } else {
            return businessContactsData;
          }
        },
      );

      await formik.setFieldValue("business_contacts", formikData);
      if (pageType == "EditContactPopup") {
        onCloseHandleSubmit();
      } else {
        onClose();
      }
    }
    // }
  };

  const formikValue = useFormik({
    initialValues: {
      contactTypeformik: "",
      contactTitle: "",
      phoneNumber: "",
      is_private: "",
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Close the dropdown if clicked outside
      setIsPrivacyDropdownOpen(false);
    }
  };

  useEffect(() => {
    // Attach the listener on component mount
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col w-full text-sm ">
      <div className="flex flex-col w-full text-sm ">
        <div className="flex items-center w-full  border-b pb-4 h-[60px] ">
          <h1 className="px-4 mt-6 font-semibold uppercase">
            {Object?.keys(editContact || {})?.length != 0 ? "Update" : "New"}{" "}
            contact
          </h1>
          <button onClick={onClose} className="absolute top-4 right-4">
            <IoClose size={25} />
          </button>
        </div>
        <div className="flex w-full ">
          <div className="flex flex-col w-1/2 py-4 pb-8 pl-4 space-y-2 text-left ml-[3px]">
            <p>Contact type</p>
            <div
              onClick={handleIsScrollDropdownOpen}
              className="relative flex w-full "
            >
              <ScrollDropdown
                formik={formik}
                contactType={contactType}
                setContactType={(data) => {
                  setContactType(data);
                  formikValue?.setFieldValue("contactTypeformik", "");
                }}
                contactTypeResp={contactTypeResp}
              />
            </div>
          </div>
          <div className="flex flex-col w-1/2 p-4 space-y-2 text-left mr-[5px]">
            <p>Contact title</p>
            <input
              placeholder="Add contact title here"
              type="text"
              name="contactTitle"
              autoComplete="off"
              value={
                formikValue?.values?.contactTitle
                  ? formikValue?.values?.contactTitle
                  : ""
              }
              onChange={formikValue?.handleChange}
              className="placeholder:text-sm placeholder:font-normal text-black dark:text-white font-semibold text-sm placeholder:text-gray-400 dark:placeholder:text-white bg-inherit w-[420px] h-[55px] rounded-lg border-2 border-gray-100 dark:border-darkModeBorder flex focus:outline-0 focus:ring-none   justify-between items-center px-4 border-gray-100"
            />
            {formikValue?.errors?.contactTitle && (
              <p className="text-danger">{formikValue?.errors?.contactTitle}</p>
            )}
          </div>
        </div>
        <div className="flex ">
          {contactType?.name == "Phone" ? (
            <div
              className={`flex flex-col p-4 pb-8 space-y-2 text-left ml-[4px] -mr-[12px]   ${
                pageType == "EditContactPopup" ? "w-full" : "w-3/4"
              }`}
            >
              <p>Phone number</p>
              <CountryCodeInput
                countryCode={countryCode}
                phoneNumber={formikValue?.values?.phoneNumber}
                setCountryCode={setCountryCode}
                setPhoneNumber={(phoneNumber) => {
                  formikValue.setFieldValue("phoneNumber", phoneNumber);
                }}
              />{" "}
              {formikValue?.errors?.phoneNumber && (
                <p className="text-danger">
                  {formikValue?.errors?.phoneNumber}
                </p>
              )}
            </div>
          ) : (
            <div
              className={`flex flex-col  ${
                pageType == "EditContactPopup" ? "w-full" : "w-1/2"
              } p-4 space-y-2 text-left`}
            >
              <p>{contactType?.name}</p>
              <input
                name="contactTypeformik"
                placeholder={`Add ${contactType?.name} here`}
                autoComplete="off"
                type={contactType?.name == "Email" ? "email" : "text"}
                value={
                  formikValue?.values?.contactTypeformik
                    ? formikValue?.values?.contactTypeformik
                    : ""
                }
                onChange={formikValue?.handleChange}
                className="placeholder:text-sm placeholder:font-normal font-semibold text-sm placeholder:text-gray-400 dark:placeholder:text-white bg-inherit w-full h-[55px] rounded-lg border-[1px] border-gray-100 dark:border-darkModeBorder flex focus:outline-0 focus:ring-none  focus:border-ipurple justify-between items-center px-4"
              />
              {formikValue?.errors?.contactTypeformik && (
                <p className="text-danger">
                  {formikValue?.errors?.contactTypeformik}
                </p>
              )}
            </div>
          )}

          <div
            className={` flex flex-col p-4 mr-[2px]   ${
              pageType == "EditContactPopup" ? "" : "mb-24"
            } text-left`}
          >
            <p className="">Contact type</p>
            <div
              className="mt-2 -mb-1.5  relative hover:cursor-pointer w-[420px] h-[55px] rounded-lg border-2 border-gray-100 dark:border-darkModeBorder flex justify-between items-center px-4 border-gray-100 "
              onClick={togglePrivacyDropdown} // Toggle dropdown on click
            >
              <div className="flex items-center gap-1">
                <p className="font-semibold">{contactPrivacy}</p>
                {contactPrivacy === "Private Contact" && (
                  <PrivateLockIcon className="w-[25px] h-[25px] text-black dark:text-white" />
                )}
              </div>
              {isPrivacyDropdownOpen ? (
                <BsChevronUp size={18} />
              ) : (
                <BsChevronDown size={18} />
              )}
              {isPrivacyDropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute left-0  top-12 w-full border border-gray-100 z-10 flex flex-col mt-1 bg-white border-1   dark:divide-darkModeBorder rounded-lg dark:bg-darkModeMain dark:border-darkModeBorder"
                >
                  <div
                    className={` h-[55px] flex gap-2 items-center px-4 hover:bg-lightBg dark:hover:bg-darkModeBG cursor-pointer ${
                      contactPrivacy == "Private Contact"
                        ? "text-[#4C5FEF]"
                        : ""
                    }`}
                    onClick={() => {
                      if (formikValue) {
                        formikValue.setFieldValue("is_private", true);
                      }
                      selectContactPrivacy("Private Contact");
                    }}
                  >
                    <p className="font-semibold">Private Contact</p>
                    <PrivateLockIcon
                      className={`w-[25px] h-[25px] ${
                        contactPrivacy == "Private Contact"
                          ? "text-[#4C5FEF]"
                          : "text-black"
                      } dark:text-white`}
                    />
                  </div>
                  <div
                    className={`w-full h-[55px] flex items-center px-4 hover:bg-lightBg dark:hover:bg-darkModeBG cursor-pointer ${
                      contactPrivacy == "Public Contact" ? "text-[#4C5FEF]" : ""
                    }`}
                    onClick={() => {
                      if (formikValue) {
                        formikValue.setFieldValue("is_private", false);
                      }
                      selectContactPrivacy("Public Contact");
                    }}
                  >
                    <p className="font-semibold">Public Contact</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-4 py-6 dark:border-darkModeBorder h-[85px] flex items-center justify-end font-[600]">
        <div className="ml-[400px] mb-[25px]">
          <button
            onClick={onClose}
            className=" w-[180px] h-[45px] uppercase rounded-full border-b  lack border-[1px]  border-[#140F26]  dark:border-white hover:bg-black hover:text-white hover:border-none dark:hover:bg-white dark:hover:text-black"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={businessLoading ? true : false}
            className="m-4 w-[180px] h-[45px] uppercase rounded-full !bg-ipurple !text-white hover:border-[1px]  border-gray-100  hover:border-ipurple hover:text-ipurple hover:bg-inherit"
            onClick={() => {
              formikValue?.handleSubmit();
              sendMixpanelEvent("Business Details Saved", { Type: "Phone" });
            }}
          >
            {Object?.keys(editContact || {})?.length != 0 ? "Update" : "Add"}{" "}
            contact
          </button>
        </div>
      </div>
    </div>
  );
}
