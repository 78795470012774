import { BsFillPlusCircleFill } from "react-icons/bs";
import ContactsCard from "./ContactsCard";
import { IoChevronDown } from "react-icons/io5";
import EditContactPopup from "./EditContactPopup";
import { useState } from "react";

export default function Contacts(props) {
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <div className="flex flex-col w-full max-h-[950px] min-h-[200px] overflow-x-hidden  dark:bg-darkModeMain   bg-white border-[1px]  border-gray-100  dark:border-darkModeBorder rounded-lg">
      {showContactForm && (
        <EditContactPopup onClose={() => setShowContactForm(false)} />
      )}
      <div className="flex justify-between items-center px-4  mb-5  h-[60px] min-h-[60px] text-sm border-b border-gray-100-[1px] border-gray-100 w-full dark:border-darkModeBorder">
        <p className="font-bold uppercase">Contacts</p>
        <button
          className="flex items-center gap-3 h-full"
          onClick={() => setShowContactForm(true)}
        >
          <BsFillPlusCircleFill size={20} />
          <p>Add contact</p>
          <IoChevronDown size={20} className="" />
        </button>
      </div>
      <div className="w-full ">
        <ContactsCard {...props} showOpt={false} />
      </div>
    </div>
  );
}

// import { PhoneNumberValidation ,UsPhoneNumberValidation} from "../../utils/validate/NumberValidation";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import PropTypes from "prop-types";
// import { DirectoryDetails } from "../../services/directory/DirectoryDetails";
// import { getUserId } from "../../routes/ProtectedRoutes";
// import { IoClose } from "react-icons/io5";
// import { useLocation } from "react-router-dom";
// EditBusinessView.propTypes = {
//   onClose: PropTypes.func,
//   formik: PropTypes.object.any,
//   data: PropTypes.any,
//   editContact: PropTypes.object,
//   editIndex: PropTypes.any,
//   pageType: PropTypes.any,
//   onCloseHandleSubmit: PropTypes.any,
// };

// export default function EditBusinessView(props) {
//   const {
//     onClose,
//     formik,
//     data,
//     editContact,
//     editIndex,
//     pageType,
//     onCloseHandleSubmit,
//   } = props;