export const PdfIcon = () => [
  <svg
    height="50px"
    width="50px"
    version="1.1"
    id="Layer_1"
    key={"pdfIcon"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 309.267 309.267"
  >
    <g>
      <path
        style={{ fill: "#E2574C" }}
        d="M38.658,0h164.23l87.049,86.711v203.227c0,10.679-8.659,19.329-19.329,19.329H38.658
                      c-10.67,0-19.329-8.65-19.329-19.329V19.329C19.329,8.65,27.989,0,38.658,0z"
      />
      <path
        style={{ fill: "#B53629" }}
        d="M289.658,86.981h-67.372c-10.67,0-19.329-8.659-19.329-19.329V0.193L289.658,86.981z"
      />
      <path
        style={{ fill: "#FFFFFF" }}
        d="M217.434,146.544c3.238,0,4.823-2.822,4.823-5.557c0-2.832-1.653-5.567-4.823-5.567h-18.44
                      c-3.605,0-5.615,2.986-5.615,6.282v45.317c0,4.04,2.3,6.282,5.412,6.282c3.093,0,5.403-2.242,5.403-6.282v-12.438h11.153
                      c3.46,0,5.19-2.832,5.19-5.644c0-2.754-1.73-5.49-5.19-5.49h-11.153v-16.903C204.194,146.544,217.434,146.544,217.434,146.544z
                       M155.107,135.42h-13.492c-3.663,0-6.263,2.513-6.263,6.243v45.395c0,4.629,3.74,6.079,6.417,6.079h14.159
                      c16.758,0,27.824-11.027,27.824-28.047C183.743,147.095,173.325,135.42,155.107,135.42z M155.755,181.946h-8.225v-35.334h7.413
                      c11.221,0,16.101,7.529,16.101,17.918C171.044,174.253,166.25,181.946,155.755,181.946z M106.33,135.42H92.964
                      c-3.779,0-5.886,2.493-5.886,6.282v45.317c0,4.04,2.416,6.282,5.663,6.282s5.663-2.242,5.663-6.282v-13.231h8.379
                      c10.341,0,18.875-7.326,18.875-19.107C125.659,143.152,117.425,135.42,106.33,135.42z M106.108,163.158h-7.703v-17.097h7.703
                      c4.755,0,7.78,3.711,7.78,8.553C113.878,159.447,110.863,163.158,106.108,163.158z"
      />
    </g>
  </svg>,
];
export const ticketIcon = () => [
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    key={"ticketIcon"}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Icons/Ticket"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M13.03125,4 C11.7392578,4 10.671387,4.97109375 10.5285643,6.2 L8.53125,6.2 C7.14257812,6.2 6,7.3171875 6,8.675 L6,23.525 C6,24.8828125 7.14257812,26 8.53125,26 L21.46875,26 C22.8574219,26 24,24.8828125 24,23.525 L24,8.675 C24,7.3171875 22.8574219,6.2 21.46875,6.2 L19.4714357,6.2 C19.328613,4.97109375 18.2607422,4 16.96875,4 L13.03125,4 Z M13.03125,5.65 L16.96875,5.65 C17.4455565,5.65 17.8125,6.0087892 17.8125,6.475 C17.8125,6.9412108 17.4455565,7.3 16.96875,7.3 L13.03125,7.3 C12.5544435,7.3 12.1875,6.9412108 12.1875,6.475 C12.1875,6.0087892 12.5544435,5.65 13.03125,5.65 Z M8.53125,7.85 L10.9328612,7.85 C11.3898924,8.51171875 12.1611328,8.95 13.03125,8.95 L16.96875,8.95 C17.8388672,8.95 18.6101076,8.51171875 19.0671388,7.85 L21.46875,7.85 C21.9455565,7.85 22.3125,8.2087892 22.3125,8.675 L22.3125,23.525 C22.3125,23.9912108 21.9455565,24.35 21.46875,24.35 L8.53125,24.35 C8.0544435,24.35 7.6875,23.9912108 7.6875,23.525 L7.6875,8.675 C7.6875,8.2087892 8.0544435,7.85 8.53125,7.85 Z"
        id="Combined-Shape"
        fill="#140F26"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>,
];
export const DocIcon = () => [
  <svg
    width="40px"
    height="50px"
    viewBox="0 0 40 50"
    version="1.1"
    key="DocIcon"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>File-Icon@1x</title>
    <g
      id="💎-Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Cells/Files-&amp;-Images/Doc-(Rounded)"
        transform="translate(-15, -15)"
      >
        <g id="File-Icon" transform="translate(15, 15)">
          <path
            d="M40,44 C40,47.3137085 37.3137085,50 34,50 L6,50 C2.6862915,50 2.02906125e-16,47.3137085 0,44 L0,6 C-4.05812251e-16,2.6862915 2.6862915,4.05812251e-16 6,0 L26,0 L40,14 L40,44 Z"
            id="Combined-Shape"
            fill="#4C5FEF"
          ></path>
          <text
            id="Docx"
            font-family="Montserrat-Bold, Montserrat"
            font-size="9"
            font-weight="bold"
            fill="#FFFFFF"
          >
            <tspan x="7.4985" y="41">
              Docx
            </tspan>
          </text>
          <path
            d="M26,0 L40,14 L32,14 C28.6862915,14 26,11.3137085 26,8 L26,0 L26,0 Z"
            id="Rectangle"
            fill="#3E4CBF"
          ></path>
        </g>
      </g>
    </g>
  </svg>,
];
