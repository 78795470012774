import StatusView from "./views/StatusView";
import TranscriptView from "./views/TranscriptView";
import BusinessView from "./views/BusinessView";
import NewNotesView from "../business-profile/views/NewNotesView";
import IdentitiesView from "./views/IdentitiesView";
import ActivityView from "./views/ActivityView";
import FilesView from "./views/FilesView";
import NewTicketCall from "./views/NewTicketCall";
import { useEffect, useState } from "react";
import CallEnded from "./views/CallEnded";
import DetailsView from "./views/DetailsView";
import { useSidebarVisibility } from "../../context/SidebarVisibilityContext";
import { CallDetailsService } from "../../services/calls/CallDetailsService";
import { AiChatApi } from "../../services/chat/AiChat";
import ChatContainer from "../aichat/ChatContainer";
export default function TicketsNav(props) {
  const [selectedNote, setSelectedNote] = useState(null);
  const [editNote, setEditNote] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const { isSidebarVisible } = useSidebarVisibility();
  const { clearDetailState, clearAiChatState, clearState } = AiChatApi(
    (state) => state
  );
  const { clearTranscriptOpt, clearTranscriptsState } = CallDetailsService(
    (state) => state
  );
  const tabClass = (tabName) => {
    return `cursor-pointer pb-2 mb-[-18px] ${
      activeTab === tabName
        ? "border-b border-gray-100-[3px] border-ipurple text-ipurple"
        : ""
    }`;
  };

  let {
    activeTab,
    changeTab,
    getDataApi,
    setActiveTab,
    businessId,
    singleTicketData,
  } = props || {};

  const renderView = () => {
    switch (activeTab) {
      case "Status":
        return <StatusView getDataApi={getDataApi} />;
      case "Business":
        return <BusinessView setActiveTab={setActiveTab} />;
      case "Transcript":
        return <TranscriptView businessData={singleTicketData?.business} />;
      case "Notes":
        return (
          <NewNotesView
            business_id={businessId}
            // key={"ticket-tab"}
            setSelectedNote={setSelectedNote}
            selectedNote={selectedNote}
            editNote={editNote}
            setEditNote={setEditNote}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            isEditorOpen={isEditorOpen}
            setIsEditorOpen={setIsEditorOpen}
          />
        );
      case "Files":
        return <FilesView />;
      case "Identities":
        return <IdentitiesView />;
      case "Activity":
        return <ActivityView />;
      case "Details":
        return <DetailsView />;
      case "NewTicketCall":
        return <NewTicketCall />;
      case "CallEnded":
        return <CallEnded />;
      case "AI":
        return (
          <div className="relative w-full mt-5 ">
            <ChatContainer businessIdAiChat={businessId} />
          </div>
        );

      default:
        return <DetailsView />;
    }
  };
  useEffect(() => {
    clearTranscriptsState();
    clearTranscriptOpt();
  }, []);

  return (
    <div className=" z-20 w-full !h-[calc(100dvh-295px)] min-h-[calc(100dvh-295px)]">
      <nav
        className={`top-[250px] w-full bg-white  dark:bg-darkModeMain h-[50px] flex gap-4 items-center border-b-[1px] dark:border-darkModeBorder z-10 ${
          isSidebarVisible ? "left-20" : "left-0 px-3"
        }`}
      >
        <ul className="px-4  flex gap-8 text-[14px] font-semibold">
          <li
            className={tabClass("Status")}
            onClick={() => changeTab("Status")}
          >
            Status
          </li>
          <li
            className={tabClass("Transcript")}
            onClick={() => changeTab("Transcript")}
          >
            Transcript
          </li>
          <li
            className={tabClass("AI")}
            onClick={() => {
              clearDetailState();
              clearAiChatState();
              clearState();
              changeTab("AI");
            }}
          >
            AI
          </li>
          <li className={tabClass("Notes")} onClick={() => changeTab("Notes")}>
            Notes
          </li>
        </ul>
      </nav>

      <div className="z-10 w-full flex-none mt-[18px] px-4 bg-lightBg dark:bg-darkModeBG ">
        {renderView()}
      </div>
    </div>
  );
}
