import { useState } from "react";
import Contacts from "../Contacts";
import ReviewPopup from "../../ReviewPopup";
import AssociatedProfilesContainer from "../AssociatedProfiles";
import LocationsHoursContainer from "../LocationsHoursContainer";
import ReviewsContainer from "../ReviewsContainer";
import { DirectoryDetails } from "../../../services/directory/DirectoryDetails";
import Autocomplete from "react-google-autocomplete";
import { MAP_KEYS } from "../../../utils/constants";
export default function ContactsView(props) {
  const { fetchBusinessDetails } = DirectoryDetails((state) => state);
  let { business_id, setActiveTab } = props || {};
  const [showReviewPopup, setShowReviewPopup] = useState(false);

  const handleAddReviewClick = () => {
    setShowReviewPopup(true);
  };

  const handleClosePopup = () => {
    setShowReviewPopup(false);
  };

  return (
    <div className="flex w-full gap-4  ">
      {showReviewPopup && (
        <ReviewPopup
          onClose={handleClosePopup}
          fetchBusinessDetails={fetchBusinessDetails}
        />
      )}

      <div className="flex flex-col w-2/3 space-y-4 overflow-x-hidden ">
        <LocationsHoursContainer businessDetailsData={fetchBusinessDetails} />
        <ReviewsContainer />
      </div>
      <div className="w-1/3 min-w-[450px] flex flex-col space-y-4 ">
        <Contacts />
        <AssociatedProfilesContainer />
      </div>
    </div>
  );
}
