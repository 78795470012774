import PropTypes from "prop-types";
const daysOfWeek = [
  { id: 1, name: "Mon" },
  { id: 2, name: "Tue" },
  { id: 3, name: "Wed" },
  { id: 4, name: "Thu" },
  { id: 5, name: "Fri" },
  { id: 6, name: "Sat" },
  { id: 7, name: "Sun" },
];

AddHoursPopup.propTypes = {
  selectedDays: PropTypes.any,
  setSelectedDays: PropTypes.any,
  toggleDaySelection: PropTypes.func,
};

export default function AddHoursPopup(props) {
  const { selectedDays, toggleDaySelection } = props;

  return (
    <div className="flex flex-col w-full h-full text-sm ">
      <div className="flex flex-col p-4 space-y-2">
        <div className="text-left">
          <label className="">Select Days</label>
        </div>
        <div className="flex items-center font-[600] gap-3 p-2">
          {daysOfWeek.map((day) => (
            <button
              key={day.id}
              className={`w-[40px] text-[11px] h-[40px] rounded-md ${
                selectedDays?.includes(day.name)
                  ? "bg-ipurple text-white"
                  : "bg-gray-100 dark:bg-darkModeBG dark:border-[1px]  border-gray-100  dark:border-darkModeBorder"
              }`}
              onClick={() => toggleDaySelection({ name: day.name, id: day.id })}
            >
              {day.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
