import { useEffect, useRef } from "react";
import { EditIcon, TrashIcon } from "../icons/Icons";
import PropTypes from "prop-types";
Dropdown.propTypes = {
  handleEdit: PropTypes.func,
  handleRemove: PropTypes.func,
  closeDropdown: PropTypes.any,
  hideRemove: PropTypes.any,
  styleclass: PropTypes.any,
  hideEdit: PropTypes.any,
};
export default function Dropdown(props) {
  const {
    handleRemove,
    handleEdit,
    hideRemove,
    hideEdit,
    styleclass,
    activeComponent,
    dropdownIdentify,
  } = props;
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      props?.closeDropdown(); // Call the function to close the dropdown
    }
  };

  useEffect(() => {
    // Attach the listener when the component is mounted
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className={`${styleclass} relative ${
        [
          "Social Security",
          "Phones",
          "Phone",
          "Bio",
          "Address",
          "Addresses",
          "Birthday",
          "Driver's License",
          "License",
          "Email",
          "Emails",
        ].includes(activeComponent)
          ? "right-[-200px] w-[220px]"
          : ""
      } ${
        dropdownIdentify === "item"
          ? "w-[220px] right-[-200px]" // Add specific styles for "item"
          : dropdownIdentify === "vault"
            ? "w-[160px]" // Add specific styles for "vault"
            : ""
      } z-10 text-[13px] divide-y divide-y-1 dark:divide-darkModeBorder flex flex-col justify-center items-center h-[80px] rounded-lg bg-white dark:bg-darkModeMain border border-gray-100 dark:border-darkModeBorder`}
      ref={dropdownRef}
    >
      {!hideEdit && (
        <button
          className="  flex items-center z-50 rounded-t-lg w-full gap-1 px-2 hover:bg-light  h-[40px] dark:hover:bg-darkModeBG"
          onClick={handleEdit}
        >
          <EditIcon className=" text-black  hover:text-ipurple font:semi-bold dark:text-white h-[23px] w-[21px] mt-[5px] mr-[3px]" />

          <p className="font-semibold hover:text-ipurple">
            {activeComponent === undefined && dropdownIdentify == "vault"
              ? "Rename"
              : "Edit"}
          </p>
        </button>
      )}
      {!hideRemove && (
        <button
          className=" flex items-center z-50 rounded-b-lg w-full gap-1 px-2 hover:bg-lightBg h-[40px]  dark:hover:bg-darkModeBG"
          onClick={handleRemove}
        >
          <TrashIcon className=" text-black hover:text-ipurple dark:text-white h-[23px] w-[21px] mt-[5px] mr-[3px]" />

          <p className="font-semibold hover:text-ipurple">
            {" "}
            {activeComponent === undefined && dropdownIdentify == "vault"
              ? "Remove"
              : "Delete"}
          </p>
        </button>
      )}
    </div>
  );
}
